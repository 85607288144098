import axios from "./axios";
import request from "./Request";
import { has, propOr, pathOr, anyPass, isEmpty, isNil, map } from "ramda";
import {
  getFormattedExclusiveStats,
  seasonalStatus,
} from "./ExclusiveSeasonStatus";
import { apiInstance } from "./axios";


async function fetchTeamDetails(teamId) {
  const response = await apiInstance.get(`nfl/team/${teamId}/profile`);
  return response.data.response;
}

async function fetchTeamStats(teamId) {
  const response = await apiInstance.get(`nfl/team/${teamId}/standing?season_type=REG`);
  return response.data.response.record;

}



export function moveGameAndRemoveDate(schedule, sourceDate1, destinationDate1, destinationDate2) {

  const sourceIndex = schedule.findIndex(entry => entry.date === sourceDate1);
  const destinationIndex = schedule.findIndex(entry => entry.date === destinationDate1);
  const destinationIndex2 = schedule.findIndex(entry => entry.date === destinationDate2);


  if (sourceIndex !== -1 && destinationIndex !== -1 && destinationIndex2 !== -1) {
    let gameof15 = schedule[sourceIndex].games
    let gameof16 = schedule[destinationIndex].games
    let gameof17 = schedule[destinationIndex2].games
    let firstGame = gameof16[0];
    gameof15.push(firstGame);
    gameof16.shift();
    let newGame = gameof16.concat(gameof17)
    schedule[destinationIndex].games = [...gameof15];
    schedule[destinationIndex2].games = [...newGame];
    schedule.splice(sourceIndex, 1);
  }

  return schedule;
}


export function getWeekNumber() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
  if ((currentMonth === 12 && currentDate.getDate() >= 5 && currentDate.getDate() < 12) || currentMonth === 1) {
    return 14;
  } else if ((currentMonth === 12 && currentDate.getDate() >= 12 && currentDate.getDate() < 19) || currentMonth === 2) {
    return 15;
  } else if ((currentMonth === 12 && currentDate.getDate() >= 19 && currentDate.getDate() < 26) || currentMonth === 3) {
    return 16;
  } else if ((currentMonth === 12 && currentDate.getDate() >= 26) || currentMonth === 4) {
    return 17;
  } else {
    // Default case, if none of the conditions are met
    return 13; // or any other value to indicate an error or invalid date
  }
}


// export async function processData(games) {
//   const fetchedTeamIds = new Set();

//   const gameDetail = await Promise.all(
//     games.map(async (gamesData) => {
//       const homeTeamId = gamesData.home.id;
//       const awayTeamId = gamesData.away.id;

//       // Check if the team ID has already been fetched
//       if (fetchedTeamIds.has(homeTeamId) && fetchedTeamIds.has(awayTeamId)) {
//         return null; // Skip making API call if both home and away team IDs are already fetched
//       }

//       const [teamDetailsHome, teamStatsHome] = await getTeamDetailsAndStats(homeTeamId, fetchedTeamIds);
//       const [teamDetailsAway, teamStatsAway] = await getTeamDetailsAndStats(awayTeamId, fetchedTeamIds);

//       // Mark team IDs as fetched
//       fetchedTeamIds.add(homeTeamId);
//       fetchedTeamIds.add(awayTeamId);

//       const formattedDate = formatScheduledDateTime(gamesData.scheduled);

//       return {
//         date: formattedDate,
//         time: new Date(gamesData.scheduled).toLocaleTimeString([], {
//           hour: '2-digit',
//           minute: '2-digit',
//           hour12: true,
//         }),
//         homeTeam: teamDetailsHome.name,
//         homeTeamId,
//         homeTeamLogo: teamDetailsHome ? teamDetailsHome.logo_url : null,
//         homeTeamWinLoss: teamStatsHome ? `${teamStatsHome.wins}-${teamStatsHome.losses}` : null,
//         awayTeam: teamDetailsAway.name,
//         awayTeamId,
//         awayTeamLogo: teamDetailsAway ? teamDetailsAway.logo_url : null,
//         awayTeamWinLoss: teamStatsAway ? `${teamStatsAway.wins}-${teamStatsAway.losses}` : null,
//         venue: `${gamesData.venue.name} in ${gamesData.venue.city}, ${gamesData.venue.state}`,
//         broadcast: gamesData.broadcast.network,
//       };
//     })
//   );

//   // Filter out null values (skipped API calls)
//   const filteredGameDetailResults = gameDetail.filter((result) => result !== null);

//   const groupedGames = filteredGameDetailResults.reduce((grouped, game) => {
//     const key = game.date;
//     if (!grouped[key]) {
//       grouped[key] = [];
//     }
//     grouped[key].push(game);
//     return grouped;
//   }, {});

//   const groupedGamesArray = Object.keys(groupedGames).map((key) => ({
//     date: key,
//     games: groupedGames[key],
//   }));

//   console.log(groupedGamesArray)
//   return groupedGamesArray;
// }

async function getTeamDetailsAndStats(teamId, fetchedTeamIds) {
  if (fetchedTeamIds.has(teamId)) {
    return [null, null];
  }
  const [teamDetails, teamStats] = await Promise.all([fetchTeamDetails(teamId), fetchTeamStats(teamId)]);
  return [teamDetails, teamStats];
}



//old
export async function processData(games) {
  const fetchedTeamIds = new Set();

  const gameDetail = games.map(async (gamesData) => {
    const homeTeamId = gamesData.home.id;
    const awayTeamId = gamesData.away.id;

    // Check if the team ID has already been fetched
    if (fetchedTeamIds.has(homeTeamId) && fetchedTeamIds.has(awayTeamId)) {
      return null; // Skip making API call if both home and away team IDs are already fetched
    }

    const teamDetailsHome = fetchedTeamIds.has(homeTeamId)
      ? null
      : await fetchTeamDetails(homeTeamId);

    const teamDetailsAway = fetchedTeamIds.has(awayTeamId)
      ? null
      : await fetchTeamDetails(awayTeamId);

    // Mark team IDs as fetched
    fetchedTeamIds.add(homeTeamId);
    fetchedTeamIds.add(awayTeamId);

    return {
      date: gamesData.scheduled,
      time: new Date(gamesData.scheduled).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
      homeTeam: teamDetailsHome.name,
      homeTeamId,
      homeTeamLogo: teamDetailsHome ? teamDetailsHome.logo_url : null,
      homeTeamWinLoss: teamDetailsHome ? teamDetailsHome.winLoss : null,
      awayTeam: teamDetailsAway.name,
      awayTeamId,
      awayTeamLogo: teamDetailsAway ? teamDetailsAway.logo_url : null,
      awayTeamWinLoss: teamDetailsAway ? teamDetailsAway.winLoss : null,
      venue: `${gamesData.venue.name} in ${gamesData.venue.city}, ${gamesData.venue.state}`,
      broadcast: gamesData.broadcast.network,
    };
  });

  const gameDetailResults = await Promise.all(gameDetail);

  // Filter out null values (skipped API calls)
  const filteredGameDetailResults = gameDetailResults.filter((result) => result !== null);

  // Sort the games based on the date (without time)
  filteredGameDetailResults.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  const groupedGames = filteredGameDetailResults.reduce((grouped, game) => {
    const key = game.date.split('T')[0]; // Use only the date part without the time
    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(game);
    return grouped;
  }, {});

  const groupedGamesArray = Object.keys(groupedGames).map((key) => ({
    date: formatScheduledDateTime(key), // Use the date as is without formatting
    games: groupedGames[key],
  }));


  return groupedGamesArray;
}

export async function FormatUpcomingGames(games) {

  const gameDetail = games?.map(async (gamesData) => {
    const homeTeamId = gamesData.home.id;
    const awayTeamId = gamesData.away.id;
    return {
      date: gamesData.scheduled,
      time: new Date(gamesData.scheduled).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
      homeTeam: gamesData.home.team_name,
      homeTeamId,
      homeTeamLogo: gamesData.home.logo,
      awayTeam: gamesData.away.team_name,
      awayTeamId,
      awayTeamLogo: gamesData.away.logo,
      venue: `${gamesData.venue.name} in ${gamesData.venue.city}, ${gamesData.venue.state}`,
      broadcast: gamesData.broadcast.network,
    };
  });
  console.log("gameDetail", gameDetail)

  if (gameDetail !== undefined) {
    const gameDetailResults = await Promise.all(gameDetail);
    console.log("gameDetailResults", gameDetailResults)
    // Filter out null values (skipped API calls)
    const filteredGameDetailResults = gameDetailResults.filter((result) => result !== null);

    // Sort the games based on the date (without time)
    filteredGameDetailResults.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    const groupedGames = filteredGameDetailResults.reduce((grouped, game) => {
      const key = game.date.split('T')[0]; // Use only the date part without the time
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(game);
      return grouped;
    }, {});

    const groupedGamesArray = Object.keys(groupedGames).map((key) => ({
      date: formatScheduledDateTime(key), // Use the date as is without formatting
      games: groupedGames[key],
    }));

    console.log("groupedGamesArray", groupedGamesArray)
    return groupedGamesArray;
  }
}

// Example usage

export const GetLogin = async (data) => {
  try {
    const response = await axios.post(request.LOGIN, data);
    return response.data
    // if (response.data.email_varify === "1") {
    //   if (response.data.Authorization) {
    //     localStorage.setItem("tokens", response.data.Authorization);
    //     localStorage.setItem("user_id", response.data.user_id);
    //     localStorage.setItem("user_name", response.data.username);
    //     localStorage.setItem("userDetail", JSON.stringify(response.data));
    //     // sessionStorage.setItem("tokens", response.data.Authorization);
    //     // sessionStorage.setItem("user_id", response.data.user_id);
    //     // sessionStorage.setItem("user_name", response.data.username);
    //     //setuserName(response.data.username);

    //     localStorage.setItem("islogin", true);
    //     localStorage.setItem("islogged", true);
    //     //sessionStorage.setItem('userDetail',JSON.stringify(response.data))
    //     //sessionStorage.setItem("islogin", true);
    //     //sessionStorage.setItem("islogged", true);
    //     dispatch({ type: "LOGIN", payload: true });
    //     dispatch({ type: "TOKEN", payload: response.data.Authorization });
    //     //setisLogin(true);

    //     setTimeout(() => {
    //       //getUserSettings(dispatch);
    //       localStorage.setItem("category_type", "nfl");
    //       // getUser();
    //       // userTeam();
    //       //history.push("/");
    //     }, 1000);
    //   }
    // } else {
    //   errorToast("Please Verify Your Account");
    // }
  } catch (err) {
    // if (!err.respsonse) {
    //   errorToast(err.response.data.message);
    // }
  }
}

function formatScheduledDateTime(scheduledDateTime) {
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  const formattedDate = new Date(scheduledDateTime).toLocaleDateString('en-US', options);

  // Add the ordinal suffix to the day
  const dayWithSuffix = addOrdinalSuffix(new Date(scheduledDateTime).getDate());

  return formattedDate.replace(/\d+/, dayWithSuffix);
}

function addOrdinalSuffix(number) {
  if (number >= 11 && number <= 13) {
    return number + 'TH';
  }

  const lastDigit = number % 10;

  switch (lastDigit) {
    case 1:
      return number + 'ST';
    case 2:
      return number + 'ND';
    case 3:
      return number + 'RD';
    default:
      return number + 'TH';
  }
}

// export const FormatUpcomingGames = (games) => {

//   console.log("FormatUpcomingGames", games);

//   games.map((data) => {
//     console.log('data', data)
//   })



//   const gameDetail = games.map(gamesData => ({
//     date: gamesData.scheduled,
//     time: new Date(gamesData.scheduled).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
//     homeTeam: gamesData.home.name,
//     homeTeamId: gamesData.home.id,
//     awayTeam: gamesData.away.name,
//     awayTeamId: gamesData.away.id,
//     venue: `${gamesData.venue.name} in ${gamesData.venue.city}, ${gamesData.venue.state}`,
//     broadcast: gamesData.broadcast.network,
//   }))

//   console.log('gameDetail', gameDetail)

//   // Group the games based on date and time
//   const groupedGames = gameDetail.reduce((grouped, game) => {
//     const key = `${game.date}`;
//     if (!grouped[key]) {
//       grouped[key] = [];
//     }
//     grouped[key].push(game);
//     return grouped;
//   }, {});

//   // Convert the grouped games into an array
//   const groupedGamesArray = Object.keys(groupedGames).map(key => ({
//     dateTime: formatScheduledDateTime(key),
//     games: groupedGames[key],
//   }));

//   console.log("groupedGamesArray", groupedGamesArray)
//   //return groupedGamesArray;

// }






// For local Company Account 
// export const firebaseConfig = {
//   // Your Firebase config here
//   apiKey: "AIzaSyB5vxQtbmEHrdCYnSECktQJqpeD0jwglUU",
//   authDomain: "fanalyze-368205.firebaseapp.com",
//   projectId: "fanalyze-368205",
//   storageBucket: "fanalyze-368205.appspot.com",
//   messagingSenderId: "364456177633",
//   appId: "1:364456177633:web:0feda760cbfbbfdf916ca0",
//   measurementId: "G-TVJVPMKHZY"
// };


// Clients Account 
export const firebaseConfig = {
  apiKey: "AIzaSyClZtr4LX3V5C3I8Ox1MywcyOJQlDK6dOk",
  authDomain: "fanalyze-website.firebaseapp.com",
  projectId: "fanalyze-website",
  storageBucket: "fanalyze-website.appspot.com",
  messagingSenderId: "499679730185",
  appId: "1:499679730185:web:fa85a604d558f9b7a476d8",
  measurementId: "G-DF2LFNMDDG"
}

const hitters = ["3B", "CF", "SS", "2B", "C", "1B", "RF", "LF", "DH", "OF"];
const pitchers = ["P", "SP", "RP"];
const qb = ["QB"];
const rb = ["RB"];
const wr = ["WR"];
const te = ["TE"];
const d = ["D"];
const k = ["K"];

const getSettingFullName = new Map([
  ["AR", "air_yards"],
  ["APT", "avg_pocket_time"],
  ["H", "hurries"],
  ["K", "knockdowns"],
  ["OTH", "on_target_throws"],
  ["RAT", "redzone_attempts"],

  ["A", "attempts"],
  ["B", "blocked"],
  ["L", "longest"],
  ["PCT", "Extra Point Percentage"],

  //receiving Exclusive Stats
  ["AY", "air_yards"],
  ["BT", "broken_tackles"],
  ["DP", "dropped_passes"],
  ["T", "targets"],
  ["RT", "redzone_targets"],
  ["YAC", "yards_after_catch"],
  ["YACT", "yards_after_contact"],

  //defense Exclusive stats
  ["BP", "batted_passes"],
  ["H", "hurries"],
  ["K", "knockdowns"],
  ["QBH", "qb_hits"],
  ["S", "safeties"],

  //rushing Exclusive stats
  ["YAC", "yards_after_catch"],
  ["YACO", "yards_after_contact"],
  ["BT", "broken_tackles"],
  ["RZA", "redzone_targets"],
  ["S", "Scrambles"],

  //Hitters Exclusive Stats
  ["BABIP", "BABIP"],
  ["ISO", "ISO"],
  ["BB", "BB%"],
  ["LD", "LD%"],
  ["FB", "FB%"],
  ["GB", "GB%"],
  ["HR/FB", "HR/FB"],
  ["GBFB", "GBFB"],
  ["K%", "K%"],

  //Pitchers Exclusive Stats
  ["K9", "K/9"],
  ["KBB", "K/BB"],
  ["IRA", "IRA"],
  ["BQR", "BQR"],
  ["BQRS", "BQR-S"],
  ["PO", "PO"],
  ["PIP", "P/IP"],
  ["HR9", "HR/9"],
  ["HR", "H/9"],

  // ========= NBA =========
  ["EFF_EX", "EFF_EX"],
  ["TSP_EX", "TSP_EX"],
  ["FTR_EX", "FTR_EX"],
  ["EFP_EX", "EFP_EX"],
  ["FBP_EX", "FBP_EX"],
  ["PPP_EX", "PPP_EX"],
  ["SCP_EX", "SCP_EX"],
]);

const getPlayerStatus = new Map([
  ["A", "Active"],
  ["ACT", "Active"],
  ["BRV", "Bereavement List"],
  ["D7", "7 Day Disabled List"],
  ["D10", "10 Day Disabled List"],
  ["D60", "60 Day Disabled List"],
  ["DFA", "Designated For Assignment"],
  ["FA", "Free Agent"],
  ["FME", "Family Medical Emergency"],
  ["LV", "Paid Leave"],
  ["MIN", "Minors"],
  ["IR", "Injured Reserve"],
  ["D-LEAGUE", "D-League"],
  ["NWT", "Not With Team"],
  ["FA", "Free Agent"],
  ["NRI", "Non-Roster Invite"],
  ["PL", "Paternity Leave"],
  ["RST", "Restricted"],
  ["RET", "Retired"],
  ["SUS", "Suspended"],
  ["UDP", "Unsigned Draft Pick"],
  ["WV", "Waivers"],
  ["A01", "Active"],
  ["A02", "Active/PUP"],
  ["A03", "Active/NFIN"],
  ["A04", "Active/Left Sq."],
  ["A05", "Active/Suspend"],
  ["A06", "Active/NFIL"],
  ["D02", "Deceased"],
  ["E01", "Ex/Left Squad"],
  ["E02", "Ex/Comm. Perm."],
  ["E08", "Ex/LS; Not 90"],
  ["E14", "Ex/Internat"],
  ["E17", "Ex/CP; No 90"],
  ["E18", "Ex/COVID-19"],
  ["F01", "Free Agent"],
  ["F02", "FA-Pending Susp"],
  ["F09", "FA (From PS)"],
  ["F12", "FA Susp-1 Yr"],
  ["F13", "FA Susp <1Yr "],
  ["I01", "Inactive"],
  ["I05", "Inact/Suspended"],
  ["P01", "Practice Squad"],
  ["P02", "Prac Sq.; Inj"],
  ["P03", "Int'l PSquad"],
  ["P04", "PS; Com Susp"],
  ["P06", "PS; Exc"],
  ["P07", "PS; Vet"],
  ["P09", "PS; COVID19"],
  ["P10", "PS/Pro"],
  ["P11", "PS/Exc-Pro"],
  ["P12", "PS/Vet Pro"],
  ["R01", "R/Injured"],
  ["R02", "R/Retired"],
  ["R03", "R/DNR"],
  ["R04", "R/PUP"],
  ["R05", "R/NFIN"],
  ["R06", "R/Left Squad"],
  ["R08", "R/Military"],
  ["R09", "R/Drft; Unsign"],
  ["R10", "R/Sup Drft; Uns"],
  ["R15", "R/Cont. Expired"],
  ["R16", "R/1st Ref. Rts"],
  ["R18", "R/1st Ref Rts E"],
  ["R19", "R/Acq. Via 1st"],
  ["R20", "R/VFA"],
  ["R22", "R/No Offer Shee"],
  ["R23", "Reserve/Future"],
  ["R27", "R/NFIL"],
  ["R30", "R/Com Sus-1 Yr"],
  ["R33", "R/Club Susp."],
  ["R34", "R/I-W/I; Not 90"],
  ["R36", "R/I; NOT 90"],
  ["R37", "R/PUP-W/FP No90"],
  ["R38", "R/NFIN-W/FP No"],
  ["R39", "R/NFIL-W/FP No"],
  ["R40", "R/Susp < 1Yr"],
  ["R41", "R/PUP; Not 90"],
  ["R42", "R/NFIN; Not 90"],
  ["R43", "R/NFIL; Not 90"],
  ["R46", "R/NFI-W/NFINo90"],
  ["R47", "R/NFIN; DFR"],
  ["R48", "R/Injured; DFR"],
  ["R49", "R/NFIL; DFR"],
  ["R50", "R/Transition"],
  ["R51", "R/Franchise"],
  ["R52", "R/Exc. Rights"],
  ["R53", "R/Exc; Not 90"],
  ["R54", "R/1stRef; No 90"],
  ["R55", "R/Fran; Not 90"],
  ["R56", "R/Trans; Not 90"],
  ["R57", "R/DrUn; Not 90"],
  ["R58", "R/SpDrUn; Not90"],
  ["R59", "R/COVID-19"],
  ["R62", "R/OptOut"],
  ["U01", "R/UFA"],
  ["U02", "UFA; Re-sign"],
  ["U03", "R/UFA; ROFR"],
  ["U04", "UFA-Susp"],
  ["U05", "R/UFAROFR; No90"],
  ["W01", "Waivers/Rec; Ac"],
  ["W03", "Waivers/No Rec."],
  ["W04", "Waivers/Injured"],
  ["W05", "Waivers/Pro Rec"],
  ["W06", "W/I; Prior 53"],
  ["W07", "W/FP; Prior 53"],
]);
const SETTINGS = {
  player: {
    exclusive_stats: {
      hitter: {
        BABIP: true,
        ISO: true,
        "BB%": true,
        "LD%": true,
        "FB%": true,
        "GB%": true,
        "HR/FB": true,
        GBFB: true,
        "K%": true,
      },
      pitcher: {
        "K/9": true,
        "K/BB": true,
        "H/9": true,
        IRA: true,
        BQR: true,
        "BQR-S": true,
        PO: true,
        "P/IP": true,
        "HR/9": true,
        "BB/9": true,
      },
      passing: {
        air_yards: true,
        avg_pocket_time: true,
        hurries: true,
        knockdowns: true,
        on_target_throws: true,
        redzone_attempts: true,
        dropped_passes: true,
      },
      rushing: {
        yards_after_catch: true,
        yards_after_contact: true,
        broken_tackles: true,
        redzone_targets: true,
        Scrambles: true,
      },
      receiving: {
        air_yards: true,
        broken_tackles: true,
        dropped_passes: true,
        targets: true,
        redzone_targets: true,
        yards_after_catch: true,
        yards_after_contact: true,
      },
      defense: {
        batted_passes: true,
        hurries: true,
        knockdowns: true,
        qb_hits: true,
        safeties: true,
      },
      field_goals: {
        attempts: true,
        blocked: true,
        longest: true,
      },
      nba: {
        EFF_EX: true,
        TSP_EX: true,
        FTR_EX: true,
        EFP_EX: true,
        FBP_EX: true,
        PPP_EX: true,
        SCP_EX: true,
      },
    },
    season_stats: {
      hitter: {
        G: true,
        AB: true,
        R: true,
        H: true,
        HR: true,
        twoB: true,
        threeB: true,
        RBI: true,
        BB: true,
        SO: true,
        SB: true,
        CS: true,
        AVG: true,
        OBP: true,
        SLG: true,
        OPS: true,
      },
      pitcher: {
        G: true,
        W: true,
        L: true,
        ERA: true,
        SV: true,
        SVO: true,
        IP: true,
        H: true,
        R: true,
        ER: true,
        HR: true,
        HB: true,
        BB: true,
        IBB: true,
        SO: true,
        WHIP: true,
      },
      passing: {
        COMP: true,
        PATT: true,
        PYDS: true,
        PAVG: true,
        PTD: true,
        INT: true,
        SCK: true,
        QBR: true,
        RATT: true,
        RYDS: true,
        RAVG: true,
        RTD: true,
        FUM: true,
        LOST: true,
      },
      rushing: {
        RATT: true,
        RYDS: true,
        RAVG: true,
        LNG: true,
        RTD: true,
        REC: true,
        REYDS: true,
        REAVG: true,
        RETD: true,
      },
      receiving: {
        REC: true,
        YDS: true,
        REAVG: true,
        TD: true,
        LNG: true,
        TGT: true,
      },
      defense: {
        AST: true,
        TOT: true,
        SACK: true,
        SCKYADS: true,
        TFL: true,
        PD: true,
        INT: true,
        FR: true,
      },
      field_goals: {
        ATT: true,
        M: true,
        MI: true,
        PCT: true,
        LNG: true,
        EM: true,
        EPCT: true,
      },
      nba: {
        GP: true,
        MIN: true,
        PTS: true,
        REB: true,
        ASTT: true,
        TOV: true,
        STL: true,
        BLK: true,
        FGM: true,
        FGA: true,
        FGP: true,
        THPM: true,
        THPA: true,
        THPP: true,
        FTA: true,
        FTP: true,
        PF: true,
        DDT: true,
        TDTH: true,
      },
    },
    next_game: {
      show_next_game: true,
    },
    player_trends: {
      show_player_trends: true,
    },
  },
  team: {
    exclusive_stats: {
      hitter: {
        BABIP: true,
        ISO: true,
        "BB%": true,
        "LD%": true,
        "FB%": true,
        "GB%": true,
        "HR/FB": true,
        GBFB: true,
        "K%": true,
      },
      pitcher: {
        "K/9": true,
        "K/BB": true,
        "H/9": true,
        IRA: true,
        BQR: true,
        "BQR-S": true,
        PO: true,
        "P/IP": true,
        "HR/9": true,
        "BB/9": true,
      },
      passing: {
        air_yards: true,
        avg_pocket_time: true,
        hurries: true,
        knockdowns: true,
        on_target_throws: true,
        redzone_attempts: true,
        dropped_passes: true,
      },
      rushing: {
        yards_after_catch: true,
        yards_after_contact: true,
        broken_tackles: true,
        redzone_targets: true,
        Scrambles: true,
      },
      receiving: {
        air_yards: true,
        broken_tackles: true,
        dropped_passes: true,
        targets: true,
        redzone_targets: true,
        yards_after_catch: true,
        yards_after_contact: true,
      },
      defense: {
        batted_passes: true,
        hurries: true,
        knockdowns: true,
        qb_hits: true,
        safeties: true,
      },
      field_goals: {
        attempts: true,
        blocked: true,
        longest: true,
      },
      nba: {
        EFF_EX: true,
        TSP_EX: true,
        FTR_EX: true,
        EFP_EX: true,
        FBP_EX: true,
        PPP_EX: true,
        SCP_EX: true,
      },
    },
    season_stats: {
      hitter: {
        G: true,
        AB: true,
        R: true,
        H: true,
        HR: true,
        twoB: true,
        threeB: true,
        RBI: true,
        BB: true,
        SO: true,
        SB: true,
        CS: true,
        AVG: true,
        OBP: true,
        SLG: true,
        OPS: true,
      },
      pitcher: {
        G: true,
        W: true,
        L: true,
        ERA: true,
        SV: true,
        SVO: true,
        IP: true,
        H: true,
        R: true,
        ER: true,
        HR: true,
        HB: true,
        BB: true,
        IBB: true,
        SO: true,
        WHIP: true,
      },
      passing: {
        COMP: true,
        PATT: true,
        PYDS: true,
        PAVG: true,
        PTD: true,
        INT: true,
        SCK: true,
        QBR: true,
      },
      rushing: {
        RATT: true,
        RYDS: true,
        RAVG: true,
        LNG: true,
        RTD: true,
      },
      receiving: {
        REC: true,
        YDS: true,
        REAVG: true,
        TD: true,
        LNG: true,
        TGT: true,
      },
      defense: {
        AST: true,
        TOT: true,
        SACK: true,
        SCKYADS: true,
        TFL: true,
        PD: true,
        INT: true,
        FR: true,
      },
      field_goals: {
        ATT: true,
        M: true,
        MI: true,
        PCT: true,
        LNG: true,
        EM: true,
        EPCT: true,
      },
      nba: {
        GP: true,
        PTS: true,
        REB: true,
        ASTT: true,
        TOV: true,
        STL: true,
        BLK: true,
        FGM: true,
        FGA: true,
        FGP: true,
        THPM: true,
        THPA: true,
        THPP: true,
        FTA: true,
        FTP: true,
        PF: true,
      },
    },
    next_game: {
      show_next_game: true,
    },
    player_trends: {
      show_player_trends: true,
    },
  },
};

export const NON_USER_SETTINGS = {
  player: {
    exclusive_stats: {
      hitter: {
        BABIP: false,
        ISO: false,
        "BB%": false,
        "LD%": false,
        "FB%": false,
        "GB%": false,
        "HR/FB": false,
        GBFB: false,
        "K%": false,
      },
      pitcher: {
        "K/9": false,
        "K/BB": false,
        "H/9": false,
        IRA: false,
        BQR: false,
        "BQR-S": false,
        PO: false,
        "P/IP": false,
        "HR/9": false,
        "BB/9": false,
      },
      passing: {
        air_yards: true,
        avg_pocket_time: true,
        hurries: true,
        knockdowns: true,
        on_target_throws: true,
        redzone_attempts: true,
        dropped_passes: true,
      },
      rushing: {
        yards_after_catch: true,
        yards_after_contact: true,
        broken_tackles: true,
        redzone_targets: true,
        Scrambles: true,
      },
      receiving: {
        air_yards: true,
        broken_tackles: true,
        dropped_passes: true,
        targets: true,
        redzone_targets: true,
        yards_after_catch: true,
        yards_after_contact: true,
      },
      defense: {
        batted_passes: true,
        hurries: true,
        knockdowns: true,
        qb_hits: true,
        safeties: true,
      },
      field_goals: {
        attempts: true,
        blocked: true,
        longest: true,
      },
      nba: {
        EFF_EX: true,
        TSP_EX: true,
        FTR_EX: true,
        EFP_EX: true,
        FBP_EX: true,
        PPP_EX: true,
        SCP_EX: true,
      },
    },
    season_stats: {
      hitter: {
        G: true,
        AB: true,
        R: true,
        H: true,
        HR: true,
        twoB: true,
        threeB: true,
        RBI: true,
        BB: true,
        SO: true,
        SB: true,
        CS: true,
        AVG: true,
        OBP: true,
        SLG: true,
        OPS: true,
      },
      pitcher: {
        G: true,
        W: true,
        L: true,
        ERA: true,
        SV: true,
        SVO: true,
        IP: true,
        H: true,
        R: true,
        ER: true,
        HR: true,
        HB: true,
        BB: true,
        IBB: true,
        SO: true,
        WHIP: true,
      },
      passing: {
        COMP: true,
        PATT: true,
        PYDS: true,
        PAVG: true,
        PTD: true,
        INT: true,
        SCK: true,
        QBR: true,
        RATT: true,
        RYDS: true,
        RAVG: true,
        RTD: true,
        FUM: true,
        LOST: true,
      },
      rushing: {
        RATT: true,
        RYDS: true,
        RAVG: true,
        LNG: true,
        RTD: true,
        REC: true,
        REYDS: true,
        REAVG: true,
        RETD: true,
      },
      receiving: {
        REC: true,
        YDS: true,
        REAVG: true,
        TD: true,
        LNG: true,
        TGT: true,
      },
      defense: {
        AST: true,
        TOT: true,
        SACK: true,
        SCKYADS: true,
        TFL: true,
        PD: true,
        INT: true,
        FR: true,
      },
      field_goals: {
        ATT: true,
        M: true,
        MI: true,
        PCT: true,
        LNG: true,
        EM: true,
        EPCT: true,
      },
      nba: {
        GP: true,
        MIN: true,
        PTS: true,
        REB: true,
        ASTT: true,
        TOV: true,
        STL: true,
        BLK: true,
        FGM: true,
        FGA: true,
        FGP: true,
        THPM: true,
        THPA: true,
        THPP: true,
        FTA: true,
        FTP: true,
        PF: true,
        DDT: true,
        TDTH: true,
      },
    },
    next_game: {
      show_next_game: true,
    },
    player_trends: {
      show_player_trends: true,
    },
  },
  team: {
    exclusive_stats: {
      hitter: {
        BABIP: false,
        ISO: false,
        "BB%": false,
        "LD%": false,
        "FB%": false,
        "GB%": false,
        "HR/FB": false,
        GBFB: false,
        "K%": false,
      },
      pitcher: {
        "K/9": false,
        "K/BB": false,
        "H/9": false,
        IRA: false,
        BQR: false,
        "BQR-S": false,
        PO: false,
        "P/IP": false,
        "HR/9": false,
        "BB/9": false,
      },
      passing: {
        air_yards: true,
        avg_pocket_time: true,
        hurries: true,
        knockdowns: true,
        on_target_throws: true,
        redzone_attempts: true,
        dropped_passes: true,
      },
      rushing: {
        yards_after_catch: true,
        yards_after_contact: true,
        broken_tackles: true,
        redzone_targets: true,
        Scrambles: true,
      },
      receiving: {
        air_yards: true,
        broken_tackles: true,
        dropped_passes: true,
        targets: true,
        redzone_targets: true,
        yards_after_catch: true,
        yards_after_contact: true,
      },
      defense: {
        batted_passes: true,
        hurries: true,
        knockdowns: true,
        qb_hits: true,
        safeties: true,
      },
      field_goals: {
        attempts: true,
        blocked: true,
        longest: true,
      },
      nba: {
        EFF_EX: true,
        TSP_EX: true,
        FTR_EX: true,
        EFP_EX: true,
        FBP_EX: true,
        PPP_EX: true,
        SCP_EX: true,
      },
    },
    season_stats: {
      hitter: {
        G: true,
        AB: true,
        R: true,
        H: true,
        HR: true,
        twoB: true,
        threeB: true,
        RBI: true,
        BB: true,
        SO: true,
        SB: true,
        CS: true,
        AVG: true,
        OBP: true,
        SLG: true,
        OPS: true,
      },
      pitcher: {
        G: true,
        W: true,
        L: true,
        ERA: true,
        SV: true,
        SVO: true,
        IP: true,
        H: true,
        R: true,
        ER: true,
        HR: true,
        HB: true,
        BB: true,
        IBB: true,
        SO: true,
        WHIP: true,
      },
      passing: {
        COMP: true,
        PATT: true,
        PYDS: true,
        PAVG: true,
        PTD: true,
        INT: true,
        SCK: true,
        QBR: true,
      },
      rushing: {
        RATT: true,
        RYDS: true,
        RAVG: true,
        LNG: true,
        RTD: true,
      },
      receiving: {
        REC: true,
        YDS: true,
        REAVG: true,
        TD: true,
        LNG: true,
        TGT: true,
      },
      defense: {
        AST: true,
        TOT: true,
        SACK: true,
        SCKYADS: true,
        TFL: true,
        PD: true,
        INT: true,
        FR: true,
      },
      field_goals: {
        ATT: true,
        M: true,
        MI: true,
        PCT: true,
        LNG: true,
        EM: true,
        EPCT: true,
      },
      nba: {
        GP: true,
        PTS: true,
        REB: true,
        ASTT: true,
        TOV: true,
        STL: true,
        BLK: true,
        FGM: true,
        FGA: true,
        FGP: true,
        THPM: true,
        THPA: true,
        THPP: true,
        FTA: true,
        FTP: true,
        PF: true,
      },
    },
    next_game: {
      show_next_game: true,
    },
    player_trends: {
      show_player_trends: true,
    },
  },
};
let sendheaders = {
  headers: {
    Authorization: "Bearer " + request.Token,
  },
};
export const isEmptyOrNil = anyPass([isEmpty, isNil]);

export const OpenUrls = (url) => {
  window.open(url, "_blank");
};

const calculateAge = (date) => {
  const dob = new Date(date);
  const diffMs = Date.now() - dob.getTime();
  let ageDt = new Date(diffMs);
  return Math.abs(ageDt.getUTCFullYear() - 1970);
};

export const getUserSettings = async (dispatch) => {
  const isLoggedIn = localStorage.getItem("islogin");
  if (isLoggedIn === "false" || isLoggedIn === false || isLoggedIn === null) {
    localStorage.setItem("user_setting", JSON.stringify(NON_USER_SETTINGS));
    dispatch({
      type: "USER_SETTING",
      payload: NON_USER_SETTINGS,
    });
    return;
  }
  const userSettingsResponse = await axios.get(
    request.USER_SETTINGS,
    sendheaders
  );
  if (!isEmptyOrNil(userSettingsResponse.data)) {
    const status = pathOr("", ["data", "status"], userSettingsResponse);
    const settings = pathOr("", ["data", "settings"], userSettingsResponse);
    if (status === "success") {
      if (!isEmptyOrNil(settings)) {
        localStorage.setItem(
          "user_setting",
          JSON.stringify(JSON.parse(settings))
        );
        dispatch({ type: "USER_SETTING", payload: JSON.parse(settings) });
      } else {
        localStorage.setItem("user_setting", JSON.stringify(SETTINGS));
        // localStorage.setItem("user_setting",JSON.stringify(JSON.parse(SETTINGS)))
        dispatch({
          type: "USER_SETTING",
          payload: SETTINGS,
        });
      }
    }
  }
};

export const updateUserSettingsOnBackend = async (dispatch) => {
  const settings = JSON.parse(localStorage.getItem("user_setting"));
  dispatch({ type: "USER_SETTING", payload: settings });
  // axios.get(request.USER_SETTINGS,sendheaders);
  //const userSettingsResponse = await UserApiHandlers.updateUserSettings(JSON.stringify(settings), );
  const userSettingsResponse = await axios.post(
    request.USER_SETTINGS,
    { settings: JSON.stringify(settings) },
    sendheaders
  );
  if (!isEmptyOrNil(userSettingsResponse.data)) {
    const status = pathOr("", ["data", "status"], userSettingsResponse);
    if (status === "success") {
      return {
        message: "Success",
        description: `Settings updated successfully!!`,
      };
    }
  }
};

const sum = (data) => {
  return data.reduce((a, b) => {
    return a + b;
  }, 0);
};

const zipp = (a, b) => {
  return map((key) => {
    return { keys: key, value: [a[key], b[key]] };
  }, Object.keys(a));
};

const zippThree = (a, b, c) => {
  return map((key) => {
    return { keys: key, value: [a[key], b[key], c[key]] };
  }, Object.keys(a));
};

const formatStateData = (searsonPlayerData) => {
  if (searsonPlayerData.length === 2) {
    return {
      Season: zipp(
        pathOr([], ["0", "Season"], searsonPlayerData),
        pathOr([], ["1", "Season"], searsonPlayerData)
      ),
      Exclusive: zipp(
        pathOr([], ["0", "Excslusive"], searsonPlayerData),
        pathOr([], ["1", "Excslusive"], searsonPlayerData)
      ),
    };
  } else {
    return {
      Season: zippThree(
        pathOr([], ["0", "Season"], searsonPlayerData),
        pathOr([], ["1", "Season"], searsonPlayerData),
        pathOr([], ["2", "Season"], searsonPlayerData)
      ),
      Exclusive: zippThree(
        pathOr([], ["0", "Excslusive"], searsonPlayerData),
        pathOr([], ["1", "Excslusive"], searsonPlayerData),
        pathOr([], ["2", "Excslusive"], searsonPlayerData)
      ),
    };
  }
};

const formatStateDataTeamThree = (searsonPlayerData) => {
  const hitting = zippThree(
    pathOr([], ["0", "Season", "hitting"], searsonPlayerData),
    pathOr([], ["1", "Season", "hitting"], searsonPlayerData),
    pathOr([], ["2", "Season", "hitting"], searsonPlayerData)
  );
  const pitching = zippThree(
    pathOr([], ["0", "Season", "pitching"], searsonPlayerData),
    pathOr([], ["1", "Season", "pitching"], searsonPlayerData),
    pathOr([], ["2", "Season", "pitching"], searsonPlayerData)
  );
  const passing = zippThree(
    pathOr([], ["0", "Season", "passing"], searsonPlayerData),
    pathOr([], ["1", "Season", "passing"], searsonPlayerData),
    pathOr([], ["2", "Season", "passing"], searsonPlayerData)
  );
  const rushing = zippThree(
    pathOr([], ["0", "Season", "rushing"], searsonPlayerData),
    pathOr([], ["1", "Season", "rushing"], searsonPlayerData),
    pathOr([], ["2", "Season", "rushing"], searsonPlayerData)
  );
  const receiving = zippThree(
    pathOr([], ["0", "Season", "receiving"], searsonPlayerData),
    pathOr([], ["1", "Season", "receiving"], searsonPlayerData),
    pathOr([], ["2", "Season", "receiving"], searsonPlayerData)
  );
  const defense = zippThree(
    pathOr([], ["0", "Season", "defense"], searsonPlayerData),
    pathOr([], ["1", "Season", "defense"], searsonPlayerData),
    pathOr([], ["2", "Season", "defense"], searsonPlayerData)
  );
  const field_goals = zippThree(
    pathOr([], ["0", "Season", "field_goals"], searsonPlayerData),
    pathOr([], ["1", "Season", "field_goals"], searsonPlayerData),
    pathOr([], ["2", "Season", "field_goals"], searsonPlayerData)
  );
  const nba = zippThree(
    pathOr([], ["0", "Season", "nba"], searsonPlayerData),
    pathOr([], ["1", "Season", "nba"], searsonPlayerData),
    pathOr([], ["2", "Season", "nba"], searsonPlayerData)
  );

  const Ehitting = zippThree(
    pathOr([], ["0", "Excslusive", "hitting"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "hitting"], searsonPlayerData),
    pathOr([], ["2", "Excslusive", "hitting"], searsonPlayerData)
  );
  const Epitching = zippThree(
    pathOr([], ["0", "Excslusive", "pitching"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "pitching"], searsonPlayerData),
    pathOr([], ["2", "Excslusive", "pitching"], searsonPlayerData)
  );
  const Epassing = zippThree(
    pathOr([], ["0", "Excslusive", "passing"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "passing"], searsonPlayerData),
    pathOr([], ["2", "Excslusive", "passing"], searsonPlayerData)
  );
  const Erushing = zippThree(
    pathOr([], ["0", "Excslusive", "rushing"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "rushing"], searsonPlayerData),
    pathOr([], ["2", "Excslusive", "rushing"], searsonPlayerData)
  );
  const Ereceiving = zippThree(
    pathOr([], ["0", "Excslusive", "receiving"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "receiving"], searsonPlayerData),
    pathOr([], ["2", "Excslusive", "receiving"], searsonPlayerData)
  );
  const Edefense = zippThree(
    pathOr([], ["0", "Excslusive", "defense"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "defense"], searsonPlayerData),
    pathOr([], ["2", "Excslusive", "defense"], searsonPlayerData)
  );
  const Efield_goals = zippThree(
    pathOr([], ["0", "Excslusive", "field_goals"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "field_goals"], searsonPlayerData),
    pathOr([], ["2", "Excslusive", "field_goals"], searsonPlayerData)
  );
  const Enba = zippThree(
    pathOr([], ["0", "Excslusive", "nba"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "nba"], searsonPlayerData),
    pathOr([], ["2", "Excslusive", "nba"], searsonPlayerData)
  );

  const Season = {
    hitting: hitting,
    pitching: pitching,
    passing: passing,
    rushing: rushing,
    receiving: receiving,
    defense: defense,
    field_goals: field_goals,
    nba: nba,
  };
  const Exclusive = {
    hitting: Ehitting,
    pitching: Epitching,
    passing: Epassing,
    rushing: Erushing,
    receiving: Ereceiving,
    defense: Edefense,
    field_goals: Efield_goals,
    nba: Enba,
  };
  return { Season, Exclusive };
};

const formatStateDataTeam = (searsonPlayerData) => {
  const hitting = zipp(
    pathOr([], ["0", "Season", "hitting"], searsonPlayerData),
    pathOr([], ["1", "Season", "hitting"], searsonPlayerData)
  );
  const pitching = zipp(
    pathOr([], ["0", "Season", "pitching"], searsonPlayerData),
    pathOr([], ["1", "Season", "pitching"], searsonPlayerData)
  );
  const passing = zipp(
    pathOr([], ["0", "Season", "passing"], searsonPlayerData),
    pathOr([], ["1", "Season", "passing"], searsonPlayerData)
  );
  const rushing = zipp(
    pathOr([], ["0", "Season", "rushing"], searsonPlayerData),
    pathOr([], ["1", "Season", "rushing"], searsonPlayerData)
  );
  const receiving = zipp(
    pathOr([], ["0", "Season", "receiving"], searsonPlayerData),
    pathOr([], ["1", "Season", "receiving"], searsonPlayerData)
  );
  const defense = zipp(
    pathOr([], ["0", "Season", "defense"], searsonPlayerData),
    pathOr([], ["1", "Season", "defense"], searsonPlayerData)
  );
  const field_goals = zipp(
    pathOr([], ["0", "Season", "field_goals"], searsonPlayerData),
    pathOr([], ["1", "Season", "field_goals"], searsonPlayerData)
  );
  const nba = zipp(
    pathOr([], ["0", "Season", "nba"], searsonPlayerData),
    pathOr([], ["1", "Season", "nba"], searsonPlayerData)
  );

  const Ehitting = zipp(
    pathOr([], ["0", "Excslusive", "hitting"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "hitting"], searsonPlayerData)
  );
  const Epitching = zipp(
    pathOr([], ["0", "Excslusive", "pitching"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "pitching"], searsonPlayerData)
  );
  const Epassing = zipp(
    pathOr([], ["0", "Excslusive", "passing"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "passing"], searsonPlayerData)
  );
  const Erushing = zipp(
    pathOr([], ["0", "Excslusive", "rushing"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "rushing"], searsonPlayerData)
  );
  const Ereceiving = zipp(
    pathOr([], ["0", "Excslusive", "receiving"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "receiving"], searsonPlayerData)
  );
  const Edefense = zipp(
    pathOr([], ["0", "Excslusive", "defense"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "defense"], searsonPlayerData)
  );
  const Efield_goals = zipp(
    pathOr([], ["0", "Excslusive", "field_goals"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "field_goals"], searsonPlayerData)
  );
  const Enba = zipp(
    pathOr([], ["0", "Excslusive", "nba"], searsonPlayerData),
    pathOr([], ["1", "Excslusive", "nba"], searsonPlayerData)
  );

  const Season = {
    hitting: hitting,
    pitching: pitching,
    passing: passing,
    rushing: rushing,
    receiving: receiving,
    defense: defense,
    field_goals: field_goals,
    nba: nba,
  };
  const Exclusive = {
    hitting: Ehitting,
    pitching: Epitching,
    passing: Epassing,
    rushing: Erushing,
    receiving: Ereceiving,
    defense: Edefense,
    field_goals: Efield_goals,
    nba: Enba,
  };
  return { Season, Exclusive };
};

export const Pointsdata = async (
  pointadata,
  dispatch,
  type,
  setting,
  selectednflTeamPos
) => {

  let pointData = [];

  if (type === "player") {
    map((profile) => {
      const seasonStats = propOr([], "Season", profile);
      const exclusiveStats = propOr({}, "Excslusive", profile);

      const seasondata = map((daata) => {
        if (
          pathOr(
            false,
            [
              type,
              "season_stats",
              selectednflTeamPos === "hitting"
                ? "hitter"
                : selectednflTeamPos === "pitching"
                  ? "pitcher"
                  : selectednflTeamPos,
              daata,
            ],
            setting
          )
        ) {
          return seasonStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(seasonStats));

      const exclusivdata = map((daata) => {
        if (
          pathOr(
            false,
            [
              type,
              "exclusive_stats",
              selectednflTeamPos,
              getSettingFullName.get(daata),
            ],
            setting
          )
        ) {
          return exclusiveStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(exclusiveStats));

      const seasonStatsPoints = sum(seasondata);
      const exclusiveStatsPoints = sum(exclusivdata);

      const playerTotalPoints =
        Math.round(seasonStatsPoints) + Math.round(exclusiveStatsPoints);
      pointData.push(playerTotalPoints);
    }, pointadata);

    const totalPoint = { PlayerPoint: pointData };

    dispatch({ type: "TOTAL_POINT", payload: totalPoint });
  } else {
    map((profile) => {
      const seasonHittingStats = pathOr({}, ["Season", "hitting"], profile);
      const seasonPitchingStats = pathOr({}, ["Season", "pitching"], profile);
      const seasonPassingStats = pathOr({}, ["Season", "passing"], profile);
      const seasonRushingStats = pathOr({}, ["Season", "rushing"], profile);
      const seasonReceivingStats = pathOr({}, ["Season", "receiving"], profile);
      const seasonDefenseStats = pathOr({}, ["Season", "defense"], profile);
      const seasonFieldgoalsStats = pathOr(
        {},
        ["Season", "field_goals"],
        profile
      );
      const seasonNBAStats = pathOr({}, ["Season", "nba"], profile);

      const seasonHittingStatsdata = map((daata) => {


        if (pathOr(false, [type, "season_stats", "hitter", daata], setting)) {
          return seasonHittingStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(seasonHittingStats));

      const seasonPitchingStatsdata = map((daata) => {
        if (pathOr(false, [type, "season_stats", "pitcher", daata], setting)) {
          return seasonPitchingStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(seasonPitchingStats));

      const seasonPassingStatsdata = map((daata) => {
        if (pathOr(false, [type, "season_stats", "passing", daata], setting)) {
          return seasonPassingStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(seasonPassingStats));

      const seasonRushingStatsdata = map((daata) => {
        if (pathOr(false, [type, "season_stats", "rushing", daata], setting)) {
          return seasonRushingStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(seasonRushingStats));

      const seasonReceivingStatsdata = map((daata) => {
        if (
          pathOr(false, [type, "season_stats", "receiving", daata], setting)
        ) {
          return seasonReceivingStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(seasonReceivingStats));

      const seasonDefenseStatsdata = map((daata) => {
        if (pathOr(false, [type, "season_stats", "defense", daata], setting)) {
          return seasonDefenseStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(seasonDefenseStats));

      const seasonFieldgoalsStatsdata = map((daata) => {
        if (
          pathOr(false, [type, "season_stats", "field_goals", daata], setting)
        ) {
          return seasonFieldgoalsStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(seasonFieldgoalsStats));

      const seasonNBAStatsdata = map((daata) => {
        if (pathOr(false, [type, "season_stats", "nba", daata], setting)) {
          return seasonNBAStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(seasonNBAStats));

      const exclusiveHittingStats = pathOr(
        {},
        ["Excslusive", "hitting"],
        profile
      );
      const exclusivePitchingStats = pathOr(
        {},
        ["Excslusive", "pitching"],
        profile
      );
      const exclusivePassingStats = pathOr(
        {},
        ["Excslusive", "passing"],
        profile
      );
      const exclusiveRushingStats = pathOr(
        {},
        ["Excslusive", "rushing"],
        profile
      );
      const exclusiveReceivingStats = pathOr(
        {},
        ["Excslusive", "receiving"],
        profile
      );
      const exclusiveDefenseStats = pathOr(
        {},
        ["Excslusive", "defense"],
        profile
      );
      const exclusiveFieldgoalsStats = pathOr(
        {},
        ["Excslusive", "field_goals"],
        profile
      );
      const exclusiveNBAStats = pathOr({}, ["Excslusive", "nba"], profile);

      const exclusiveHittingStatsdata = map((daata) => {
        if (
          pathOr(false, [type, "exclusive_stats", "hitter", daata], setting)
        ) {
          return exclusiveHittingStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(exclusiveHittingStats));

      const exclusivePitchingStatsdata = map((daata) => {
        if (
          pathOr(false, [type, "exclusive_stats", "pitcher", daata], setting)
        ) {
          return exclusivePitchingStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(exclusivePitchingStats));

      const exclusivePassingStatsdata = map((daata) => {
        if (
          pathOr(false, [type, "exclusive_stats", "passing", daata], setting)
        ) {
          return exclusivePassingStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(exclusivePassingStats));

      const exclusiveRushingStatsdata = map((daata) => {
        if (
          pathOr(false, [type, "exclusive_stats", "rushing", daata], setting)
        ) {
          return exclusiveRushingStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(exclusiveRushingStats));

      const exclusiveReceivingStatsdata = map((daata) => {
        if (
          pathOr(false, [type, "exclusive_stats", "receiving", daata], setting)
        ) {
          return exclusiveReceivingStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(exclusiveReceivingStats));

      const exclusiveDefenseStatsdata = map((daata) => {
        if (
          pathOr(false, [type, "exclusive_stats", "defense", daata], setting)
        ) {
          return exclusiveDefenseStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(exclusiveDefenseStats));

      const exclusiveFieldgoalsStatsdata = map((daata) => {
        if (
          pathOr(
            false,
            [type, "exclusive_stats", "field_goals", daata],
            setting
          )
        ) {
          return exclusiveFieldgoalsStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(exclusiveFieldgoalsStats));

      const exclusiveNBAStatsdata = map((daata) => {
        if (pathOr(false, [type, "exclusive_stats", "nba", daata], setting)) {
          return exclusiveNBAStats[daata];
        } else {
          return 0;
        }
      }, Object.keys(exclusiveNBAStats));

      const seasonhittingPoints = sum(seasonHittingStatsdata);
      const seasonpitchingPoints = sum(seasonPitchingStatsdata);
      const seasonpassingPoints = sum(seasonPassingStatsdata);
      const seasonrushingPoints = sum(seasonRushingStatsdata);
      const seasonreceivingPoints = sum(seasonReceivingStatsdata);
      const seasondefensePoints = sum(seasonDefenseStatsdata);
      const seasonfieldgoalsPoints = sum(seasonFieldgoalsStatsdata);
      const seasonNBAPoints = sum(seasonNBAStatsdata);

      const exclusivehittingPoints = sum(exclusiveHittingStatsdata);
      const exclusivepitchingPoints = sum(exclusivePitchingStatsdata);
      const exclusivepassingPoints = sum(exclusivePassingStatsdata);
      const exclusiverushingPoints = sum(exclusiveRushingStatsdata);
      const exclusivereceivingPoints = sum(exclusiveReceivingStatsdata);
      const exclusivedefensePoints = sum(exclusiveDefenseStatsdata);
      const exclusivefieldgoalsPoints = sum(exclusiveFieldgoalsStatsdata);
      const exclusivenbaPoints = sum(exclusiveNBAStatsdata);

      const teamHittingPoints =
        Math.round(seasonhittingPoints) + Math.round(exclusivehittingPoints);
      const teamPitchingPoints =
        Math.round(seasonpitchingPoints) + Math.round(exclusivepitchingPoints);
      const teamPassingPoints =
        Math.round(seasonpassingPoints) + Math.round(exclusivepassingPoints);
      const teamRushingPoints =
        Math.round(seasonrushingPoints) + Math.round(exclusiverushingPoints);
      const teamReceivingPoints =
        Math.round(seasonreceivingPoints) +
        Math.round(exclusivereceivingPoints);
      const teamDefensePoints =
        Math.round(seasondefensePoints) + Math.round(exclusivedefensePoints);
      const teamFieldgoalsPoints =
        Math.round(seasonfieldgoalsPoints) +
        Math.round(exclusivefieldgoalsPoints);
      const teamNBAPoints =
        Math.round(seasonNBAPoints) + Math.round(exclusivenbaPoints);

      pointData.push(
        teamHittingPoints +
        teamPitchingPoints +
        teamPassingPoints +
        teamRushingPoints +
        teamReceivingPoints +
        teamDefensePoints +
        teamFieldgoalsPoints +
        teamNBAPoints
      );
    }, pointadata);

    const totalPoint = { PlayerPoint: pointData };
    dispatch({ type: "TOTAL_POINT", payload: totalPoint });
  }
};

const getPos = (selected_cat, type) => {
  const data = JSON.parse(localStorage.getItem("playerteamInfo"));
  const pos = pathOr(
    selected_cat === "nfl"
      ? "passing"
      : selected_cat === "nba"
        ? "nba"
        : "hitting",
    ["0", "position"],
    data
  );
  if (selected_cat === "nba") {
    return "nba";
  }

  if (selected_cat === "mlb") {
    if (type === "player") {
      if (pitchers.includes(pos)) {
        return "pitcher";
      } else {
        return "hitter";
      }
    }
  }
  if (selected_cat === "nfl") {

    if (type === "player") {
      if (pos === "QB") {
        return "passing";
      } else if (pos === "RB") {
        return "rushing";
      } else if (pos === "WR" || pos === "TE") {
        return "receiving";
      } else if (pos === "D") {
        return "defense";
      } else {
        return "field_goals";
      }
    }
  }
};

export const GetCurrentSeasonStatData = async (
  dispatch,
  selected_cat,
  type,
  id,
  position,
  playerType,
  state,
  settingBar,
  selectednflTeamPos
) => {
  var selectednflTeamPos = getPos(selected_cat, type);
  let response;
  let responseMlbSeason;
  if (selected_cat === "mlb") {
    response = await apiInstance.get(
      selected_cat +
      "/" +
      type +
      "/" +
      id +
      "/seasonal_exclusive_stats?season_yr=2023&season_type=REG"
    );
    responseMlbSeason = await apiInstance.get(
      selected_cat +
      "/" +
      type +
      "/" +
      id +
      "/seasonal_stats?season_yr=2023&season_type=REG"
    );
  } else {
    response = await apiInstance.get(
      selected_cat +
      "/" +
      type +
      "/" +
      id +
      "/avg_seasonal_stats?num_of_seasons=1"
    );
  }
  if (response) {
    let seasonaldata =
      selected_cat === "mlb"
        ? seasonalStatus(responseMlbSeason, position, type, selected_cat)
        : seasonalStatus(response, position, type, selected_cat);
    let ExclusiveData = getFormattedExclusiveStats(
      response,
      position,
      type,
      selected_cat
    );

    setTimeout(async () => {
      const data = { Season: seasonaldata, Excslusive: ExclusiveData };
      let searsonPlayerData = propOr([], "seasonExclusiveData1", state);

      if (playerType === 1) {
        if (searsonPlayerData.length === 0) {
          searsonPlayerData.push(data);
        }
      }

      if (playerType === 2) {
        if (searsonPlayerData.length === 1) {
          searsonPlayerData.push(data);
        }
      }

      if (playerType === 3) {
        if (searsonPlayerData.length === 2) {
          searsonPlayerData.push(data);
        }
      }

      const playerCompare =
        type === "player"
          ? formatStateData(searsonPlayerData)
          : searsonPlayerData.length === 2
            ? formatStateDataTeam(searsonPlayerData)
            : formatStateDataTeamThree(searsonPlayerData);
      await Pointsdata(
        searsonPlayerData,
        dispatch,
        type,
        settingBar,
        selectednflTeamPos
      );

      await dispatch({
        type: "SEASON_EXCLUSIVE_DATA_1",
        payload: searsonPlayerData,
      });
      await dispatch({
        type: "SEASON_EXCLUSIVE_DATA_1_DEMO",
        payload: playerCompare,
      });
    }, 2000);
  }
};

export const GetLastSeasonStatData = async (
  dispatch,
  selected_cat,
  type,
  id,
  position,
  playerType,
  state
) => {
  const response = await apiInstance.get(
    selected_cat +
    "/" +
    type +
    "/" +
    id +
    "/avg_seasonal_stats?num_of_seasons=2"
  );
  if (response) {
    let seasonaldata = seasonalStatus(response, position, type, selected_cat);
    let ExclusiveData = getFormattedExclusiveStats(
      response,
      position,
      type,
      selected_cat
    );

    const data = { Season: seasonaldata, Excslusive: ExclusiveData };

    let searsonPlayerData = propOr([], "seasonExclusiveData2demo", state);

    if (playerType === 1) {
      if (searsonPlayerData.length === 0) {
        searsonPlayerData.push(data);
      }
    }

    if (playerType === 2) {
      if (searsonPlayerData.length === 1) {
        searsonPlayerData.push(data);
      }
    }

    if (playerType === 3) {
      if (searsonPlayerData.length === 2) {
        searsonPlayerData.push(data);
      }
    }

    const playerCompare =
      type === "player"
        ? formatStateData(searsonPlayerData)
        : searsonPlayerData.length === 2
          ? formatStateDataTeam(searsonPlayerData)
          : formatStateDataTeamThree(searsonPlayerData);
    await dispatch({ type: "SEASON_EXCLUSIVE_DATA_2", payload: playerCompare });
    await dispatch({
      type: "SEASON_EXCLUSIVE_DATA_2_DEMO",
      payload: searsonPlayerData,
    });
  }
};

export const GetAllSeasonStatData = async (
  dispatch,
  selected_cat,
  type,
  id,
  position,
  playerType,
  state
) => {
  const response = await apiInstance.get(
    selected_cat + "/" + type + "/" + id + "/seasonal_stats?season_type=REG"
  );
  if (response) {
    let seasonaldata = seasonalStatus(response, position, type, selected_cat);
    let ExclusiveData = getFormattedExclusiveStats(
      response,
      position,
      type,
      selected_cat
    );

    const data = { Season: seasonaldata, Excslusive: ExclusiveData };
    let searsonPlayerData = propOr([], "seasonExclusiveData3demo", state);

    if (playerType === 1) {
      if (searsonPlayerData.length === 0) {
        searsonPlayerData.push(data);
      }
    }

    if (playerType === 2) {
      if (searsonPlayerData.length === 1) {
        searsonPlayerData.push(data);
      }
    }

    if (playerType === 3) {
      if (searsonPlayerData.length === 2) {
        searsonPlayerData.push(data);
      }
    }
    const playerCompare =
      type === "player"
        ? formatStateData(searsonPlayerData)
        : searsonPlayerData.length === 2
          ? formatStateDataTeam(searsonPlayerData)
          : formatStateDataTeamThree(searsonPlayerData);
    await dispatch({ type: "SEASON_EXCLUSIVE_DATA_3", payload: playerCompare });
    await dispatch({
      type: "SEASON_EXCLUSIVE_DATA_3_DEMO",
      payload: searsonPlayerData,
    });
  }
};

export const GetTeamPlayerData = async (
  dispatch,
  selected_cat,
  type,
  id,
  ifMyteam = false,
  state
) => {
  let data = localStorage.getItem("playerteamInfo");
  let playerteamInfo = data === "" || data === null ? [] : JSON.parse(data);
  var response = await apiInstance.get(
    selected_cat + "/" + type + "/" + id + "/profile"
  );

  var standingResponse =
    type === "team"
      ? selected_cat === "mlb"
        ? await apiInstance.get(
          selected_cat +
          "/" +
          type +
          "/" +
          id +
          "/standing?season_yr=2023&season_type=REG"
        )
        : await apiInstance.get(
          selected_cat + "/" + type + "/" + id + "/standing?season_type=REG"
        )
      : {};

  const formatPlayer = FormatPlayerData(
    response.data.response,
    selected_cat,
    standingResponse
  );
  if (playerteamInfo.length === 0) {
    playerteamInfo.push(formatPlayer);
  } else if (playerteamInfo.length === 1) {
    playerteamInfo.push(formatPlayer);
  } else if (playerteamInfo.length === 2) {
    playerteamInfo.push(formatPlayer);
  }
  localStorage.setItem("playerteamInfo", "");
  localStorage.setItem("playerteamInfo", JSON.stringify(playerteamInfo));
  await dispatch({ type: "PLAYER_TEAM_INFO", payload: playerteamInfo });

  if (ifMyteam) {

    dispatch({ type: "TEAM_PLAYER_SELECTED", payload: false });
    // window.location.reload();
  }
};

export const ShareTeamPlayer = async (
  dispatch,
  selected_cat,
  type,
  id,
  state
) => {
  let data = localStorage.getItem("playerteamInfo");
  let playerteamInfo = data === "" ? [] : JSON.parse(data);
  var response = await apiInstance.get(
    selected_cat + "/" + type + "/" + id + "/profile"
  );
  var standingResponse =
    type === "team"
      ? selected_cat === "mlb"
        ? await apiInstance.get(
          selected_cat +
          "/" +
          type +
          "/" +
          id +
          "/standing?season_yr=2023&season_type=REG"
        )
        : await apiInstance.get(
          selected_cat + "/" + type + "/" + id + "/standing?season_type=REG"
        )
      : {};
  const formatPlayer = FormatPlayerData(
    response.data.response,
    selected_cat,
    standingResponse
  );
  if (playerteamInfo.length === 0) {
    playerteamInfo.push(formatPlayer);
  } else if (playerteamInfo.length === 1) {
    playerteamInfo.push(formatPlayer);
  } else if (playerteamInfo.length === 2) {
    playerteamInfo.push(formatPlayer);
  }
  localStorage.setItem("playerteamInfo", "");
  localStorage.setItem("playerteamInfo", JSON.stringify(playerteamInfo));
};

const FormatPlayerData = (profile, selectGameType, teamStanding) => {
  let teamstand = pathOr("", ["data", "response"], teamStanding);

  const isNFL = selectGameType === "nfl";
  const isNBA = selectGameType === "nba";
  const isPlayer = has("photo_url", profile);
  const name = isNFL ? "name" : isPlayer ? "full_name" : "name";
  const imageUrl = isPlayer ? "photo_url" : "logo_url";
  const playerDob = isNFL
    ? propOr("", "birth_date", profile)
    : propOr("", "birthdate", profile);
  const teamName = isPlayer
    ? pathOr("", ["team", "name"], profile)
    : propOr("", "name", profile);
  const teamMarket = isPlayer
    ? pathOr("", ["team", "market"], profile)
    : propOr("", "market", profile);
  const rank = isNFL
    ? pathOr("", ["record", "rank", "division"], teamstand)
    : isNBA
      ? pathOr("", ["record", "calc_rank", "conf_rank"], teamstand)
      : pathOr("", ["rank", "division"], teamstand);
  return {
    name: propOr("", name, profile),
    id: propOr("", "id", profile),
    profileImageUrl: propOr("", imageUrl, profile),
    type: isPlayer ? "player" : "team",
    teamMarket,
    teamName,
    ...(isNBA
      ? !isPlayer && { teamAbbr: propOr("", "abbr_name", profile) }
      : !isPlayer && { teamAbbr: propOr("", "abbr", profile) }),
    ...(isNFL
      ? !isPlayer && { leagueName: pathOr("", ["conference", "name"], profile) }
      : isNBA
        ? !isPlayer && { leagueName: pathOr("", ["conference", "name"], profile) }
        : !isPlayer && { leagueName: pathOr("", ["league", "name"], profile) }),
    ...(isNFL
      ? !isPlayer && { leagueId: pathOr("", ["conference", "id"], profile) }
      : !isPlayer && { leagueId: pathOr("", ["league", "id"], profile) }),
    ...(!isPlayer && isNFL
      ? { leagueAlias: pathOr("", ["conference", "alias"], profile) }
      : { leagueAlias: pathOr("", ["league", "alias"], profile) }),
    ...(!isPlayer && {
      divisionName: isNBA
        ? "Conf Rank"
        : pathOr("", ["division", "name"], profile),
    }),
    ...(!isPlayer && { rank: Number(rank) }),
    ...(isNFL
      ? !isPlayer && { win: pathOr("", ["record", "wins"], teamstand) }
      : isNBA
        ? !isPlayer && { win: pathOr("", ["record", "wins"], teamstand) }
        : !isPlayer && { win: propOr("", "win", teamstand) }),
    ...(isNFL
      ? !isPlayer && { loss: pathOr("", ["record", "losses"], teamstand) }
      : isNBA
        ? !isPlayer && { loss: pathOr("", ["record", "losses"], teamstand) }
        : !isPlayer && { loss: propOr("", "loss", teamstand) }),
    ...(isNFL
      ? isNFL && { jerseyNumber: propOr("", "jersey", profile) }
      : isPlayer && { jerseyNumber: propOr("", "jersey_number", profile) }),
    ...(isNFL
      ? isNFL && { teamId: pathOr("", ["team", "id"], profile) }
      : isNBA
        ? isNBA && { teamId: pathOr("", ["team", "id"], profile) }
        : isPlayer && { teamId: pathOr("", ["draft", "team_id"], profile) }),
    ...(isNFL
      ? isNFL && { position: propOr("", "position", profile) }
      : isPlayer && { position: propOr("", "position", profile) }),
    ...(isPlayer && { throwHand: propOr("", "throw_hand", profile) }),
    ...(isPlayer && { batHand: propOr("", "bat_hand", profile) }),
    ...(isNFL
      ? isNFL && { playerStatus: propOr("", "status", profile) }
      : isPlayer && { playerStatus: propOr("", "status", profile) }),
    ...(isNFL
      ? isNFL && { status: getPlayerStatus.get(propOr("", "status", profile)) }
      : isPlayer && {
        status: getPlayerStatus.get(propOr("", "status", profile)),
      }),
    ...(isNFL
      ? isNFL && { age: calculateAge(playerDob) }
      : isPlayer && { age: calculateAge(playerDob) }),
  };
};

export const FormatDraftRank = (data) => {
  return {
    id: propOr("", "id", data),
    rank: propOr("", "rank", data),
    player: propOr("", "player", data),
    team: propOr("", "team", data),
    position: propOr("", "position", data),
    sources: propOr("", "sources", data),
    isAdded: "0",
  };
};

export const FormatDraftCheatSheet = (response, position) => {

  return propOr([], position, response).map((player) => {
    return FormatDraftRank(player);
  });
};

export const checkIfComparsionPossible = (playerTeamList, type) => {

  if (type === "team") return true;
  // const allValuesEqualInArray = arr => arr.every(v => v === arr[0])
  const positions = map((profile) => {
    const position = propOr("", "position", profile);
    if (hitters.includes(position)) return "hitter";
    if (pitchers.includes(position)) return "pitcher";
    if (qb.includes(position)) return "QB";
    if (rb.includes(position)) return "RB";
    if (wr.includes(position)) return "WR";
    if (te.includes(position)) return "TE";
    if (d.includes(position)) return "D";
    if (k.includes(position)) return "K";
  }, playerTeamList);

  const isComparisonPossible = positions.every((val, i, arr) => val === arr[0]);
  return isComparisonPossible;
};

// export type DraftRank = {
//   id: number;
//   rank?: number;
//   player: string;
//   team?: string;
//   position: string;
//   sources: any;
//   type?: string;
//   bye?: string;
//   fpp?: string;
//   week: string;
//   dt_created?: string;
//   isAdded?: string;
//   recommended?: string;
// };


