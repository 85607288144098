const request = {
  LOGIN: `auth/login`,
  SIGN_UP: `user/register`,
  SOCIAL_LOGIN: `auth/sociallogin`,
  RESET_PASSWORD: `auth/email_verification`,

  GET_USER_DATA: `user/`,
  UPDATE_USER_DATA: `user/update`,
  UPDATE_USER_PASSWORD: `user/update_password`,
  GET_USER_TEAMS: `team/get_user_teams`,
  USER_SETTINGS: `user/settings`,
  CREATE_USER_TEAM: `team/create`,
  DELETE_USER_TEAM: `team/delete`,
  ADD_PLAYER_TO_TEAM: `team/add_players`,
  DELETE_PLAYER_FROM_TEAM: `team/delete_players`,
  UPDATE_TEAM_NAME: `team/update_team`,
  UPDATE_IMPORT_TEAM :`team/import`,

  STRIPE: `stripe/subscribe`,
  CHARGEEBEE: `chargebee/hostedPageInfo`,
  // SUBCRIPTION: `chargebee/createSubscription`,
  //CANCELSUBSCRIPTION: `chargebee/cancleSubscription`,

  Token: localStorage.getItem("tokens"),
  User_ID: localStorage.getItem("user_id"),
  User_Name: localStorage.getItem("user_name"),

  GET_NEWS_NFL: `https://php.fanalyze.com/php/news_data.php`,
  GET_NEWS_NBA: `https://php.fanalyze.com/php/nba_news_data.php`,
  GET_NEWS_MLB: `https://php.fanalyze.com/php/mlb_news_data.php`,

  GET_RANK_DATA: `https://php.fanalyze.com/php/draft_api.php`,
  GET_RANKING_DATA: `https://php.fanalyze.com/php/ranking_api.php`,
  GET_PROJ_DATA: `https://php.fanalyze.com/php/weekly_proj_api.php`,
  GET_START_SIT_DATA: `https://php.fanalyze.com/php/startsit_api.php`,
  GET_WAIVERWIRE_DATA: `https://php.fanalyze.com/php/WaiverWire_api.php`,
  GET_WEEK: `https://php.fanalyze.com/php/week_name.php`,

 // GET_NBA_DRAFT_DATA: "https://u.fanalyze.com:81/php/nba_ranking_api.php",
 GET_NBA_DRAFT_DATA: "https://php.fanalyze.com/php/nba_ranking_api.php",
  GET_NBA_RANKING_DATA: `https://php.fanalyze.com/php/nba_weekly_ranking_api.php`,
  GET_NBA_PROJ_DATA: `https://php.fanalyze.com/php/nba_weekly_proj_api.php`,
  GET_NBA_START_SIT_DATA: `https://php.fanalyze.com/php/nba_startsit_api.php`,
  GET_NBA_WAIVERWIRE_DATA: `https://php.fanalyze.com/php/NBA_WaiverWire_api.php`,
  GET_NBA_WEEK: `https://php.fanalyze.com/php/nba_week_name.php`,

  GET_NFL_DRAFT_DATA: `https://php.fanalyze.com/php/ranking_api.php`,
  GET_RANK_DATA: `https://php.fanalyze.com/php/draft_api.php`,

  GET_MLB_DRAFT_DATA: `https://php.fanalyze.com/php/mlb_ranking_api.php`,
  GET_MLB_START_SIT_DATA: `https://php.fanalyze.com/php/mlb_startsit_api.php`,
  GET_MLB_PROJ_DATA: `https://php.fanalyze.com/php/mlb_weekly_proj_api.php`,
  GET_MLB_WAIVERWIRE_DATA: `https://php.fanalyze.com/php/MLB_WaiverWire_api.php`,

  VERIFICATION: `auth/verification/`,
  UPDATE_PASSWORD: `auth/update_password/`,

  //SUBSCRIPRIONS
  SUBCRIPTION: "stripe/subscribe",
  CANCELSUBSCRIPTION: "stripe/cancel",
  RETRIVE_SUBSCRIPTION : `stripe/retriveSubscription`,  
  SUBSCRIPTION_SUCCESS:`stripe/subscribeSuccess`,
  
  IMPORT_TEAM :"teamScrap",

  SCHEDULE_GAMES:'https://php.fanalyze.com/php/schedule_games.php',
  GAME_WEEK:13,


};
export default request;
