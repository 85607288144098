import React, {createContext, useReducer} from "react";
import Reducer from './Reducer'


const initialState = {
  login: false,
  token: "",
  subscriptionPlan: [],
  openModal: false,
  category_type: { category: "NFL" },
  player_1: "",
  chartData: "",
  news: "",
  add_Player_List: [],
  oddAmount: ["-110"],
  user_setting: {},
  total_points: {},
  my_team_player: { Ptwo: false, Pthree: false },
  seasonExclusiveData1: [],
  seasonExclusiveData1demo: [],
  seasonExclusiveData2: [],
  seasonExclusiveData2demo: [],
  seasonExclusiveData3: [],
  seasonExclusiveData3demo: [],
  playerteamInfo: [],
  myteam: false,
  teamPlayerSelected: false,
  nfloptimalranking: [],
};

export const Store = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);