import React, { Fragment,useState,useEffect,useContext} from 'react';
import { Tab, Tabs } from "react-bootstrap";
import Tab_component from "./tab_component";
import Checkbox from './Checkbox';
import { useHistory } from "react-router-dom";
import {If,Else,Then} from 'react-if';
import ToggleComp from './ToggleComp';
import {Context} from "./Utility/store";
import { updateUserSettingsOnBackend } from './Utility/actionMethod';
import {has,propOr,pathOr,map} from 'ramda';





const SETTINGS = {
  player: {
    exclusive_stats: {
      hitter: {
        BABIP: true,
        ISO: true,
        'BB%': true,
        'LD%': true,
        'FB%': true,
        'GB%': true,
        'HR/FB': true,
        GBFB: true,
        'K%': true,
      },
      pitcher: {
        'K/9': true,
        'K/BB': true,
        'H/9': true,
        IRA: true,
        BQR: true,
        'BQR-S': true,
        PO: true,
        'P/IP': true,
        'HR/9': true,
        'BB/9': true,
      },
      passing: {
        'air_yards': true,
        'avg_pocket_time': true,
        'hurries': true,
        'knockdowns': true,
        'on_target_throws': true,
        'redzone_attempts': true,
        'dropped_passes': true,
      },
      rushing: {
        'yards_after_catch': true,
        'yards_after_contact': true,
        'broken_tackles': true,
        'redzone_targets':true,
        'Scrambles': true,
      },
      receiving: {
        'air_yards': true,
        'broken_tackles': true,
        'dropped_passes': true,
        'targets':true,
        'redzone_targets': true,
        'yards_after_catch': true,
        'yards_after_contact': true,
      },
      defense: {
        'batted_passes': true,
        'hurries': true,
        'knockdowns': true,
        'qb_hits':true,
        'safeties': true,
      },
      field_goals: {
        'attempts': true,
        'blocked': true,
        'longest': true,
      },
      nba: {
        'EFF_EX': true,
        'TSP_EX': true,
        'FTR_EX': true,
        'EFP_EX': true,
        'FBP_EX': true,
        'PPP_EX': true,
        'SCP_EX': true,
      },
    },
    season_stats: {
      hitter: {
        G: true,
        AB: true,
        R: true,
        H: true,
        HR: true,
        twoB: true,
        threeB: true,
        RBI: true,
        BB: true,
        SO: true,
        SB: true,
        CS: true,
        AVG: true,
        OBP: true,
        SLG: true,
        OPS: true,
      },
      pitcher: {
        G: true,
        W: true,
        L: true,
        ERA: true,
        SV: true,
        SVO: true,
        IP: true,
        H: true,
        R: true,
        ER: true,
        HR: true,
        HB: true,
        BB: true,
        IBB: true,
        SO: true,
        WHIP: true,
      },
      passing: {
        COMP: true,
        PATT: true,
        PYDS: true,
        PAVG: true,
        PTD: true,
        INT: true,
        SCK: true,
        QBR: true,
        RATT: true,
        RYDS: true,
        RAVG: true,
        RTD: true,
        FUM: true,
        LOST: true,
      },
      rushing: {
        RATT: true,
        RYDS: true,
        RAVG: true,
        LNG: true,
        RTD: true,
        REC: true,
        REYDS: true,
        REAVG: true,
        RETD: true,
      },
      receiving: {
        REC: true,
        YDS: true,
        REAVG: true,
        TD: true,
        LNG: true,
        TGT: true,
      },
      defense: {
        AST: true,
        TOT: true,
        SACK: true,
        SCKYADS: true,
        TFL: true,
        PD: true,
        INT: true,
        FR: true,
      },
      field_goals: {
        ATT: true,
        M: true,
        MI: true,
        PCT: true,
        LNG: true,
        EM: true,
        EPCT: true,
      },
      nba: {
        GP: true,
        MIN: true,
        PTS: true,
        REB: true,
        ASTT: true,
        TOV: true,
        STL: true,
        BLK: true,
        FGM: true,
        FGA: true,
        FGP: true,
        THPM: true,
        THPA: true,
        THPP: true,
        FTA: true,
        FTP: true,
        PF: true,
        DDT: true,
        TDTH: true
      },
    },
    next_game: {
      show_next_game: true,
    },
    player_trends: {
      show_player_trends: true,
    },
  },
  team: {
    exclusive_stats: {
      hitter: {
        BABIP: true,
        ISO: true,
        'BB%': true,
        'LD%': true,
        'FB%': true,
        'GB%': true,
        'HR/FB': true,
        GBFB: true,
        'K%': true,
      },
      pitcher: {
        'K/9': true,
        'K/BB': true,
        'H/9': true,
        IRA: true,
        BQR: true,
        'BQR-S': true,
        PO: true,
        'P/IP': true,
        'HR/9': true,
        'BB/9': true,
      },
      passing: {
        'air_yards': true,
        'avg_pocket_time': true,
        'hurries': true,
        'knockdowns': true,
        'on_target_throws': true,
        'redzone_attempts': true,
        'dropped_passes': true,
      },
      rushing: {
        'yards_after_catch': true,
        'yards_after_contact': true,
        'broken_tackles': true,
        'redzone_targets':true,
        'Scrambles': true,
      },
      receiving: {
        'air_yards': true,
        'broken_tackles': true,
        'dropped_passes': true,
        'targets':true,
        'redzone_targets': true,
        'yards_after_catch': true,
        'yards_after_contact': true,
      },
      defense: {
        'batted_passes': true,
        'hurries': true,
        'knockdowns': true,
        'qb_hits':true,
        'safeties': true,
      },
      field_goals: {
        'attempts': true,
        'blocked': true,
        'longest': true,
      },
      nba: {
        'EFF_EX': true,
        'TSP_EX': true,
        'FTR_EX': true,
        'EFP_EX': true,
        'FBP_EX': true,
        'PPP_EX': true,
        'SCP_EX': true,
      },
    },
    season_stats: {
      hitter: {
        G: true,
        AB: true,
        R: true,
        H: true,
        HR: true,
        twoB: true,
        threeB: true,
        RBI: true,
        BB: true,
        SO: true,
        SB: true,
        CS: true,
        AVG: true,
        OBP: true,
        SLG: true,
        OPS: true,
      },
      pitcher: {
        G: true,
        W: true,
        L: true,
        ERA: true,
        SV: true,
        SVO: true,
        IP: true,
        H: true,
        R: true,
        ER: true,
        HR: true,
        HB: true,
        BB: true,
        IBB: true,
        SO: true,
        WHIP: true,
      },
      passing: {
        COMP: true,
        PATT: true,
        PYDS: true,
        PAVG: true,
        PTD: true,
        INT: true,
        SCK: true,
        QBR: true,
      },
      rushing: {
        RATT: true,
        RYDS: true,
        RAVG: true,
        LNG: true,
        RTD: true,
      },
      receiving: {
        REC: true,
        YDS: true,
        REAVG: true,
        TD: true,
        LNG: true,
        TGT: true,
      },
      defense: {
        AST: true,
        TOT: true,
        SACK: true,
        SCKYADS: true,
        TFL: true,
        PD: true,
        INT: true,
        FR: true,
      },
      field_goals: {
        ATT: true,
        M: true,
        MI: true,
        PCT: true,
        LNG: true,
        EM: true,
        EPCT: true,
      },
      nba: {
        GP: true,
        PTS: true,
        REB: true,
        ASTT: true,
        TOV: true,
        STL: true,
        BLK: true,
        FGM: true,
        FGA: true,
        FGP: true,
        THPM: true,
        THPA: true,
        THPP: true,
        FTA: true,
        FTP: true,
        PF: true,
      },
  },
    next_game: {
      show_next_game: true,
    },
    player_trends: {
      show_player_trends: true,
    },
  },
};

export const NON_USER_SETTINGS = {
  player: {
    exclusive_stats: {
      hitter: {
        BABIP: false,
        ISO: false,
        'BB%': false,
        'LD%': false,
        'FB%': false,
        'GB%': false,
        'HR/FB': false,
        GBFB: false,
        'K%': false,
      },
      pitcher: {
        'K/9': false,
        'K/BB': false,
        'H/9': false,
        IRA: false,
        BQR: false,
        'BQR-S': false,
        PO: false,
        'P/IP': false,
        'HR/9': false,
        'BB/9': false,
      },
      passing: {
        'air_yards': true,
        'avg_pocket_time': true,
        'hurries': true,
        'knockdowns': true,
        'on_target_throws': true,
        'redzone_attempts': true,
        'dropped_passes': true,
      },
      rushing: {
        'yards_after_catch': true,
        'yards_after_contact': true,
        'broken_tackles': true,
        'redzone_targets':true,
        'Scrambles': true,
      },
      receiving: {
        'air_yards': true,
        'broken_tackles': true,
        'dropped_passes': true,
        'targets':true,
        'redzone_targets': true,
        'yards_after_catch': true,
        'yards_after_contact': true,
      },
      defense: {
        'batted_passes': true,
        'hurries': true,
        'knockdowns': true,
        'qb_hits':true,
        'safeties': true,
      },
      field_goals: {
        'attempts': true,
        'blocked': true,
        'longest': true,
      },
      nba: {
        'EFF_EX': true,
        'TSP_EX': true,
        'FTR_EX': true,
        'EFP_EX': true,
        'FBP_EX': true,
        'PPP_EX': true,
        'SCP_EX': true,
      },
    },
    season_stats: {
      hitter: {
        G: true,
        AB: true,
        R: true,
        H: true,
        HR: true,
        twoB: true,
        threeB: true,
        RBI: true,
        BB: true,
        SO: true,
        SB: true,
        CS: true,
        AVG: true,
        OBP: true,
        SLG: true,
        OPS: true,
      },
      pitcher: {
        G: true,
        W: true,
        L: true,
        ERA: true,
        SV: true,
        SVO: true,
        IP: true,
        H: true,
        R: true,
        ER: true,
        HR: true,
        HB: true,
        BB: true,
        IBB: true,
        SO: true,
        WHIP: true,
      },
      passing: {
        COMP: true,
        PATT: true,
        PYDS: true,
        PAVG: true,
        PTD: true,
        INT: true,
        SCK: true,
        QBR: true,
        RATT: true,
        RYDS: true,
        RAVG: true,
        RTD: true,
        FUM: true,
        LOST: true,
      },
      rushing: {
        RATT: true,
        RYDS: true,
        RAVG: true,
        LNG: true,
        RTD: true,
        REC: true,
        REYDS: true,
        REAVG: true,
        RETD: true,
      },
      receiving: {
        REC: true,
        YDS: true,
        REAVG: true,
        TD: true,
        LNG: true,
        TGT: true,
      },
      defense: {
        AST: true,
        TOT: true,
        SACK: true,
        SCKYADS: true,
        TFL: true,
        PD: true,
        INT: true,
        FR: true,
      },
      field_goals: {
        ATT: true,
        M: true,
        MI: true,
        PCT: true,
        LNG: true,
        EM: true,
        EPCT: true,
      },
      nba: {
        GP: true,
        MIN: true,
        PTS: true,
        REB: true,
        ASTT: true,
        TOV: true,
        STL: true,
        BLK: true,
        FGM: true,
        FGA: true,
        FGP: true,
        THPM: true,
        THPA: true,
        THPP: true,
        FTA: true,
        FTP: true,
        PF: true,
        DDT: true,
        TDTH: true
      },
  },
    next_game: {
      show_next_game: true,
    },
    player_trends: {
      show_player_trends: true,
    },
  },
  team: {
    exclusive_stats: {
      hitter: {
        BABIP: false,
        ISO: false,
        'BB%': false,
        'LD%': false,
        'FB%': false,
        'GB%': false,
        'HR/FB': false,
        GBFB: false,
        'K%': false,
      },
      pitcher: {
        'K/9': false,
        'K/BB': false,
        'H/9': false,
        IRA: false,
        BQR: false,
        'BQR-S': false,
        PO: false,
        'P/IP': false,
        'HR/9': false,
        'BB/9': false,
      },
      passing: {
        'air_yards': true,
        'avg_pocket_time': true,
        'hurries': true,
        'knockdowns': true,
        'on_target_throws': true,
        'redzone_attempts': true,
        'dropped_passes': true,
      },
      rushing: {
        'yards_after_catch': true,
        'yards_after_contact': true,
        'broken_tackles': true,
        'redzone_targets':true,
        'Scrambles': true,
      },
      receiving: {
        'air_yards': true,
        'broken_tackles': true,
        'dropped_passes': true,
        'targets':true,
        'redzone_targets': true,
        'yards_after_catch': true,
        'yards_after_contact': true,
      },
      defense: {
        'batted_passes': true,
        'hurries': true,
        'knockdowns': true,
        'qb_hits':true,
        'safeties': true,
      },
      field_goals: {
        'attempts': true,
        'blocked': true,
        'longest': true,
      },
      nba: {
        'EFF_EX': true,
        'TSP_EX': true,
        'FTR_EX': true,
        'EFP_EX': true,
        'FBP_EX': true,
        'PPP_EX': true,
        'SCP_EX': true,
      },
    },
    season_stats: {
      hitter: {
        G: true,
        AB: true,
        R: true,
        H: true,
        HR: true,
        twoB: true,
        threeB: true,
        RBI: true,
        BB: true,
        SO: true,
        SB: true,
        CS: true,
        AVG: true,
        OBP: true,
        SLG: true,
        OPS: true,
      },
      pitcher: {
        G: true,
        W: true,
        L: true,
        ERA: true,
        SV: true,
        SVO: true,
        IP: true,
        H: true,
        R: true,
        ER: true,
        HR: true,
        HB: true,
        BB: true,
        IBB: true,
        SO: true,
        WHIP: true,
      },
      passing: {
        COMP: true,
        PATT: true,
        PYDS: true,
        PAVG: true,
        PTD: true,
        INT: true,
        SCK: true,
        QBR: true,
      },
      rushing: {
        RATT: true,
        RYDS: true,
        RAVG: true,
        LNG: true,
        RTD: true,
      },
      receiving: {
        REC: true,
        YDS: true,
        REAVG: true,
        TD: true,
        LNG: true,
        TGT: true,
      },
      defense: {
        AST: true,
        TOT: true,
        SACK: true,
        SCKYADS: true,
        TFL: true,
        PD: true,
        INT: true,
        FR: true,
      },
      field_goals: {
        ATT: true,
        M: true,
        MI: true,
        PCT: true,
        LNG: true,
        EM: true,
        EPCT: true,
      },
      nba: {
        GP: true,
        PTS: true,
        REB: true,
        ASTT: true,
        TOV: true,
        STL: true,
        BLK: true,
        FGM: true,
        FGA: true,
        FGP: true,
        THPM: true,
        THPA: true,
        THPP: true,
        FTA: true,
        FTP: true,
        PF: true,
      },
  },
    next_game: {
      show_next_game: true,
    },
    player_trends: {
      show_player_trends: true,
    },
  },
};


const SettingModal = (props) => {
let history = useHistory();
const [gameType,setgameType] = useState("nfl");
const [playerOrteam , setplayerOrteam] = useState('player')
const [selectednflTeamPos,setselectednflTeamPos] = useState( gameType === "nfl" ? 'passing' : gameType === "mlb" ? "hitter":"nba");
const [state,dispatch] = useContext(Context);
const settings = JSON.parse(localStorage.getItem("user_setting"));
const [uisetting,setuisetting] = useState(settings);
let islogin = localStorage.getItem("islogged");

const ChangeCategoryHandler = (cat) => {
  if(cat === "nfl"){
    setgameType("nfl");
  }else if (cat === "mlb"){
    setgameType("mlb");
  }else{
    setgameType("nba");
  }
}






useEffect(()=>(
    gameType === "nfl" ? setselectednflTeamPos('passing') : gameType === "mlb" ? setselectednflTeamPos("hitter"):setselectednflTeamPos("nba")
),[gameType])

// const updateSpecificSetting = (
//     val, name, playerOrteam, type,state
//   ) => {
    
//     const newSettings = {...settings};
  
//     if(type === "section"){
//         newSettings[playerOrteam][state][name] = val;

//     }else{
//         newSettings[playerOrteam][state][type][name] = val;
//     }    
    
//     localStorage.setItem("user_setting",JSON.stringify(newSettings));
//     dispatch({type: 'user_setting', payload:settings})
//     setuisetting(settings);
//     // const hitterPitcher = gameTypeIndex === 0 ?  hitterPitcherIndex === 0 ? 'hitter' : 'pitcher' : gameTypeIndex === 1 ? hitterPitcherIndex === 0 ? 'passing' : hitterPitcherIndex === 1 ? 'rushing' : hitterPitcherIndex === 2 ? 'receiving' : hitterPitcherIndex === 3 ? 'defense' : 'field_goals' : 'nba';
//     // const newSettings = {...settings};
//     // if (section === 'next_game' || section === 'player_trends')
//     //   newSettings[profileType][section][property] = val;
//     // else newSettings[profileType][section][hitterPitcher][property] = val;
//     // dispatch(updateUserSettings(newSettings, ''));
//      setTimeout(() => {
//        dispatch(updateUserSettingsOnBackend());
//      }, 100);
//   };


  const updateSpecificSetting = (
    val, name, playerOrteam, type,state
  ) => {
    
    const newSettings = {...settings};
    if(type === "section"){
        newSettings[playerOrteam][state][name] = val;
    }else{
        newSettings[playerOrteam][state][type][name] = val;
    }
    localStorage.setItem("user_setting",JSON.stringify(newSettings));
    setuisetting(settings);
    // const hitterPitcher = gameTypeIndex === 0 ?  hitterPitcherIndex === 0 ? 'hitter' : 'pitcher' : gameTypeIndex === 1 ? hitterPitcherIndex === 0 ? 'passing' : hitterPitcherIndex === 1 ? 'rushing' : hitterPitcherIndex === 2 ? 'receiving' : hitterPitcherIndex === 3 ? 'defense' : 'field_goals' : 'nba';
    // const newSettings = {...settings};
    // if (section === 'next_game' || section === 'player_trends')
    //   newSettings[profileType][section][property] = val;
    // else newSettings[profileType][section][hitterPitcher][property] = val;
    // dispatch(updateUserSettings(newSettings, ''));
     setTimeout(() => {
       dispatch(updateUserSettingsOnBackend(dispatch));
     }, 100);
  };

  
const openSigupModal = () => {
  dispatch({type: 'OPEN_MODAL', payload: true })
}


const UpgradePackageHandler = () => {
  history.push("/pricing")
}




  return (
    <Fragment>
    {/* ====== BREADCRUMB ====== */}

    <section className="setting-wrapper pb-100">
    {
      islogin === "false" ?  
        <div className='sign-up'>
        <button className="common-btn" onClick={openSigupModal}>Sign up For free trial</button>
        </div>
        :
        ""
    }

    {
      islogin === "true" ?  
      <div className={props.showData  ? "sign-up" : " d-none" }>
      <button className="common-btn" onClick={UpgradePackageHandler}>Customize Upgrade Now</button>
    </div>
        :
        ""
    }

    
    <div className="container">
     <ul className="nav nav-tabs main-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
                <a className= {gameType === 'nfl' ? "nav-link active" : "nav-link" } id="nfl-tab" onClick={() => ChangeCategoryHandler("nfl")} data-bs-toggle="tab" href="#nfl" role="tab" aria-controls="nfl" aria-selected="true">
                    <div className="game-tabs"> <img src="assets/images/nlf_tab.png" alt="nfl" />
                        <h6>NFL</h6> </div>
                </a>
            </li>
            <li className="nav-item" role="presentation">
                <a className={gameType === 'mlb' ? "nav-link active" : "nav-link" } id="mbl-tab" onClick={() => ChangeCategoryHandler("mlb")} data-bs-toggle="tab" href="#mbl" role="tab" aria-controls="mbl" aria-selected="false">
                    <div className="game-tabs"> <img src="assets/images/mlb_tab.png" alt="mbl" />
                        <h6>mlb</h6> </div>
                </a>
            </li>
            <li className="nav-item" role="presentation">
                <a className={gameType === 'nba' ? "nav-link active" : "nav-link" } id="nba-tab" onClick={() => ChangeCategoryHandler("nba")} data-bs-toggle="tab" href="#nba" role="tab" aria-controls="nba" aria-selected="false">
                    <div className="game-tabs"> <img src="assets/images/nba_tab.png" alt="nfl" />
                        <h6>nba</h6> </div>
                </a>
            </li>
     </ul>
      <div className="player-team-wrapper">
          <div className="player-team-inner">
              <button  className={playerOrteam === 'player'? 'active' : ""} onClick={()=>setplayerOrteam('player')}> player </button>
              <button className={playerOrteam === 'team'? 'active' : ""} onClick={()=>setplayerOrteam('team')}> team </button>
          </div>
      </div>
     <div className="next-game-wrapper">
  
            <div className="next-game exclusive-chk">
            <h5>{"Next game"}</h5>
            <div className="form-check form-switch ">
                <input
                className="form-check-input"
                type="checkbox"
                id="mySwitch"
                name="darkmode"
                value="yes"
                checked={ pathOr(false,[playerOrteam,'next_game','show_next_game'],uisetting)}
                onChange={(e) => {updateSpecificSetting(e.target.checked, "show_next_game", playerOrteam,"section","next_game")}}
                
                />{" "}
             </div>
            
            </div>
            <div className="next-game exclusive-chk">
            <h5>{"Player trends"}</h5>
            <div className="form-check form-switch ">
                <input
                className="form-check-input"
                type="checkbox"
                id="mySwitch"
                name="darkmode"
                value="yes"
                checked={ pathOr(false,[ playerOrteam,'player_trends','show_player_trends'],uisetting)}
                onChange={(e) => {updateSpecificSetting(e.target.checked, "show_player_trends", playerOrteam,"section","player_trends")}}
                />{" "}
             </div>
            
            </div>
      </div>
    </div>


  {/*
  //   ======== FOR NFL BUTTON ========*/}
  <section className="common-section-lg mt-5">
    <div className="container">
    <If condition={gameType === "nfl"}>
        <section className='ranking-table-wrap'>
        <div className="container">
        <ul className="mb-3 nav nav-tabs" role="tablist">
        <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="passing"?"nav-link active":"nav-link"} onClick={()=>setselectednflTeamPos("passing")} >Pass</button></li>
        <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="rushing"?"nav-link active ":"nav-link"} onClick={()=>setselectednflTeamPos("rushing")}>Rush</button></li>
        <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="receiving"?"nav-link active":"nav-link"} onClick={()=>setselectednflTeamPos("receiving")} >Rec</button></li>
        <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="defense" ?"nav-link active":"nav-link"} onClick={()=>setselectednflTeamPos("defense" )}>Def</button></li>
        <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="field_goals"?"nav-link active":"nav-link"} onClick={()=>setselectednflTeamPos("field_goals")} >Kick</button></li>
        </ul>
        </div>                     
        </section>
    </If>
      {/*======== FOR MLB BUTTON  ========*/}
      <If condition={gameType === "mlb"}>
          <section className="common-section-lg mt-5">
          <div className="container">
          <section className='ranking-table-wrap'>
          <div className="container">
          <ul className="mb-3 nav nav-tabs" role="tablist">
            <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="hitter"?"nav-link active":"nav-link"} onClick={()=>setselectednflTeamPos("hitter")} >hitter</button></li>
            <li className="nav-item" ><button type="button"   className={selectednflTeamPos ==="pitcher"?"nav-link active ":"nav-link"} onClick={()=>setselectednflTeamPos("pitcher")}>pitcher</button></li>
          </ul>
          </div>                        
          </section> 
          </div>
          </section>
      </If>

      <section>
      <div className='container'>
          <div className='row'>
              <div className="exclusive_data_wrapper p-50">
              <h3>Exclusive Data</h3>
                  <div className="row">
                      { Object.keys(pathOr([],[playerOrteam,'exclusive_stats',selectednflTeamPos],uisetting)).map((key, index)=>(
                          <ToggleComp 
                          key={index}
                          value={pathOr([],[playerOrteam,'exclusive_stats',selectednflTeamPos,key],uisetting)} 
                          name={key}
                          type = {selectednflTeamPos}
                          state = {'exclusive_stats'}
                          playerOrteam = {playerOrteam}
                          updateSettings={(val, name, playerOrteam, type,state)=>{
                              updateSpecificSetting(val, name, playerOrteam, type,state)
                              
                          }}
                          />
                      ))

                     
                      }
                  </div>
              </div> 

              <div className="exclusive_data_wrapper p-50">
              <h3>Season  Data</h3>
                  <div className="row">
                      { Object.keys(pathOr([],[playerOrteam,'season_stats',selectednflTeamPos],uisetting)).map((key, index)=>(
                          <ToggleComp 
                              key={index}
                              value={pathOr([],[playerOrteam,'season_stats',selectednflTeamPos,key],uisetting)} 
                              name={key}
                              type = {selectednflTeamPos}
                              state = {'season_stats'}
                              playerOrteam = {playerOrteam}
                              updateSettings={(val, name, playerOrteam, type,state)=>{
                                  updateSpecificSetting(val, name, playerOrteam, type,state)
                                  
                              }
                          }
                          />
                      ))
                     
                      }
                  </div>
              </div> 
          </div>    
      </div>
     </section>


      </div>
  </section>
  </section>
  </Fragment>
  )
}

export default SettingModal