import {pathOr,isEmpty,propOr,isNil} from 'ramda';
import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import momentTimezone from 'moment';

export const getFormattedNextGame = (response, teamId, selectGameType) => {
  
     const nextGame = pathOr([], ['data', 'response'], response);
    if (isEmpty(nextGame) , isNil(nextGame)) return [];
     return formatNextGame(nextGame, teamId, selectGameType)
  };

  
const getScheduledTime = (time, format) => momentTimezone(time).tz("America/New_York").format(format);

  const formatNextGame = (nextGame, teamId, selectGameType) => {
    const isNFL = selectGameType === 'nfl'
    const isNBA = selectGameType === 'nba'
    const scheduledTime = isNFL ? pathOr('', ['weeks','games','scheduled'], nextGame) : propOr('', 'scheduled', nextGame)
    let home, away, fixture;
    if(isNFL){
      if (teamId === pathOr('', ['weeks','games','away', 'id'], nextGame)) {
        home = pathOr({}, ['weeks','games','away'], nextGame);
        away = pathOr({}, ['weeks','games','home'], nextGame);
        fixture = `${propOr('', 'name', home)} @ ${propOr('', 'name', away)}`;
      } else {
        home = pathOr({}, ['weeks','games','home'], nextGame);
        away = pathOr({}, ['weeks','games','away'], nextGame);
        fixture = `${propOr('', 'name', home)} vs ${propOr('', 'name', away)}`
      }
    }else if(isNBA){
      if (teamId === pathOr('', ['away', 'id'], nextGame)) {
        home = propOr({}, 'away', nextGame);
        away = propOr({}, 'home', nextGame);
        fixture = `${propOr('', 'name', home)} @ ${propOr('', 'name', away)}`;
      } else {
        home = propOr({}, 'home', nextGame);
        away = propOr({}, 'away', nextGame);
        fixture = `${propOr('', 'name', home)} vs ${propOr('', 'name', away)}`
      }
    }else{
      if (teamId === propOr('', 'away_team', nextGame)) {
        home = propOr({}, 'away', nextGame);
        away = propOr({}, 'home', nextGame);
        fixture = `${propOr('', 'name', home)} @ ${propOr('', 'name', away)}`;
      } else {
        home = propOr({}, 'home', nextGame);
        away = propOr({}, 'away', nextGame);
        fixture = `${propOr('', 'name', home)} vs ${propOr('', 'name', away)}`
      }
    }
  
    
   
    return{
        home,
        away,
        fixture,
        scheduledTime: getScheduledTime(scheduledTime, 'ddd, MMM D, YYYY h:mm A'),
        venueCity: isNFL ? pathOr('', ['weeks','games','venue', 'city'], nextGame) : pathOr('', ['venue', 'city'], nextGame),
        venueName: isNFL ? pathOr('', ['weeks','games','venue', 'name'], nextGame) : pathOr('', ['venue', 'name'], nextGame)
      }
  }