import React, { Fragment, useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { Tab, Tabs, Modal, Spinner } from "react-bootstrap";
import request from "./Utility/Request";
import { nflProjData, nbaProjData } from "./Utility/GetProjectileData";
import { If, Then } from "react-if";
import { has, propOr, pathOr, map } from "ramda";
import {
  GetTeamPlayerData,
  getUserSettings,
  isEmptyOrNil,
} from "./Utility/actionMethod";
import { Context } from "./Utility/store";
import { apiInstance } from "./Utility/axios";

import { errorToast } from "./Utility/toastify";
import { ToastContainer } from "react-toastify";

function Projectile() {
  var categoryType = localStorage.getItem("category_type").toLowerCase();
  let history = useHistory();
  const auth = localStorage.getItem("tokens");
  const nfl_btn = ["QB", "RB", "WR", "TE", "DE", "K"];
  const nba_btn = ["PG", "SG", "SF", "PF", "C"];
  const mlb_btn = ["All", "C", "1B", "2B", "3B", "SS", "OF", "SP", "RP"];

  const [state, dispatch] = useContext(Context);
  const selected_cat = localStorage.getItem("category_type").toLowerCase();
  const [Projectile, setProjectile] = useState([]);
  const [showRankingModal, setShowRankingModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const RankingHandleClose = () => setShowRankingModal(false);
  const RankingHandleOpen = (data, detail) => {

    setSource({ data: data, detail: detail });
    setShowRankingModal(true);
  };
  var user =
    localStorage.getItem("userDetail") !== "" ||
    localStorage.getItem("userDetail") !== null
      ? JSON.parse(localStorage.getItem("userDetail"))
      : {};
  const [selectednflTeamPos, setselectednflTeamPos] = useState(
    selected_cat === "nfl" ? "QB" : selected_cat === "mlb" ? "All" : "PG"
  );
  const [showComparisionModal, setshowComparisionModal] = useState(false);
  const comparisonHandleClose = () => setshowComparisionModal(false);

  const [showSeacrhModal, setshowSeacrhModal] = useState(false);
  const SeacrhModalClose = () => setshowSeacrhModal(false);

  const [searchValue, setsearchValue] = useState("");
  const [searchTerm, setsearchTerm] = useState("");
  const [ShowPlayerList, setShowPlayerList] = useState(false);
  const [ShowcompPlayerList, setShowcompPlayerList] = useState(false);
  const [SearchedPlayer, setSearchedPlayer] = useState([]);
  const [Player1Compare, setPlayer1Compare] = useState([]);
  const [Player2Compare, setPlayer2Compare] = useState([]);
  const [counter, setCounter] = useState(0);

  const [AllPlayer, setAllPlayer] = useState([]);
  const [Source, setSource] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const searchList = [...playerList, ...teamList];

  useEffect(() => {
    getRanking();
  }, []);

  // useEffect(()=>{
  //     if(searchValue === ""){
  //         setShowPlayerList(false)
  //     }else{
  //         setShowPlayerList(true)
  //     }
  // },[searchValue])

  useEffect(() => {
    if (showComparisionModal === false) {
      setCounter(0);
      setPlayer2Compare("");
      setPlayer1Compare("");
    }
  }, [showComparisionModal]);

  // ======= GET PLAYER LIST =======
  const getPlayerHanlder = (e) => {
    if (e.length > 3) {
      setShowcompPlayerList(true);
    } else {
      setShowcompPlayerList(false);
    }
    if (e === "") {
      setSearchedPlayer("");
    }
    const datafilter = AllPlayer.filter((val) => {
      return val.player.toLowerCase().includes(e.toLowerCase());
    });
    setSearchedPlayer(datafilter);
  };

  // ========== NEW TAB ==========
  const openUrl = (url) => {
    window.open(url, "_blank");
  };

  // ======== GET RANKING DATA ========
  const getRanking = async () => {
    let response;
    try {
      if (selected_cat === "nfl") {
        response = await axios.get(request.GET_PROJ_DATA);
      } else if (selected_cat === "mlb") {
        response = await axios.get(request.GET_MLB_PROJ_DATA);
      } else {
        response = await axios.get(request.GET_NBA_PROJ_DATA);
      }
      if (response) {
        setProjectile(response.data);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let allData = [];
    map(
      (pos) => {
        map((data) => {
          allData.push(data);
        }, propOr([], pos, Projectile));
      },

      selected_cat === "nfl"
        ? nfl_btn
        : selected_cat === "mlb"
        ? mlb_btn
        : nba_btn
    );
    setAllPlayer(allData);
  }, [Projectile]);

  // ========= OPEN COMPARE MODAL =====
  const HandleCompareModal = () => {
    setshowComparisionModal(true);
  };

  // ========== COMPARE PLAYER ========
  const PlayerCompare = (data) => {
    setShowPlayerList(false);
    if (data.id === Player1Compare.id) {
      errorToast("player Already Selected");
    } else if (data.id === Player2Compare.id) {
      errorToast("player Already Selected");
    } else {
      if (counter === 0) {
        setShowcompPlayerList(false);
        setPlayer1Compare(data);
        setsearchValue("");
        setCounter(counter + 1);
      }
      if (counter === 1) {
        setShowcompPlayerList(false);
        setPlayer2Compare(data);
        setsearchValue("");
        setCounter(counter + 1);
      }
    }
  };

  const removePlayerHandler = (id) => {
    if (Player1Compare.id === id) {
      setPlayer1Compare(Player2Compare);
      setCounter(counter - 1);
      setPlayer2Compare("");
    } else {
      setPlayer2Compare("");
      setCounter(counter - 1);
    }
  };

  const getPlayerList = async (player) => {
    var categoryType = localStorage.getItem("category_type").toLowerCase();
    try {
      const response = await apiInstance.get(
        categoryType + "/players/list?prefix=" + player
      );
      if (response) {
        setPlayerList(response.data.response);
        setLoader(false);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const getTeamList = async (teamVal) => {
    var categoryType = localStorage.getItem("category_type").toLowerCase();
    try {
      const response = await apiInstance.get(
        categoryType + "/teams/list?prefix=" + teamVal
      );
      if (response) {
        setTeamList(response.data.response);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const getlist = (e) => {
    getPlayerList(e.target.value);
    getTeamList(e.target.value);
    if (e.target.value.length > 3) {
      setShowPlayerList(true);
    } else {
      setShowPlayerList(false);
    }
  };

  const singlePlayerHandler = async (data) => {
    //let type = propOr('','logo_url',data)
    let type = has("logo_url", data) ? "team" : "player";
    let id = propOr("", "id", data);
    await GetTeamPlayerData(
      dispatch,
      categoryType.toLowerCase(),
      type,
      id,
      state
    );
    let teamurl = data.logo_url;
    if (teamurl === undefined) {
      localStorage.setItem("player_1", "");
      localStorage.setItem("player_1", JSON.stringify(data));
      localStorage.setItem("isplayer1", true);
    } else {
      localStorage.setItem("team_1", "");
      localStorage.setItem("team_1", JSON.stringify(data));
      localStorage.setItem("isteam", true);
    }
    // localStorage.setItem("player_1" ,"");
    // localStorage.setItem("player_1" ,JSON.stringify(player));
    // localStorage.setItem("isplayer1",true);
    history.push("/player-profile");
  };

  const openLoginModal = () => {
    history.push("/login");
    // dispatch({ type: "OPEN_LOGIN_MODAL", payload: true });
  };

  // const showExclusiveStatsFunc = (islogin, user) => {
  //   if (user === {} && islogin === false) {
  //     return true;
  //   }

  //   var isTrial = propOr(false, "is_trial", user);
  //   var subscriptionPackage = propOr(false, "subscribedPlans", user);

  //   if (islogin === false) return true;
  //   if (isTrial) return false;
  //   else {
  //     if (!isEmptyOrNil(subscriptionPackage)) {
  //       let purchasePlan = true;
  //       map((plan) => {
  //         if (!plan[1]) {
  //           purchasePlan = false;
  //         }
  //       }, subscriptionPackage);
  //       return purchasePlan;
  //     } else {
  //       return true;
  //     }
  //   }
  // };

 
  const showExclusiveStatsFunc = () => {
    
    // if (user === {} && islogin === false) {
    //   return true;
    // }

    //var isTrial = propOr(false, "is_trial", user);
    var isTrial = user.is_trial;
    console.log("isTrial",isTrial)
    // var subscriptionPackage = propOr(false, "subscribedPlans", user);
    var subscriptionPackage = user.subscribedPlans
    //if (islogin === false) return true;
      if (isTrial) return false;
      if (!isEmptyOrNil(subscriptionPackage)) {
        let purchasePlan = true;
        map((plan) => {
          console.log('plan',plan)
          if (!plan[1]) {
            purchasePlan = false;
          }
        }, subscriptionPackage);
        return purchasePlan;
      } else {
        return true;
      }
    
  };

  return (
    <Fragment>
      {/* ====== BREADCRUMB ====== */}

      {!loader ? (
        <Fragment>
          <section className="brd-cmb  p-50">
            <div className="container">
              <div className="breadcrumb-wrapper">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="./">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Projections
                    </li>
                  </ol>
                </nav>
                {/*
                <div className="search-button-wrapper">
                  <div className="search-wrapper">
                    <div className="player-search-wrap mt-0">
                      <div className="player-search-box">
                        <React.Fragment>
                          <span>
                            <i className="fas fa-search"></i>
                          </span>
                          <input
                            type="text"
                            name=""
                            placeholder={
                              categoryType === "nfl"
                                ? "Search NFL Players or Teams"
                                : categoryType === "mlb"
                                ? "Search MLB Players or Teams"
                                : "Search NBA Players or Teams"
                            }
                            onChange={getlist}
                          />
                        </React.Fragment>
                      </div>

                      <If condition={ShowPlayerList}>
                        <Then>
                          <ul
                            className={
                              searchList.length < 5
                                ? "search-result h-auto"
                                : "search-result h-400"
                            }
                          >
                            <div className={loader ? "sipnner-wrap" : "d-none"}>
                              <Spinner animation="border" />
                            </div>

                            {searchList.length !== 0 ? (
                              searchList.map((searchitem, index) => (
                                <div>
                                  {
                                    <li
                                      onClick={() =>
                                        singlePlayerHandler(searchitem)
                                      }
                                      key={index}
                                    >
                                      {" "}
                                      <span className="searchListImage">
                                        <img
                                          src={
                                            searchitem.photo_url !== undefined
                                              ? searchitem.photo_url
                                              : searchitem.logo_url
                                          }
                                          alt="-"
                                        />
                                      </span>{" "}
                                      {searchitem.photo_url
                                        ? (categoryType === "nfl"
                                            ? searchitem.name.length
                                            : searchitem.full_name.length) ===
                                          ""
                                          ? "No Such Player or Team Found"
                                          : categoryType === "nfl"
                                          ? searchitem.name
                                          : searchitem.full_name
                                        : searchitem.market_name}
                                    </li>
                                  }
                                </div>
                              ))
                            ) : (
                              <li>No such Player or Team Found </li>
                            )}
                          </ul>
                        </Then>
                      </If>
                    </div>
                  </div>

                  <div className="compare-btn-wrap">
                    <button onClick={HandleCompareModal}>
                      <img
                        src="assets/images/icons/compare.png"
                        alt="compare"
                      />
                    </button>
                    <button>
                      <img src="assets/images/icons/share.png" alt="compare" />
                    </button>
                  </div>
                </div>
                */}
              </div>
            </div>
          </section>
          <section className="nfl-draftCheat-main-wrapper  pos-relative">
            {auth === null || auth === "null"|| auth === ""  ? (
              <div className="not-logged">
                <div>
                  <button
                    className="common-btn check-sub-btn"
                    onClick={openLoginModal}
                  >
                    Sign Up for a Free Trial{" "}
                  </button>
                </div>
              </div>
            ) : (
              showExclusiveStatsFunc() && (
                <div className="not-logged">
                  <div>
                    <Link
                      to="/pricing"
                      className="common-btn check-sub-btn inline-block"
                    >
                      {" "}
                      Upgrade To access{" "}
                    </Link>
                  </div>
                </div>
              )
            )}
            <section className="ranking-table-wrap ranking-search nfl-draftcheat">
              <div className="table-player-search">
                <div className="outter-wrapper">
                  <div className="search-wrapper">
                    <div className="player-search-wrap mt-0">
                      <div className="player-search-box">
                        <React.Fragment>
                          <span>
                            <i className="fas fa-search"></i>
                          </span>
                          <input
                            type="text"
                            name=""
                            placeholder="Search Player"
                            value={searchTerm}
                            onChange={(e) => {
                              setsearchTerm(e.target.value);
                            }}
                          />
                        </React.Fragment>
                      </div>
                    </div>
                  </div>
                  <div className="search-button-wrapper">
                    <div className="compare-btn-wrap">
                      <button onClick={HandleCompareModal}>
                        <img
                          src="assets/images/icons/compare.png"
                          alt="compare"
                        />
                      </button>
                      {/*
                        <button>
                        <img src="assets/images/icons/share.png" alt="compare" />
                        </button>
                        */}
                    </div>
                  </div>
                </div>
              </div>

              <ul className="mb-3 nav nav-tabs" role="tablist">
                {selected_cat === "nfl"
                  ? nfl_btn.map((val, index) => (
                      <li key={index} className="nav-item">
                        <button
                          type="button"
                          className={
                            selectednflTeamPos === val
                              ? "nav-link active"
                              : "nav-link"
                          }
                          onClick={() => setselectednflTeamPos(val)}
                        >
                          {val}
                        </button>
                      </li>
                    ))
                  : selected_cat === "mlb"
                  ? mlb_btn.map((val, index) => (
                      <li key={index} className="nav-item">
                        <button
                          type="button"
                          className={
                            selectednflTeamPos === val
                              ? "nav-link active"
                              : "nav-link"
                          }
                          onClick={() => setselectednflTeamPos(val)}
                        >
                          {val}
                        </button>
                      </li>
                    ))
                  : nba_btn.map((val, index) => (
                      <li key={index} className="nav-item">
                        <button
                          type="button"
                          className={
                            selectednflTeamPos === val
                              ? "nav-link active"
                              : "nav-link"
                          }
                          onClick={() => setselectednflTeamPos(val)}
                        >
                          {val}
                        </button>
                      </li>
                    ))}
              </ul>
            </section>

            <section className="ranking-table-wrap draft-table-wrap p-0">
              <div className="container">
                <table className="table table-bordered table-striped draft-table">
                  <thead>
                    <tr>
                      <th>Proj+</th>
                      <th>Player</th>
                      <th>Sources</th>
                    </tr>
                  </thead>
                  <tbody>
                    {propOr([], selectednflTeamPos, Projectile)
                      .filter((val) => {
                        if (searchTerm == "") {
                          return val;
                        } else if (
                          val.player
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return val;
                        }
                      })
                      .map((data, index) => (
                        <tr key={index}>
                          <td>{data.fpp}</td>
                          <td>
                            {data.player} {data.team} ({data.position} ){" "}
                          </td>
                          <td>
                            <img
                              src="assets/images/table_check.png"
                              alt="table_check"
                              onClick={() =>
                                RankingHandleOpen(data.sources, data)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </section>
          </section>
        </Fragment>
      ) : (
        <div className={loader ? "sipnner-wrap" : "d-none"}>
          <Spinner animation="border" />
        </div>
      )}

      {/* ===============  RANKING MODAL===============  */}
      <Modal
        show={showRankingModal}
        onHide={RankingHandleClose}
        size="lg"
        centered
        keyboard={false}
        className="ranking-modal"
      >
        <Modal.Header>
          {showRankingModal ? (
            <h4 className="modal-title">
              {Source.detail.player} {Source.detail.team} (
              {Source.detail.position})
            </h4>
          ) : (
            ""
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            {showRankingModal ? (
              <h6> {Source.detail.fpp} Average Projection</h6>
            ) : (
              ""
            )}
            <ul>
              {showRankingModal
                ? Source.data.map((data, index) => (
                    <li key={index}>
                      <span>{data.fpp}</span>
                      <p>{data.wbname}</p>
                      <span
                        className="weburl"
                        onClick={() => {
                          openUrl(data.wburl);
                        }}
                      >
                        <i className="fas fa-external-link-alt"></i>
                      </span>
                    </li>
                  ))
                : ""}
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      {/* =============== COMPARISION MODAL  ===============  */}
      <Modal
        show={showComparisionModal}
        onHide={comparisonHandleClose}
        size="lg"
        centered
        keyboard={false}
        className="ranking-modal comparision-modal"
      >
        <Modal.Header>
          <h5>Player Comparision</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="search-button-wrapper">
            <div className="search-wrapper">
              <div className="player-search-wrap mt-0">
                <div className="player-search-box">
                  {counter !== 2 ? (
                    <React.Fragment>
                      <span>
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        type="text"
                        name=""
                        placeholder="Search Player"
                        value={searchValue}
                        onChange={(e) => {
                          setsearchValue(e.target.value);
                          getPlayerHanlder(e.target.value);
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  <If condition={counter > 0}>
                    <div className="sreached-player-block">
                      {counter > 0 ? (
                        <span
                          onClick={() => removePlayerHandler(Player1Compare.id)}
                        >
                          {Player1Compare.player}
                          <i className="far fa-times-circle"></i>
                        </span>
                      ) : (
                        ""
                      )}
                      {counter === 2 ? (
                        <span
                          onClick={() => removePlayerHandler(Player2Compare.id)}
                        >
                          {Player2Compare.player}
                          <i className="far fa-times-circle"></i>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </If>
                </div>
              </div>
              <If condition={ShowcompPlayerList}>
                <ul
                  className={
                    SearchedPlayer.length < 5
                      ? "search-result h-auto"
                      : "search-result h-400"
                  }
                >
                  {SearchedPlayer.length !== 0
                    ? SearchedPlayer.map((list, index) => (
                        <li key={index} onClick={() => PlayerCompare(list)}>
                          {list.player}
                        </li>
                      ))
                    : ""}
                </ul>
              </If>
            </div>
          </div>
          <div className="playerComparisonWrap">
            <div className="playerComparison1">
              {Player2Compare.length !== 0 ? (
                <h4 className="modal-title">
                  {Player1Compare.player} {Player1Compare.team} (
                  {Player1Compare.position})
                </h4>
              ) : (
                ""
              )}
              {Player2Compare.length !== 0 ? (
                <h6> {Player1Compare.rank} Average Ranking</h6>
              ) : (
                ""
              )}
              <ul>
                {Player2Compare.length !== 0
                  ? Player1Compare.sources.map((data, index) => (
                      <li key={index}>
                        <span>{data.fpp}</span>
                        <p>{data.wbname}</p>
                        <span
                          className="weburl"
                          onClick={() => {
                            openUrl(data.wburl);
                          }}
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </span>
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
            <div className="playerComparison2">
              {Player2Compare.length !== 0 ? (
                <h4 className="modal-title">
                  {Player2Compare.player} {Player2Compare.team} (
                  {Player2Compare.position})
                </h4>
              ) : (
                ""
              )}
              {Player2Compare.length !== 0 ? (
                <h6> {Player2Compare.rank} Average Ranking</h6>
              ) : (
                ""
              )}
              <ul>
                {Player2Compare.length !== 0
                  ? Player2Compare.sources.map((data, index) => (
                      <li key={index}>
                        <span>{data.fpp}</span>
                        <p>{data.wbname}</p>
                        <span
                          className="weburl"
                          onClick={() => {
                            openUrl(data.wburl);
                          }}
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </span>
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </Fragment>
  );
}

export default Projectile;
