import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <section className="brd-cmb  p-50 ">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="./"> Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Privacy Policy
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <section className="privacy-policy-wrapper">
        <div className="container">
          <div className="privacy-policy">
            <h3 className="title">Privacy Policy</h3>
            <h4>Introduction</h4>
            <p>
              This Privacy Policy explains Fanalyze Inc’s (“Fanalyze” or “we” or
              “our”) privacy practices for visitors to our proprietary iOS,
              Android and Web Application (the “Fanalyze App”). We created this
              Privacy Policy to explain what information we gather from you when
              you visit our Fanalyze App and how we may use and disclose this
              information. This Privacy Policy is incorporated into and made a
              part of Fanalyze’s Terms of Use.
            </p>
            <ul>
              <p> This policy applies to information we collect: </p>
              <li>On this Fanalyze App</li>
              <li>
                In email, text and other electronic messages between you and
                this Fanalyze App.{" "}
              </li>
              <li>
                {" "}
                When you interact with our advertising and applications on
                third-party websites and services if those applications or
                advertising include links to this policy. It does not apply to
                information collected by:{" "}
              </li>
              <li>
                {" "}
                Us offline or through any other means, including any third party
                (including our affiliates); or{" "}
              </li>
              <li>
                {" "}
                Any third party (including our affiliates), including through
                any application or content (including advertising) that may link
                to or be accessible from the Fanalyze App.
              </li>
            </ul>
            <p>
              By using our Fanalyze App, you consent to the collection, use and
              disclosure of your personal information as described in this
              Privacy Policy. We reserve the right to change this Privacy Policy
              at any time. (See “Changes to Our Privacy Policy” section)
            </p>
            <h4>How Do We Use Your Information?</h4>
            <p>
              We collect information you provide directly to us. For example,
              you share information directly with us when you create an account,
              fill out a form, submit or post content through our Services,
              purchase a membership, communicate with us via third-party
              platforms, request customer support, or otherwise communicate with
              us. The types of personal information we may collect include your
              name, email address, your content, photos, posts, and any other
              information you choose to provide.
            </p>
            <p>
              We do not collect payment information through our Services. We
              rely on third parties to process payments in connection with our
              Services. Any information you provide to facilitate such a payment
              is subject to the third-party payment processor’s privacy policy,
              and we encourage you to review this policy before you provide any
              information to the payment processor.
            </p>
            <div>
              <h4>
                {" "}
                Information We Collect Automatically When You Interact with Us
              </h4>
              <p>
                In some instances, we automatically collect certain information,
                including
              </p>
              <ul>
                <li>
                  <b>Activity Information:</b> We collect information about your
                  activity on our Services, such as, pages visited and when you
                  share links
                </li>
                <li>
                  <b>Transactional Information:</b> When you purchase a
                  membership, we collect information about the transaction, such
                  as subscription details, purchase price, and the date of the
                  transaction.
                </li>
                <li>
                  <b>Device and Usage Information:</b> We collect information
                  about how you access our Services, including data about the
                  device and network you use, such as your hardware model,
                  operating system version, mobile network, IP address, unique
                  device identifiers, browser type, and app version. We also
                  collect information about your activity on our Services, such
                  as access times, pages viewed, links clicked, and the page you
                  visited before navigating to our Services.
                </li>
              </ul>
              <h4>Use of Information</h4>
              <p>
                {" "}
                We use the information we collect to provide, maintain, and
                improve our Services. We also use the information we collect to:{" "}
              </p>
              <ul>
                <li>Create and maintain your Fanalyze account; </li>
                <li>
                  {" "}
                  Process transactions and send related information, such as
                  confirmations, receipts, and user experience surveys;{" "}
                </li>
                <li>
                  {" "}
                  Send you technical notices, security alerts, and support and
                  administrative messages;{" "}
                </li>
                <li>
                  Respond to your comments and questions and provide customer
                  service;
                </li>
                <li>
                  Communicate with you about new content, products, services,
                  and features offered by Fanalyze and provide other news and
                  information we think will interest you
                </li>
                <li>
                  Monitor and analyze trends, usage, and activities in
                  connection with our Services;
                </li>
                <li>
                  Detect, investigate, and prevent security incidents and other
                  malicious, deceptive, fraudulent, or illegal activity and
                  protect the rights and property of Fanalyze and others;
                </li>
                <li>Debug to identify and repair errors in our Services;</li>
                <li>Comply with our legal and financial obligations; and</li>
                <li>
                  Carry out any other purpose described to you at the time the
                  information was collected.
                </li>
              </ul>
              We may use the information that we collect from you when you
              register to use the Mobile App, purchase services from the Mobile
              App, surf the Mobile App or use certain features of the Mobile App
              in the following ways: 1. To allow us to better serve you in
              responding to your customer service requests. 2. To quickly
              process your transactions. 3. To administer a promotion, survey or
              other feature of the Mobile App.
              <h4>Deletion of personal Information</h4>
              <p></p>
              According to the Facebook Platform rules, we have to provide User
              Data Deletion Callback URL . If you want to delete your activities
              for Fanalyze.com, you can remove your activities by the following
              instructions.
              <ol>
                <li>
                  Go to Your Facebook Account’s Setting &amp; Privacy. Click
                  "Setting ".
                </li>
                <li>
                  Then, go to "Apps and Websites" and you will see all of your
                  Apps activities.
                </li>
                <li>Select the option box of Fanalyze.</li>
                <li>Click "Remove" button.</li>
                <li>
                  Congratulation , you are successfully remove your activities
                </li>
              </ol>
              <h4>Account Deletion Process:</h4>
              <p>User Initiated Deletion:</p>
              <p>
                If you decide to delete your account on Fanalyze App, you can do
                so by following these steps:
              </p>
              <ol>
                <li>Log in to your account.</li>
                <li>Navigate to the menu & scroll down</li>
                <li>Look for the "Delete Account" or similar option.</li>
                <li>
                  Follow the provided prompts to confirm and complete the
                  deletion process.
                </li>
              </ol>
              <p>
                Data Retention:
                <br /> Once you initiate the deletion process, we will promptly
                deactivate your account and remove your personal information
                from our active databases. However, please note that some data
                may be retained for a limited period in our backup systems or as
                required by law.
              </p>
              <p>
                Data Removal:
                <br />
                We ensure that your personal information is securely and
                permanently deleted from our servers within a reasonable
                timeframe after account deactivation. This includes removing any
                identifiable information associated with your account, such as
                profile details, order history, and other personal data
              </p>
            </div>

            <p></p>
            <h4> Children Under the Age of 13</h4>
            <p>
              Our Fanalyze App is not intended for children under 13 years of
              age. No one under age 13 may provide any personal information on
              the Fanalyze App. We do not knowingly collect personal information
              from children under 13. If you are under 13, do not use or provide
              any information on this Fanalyze App or through any of its
              features, register on the Fanalyze App, make any purchases through
              the Fanalyze App, use any of the interactive or public comment
              features of this Fanalyze App, or provide any information about
              yourself to us, including your name, address, telephone number,
              email address, or any screen name or user name you may use. If we
              learn we have collected or received personal information from a
              child under 13 without verification of parental consent, we will
              delete that information. If you believe we might have any
              information from or about a child under 13, please contact us at
              dev@fanalyze.com.
            </p>
            <h4>What Information Do We Collect?</h4>
            <p>
              We collect information from you when you register to use or log
              into the Fanalyze App, place an order for services through the
              Fanalyze App, respond to communication such as e-mail or
              participate in other features of our Fanalyze App.
            </p>
            <p>
              When registering to use the Fanalyze App or ordering services from
              the Fanalyze App, we may ask you for information by which you may
              be personally identified, such as your name, e-mail address,
              mailing address, phone number, or credit card information. You
              may, however, visit certain unrestricted areas of the Fanalyze App
              without providing such information.
            </p>
            <ol>
              <p> Additionally, we collect:</p>
              <li>
                IP addresses and session identifiers to analyze trends, to
                administer the Fanalyze App, to track user activities, to infer
                user interests and to otherwise induce, deduce and gather
                information about individual users and market segments.
              </li>
              <li>
                Information about visitors and visits to our Fanalyze App, like
                many applications.
              </li>
            </ol>
            <h4>How Do We Use Your Information?</h4>
            <p>
              We may use the information, including any personal information,
              that we collect from you when you register to use the Fanalyze
              App, purchase services from the Fanalyze App, surf the Fanalyze
              App or use certain features of the Fanalyze App in the following
              ways:{" "}
            </p>
            <ol>
              <li>
                {" "}
                allow us to better serve you in responding to your customer
                service requests.
              </li>
              <li>
                {" "}
                provide you with information, products, or services that you
                request from us.
              </li>
              <li> fulfill any other purpose for which you provide it.</li>
              <li>
                {" "}
                provide you with notices about your subscription, including
                expiration and renewal notices.
              </li>
              <li>
                {" "}
                carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection.{" "}
              </li>
              <li>
                {" "}
                notify you about any changes to our Fanalyze App or any products
                or services we offer or provide through it.{" "}
              </li>
              <li>
                {" "}
                allow you to participate in interactive features on our Fanalyze
                App.
              </li>
              <li> quickly process your transactions.</li>
              <li>
                {" "}
                administer a promotion, survey, or other feature of the Fanalyze
                App.
              </li>
              <li>For any other purpose with your consent.</li>
            </ol>
            <p>
              We may also use your information to contact you about our goods
              and services that may be of interest to you. If you do not want us
              to use your information in this way, please check the relevant box
              located on the registration form or click on the opt-out link
              provided in the email
            </p>
            <h4>
              Do We Disclose the Information We Collect to Outside Parties?
            </h4>
            <p>
              We generally do not sell to, trade with, or otherwise transfer to
              outside parties your personally identifiable information unless we
              provide you with advance notice, except as described herein. The
              term "outside parties" does not include hosting partners and other
              parties who assist us in operating our Fanalyze App, conducting
              our business, or servicing you, so long as those parties agree to
              keep this information confidential. In the event that we sell our
              company, or otherwise transfer any assets of our company, we may
              provide your information to the purchaser so that you may continue
              the relationship or business with us and our products. We may also
              release your information when we believe that release is necessary
              to comply with law, enforce the policies of our Fanalyze App or
              protect our or others' rights, property, or safety. However,
              non-personally identifiable visitor information may be provided to
              other parties for marketing, advertising, or other uses.
            </p>
            <h4>Accessing and Correcting Your Information</h4>
            <p>
              You can review and change your personal information by logging
              into the Fanalyze App and visiting your account profile page.
            </p>
            <p>
              You may also send us an email at dev@fanalyze.com to request
              access to, correct any personal information that you have provided
              to us. We may not accommodate a request to change information if
              we believe the change would violate any law or legal requirement
              or cause the information to be incorrect.
            </p>
            <p>
              If you delete your User Contributions from the Website, copies of
              your User Contributions may remain viewable in cached and archived
              pages or might have been copied or stored by other Fanalyze App
              users. Proper access and use of information provided on the
              Fanalyze App, including User Contributions, is governed by our
              terms of use [URL LINK TO TERMS OF USE].
            </p>
            <h4>Safeguarding Your Personal Information</h4>
            <p>
              We follow generally accepted industry security standards to
              safeguard and help prevent unauthorized access, maintain data
              security, and correctly use personal identification and financial
              information. However, no commercial method of information transfer
              over the Internet or electronic data storage is known to be 100%
              secure. As a result, we cannot guarantee the absolute security of
              such information during its transmission or its storage in our
              systems, and you should always take care to safeguard your
              personal information.{" "}
            </p>
            <p>
              The safety and security of your information also depends on you.
              Where we have given you (or where you have chosen) a password for
              access to certain parts of our Fanalyze App, you are responsible
              for keeping this password confidential. We ask you not to share
              your password with anyone. We urge you to be careful about giving
              out information in public areas of the Fanalyze App like
              discussion forums. The information you share in public areas may
              be viewed by any user of the Website.
            </p>
            <h4>Changes to our Privacy Policy </h4>
            <p>
              Policy changes will apply only to information collected after the
              date of the change. Your continued use of this Fanalyze App after
              we make changes is deemed to be an acceptance of those changes, so
              please check the policy periodically for updates. If we make any
              material changes to our Privacy Policy, we will post a new policy
              on our website and update the “last updated” date set forth above.
              Additionally, if we make material changes to how we treat our
              users’ personal information, we will notify you [by email to the
              email address specified in your account] [and/or] [through a
              notice on the Fanalyze App home page]. The date the privacy policy
              was last revised is identified at the top of the page. You are
              responsible for ensuring we have an up-to-date active and
              deliverable email address for you, and for periodically visiting
              our Fanalyze App and this privacy policy to check for any changes.
            </p>
            <h4>
              What Should You Consider Before Clicking on Third Party Links?
            </h4>
            <p>
              This Privacy Policy applies solely to information collected by our
              Fanalyze App. In an attempt to provide you with increased value,
              we may include third party links on our Fanalyze App. These linked
              websites and Fanalyze Apps have separate and independent privacy
              policies. We therefore have no responsibility or liability for the
              content and activities of these linked websites and Fanalyze Apps.
              Nonetheless, we seek to protect the integrity of our Fanalyze App
              and welcome any feedback about these linked websites and Fanalyze
              Apps (including if a specific link does not work).
            </p>
            <h4>Where Do I Direct Questions About this Privacy Policy?</h4>
            <p>
              We welcome your questions, comments, and concerns about our
              Privacy Policy. Please send us any and all feedback pertaining to
              this Privacy Policy to dev@fanalyze.com.
            </p>

            <h4>HOW DO WE HANDLE YOUR SOCIAL LOGINS</h4>
            <p>
              If you choose to register or log in to our Services using a social
              media account, we may have access to certain information about
              you.
            </p>

            <p>
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media
              platform.
            </p>

            <p>
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use, and share your personal information, and how
              you can set your privacy preferences on their sites and apps.
            </p>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default PrivacyPolicy;
