import React, { useState, useContext, useEffect, Fragment } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory,Link } from "react-router-dom";
import  instance, {scrapInstance}  from "./Utility/axios";
import request from "./Utility/Request";
import { errorToast, successToast } from "./Utility/toastify";
// import axios from "./Utility/axios";

const ImportTeam = () => {
  const [loader,setLoader] = useState(false);
    const [siteType,setSiteType] = useState("espn");
    let history = useHistory();
    const [formData, setFormData] = useState({
        teamName: '',
        url: '',
        siteType,
      });

    useEffect(() => {
    setFormData({
        ...formData,
        siteType,
    })
    }, [siteType])

    
      const [errors, setErrors] = useState({});
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const validateForm = () => {
        let isValid = true;
        const newErrors = {};
    
        // Validate team name
        if (!formData.teamName.trim()) {
          newErrors.teamName = 'Team Name is required';
          isValid = false;
        }
    
        // Validate team URL
        if (!formData.url.trim()) {
          newErrors.url = 'Team URL is required';
          isValid = false;
        } else if (!/^(ftp|http|https):\/\/[^ "]+$/.test(formData.url)) {
          newErrors.url = 'Invalid URL format';
          isValid = false;
        }
    
        setErrors(newErrors);
        return isValid;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        
        let data ={
          site_type:formData.siteType,
          url:formData.url
        }
        if (validateForm()) {
          setLoader(true);
          try {
            const response = await scrapInstance.post(request.IMPORT_TEAM, data);
            if (response) {
            //console.log(response.data.records);
            AddTeamHandler(response.data.records)
            }else{
              setLoader(false);
            }
          } catch (err) {
            console.log(err)
          }
          // You can make an API call or perform other actions here
        } else {
          console.log('Form is invalid. Please fix the errors.');
          setLoader(false);
        }
      
      };

      const checkCategoty = () => {
        let cat = localStorage.getItem("category_type").toLowerCase();
        console.log("cat",cat)
        if(cat === "nfl"){
          return 3;
        } else if(cat === "mlb"){
          return 2;
        }   else{
          return 1;
        }
        
      }

      

      const AddTeamHandler = async (playerInfo) => {
        let sendheaders = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tokens"),
          },
        };
        let data = {
          category : checkCategoty(),
          teamName : formData.teamName,
          playerInfo

        }
        try {
          const response = await instance.post(request.UPDATE_IMPORT_TEAM, data,sendheaders);
          if(response.status === 200){
            successToast("Team Imported successfully");
            history.push("/team");
          }else{
            errorToast("Please Try Again");
          }
          setLoader(false);

        } catch (error) {
          setLoader(false);
        }
      }



    
  return (
    <Fragment>
        {/* ====== BREADCRUMB ====== */}

        <section className="brd-cmb  p-50">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="./team">Team</Link>
              </li>
              {/*
              <li className="breadcrumb-item">
                <span>Profile</span>
              </li>
              */}
              <li className="breadcrumb-item active" aria-current="page">
                import Team
              </li>
            </ol>
          </nav>
        </div>
      </section>
    <section>
    <div className="container">
        <div className="import-wrapper">
        <div className="ulrtabs">
            <span className={siteType === "espn" && "active" } onClick={()=>setSiteType("espn")}>ESPN</span>
            <span className={siteType === "nfl" && "active" } onClick={()=>setSiteType("nfl")}>nfl</span>
        </div>
        <form onSubmit={handleSubmit}>
        <div className="input-group">
           
            <input
            type="text"
            name="teamName"
            className="form-control"
            placeholder="Team Name"
            value={formData.teamName}
            onChange={handleChange}
            />
            {errors.teamName && <span style={{ color: 'red' }}>{errors.teamName}</span>}
        </div>
        <div className="input-group">
            <input
            type="text"
            name="url"
            className="form-control"
            placeholder="Copy and Paste your team URL"
            value={formData.url}
            onChange={handleChange}
            />
            {errors.url && <span style={{ color: 'red' }}>{errors.url}</span>}
        </div>
        <button type="submit" className="common-btn">Import</button>
        </form>
        </div>
    </div>
    </section>
    <div className={loader ? "sipnner-wrap" : "d-none"}>
                          <Spinner animation="border" />
                        </div>
    </Fragment>
  )
}

export default ImportTeam