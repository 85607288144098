import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal, Dropdown, DropdownButton, Accordion } from "react-bootstrap";
import {
  FaEnvelope,
  FaUserAlt,
  FaLock,
  FaRegEye,
  FaRegEyeSlash,
  FaBars,
} from "react-icons/fa";
import { useForm } from "react-hook-form";
import axios from "./Utility/axios";
import request from "./Utility/Request";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { successToast, errorToast } from "./Utility/toastify";
import { GetTeamPlayerData, getUserSettings } from "./Utility/actionMethod";
import { propOr, has } from "ramda";
import { ToastContainer } from "react-toastify";
import { Context } from "./Utility/store";
import { If, Then, Else } from "react-if";

// import GoogleLogin from "react-google-login";
// import FacebookLogin from "react-facebook-login";
// import { gapi } from "gapi-script";
// import { load } from "gapi-script";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import {LoginSocialFacebook} from 'reactjs-social-login'
import {FacebookLoginButton} from 'react-social-login-buttons'

import { initializeApp } from 'firebase/app';
import { getAnalytics,logEvent } from 'firebase/analytics';
import { firebaseConfig } from "./Utility/actionMethod";




// IMAGES
import logo from "../assets/images/logo.png";
import loginLogo from "../assets/images/logo_f.png";
import user from "../assets/images/icons/user.svg";
import nflOdds from "../assets/images/icons/nfl_odds.svg";
import lock from "../assets/images/icons/lock.svg";
import subscription from "../assets/images/icons/subps.svg";
import cog from "../assets/images/icons/cog.svg";
import logout from "../assets/images/icons/logout.svg";

const clientId =
  "361827779858-57du0jecouul4cb8b9ttnvuosu9m091r.apps.googleusercontent.com";

const registrationValidation = Yup.object({
  name: Yup.string().required("name is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
}).required();

const loginValidation = Yup.object({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
}).required();

const forgetPassValidation = Yup.object({
  email: Yup.string().required("Email is required").email("Email is invalid"),
}).required();

const Navbar = () => {
  const [state, dispatch] = useContext(Context);
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLogin, setisLogin] = useState(false);
  const [userName, setuserName] = useState("");
  const [isteam, setisTeam] = useState(true);
  const [playerInfo, setplayerInfo] = useState([]);
  let optData = localStorage.getItem("NFL_OPTIMAL_RANKING");
  const [optimaldata, setOptimaldata] = useState([]);

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  //
  const SignupHandleShow = () => {
    setShowSignup(true);
  };
  const LoginHandleShow = () => {
    setShowLogin(true);
  };

  const SignupHandleClose = () => {
    setShowSignup(false);
    reset();
  };
  const LoginHandleClose = () => {
    setShowLogin(false);
    reset2();
    reset3();
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const ForgetPasswordClose = () => {
    setShowForgetPassword(false);
    reset3();
    reset2();
  };

  const LoginToSingUp = () => {
    setShowLogin(false);
    setShowSignup(true);
  };
  const HandleShowForgetPassword = () => {
    setShowLogin(false);
    reset3();
    setShowForgetPassword(true);
  };
  let history = useHistory();

  const [showTeamModal, setshowTeamModal] = useState(false);
  const TeamModalClose = () => setshowTeamModal(false);
  const [forgetPass, setforgetPass] = useState(false);
  const TeamModalOpen = () => {
    setshowTeamModal(true);
  };
  const [supribedPlan, setsupribedPlan] = useState([]);
  const [teamData, setteamData] = useState([]);
  const [selectedOptimalPlayer, setSelectedOptimalPlayer] = useState({});

  const [rankData, setRankData] = useState([]);
  let cat_type = localStorage.getItem("category_type");
  let tokens;


  // const [rankData, setRankData] = useState([]);
  const [topRank, setTopRank] = useState([]);
  const [optimalRankData, setOptimalRankData] = useState([]);
  
  const positionRank = ["QB", "RB", "WR", "TE", "DE", "K"];
  const nba_draft_pos = ["PG", "SG", "SF", "PF", "C"];

  // let cat_type = localStorage.getItem("category_type");
  //let optData = localStorage.getItem("NFL_OPTIMAL_RANKING");
  // let topRank = []
  const positions =
    cat_type === "NFL" || cat_type === "nfl"
      ? ["QB", "RB", "WR", "TE", "K", "D"]
      : cat_type === "NBA" || cat_type === "nba"
      ? ["G", "C", "F", "F-G", "C-F", "G-F"]
      : ["C", "P", "OF", "IF"];

  const getTopCount = [
    ["QB", 1],
    ["RB", 2],
    ["WR", 2],
    ["TE", 1],
    ["K", 1],
    ["D", 1],

    ["G", 1],
    ["C", 1],
    ["F", 1],
    ["F-G", 1],
    ["C-F", 1],
    ["G-F", 1],

    ["C", 1],
    ["P", 1],
    ["OF", 1],
    ["IF", 1],
  ];


useEffect(()=>{
  if(state.login){
    let user = localStorage.getItem("userDetail");
    if(user !== null && user !== undefined && user !== "" && user !== "null" ){
      setuserName((JSON.parse(user)).username)
    }
  }
},[state])



  useEffect(() => {
    if (state.token !== "" || state.token !== null) {
      getUser();
      userTeam();
      getUserSettings(dispatch);
    }
  }, [state.token]);

  useEffect(() => {
    const loc = window.location.pathname;
    const url = window.location.href.split("/").slice(-2)[0];

    if (url === "forgetpassword") {
      setforgetPass(true);
    } else {
      setforgetPass(false);
    }

    // try {
    //   let response = axios
    //     .get(request.GET_RANKING_DATA)
    //     .then((responses) => {
    //       console.log("stateee", state);
    //       dispatch({ type: "NFL_OPTIMAL_RANKING", payload: responses.data });
    //       localStorage.setItem(
    //         "NFL_OPTIMAL_RANKING",
    //         JSON.stringify(responses.data)
    //       );
    //        setOptimaldata(
    //          responses.data.length > 0 ? responses.data : JSON.parse(optData)
    //        );
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
    OptimalDataHandler(cat_type);






      // Load the Facebook SDK asynchronously
      // window.fbAsyncInit = function () {
      //   window.FB.init({
      //     appId: "1265278907519029",
      //     autoLogAppEvents: true,
      //     xfbml: true,
      //     version: 'v13.0', // Use the latest version of the SDK
      //   });
      // };
  
      // Initialize the SDK
      // (function (d, s, id) {
      //   var js,
      //     fjs = d.getElementsByTagName(s)[0];
      //   if (d.getElementById(id)) return;
      //   js = d.createElement(s);
      //   js.id = id;
      //   js.src = 'https://connect.facebook.net/en_US/sdk.js';
      //   fjs.parentNode.insertBefore(js, fjs);
      // })(document, 'script', 'facebook-jssdk');
  }, []);


  const OptimalDataHandler = (cateType) => {
  
    let getRequest =
      cateType === "nfl" || cateType === "NFL"
        ? request.GET_RANKING_DATA
        : cateType === "mlb" || cateType === "MLB"
        ? request.GET_MLB_DRAFT_DATA
        : request.GET_NBA_RANKING_DATA;

    try {
      let response = axios
        .get(getRequest)
        .then((responses) => {
          dispatch({ type: "NFL_OPTIMAL_RANKING", payload: responses.data });
         
          localStorage.setItem(
            "NFL_OPTIMAL_RANKING",
            JSON.stringify(responses.data)
          );
           
          let allData = [];
          let MapPostion =
            cat_type === "nfl" || cat_type === "NFL"
              ? positionRank
              : nba_draft_pos;

          MapPostion.map(
            (pos) => {
              propOr([], pos, JSON.parse(JSON.stringify(responses.data))).map(
                (data) => {
                  allData.push(data);
                }
              );
            },
            cat_type === "nfl" || cat_type === "NFL"
              ? positionRank
              : nba_draft_pos
          );
          setRankData(allData);

          // setOptimaldata(
          //   responses.data.length > 0 ? responses.data : JSON.parse(optData)
          // );
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    OptimalDataHandler(cat_type);
  }, [state.category_type]);
  

  useEffect(() => {
    if (state.openModal) {
      setShowSignup(true);
      dispatch({ type: "OPEN_MODAL", payload: false });
    }
  }, [state.openModal]);

  useEffect(() => {
    if (state.openLoginModal) {
      setShowLogin(true);
      dispatch({ type: "OPEN_LOGIN_MODAL", payload: false });
    }
  }, [state.openLoginModal]);

  // (function(d, s, id) {
  //   var js, fjs = d.getElementsByTagName(s)[0];
  //   if (d.getElementById(id)) return;
  //   js = d.createElement(s); js.id = id;
  //   js.src = "//connect.facebook.net/en_US/sdk.js";
  //   fjs.parentNode.insertBefore(js, fjs);
  // }(document, 'script', 'facebook-jssdk'));

//   (function(d, s, id){
//     var js, fjs = d.getElementsByTagName(s)[0];
//     if (d.getElementById(id)) {return;}
//     js = d.createElement(s); js.id = id;
//     js.src = "https://connect.facebook.net/en_US/sdk.js";
//     fjs.parentNode.insertBefore(js, fjs);
//   }(document, 'script', 'facebook-jssdk')
// );

  const responseFacebook = (response) => {
    console.log("responseFacebook",response)
    if (response.accessToken !== "" || response.accessToken !== undefined) {
      const userLoginData = {
        token: response.accessToken,
        id: response.id,
        email: response.email,
        name: response.name,

        type: "facebook",
      };
      
      console.log("userLoginData",userLoginData)
      socialLogin(userLoginData);
    }
  };



  // const handleFacebookLogin = () => {
  //   window.FB.login(
  //     function (response) {
  //       if (response.authResponse) {
  //         console.log('Logged in:', response);
  //         // Handle the login success, e.g., authenticate the user on your server
  //       } else {
  //         console.log('Login canceled or failed.');
  //       }
  //     },
  //     { scope: 'public_profile,email' } // Adjust the required permissions based on your needs
  //   );
  // };

  // REGITER FORM
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(registrationValidation) });
  const registerSubmit = (data) => {
    let newdata = {
      username: data.name,
      email: data.email,
      password: data.password,
    };
    const register = async () => {
      try {
        const response = await axios.post(request.SIGN_UP, newdata);
        if (response.data.Authorization) {
          if(response.data.email_varify === "1"){
          const message = response.data.message;
          successToast(message);
          SignupHandleClose();
       
        if (response.data.Authorization) {
          const message = response.data.message;
          successToast(message);
          SignupHandleClose();
        }
      }else{
        successToast("Register Successfully Please verify your email");
         // Log registration event
         logEvent(analytics, 'registration_event',  {
          tokens: response.data.Authorization,
          user_name:response.data.username,
          
        });
        
        SignupHandleClose();
        LoginHandleShow();
      }
      }
        if (response.data.status === "failure") {
          const message = response.data.message;
          errorToast(message);
        }
      } catch (err) {
        if (!err.respsonse) {
          errorToast(err.message);
        }
      }
    };
    register(data);
  };
  //LOGIN FORM
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm({ resolver: yupResolver(loginValidation) });

  const loginSubmit = (data) => {
    const login = async () => {
      try {
        const response = await axios.post(request.LOGIN, data);
        if (response.data.email_varify === "1") {
          if (response.data.Authorization) {
            successToast("You have logged Successfully");
            LoginHandleClose();
            localStorage.setItem("tokens", response.data.Authorization);
            localStorage.setItem("user_id", response.data.user_id);
            localStorage.setItem("user_name", response.data.username);
            localStorage.setItem("userDetail", JSON.stringify(response.data));
            // sessionStorage.setItem("tokens", response.data.Authorization);
            // sessionStorage.setItem("user_id", response.data.user_id);
            // sessionStorage.setItem("user_name", response.data.username);
            setuserName(response.data.username);

            localStorage.setItem("islogin", true);
            localStorage.setItem("islogged", true);
            //sessionStorage.setItem('userDetail',JSON.stringify(response.data))
            //sessionStorage.setItem("islogin", true);
            //sessionStorage.setItem("islogged", true);
            dispatch({ type: "LOGIN", payload: true });
            dispatch({ type: "TOKEN", payload: response.data.Authorization });
            setisLogin(true);

            setTimeout(() => {
              //getUserSettings(dispatch);
              localStorage.setItem("category_type", "nfl");
              // getUser();
              // userTeam();
              history.push("/");
            }, 1000);
          }
        } else {
          errorToast("Please Verify Your Account");
        }
      } catch (err) {
        if (!err.respsonse) {
          errorToast(err.response.data.message);
        }
      }
    };
    login(data);
  };

  // FORGET PASSWORD

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    reset: reset3,
  } = useForm({ resolver: yupResolver(forgetPassValidation) });

  const forgetpassSubmit = (data) => {
    const forgetpass = async () => {
      try {
        const response = await axios.post(request.RESET_PASSWORD, data);
        if (response.data.status === "Successfull") {
          successToast(response.data.message);
          ForgetPasswordClose();
        }
      } catch (err) {
        if (!err.respsonse) {
          errorToast(err.message);
        }
      }
    };
    forgetpass(data);
  };

  // LOG OUT
  const LogoutHandler = () => {
    setisLogin(false);
    setuserName("");
    localStorage.setItem("islogin", false);
    localStorage.setItem("islogged", false);
    localStorage.setItem("userDetail", null);
    localStorage.setItem("user_name", "");
    localStorage.setItem("token", "");
    localStorage.setItem("tokens", "");
    sessionStorage.setItem("islogin", false);
    sessionStorage.setItem("islogged", false);
    sessionStorage.setItem("userDetail", null);
    sessionStorage.setItem("user_name", "");
    sessionStorage.setItem("token", null);
    dispatch({ type: "TOKEN", payload: "" });
    setTimeout(() => {
      localStorage.setItem("username", "");
      localStorage.setItem("category_type", "nfl");
      getUserSettings(dispatch);
      history.push("/");
    }, 1000);
    window.FB.logout(response => {
      console.log('Logged out:', response);
    });

  };

  var islogged = localStorage.getItem("islogged");
  // var islogged = sessionStorage.getItem("islogged");
  useEffect(() => {
    if (islogged === "false") {
      dispatch({ type: "LOGIN", payload: false });
      setisLogin(false);
      setuserName("");
    } else {
      dispatch({ type: "LOGIN", payload: true });
      setisLogin(true);
      setuserName(request.User_Name);
      getUser();
      userTeam();
    }
    // if(token !== "" || token !== null){
    //   alert(0)
    //   dispatch({type: 'LOGIN', payload: true })
    //   setisLogin(true);
    //   setuserName(request.User_Name);
    //   getUser();
    //   userTeam();

    // }else{
    //   dispatch({type: 'LOGIN', payload: false})
    //   setisLogin(false);
    //   setuserName("");
    // }
  }, []);

  // useEffect(() => {
  //   if(state.login === true){
  //     checkSubsciption();
  //   }
  // }, [state.login])

  //GET USER DATA
  const getUser = async () => {
    setTimeout(async () => {
      let sendheaders = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokens"),
        },
      };
      try {
        const response = await axios.get(request.GET_USER_DATA, sendheaders);
        if (response) {
          if (response.data.success) {
            localStorage.setItem("userDetail", JSON.stringify(response.data));
          }
        }
      } catch (err) {}
    }, 1500);
  };

  // const userTeam = async () => {
  //   let sendheaders = {
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("tokens"),
  //     },
  //   };
  //   try {
  //     const response = await axios.get(request.GET_USER_TEAMS, sendheaders);
  //     if (response.data.length > 0) {
  //       setteamData(response.data);
  //     }
  //   } catch (err) {
  //     if (!err.respsonse) {
  //     }
  //   }
  // };

  // ======= MAPPING PLAYERINFO ========
  const playerInfoList = (playerData) => {
    playerData.map((playersInfo) => {
      setplayerInfo((prevValue) => [...prevValue, playersInfo.players_info]);
    });
  };

  const userTeam = async () => {
    let sendheaders = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokens"),
      },
    };
    try {
      const response = await axios.get(request.GET_USER_TEAMS, sendheaders);
      if (response.data.length > 0) {
        setisTeam(true);
        playerInfoList(response.data);
        //setLoader(false);

        let len = response.data.length;

        response.data.forEach((ele, ind) => {
          setSelectedOptimalPlayer((prev) => {
            if (ind === len - 1) {
              setteamData(response.data);
            }
            return { ...prev, [ele.id]: "players" };
          });
        });

        // setTimeout(()=>{
        //   setteamData(response.data);
        // },5000)
      } else {
        setisTeam(false);
      }
    } catch (err) {
      if (!err.respsonse) {
      }
    }
  };

  useEffect(() => {
    if (state.myteam) {
      userTeam();
    }
  }, [state.myteam]);

  const getPLayerDetail = (player) => {
    let data = {
      id: player.id_of_player,
      name: player.player_name,
      photo_url: player.url,
      position: player.position,
    };

    if (localStorage.getItem("player_1"))
      if (
        localStorage.getItem("player_1") === "" ||
        localStorage.getItem("player_1") === undefined
      ) {
        localStorage.setItem("player_1", JSON.stringify(data));
        history.push("/player-profile");
      } else if (
        localStorage.getItem("player_1") !== "" &&
        (localStorage.getItem("player_2") === "" ||
          localStorage.getItem("player_2") === undefined)
      ) {
        dispatch({
          type: "MY_TEAM_PLAYER",
          payload: { Ptwo: true, Pthree: false },
        });
        localStorage.setItem("player_2", JSON.stringify(data));
        history.push("/player-comparision");
      } else if (
        localStorage.getItem("player_1") !== "" &&
        localStorage.getItem("player_2") !== "" &&
        (localStorage.getItem("player_3") === "" ||
          localStorage.getItem("player_3") === undefined)
      ) {
        localStorage.setItem("player_3", JSON.stringify(data));
        dispatch({ type: "MY_TEAM_PLAYER", payload: true });
        history.push("/player-comparision");
      }
  };

  // useEffect(() => {
  //   gapi.load("client:auth2", () => {
  //     gapi.auth2.init({ clientId: clientId });
  //   });
  // }, []);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://apis.google.com/js/api.js";
  //   script.onload = () => {
  //     gapi.load("client:auth2", () => {
  //       gapi.auth2.init({ clientId: clientId }).then(() => {
  //         console.log("Google API initialized");
  //       });
  //     });
  //   };
  //   document.body.appendChild(script);
  // }, []);

  // const responseGoogle = (response) => {
  //   if (response.tokenId !== "" && response.tokenId !== undefined) {
  //     const userLoginData = {
  //       token: response.tokenId,
  //       id: response.googleId,
  //       email: response.profileObj.email,
  //       name: response.profileObj.name,
  //       type: "google",
  //     };
  //     socialLogin(userLoginData);
  //   }
  // };

  const responseGoogle = (response) => {
    var decode = jwt_decode(response.credential);

    if (decode) {
      const userLoginData = {
        token: decode.sub,
        id: decode.jti,
        email: decode.email,
        name: decode.name,
        type: "google",
      };
      socialLogin(userLoginData);
    } else {
      console.error("Google login response is missing required properties.");
    }
  };

  const socialLogin = async (data) => {
    try {
      const response = await axios.post(request.SOCIAL_LOGIN, data);

      if (response.data.status === "failed") {
        errorToast(response.data.message);
      } else {
        successToast("You have logged Successfully");
        LoginHandleClose();
        localStorage.setItem("tokens", response.data.Authorization);
        localStorage.setItem("user_id", response.data.user_id);
        localStorage.setItem("user_name", response.data.username);
        localStorage.setItem("userDetail", JSON.stringify(response.data));
        setuserName(response.data.username);
        localStorage.setItem("islogin", true);
        localStorage.setItem("islogged", true);
        dispatch({ type: "LOGIN", payload: true });
        dispatch({ type: "TOKEN", payload: response.data.Authorization });
        setisLogin(true);
           // Log registration event
         
            logEvent(analytics, 'registration_event',  {
              tokens: response.data.Authorization,
              user_name:response.data.username,
              
            });

        setTimeout(() => {
          //getUserSettings(dispatch);
          localStorage.setItem("category_type", "nfl");
          // getUser();
          // userTeam();
          history.push("/");
        }, 1000);
      }
    } catch (err) {
      if (!err.respsonse) {
        errorToast(err.response.data.message);
      }
    }
  };

  const playerFormatter = (players) => {
    const group = players.reduce((r, a) => {
      r[a.position] = [...(r[a.position] || []), a];
      return r;
    }, {});
    return group;
  };

  function isDuplicatePlayer(players, newPlayerId) {
    return players.some((player) => player.id === newPlayerId);
  }

  const PlayerProfileHandler = async (players, category) => {


   const player = {
     player_name: players.name,
     url: players.url,
     id_of_players: players.pid,
     id: players.id,
     position: players.position,
     rank: players.rank,
   };


    let type = "player";
    let id = propOr("", "id_of_player", player);
    var storedPlayer = localStorage.getItem("playerteamInfo");

    storedPlayer =
      storedPlayer && typeof storedPlayer === "string"
        ? JSON.parse(storedPlayer).length
        : 0;
    if (storedPlayer === 1) {
      var ExP = JSON.parse(localStorage.getItem("playerteamInfo"));
      var checkDup = isDuplicatePlayer(ExP, id);
      if (checkDup) {
        errorToast("Player Already Selected");
      } else {
        await GetTeamPlayerData(
          dispatch,
          category.toLowerCase(),
          type,
          id,
          false,
          state
        );
        //dispatch({ type: "TEAM_PLAYER_SELECTED", payload: true });
        history.push("/player-comparision");
      }
    } else if (storedPlayer === 2) {
      var ExP = JSON.parse(localStorage.getItem("playerteamInfo"));
      var checkDup = isDuplicatePlayer(ExP, id);
      if (checkDup) {
        errorToast("Player Already Selected");
      } else {
        await GetTeamPlayerData(
          dispatch,
          category.toLowerCase(),
          type,
          id,
          true,
          state
        );
        dispatch({ type: "TEAM_PLAYER_SELECTED", payload: true });
      }
    } else {
      await GetTeamPlayerData(
        dispatch,
        category.toLowerCase(),
        type,
        id,
        false,
        state
      );
      let teamurl = player.logo_url;
      if (teamurl === undefined) {
        localStorage.setItem("player_1", "");
        localStorage.setItem("player_1", JSON.stringify(player));
        localStorage.setItem("isplayer1", true);
      } else {
        localStorage.setItem("team_1", "");
        localStorage.setItem("team_1", JSON.stringify(player));
        localStorage.setItem("isteam", true);
      }
      history.push("/player-profile");
    }
    // localStorage.setItem("player_1" ,"");
    // localStorage.setItem("player_1" ,JSON.stringify(player));
    // localStorage.setItem("isplayer1",true);
    TeamModalClose();
  };

//  const handleOptionClick = (id) => {
//    const team = teamData.find((item) => item.id === id);

//    let prevTeam = teamData;
//    console.log({ prevTeam });

//    if (cat_type.toLowerCase() !== "nfl") return;

//    const players = playerFormatter(team?.players_info) || {};
//    const names = Object.fromEntries(
//      Object.entries(players).map(([key, value]) => [
//        key,
//        value.map((r) => r.player_name),
//      ])
//    );
//    console.log({ optimaldata });
//    let newObj = {};
//    Object.keys(players).forEach((ele) => {
//      if (optimaldata.hasOwnProperty(ele)) {
//        newObj[ele] = optimaldata[ele].filter((e) =>
//          names[ele].includes(e.player)
//        );
//      }
//    });

//    const teamIdToUpdate = id;
//    const RankDataArry = rankData;

//    const teamToUpdateIndex = prevTeam.findIndex(
//      (team) => team.id === teamIdToUpdate
//    );

//    if (teamToUpdateIndex !== -1) {
//      const updatedPlayersArray = JSON.parse(
//        JSON.stringify(prevTeam[teamToUpdateIndex].players_info)
//      );

//      updatedPlayersArray.forEach((playerInfo) => {
//        const playerName = playerInfo.player_name;
//        const player = Object.values(newObj)
//          .flatMap((players) => players)
//          .find((player) => player.player === playerName);

//        if (player) {
//          playerInfo.position = player.position;
//          //playerInfo.url = player.sources[0]?.wburl || "";
//          playerInfo.source = player.sources[0]?.wburl || "";
//          playerInfo.rank = player.rank;
//        }
//      });

//      prevTeam[teamToUpdateIndex].players_info = updatedPlayersArray;

//      const existingTeamIndex = RankDataArry.filter((ele) => ele.id === team.id);

//      if (existingTeamIndex.length > 0) {
//        //RankDataArry[team.id] = prevTeam[teamToUpdateIndex];
//        let index = RankDataArry.findIndex((ele) => ele.id === team.id);
//        RankDataArry[index] = team;
//      } else {
//        setRankData([...rankData, team]);
//        RankDataArry.push(team);
//      }
//    }
//    console.log({ RankDataArry });
//  };


 

 const comparePlayerName = (playerName, rankName) => {
   let playerNameArray = playerName.replace(/[^a-zA-Z ]/g, "").split(" ");
   let rankNameArray = rankName.replace(/[^a-zA-Z ]/g, "").split(" ");

   if (playerNameArray.length === 1) {
     if (playerName === rankName) {
       return true;
     } else {
       return false;
     }
   } else if (playerNameArray.length === 2) {
     let data = playerNameArray.filter((item) => rankNameArray.includes(item));
     if (data.length === 2) {
       return true;
     } else {
       return false;
     }
   } else if (playerNameArray.length === 3) {
     let data = playerNameArray.filter((item) => rankNameArray.includes(item));
     if (data.length === 2) {
       return true;
     } else {
       return false;
     }
   } else if (rankNameArray.length === 3) {
     let data = rankNameArray.filter((item) => playerNameArray.includes(item));
     if (data.length === 2) {
       return true;
     } else {
       return false;
     }
   }
 };

 let tempArr = [];

 const PositionHandler = (position, group, teamId) => {

 
   if (group[position] !== undefined) {
     group[position].map((play) => {
       console.log({ rankData });
       let data = rankData.find((rank) =>
         comparePlayerName(
           cat_type === "nfl" || cat_type === "NFL"
             ? play.player_name.toLowerCase()
             : cat_type === "nba" || cat_type === "NBA"
             ? play.player_name.toLowerCase()
             : play.player_name.toLowerCase(),
           rank.player.toLowerCase()
         )
       );

      

       if (data !== undefined) {
         const formatTopRank = {
           name: play.player_name,
           url: play.url,
           pid: play.id_of_player,
           id: play.id,
           position: play.position,
           rank: parseFloat(data.rank),
         };
         topRank.push(formatTopRank);
       }
       // console.log({ topRank });
     });
   }

   const finalR = topRank.sort((a, b) => a["rank"] - b["rank"]);

   const playerInfo = finalR.reduce((r, a) => {
     r[a.position] = [...(r[a.position] || []), a];
     return r;
   }, {});

   let mergingIdPlayer = {
     teamId,
     playerInfo,
   };

   updateOrPush(optimalRankData, mergingIdPlayer);
   // preVData.push(mergingIdPlayer);
 };

 function updateOrPush(dataArray, newData) {
   const index = dataArray.findIndex((item) => item.teamId === newData.teamId);

   if (index !== -1) {
     // Update existing data
     dataArray[index] = newData;
   } else {
     // Push new data
     dataArray.push(newData);
   }

   console.log("dataArray", dataArray);
   setOptimalRankData(dataArray);
 }

 useEffect(() => {
   console.log(optimalRankData);
 }, [optimalRankData]);

 function getTopCountForTeam(team) {
   const countEntry = getTopCount.find((entry) => entry[0] === team);
   console.log(countEntry[1]);
   return countEntry ? countEntry[1] : null;
 }

 const OptimalRankHandler = (team) => {
  console.log("team", team);
   setTopRank([]);
   const group = team.players_info.reduce((r, a) => {
     r[a.position] = [...(r[a.position] || []), a];
     return r;
   }, {});
   positions.map((p) => {
     PositionHandler(p, group, team.id);
   });
 };


  return (
    <React.Fragment>
      <header id="header" className="not-home">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="logo" />
            </Link>

            <>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavbar"
              >
                <FaBars />
              </button>
              {/*loggedIn*/}
              <div
                className={
                  islogged === true || islogged === "true"
                    ? "collapse navbar-collapse loggedIn"
                    : "collapse navbar-collapse"
                }
                id="collapsibleNavbar"
              >
                <ul className="navbar-nav">
                  {/*  <li className="nav-item">
                  <Link className="nav-link" to="/">
                    home
                  </Link>
                </li>
               
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    about
                  </Link>
                </li>
               
                 
                <li className="nav-item">
                  <Link className="nav-link" to="/odd-comparision">
                    Odds Comparision
                  </Link>
                </li>
                 */}
                  {islogged === true ||
                    (islogged === "true" && (
                      <li className="nav-item">
                        <Link className="nav-link" to="/team">
                          team
                        </Link>
                      </li>
                    ))}

                  {/*
                 
                 
                 <li className="nav-item">
                  <Link className="nav-link" to="/pricing">
                    pricing
                  </Link>
                </li>
               
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    contact
                  </Link>
                </li>
                 */}
                </ul>
              </div>

              <div className="login-btns">
                {islogged === false ||
                islogged === "false" ||
                islogged === null ? (
                  <div className="before_login">
                    <button
                      className="common-btn active"
                      data-bs-toggle="modal"
                      data-bs-target="#login-modal"
                      onClick={()=>history.push('/login')}
                    >
                      sign in
                    </button>
                    <button className="common-btn" onClick={()=>history.push('/register')}>
                      sign up
                    </button>
                  </div>
                ) : (
                  <div className="after_login d-block">
                    <DropdownButton
                      id="dropdown-basic-button"
                      className=" user_dropdown "
                      title={islogged ? userName : ""}
                    >
                      {/* title= { request.User_Name }*/}
                      <Dropdown.Item href="/profile">
                        <span>
                          {" "}
                          <img src={user} alt="-" />
                        </span>{" "}
                        Profile{" "}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={TeamModalOpen}>
                        <span>
                          {" "}
                          <img src={user} alt="-" />
                        </span>{" "}
                        My Teams{" "}
                      </Dropdown.Item>
                      {/*
                      <Dropdown.Item href="/nflodds">
                        <span>
                          {" "}
                          <img src={nflOdds} alt="-" />
                        </span>{" "}
                        NFL Odds
                        AIzaSyAio9xKIiqoEc1qFe0DpSIIVx81kalgJig
                      </Dropdown.Item>
*/}

                      {/* <Dropdown.Item href="/nfldraftcheat">
                        <span>
                          {" "}
                          <img src={nflOdds} alt="-" />
                        </span>{" "}
                        NFL DratCheatSheet
                      </Dropdown.Item> */}
                      <Dropdown.Item href="/nfloddcalc">
                        <span>
                          {" "}
                          <img src={nflOdds} alt="-" />
                        </span>{" "}
                        NFL Odds Calculator
                      </Dropdown.Item>
                      <Dropdown.Item href="/changepassword">
                        <span>
                          {" "}
                          <img src={lock} alt="-" />
                        </span>{" "}
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item href="/subscription">
                        <span>
                          {" "}
                          <img src={subscription} alt="-" />
                        </span>
                        Subscription
                      </Dropdown.Item>
                      <Dropdown.Item href="/setting">
                        <span>
                          {" "}
                          <img src={cog} alt="-" />
                        </span>{" "}
                        Settings
                      </Dropdown.Item>
                      <Dropdown.Item onClick={LogoutHandler}>
                        <span>
                          {" "}
                          <img src={logout} alt="-" />
                        </span>{" "}
                        Logout
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                )}
              </div>
            </>
          </nav>
        </div>
      </header>

      {/* ===============  FORGET PASSWORD MODAL===============  */}
      <Modal
        show={showForgetPassword}
        onHide={ForgetPasswordClose}
        size="lg"
        centered
        keyboard={false}
        className="login-modal"
      >
        <Modal.Body>
          <div className="row g-0">
            <div className="col-xl-6">
              <div className="login-detail">
                <img src={loginLogo} alt="login-logo" />
                <h5>
                  {" "}
                  Welcome to <span> Fanalyze! </span> We're building the sports
                  search engine and analysis platform.{" "}
                </h5>
                <h6>
                  Speeding up research for fantasy sports and sports betting.
                </h6>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="login-form">
                <form key="3" onSubmit={handleSubmit3(forgetpassSubmit)}>
                  <h4> forget password </h4>
                  <div>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        {...register3("email")}
                      />
                    </div>
                    <span className="error">{errors3.email?.message}</span>
                  </div>
                  <button className="common-btn with-border">
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ===============  LOGIN MODAL===============  */}
      <Modal
        show={showLogin}
        onHide={LoginHandleClose}
        size="lg"
        centered
        keyboard={false}
        className="login-modal"
      >
        <Modal.Body>
          <div className="row g-0">
            <div className="col-xl-6">
              <div className="login-detail">
                <img src={loginLogo} alt="login-logo" />
                <h5>
                  {" "}
                  Welcome to <span> Fanalyze! </span> We're building the sports
                  search engine and analysis platform.{" "}
                </h5>
                <h6>
                  Speeding up research for fantasy sports and sports betting.
                </h6>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="login-form">
                <form key="2" onSubmit={handleSubmit2(loginSubmit)}>
                  <h4> sign in </h4>

                  <div>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        {...register2("email")}
                      />
                    </div>
                    <span className="error">{errors2.email?.message}</span>
                  </div>

                  <div>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <FaLock />
                      </span>
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control"
                        placeholder="password"
                        {...register2("password")}
                      />
                      <span
                        toggle="#password"
                        className="toggle-password"
                        onClick={togglePassword}
                      >
                        {" "}
                        {passwordShown ? <FaRegEye /> : <FaRegEyeSlash />}
                      </span>
                    </div>
                    <span className="error">{errors2.password?.message}</span>
                  </div>

                  <button className="common-btn login-btn">login</button>
                  <div className="line-wrap">
                    <h6 className="line">or</h6>
                  </div>

                  {/*
                  <div className="google-btn">
                    <GoogleLogin
                      clientId={clientId}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  </div>
                  */}
                  {/* <div>
                    <FacebookLogin
                      appId="1265278907519029"
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      cssClass="my-facebook-button-class"
                      icon="fa-facebook"
                      scope="email"
                    />
                  </div> */}

                  <div>
                    <LoginSocialFacebook
                    appId="1265278907519029"
                    onResolve={(response)=>{
                      console.log(response.data)
                      responseFacebook(response.data)
                    }}
                    onReject={(err)=>{
                      console.log(err)
                    }}
                    >
                      <FacebookLoginButton/>
                    </LoginSocialFacebook>
                  </div>
                  
                  {/*361827779858-57du0jecouul4cb8b9ttnvuosu9m091r.apps.googleusercontent.com*/}
                  <div className={"google-btn"}>
                    <GoogleOAuthProvider clientId="361827779858-57du0jecouul4cb8b9ttnvuosu9m091r.apps.googleusercontent.com">
                      <GoogleLogin
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                      ></GoogleLogin>
                    </GoogleOAuthProvider>
                  </div>
                  <span
                    className="forget-pass"
                    onClick={HandleShowForgetPassword}
                  >
                    forget password?
                  </span>

                  <p>
                    Don’t have an account?{" "}
                    <span onClick={LoginToSingUp}>Signup</span>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ===============  SIGN UP MODAL===============  */}
      <Modal
        show={showSignup}
        onHide={SignupHandleClose}
        size="lg"
        centered
        keyboard={false}
        className="login-modal"
      >
        <Modal.Body>
          <div className="row g-0">
            <div className="col-xl-6">
              <div className="login-detail">
                <img src={loginLogo} alt="login-logo" />
                <h5>
                  {" "}
                  Welcome to <span> Fanalyze! </span> We're building the sports
                  search engine and analysis platform.{" "}
                </h5>
                <h6>
                  Speeding up research for fantasy sports and sports betting.
                </h6>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="login-form">
                <form onSubmit={handleSubmit(registerSubmit)}>
                  <h4> sign up </h4>
                  <div>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <FaUserAlt />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        {...register("name")}
                      />
                    </div>
                    <span className="error">{errors.name?.message}</span>
                  </div>

                  <div>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <FaEnvelope />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        {...register("email")}
                      />
                    </div>
                    <span className="error"> {errors.email?.message}</span>
                  </div>

                  <div>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <FaLock />
                      </span>
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control"
                        placeholder="password"
                        {...register("password")}
                      />
                      <span
                        toggle="#password"
                        className="toggle-password"
                        onClick={togglePassword}
                      >
                        {" "}
                        {passwordShown ? <FaRegEye /> : <FaRegEyeSlash />}
                      </span>
                    </div>
                    <span className="error"> {errors.password?.message}</span>
                  </div>

                  <button className="common-btn with-border">signup </button>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showTeamModal}
        onHide={TeamModalClose}
        size="lg"
        centered
        keyboard={false}
        className=""
      >
        <Modal.Header>
          <h4>My Team</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body team-modal-body">
            {teamData.map(
              (myteams, index) =>
                myteams.category.toLowerCase() === cat_type.toLowerCase() && (
                  <div className="row" key={myteams.id}>
                    <div className="col-lg-12">
                      <Accordion defaultActiveKey="0" className="team_acc">
                        <Accordion.Item eventKey={"" + index}>
                          <Accordion.Header className="test">
                            <div className="team-name">
                              <h6>{myteams.team_name}</h6>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="team-actions">
                              <div className="player-team-wrapper">
                                <div className="player-team-inner">
                                  <span
                                    className={`${
                                      selectedOptimalPlayer[myteams.id] ===
                                        "players" ||
                                      selectedOptimalPlayer[myteams.id] ===
                                        undefined
                                        ? "active"
                                        : ""
                                    } `}
                                    onClick={() =>
                                      //handleOptionClick(index, "players")
                                      setSelectedOptimalPlayer({
                                        ...selectedOptimalPlayer,
                                        [myteams.id]: "players",
                                      })
                                    }
                                  >
                                    {" "}
                                    Players{" "}
                                  </span>
                                  <span
                                    className={`${
                                      selectedOptimalPlayer[myteams.id] ===
                                      "optimalrank"
                                        ? "active"
                                        : ""
                                    } `}
                                    onClick={() => {
                                      OptimalRankHandler(myteams);
                                      setSelectedOptimalPlayer({
                                        ...selectedOptimalPlayer,
                                        [myteams.id]: "optimalrank",
                                      });
                                    }}
                                  >
                                    Optimal Lineup{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="positions-wrapper">
                              {selectedOptimalPlayer[myteams.id] ===
                              "players" ? (
                                Object.keys(
                                  playerFormatter(myteams.players_info)
                                ).map((val, key) => (
                                  <>
                                    <div className="player-positions">
                                      {" "}
                                      <span>{val}</span>{" "}
                                    </div>
                                    <ul className="player_list">
                                      {playerFormatter(myteams.players_info)[
                                        val
                                      ].map((players) => (
                                        <li
                                          key={players.id}
                                          onClick={() =>
                                            PlayerProfileHandler(
                                              players,
                                              myteams.category
                                            )
                                          }
                                        >
                                          <div className=" player-detail">
                                            <div className="player-img-3">
                                              {" "}
                                              <span>
                                                <img
                                                  src={players.url}
                                                  loading="lazy"
                                                  alt="-"
                                                />
                                              </span>
                                            </div>
                                            {players.player_name === ""
                                              ? "no player"
                                              : players.player_name}
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </>
                                ))
                              ) : (
                                <div className="">
                                  {optimalRankData.map(
                                    (teams) =>
                                      teams.teamId === myteams.id &&
                                      Object.keys(teams.playerInfo).map(
                                        (position) => (
                                          <>
                                            <div>
                                              <div className="player-positions">
                                                <span>{position}</span>
                                              </div>
                                            </div>
                                            <ul className="player_list">
                                              {teams["playerInfo"][
                                                position
                                              ].map((player, i) => (
                                                <li
                                                  onClick={() =>
                                                    PlayerProfileHandler(
                                                      player,
                                                      myteams.category
                                                    )
                                                  }
                                                >
                                                  <div className="player-detail">
                                                    <div className="player-img-3">
                                                      <span>
                                                        <img
                                                          src={player.url}
                                                          loading="lazy"
                                                          alt="-"
                                                        />
                                                      </span>
                                                    </div>
                                                    {player.name}
                                                  </div>
                                                  <div>
                                                    <span className="common-btn ">
                                                      {player.rank}
                                                    </span>
                                                  </div>
                                                </li>
                                              ))}
                                            </ul>
                                          </>
                                        )
                                      )
                                  )}
                                </div>
                              )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                )
            )}

            <ul></ul>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer autoClose={2000} />
    </React.Fragment>
  );
};

export default Navbar;
