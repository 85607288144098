import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { successToast, errorToast } from "./Utility/toastify";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import axios from "./Utility/axios";
import request from "./Utility/Request";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const Pricing = () => {
  const [payment, setpayment] = useState(false);
  const [plan_id, setPlan_id] = useState([]);
  const [subPlan,setsubPlan] = useState();
  const [subAmt,setsubAmt] = useState();
  const paymentClose = () => {
    setpayment(false);
  };
  const Handlepayment = (planID) => {
    setpayment(true);
    setPlan_id(planID);
  };

  let user_id = JSON.parse(localStorage.getItem("user_id"));
  // let user_id = user_detail.user_id;

  const registrationValidation = Yup.object({
    firstname: Yup.string().required("first Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    line1: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip: Yup.number().required("Zip is required"),
    country: Yup.string().required("Country is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    cardnumber: Yup.number("Only Number is allowed").required(
      "Card Number is required"
    ),
    expiry_month: Yup.number().required("Expiry Month is required"),
    expiry_year: Yup.number().required("Expiry Year is required"),
    cvv: Yup.number()
      .required("CVV is required")
      .min(3, "Password must be at least 6 characters"),
    // expiry_year
  }).required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(registrationValidation),
    mode: "onBlur",
  });

  const registerSubmit = (data) => {
    let new_data = {
      user_id: user_id,
      plan_id: plan_id,
    };

    let paymentDetail = {
      ...data,
      ...new_data,
    };

     pay(paymentDetail);
  };

  const pay = async (paymentDetail) => {
    try{
          const response = await axios.post(request.SUBCRIPTION,paymentDetail);
          if(response){
            getUser();
          }else{
            const message = response.data.message;
            errorToast(message);
          }
    }catch(err){
      if(!err.respsonse){
        errorToast(err.message);
      }
    }
};

const getUser = async() => {
  let sendheaders = {
    headers: {
      Authorization: "Bearer "+ request.Token,
    },
  };
try{
    const response = await axios.get(request.GET_USER_DATA,sendheaders);
    if(response){
      localStorage.setItem("userDetail", JSON.stringify(response.data));     
    }
}catch(err){
if(!err.respsonse){  
}
}

}


  return (
    <Fragment>
      {/*======= FANTASY SPORTS ======== */}
      <section className="fantasy_sports p-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="title_2 pb-5">
                {" "}
                Get Advanced Data & Predictive Analytics{" "}
              </h2>
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-xl-6 col-lg-6">
              <div className="fantasy_sports_img">
                <img
                  src="./assets/images/pricing_1.png"
                  alt="fantasy_sports_img"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="fantasy_sports_pricing">
                <h2 className="title_2"> Fantasy Sports Only </h2>
                <div className="price-card-wrapper">
                  <div
                    className="pricing-card"
                    onClick={() => {Handlepayment("pro-all-sports");setsubPlan("Fantasy Sports Only Yearly");setsubAmt("53.99")}}
                  >
                    <div className="pack-period">
                      <p>Yearly</p>
                      <span>Save 50%</span>
                    </div>
                    <div className="pack-price">
                      <h2>
                        <sup>$</sup>53 <sub className="after-dec">.99</sub>
                      </h2>
                    </div>
                    <div className="price-popular-tag">
                      <p>Most Popular</p>
                    </div>
                  </div>
                  <div
                    className="pricing-card"
                    onClick={() => {Handlepayment("pro-all-sports-monthly");setsubPlan("Fantasy Sports Only Monthly");setsubAmt("8.99")}}
                  >
                    <div className="pack-period">
                      <p>Monthly</p>
                    </div>
                    <div className="pack-price">
                      <h2>
                        <sup>$</sup>8 <sub className="after-dec">.99</sub>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*======= FANTASY SPORTS ======== */}
      <section className="fantasy_sports pt-0 p-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 order-xl-1 order-lg-1 order-2">
              <div className="fantasy_sports_pricing">
                <h2 className="title_2">Fantasy Sports & Sports Betting </h2>
                <div className="price-card-wrapper">
                  <div className="pricing-card active"
                  onClick={() => {Handlepayment("all-star-all-sports-yearly");setsubPlan("Fantasy Sports & Sports Betting  Yearly");setsubAmt("174.99")}}
                  >
                    <div className="pack-period"
                 
                    >
                      <p>Yearly</p>
                      <span>Save 50%</span>
                    </div>
                    <div className="pack-price">
                      <h2>
                        <sup>$</sup>174 <sub className="after-dec">.99</sub>
                      </h2>
                    </div>
                    <div className="price-popular-tag">
                      <p>Most Popular</p>
                    </div>
                  </div>
                  <div className="pricing-card"
                  onClick={() => {Handlepayment("all-star-all-sports-monthly");setsubPlan("Fantasy Sports & Sports Betting  Monthly");setsubAmt("28.99")}}
                  >
                    <div className="pack-period">
                      <p>Monthly</p>
                    </div>
                    <div className="pack-price">
                      <h2>
                        <sup>$</sup>28 <sub className="after-dec">.99</sub>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 order-xl-2 order-lg-2 order-1">
              <div className="fantasy_sports_img">
                <img
                  src="./assets/images/pricing_2.png"
                  alt="fantasy_sports_img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*======= FEATURE TABLE ======== */}
      <section className="feat_table p-100">
        <div className="container">
          <table className="table table-bordered table-striped feature_table">
            <thead>
              <tr>
                <th>All Features</th>
                <th>Fantasy Date</th>
                <th>Sports Betting Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Advanced player data</td>
                <td>
                  {" "}
                  <img
                    src="assets/images/table_check.png"
                    alt="table_check"
                  />{" "}
                </td>
                <td>
                  <img src="assets/images/table_check.png" alt="table_check" />
                </td>
              </tr>

              <tr>
                <td>Customized Player Analysis</td>
                <td>
                  {" "}
                  <img
                    src="assets/images/table_check.png"
                    alt="table_check"
                  />{" "}
                </td>
                <td>
                  <img src="assets/images/table_check.png" alt="table_check" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      {/*======= FEATURE POINTS ======== */}
      <section className="p-100  feature-points">
        <div className="container">
          <ul>
            <li>
              <h6>Advanced Analytics for All Sports</h6>
              <p>
                Get advanced player data with customized analysis for all your
                favorite sports
              </p>
            </li>

            <li>
              <h6>Create Teams for Quick Analysis</h6>
              <p>
                Create your own custom team, so you can follow your favorite
                players and get the latest news and trends
              </p>
            </li>

            <li>
              <h6>Predictive Analytics</h6>
              <p>
                Our technology analyzes historical odds data and trends to give
                you an accurate prediction, so you can get an edge on your bets
              </p>
            </li>
          </ul>
        </div>
      </section>

      {/*======= GOOGLE FANTASY ======== */}
      <section className="p-100 google-fantasy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="title_2 pb-5">
                {" "}
                The Google of Fantasy Sports & Betting{" "}
              </h2>
            </div>
          </div>

          <ul>
            <li>
              {" "}
              <span>
                <i className="fas fa-angle-right"></i>
              </span>{" "}
              Advanced player data{" "}
            </li>
            <li>
              {" "}
              <span>
                <i className="fas fa-angle-right"></i>
              </span>{" "}
              Customized analysis{" "}
            </li>
            <li>
              {" "}
              <span>
                <i className="fas fa-angle-right"></i>
              </span>{" "}
              Aggregated weekly rankings, projections, waiver wire, and start
              and sits{" "}
            </li>
            <li>
              {" "}
              <span>
                <i className="fas fa-angle-right"></i>
              </span>{" "}
              Graphical trends & predictive analytics{" "}
            </li>
            <li>
              {" "}
              <span>
                <i className="fas fa-angle-right"></i>
              </span>{" "}
              Historical player and team data{" "}
            </li>
          </ul>
        </div>
      </section>

      {/*======= SUCCESS STORIES ======== */}
      <section className="success-stories-wrapper p-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="title_2 pb-5"> Success Stories </h2>
            </div>
          </div>

          <div
            className="owl-carousel owl-theme success-stories"
            id="success-stories"
          >
            <div className="item">
              <div className="story-wrapper">
                <h5>P. Guirao</h5>
                <div className="rating">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
                <h6>Information I have been looking for</h6>
                <p>
                  Finally an app that gives me the information I need to help me
                  decide and build my fantasy lineups
                </p>
              </div>
            </div>
            <div className="item">
              <div className="story-wrapper">
                <h5>Jayden2088</h5>
                <div className="rating">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
                <h6>Time-saving and User-friendly</h6>
                <p>
                  This app is hidden gem. Been looking for a sports data app
                  with a focus on search engine
                </p>
              </div>
            </div>
            <div className="item">
              <div className="story-wrapper">
                <h5>P. Guirao2</h5>
                <div className="rating">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
                <h6>Information I have been looking for</h6>
                <p>
                  Finally an app that gives me the information I need to help me
                  decide and build my fantasy lineups
                </p>
              </div>
            </div>
            <div className="item">
              <div className="story-wrapper">
                <h5>Jayden20882</h5>
                <div className="rating">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
                <h6>Time-saving and User-friendly</h6>
                <p>
                  This app is hidden gem. Been looking for a sports data app
                  with a focus on search engine
                </p>
              </div>
            </div>

            <div className="item">
              <div className="story-wrapper">
                <h5>P. Guirao3</h5>
                <div className="rating">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
                <h6>Information I have been looking for</h6>
                <p>
                  Finally an app that gives me the information I need to help me
                  decide and build my fantasy lineups
                </p>
              </div>
            </div>
            <div className="item">
              <div className="story-wrapper">
                <h5>Jayden20883</h5>
                <div className="rating">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
                <h6>Time-saving and User-friendly</h6>
                <p>
                  This app is hidden gem. Been looking for a sports data app
                  with a focus on search engine
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*======= FAQ ======== */}
      <section className="faq p-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="title_2 pb-5"> Frequently Asked Questions </h2>
            </div>
          </div>

          <div className="faq-wrapper">
            <div className="faq-questions">
              <h5>
                What makes Fanalyze different from other fantasy & sports
                betting apps?
              </h5>
              <p>
                Fanalyze is focused on providing sports data and comparisons
                instantly through search saving users time with relavant data.
              </p>
            </div>

            <div className="faq-questions">
              <h5>Will this subscription renew automatically?</h5>
              <p>
                All subscription renew automatically for your convenience and to
                avoid any interruption of service. Your subscriptions can be
                managed and auto-renewal turned off by going to your iTunes
                settings after purchase.
              </p>
            </div>

            <div className="faq-questions">
              <h5>How do I cancel my subscription?</h5>
              <p>
                Your subscription can be cancelled through your iTunes account
                or profile screen..
              </p>
            </div>

            <div className="faq-questions">
              <h5>How do I get a refund?</h5>
              <p>
                Payments via iTunes can only be approved and refunded by Apple.
                To receive a refund you need to contact iTunes support within 14
                days from the purchase date.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={payment}
        onHide={paymentClose}
        size="lg"
        centered
        keyboard={false}
        className="login-modal payment-modal"
      >
        <Modal.Body>
          <div className="row g-0">
            <div className="col-xl-12">
              <form onSubmit={handleSubmit(registerSubmit)}>
                <h4 className="mb-5"> Subcription Plan - {subPlan} </h4>
                <div className="row">
                <div className="col-lg-6">
                <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"></span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        {...register("firstname")}
                        />
                    </div>
                    <span className="error">{errors.firstname?.message}</span>
                    </div>
                </div>
                    <div className="col-lg-6">
                    <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"></span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        {...register("lastname")}
                        />
                    </div>
                    <span className="error">{errors.lastname?.message}</span>
                    </div>
                    </div>
                    <div className="col-lg-12">
                    
                    <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"></span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="Address"
                        {...register("line1")}
                        />
                    </div>
                    <span className="error">{errors.line1?.message}</span>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    
                    <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"></span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        {...register("city")}
                        />
                    </div>
                    <span className="error">{errors.city?.message}</span>
                    </div></div>
                    <div className="col-lg-6">
                    <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"></span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="state"
                        {...register("state")}
                        />
                    </div>
                    <span className="error">{errors.state?.message}</span>
                    </div>
</div>
                    <div className="col-lg-6">
                    <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"></span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="zip"
                        {...register("zip")}
                        />
                    </div>
                    <span className="error">{errors.zip?.message}</span>
                    </div>
</div>
                    <div className="col-lg-6">
                      
                    <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"></span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="Country"
                        {...register("country")}
                        />
                    </div>
                    <span className="error">{errors.country?.message}</span>
                    </div>
</div>
                    <div className="col-lg-12">

                    <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"></span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        {...register("email")}
                        />
                    </div>
                    <span className="error">{errors.email?.message}</span>
                    </div>
                    </div>
                    <div className="col-lg-12">
                    <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"></span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="Card Number"
                        {...register("cardnumber")}
                        />
                    </div>
                    <span className="error">{errors.cardnumber?.message}</span>
                    </div></div>
                    <div className="col-lg-4">
                    <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"></span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="Expiry Month"
                        {...register("expiry_month")}
                        />
                    </div>
                    <span className="error">{errors.expiry_month?.message}</span>
                    </div>

                    </div>
                    <div className="col-lg-4">
                    <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"></span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="Expiry Year"
                        {...register("expiry_year")}
                        />
                    </div>
                    <span className="error">{errors.expiry_year?.message}</span>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"></span>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="CVV"
                        {...register("cvv")}
                        />
                    </div>
                    <span className="error">{errors.cvv?.message}</span>
                    </div>
                    </div>
                </div>
                <button className="common-btn with-border">Pay {subAmt} </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer autoClose={2000} />
    </Fragment>
  );
};

export default Pricing;
