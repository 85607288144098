import React from "react";
import { Link } from "react-router-dom";

const Odds = () => {
  const selected_Cat = localStorage.getItem("category_type").toLowerCase();
  return (
    <div className="container-fluid">
      <section className="brd-cmb  p-50">
        <div className="container">
          <div className="breadcrumb-wrapper">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="./">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {selected_Cat.toUpperCase()} Odds
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="p-50">
        <div className="container">
          {selected_Cat === "nba" ? (
            <iframe
              src="https://tallysight.com/widget/org/fanalyze/NBA/odds/latest/?id=1bd91198-003e-4d03-8715-7bace5d106e2"
              allowtransparency="true"
              title="Fanalyze NBA Picks"
              height="800px"
              width="100%"
              scrolling="auto"
            ></iframe>
          ) : selected_Cat === "mlb" ? (
            <iframe
              src="https://tallysight.com/new/widget/odds/mlb/org:fanalyze/cta/sports:mlb,nba,pga-tour,mma,premier-league/?id=942be8be-5749-4759-a380-17b1abe19d55"
              allowtransparency="true"
              title="Fanalyze MLB Picks"
              height="600px"
              width="100%"
              scrolling="auto"
            ></iframe>
          ) : (
            <iframe
              src="https://tallysight.com/widget/org/fanalyze/NFL/odds/latest/?id=fa367b8e-3ed6-4150-a7a0-647a880620dc"
              allowtransparency="true"
              title="Fanalyze NFL Picks"
              height="800px"
              width="100%"
              scrolling="auto"
            ></iframe>
          )}
        </div>
      </section>
    </div>
  );
};

export default Odds;
