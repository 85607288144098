import axios from "axios";
// axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL:"https://u.fanalyze.com"
  // baseURL: "http://192.168.1.15:3000/",
});

export const apiInstance = axios.create({
    // baseURL:"https://api2.fanalyze.com:8080/v1/"
    baseURL:"https://playerapi.fanalyze.com/v1/" 
}) 

export const baseApi = axios.create({
    // baseURL: "https://u.fanalyze.com:81/php"
    // baseURL: "https://php.fanalyze.com/"
     baseURL: "http://192.168.1.6:3000/",
}) 


export const scrapInstance = axios.create({
  baseURL:"https://python.fanalyze.com/"
// baseURL:"http://23.21.68.255:8000/"
})

export default instance