import React, { Fragment } from 'react';
import {ProgressBar} from "react-bootstrap";
import { map, propOr, pathOr} from 'ramda';

const getSettingFullName = new Map([
  ['AR','air_yards'],
  ['APT', 'avg_pocket_time'],
  ['H', 'hurries'],
  ['K', 'knockdowns'],
  ['OTH', 'on_target_throws'],
  ['RAT', 'redzone_attempts'],

  ['A', 'Attempts'],
  ['B', 'Blocked'],
  ['L', 'Longest'],
  ['PCT', 'Extra Point Percentage'],

  //receiving Exclusive Stats
  ['AY', 'air_yards'],
  ['BT', 'broken_tackles'],
  ['DP', 'dropped_passes'],
  ['T', 'targets'],
  ['RT', 'redzone_targets'],
  ['YAC', 'yards_after_catch'],
  ['YACT', 'yards_after_contact'],

  //defense Exclusive stats
  ['BP', 'batted_passes'],
  ['H', 'hurries'],
  ['K', 'knockdowns'],
  ['QBH', 'qb_hits'],
  ['S', 'safeties'],


    //rushing Exclusive stats
  ['YAC', 'yards_after_catch'],
  ['YACO', 'yards_after_contact'],
  ['BT', 'broken_tackles'],
  ['RZA', 'redzone_targets'],
  ['S', 'Scrambles'],





   //Hitters Exclusive Stats
  ['BABIP', 'BABIP'],
  ['ISO', 'ISO'],
  ['BB', 'BB%'],
  ['LD', 'LD%'],
  ['FB', 'FB%'],
  ['GB', 'GB%'],
  ['HR/FB', 'HR/FB'],
  ['GBFB', 'GBFB'],
  ['K%', 'K%'],


  //Pitchers Exclusive Stats
  ['K9', 'K/9'], 
  ['KBB', 'K/BB'],
  ['IRA', 'IRA'],
  ['BQR', 'BQR'],
  ['BQRS', 'BQR-S'],
  ['PO', 'PO'],
  ['PIP', 'P/IP'],
  ['HR9', 'HR/9'],
  ['HR', 'H/9'],

  // ========= NBA =========
  ['EFF_EX', 'EFF_EX'],
  ['TSP_EX', 'TSP_EX'],
  ['FTR_EX', 'FTR_EX'],
  ['EFP_EX', 'EFP_EX'],
  ['FBP_EX', 'FBP_EX'],
  ['PPP_EX', 'PPP_EX'],
  ['SCP_EX', 'SCP_EX'],

])

const getStatFullName = new Map([
  //Hitters Exclusive Stats
  ['BABIP', 'Batting Average on Balls in Play'],
  ['ISO', 'Isolated Power'],
  ['BB%', 'Walks Per Plate Appearance'],
  ['LD%', 'Line Drive Percentage'],
  ['FB%', 'Fly Ball Percentage'],
  ['GB%', 'Ground Ball Percentage'],
  ['HR/FB', 'Home Run to Fly Ball Rate'],
  ['GBFB', 'Ground Ball to Fly Ball Ratio Total'],
  ['K%', 'Strikeout Rate'],


  //Pitchers Exclusive Stats
  ['K/9', 'Strikeouts Per Nine Innings'],
  ['K/BB', 'Strikeout to Walk Ratio'],
  ['IRA', 'Inherited Runs Allowed'],
  ['BQR', 'Bequeathed Runners'],
  ['BQR-S', 'Bequeathed Runners Allowed'],
  ['PO', 'Pop Outs Forced'],
  ['P/IP', 'Pitches Per Inning Played'],
  ['HR/9', 'Home Runs Per Nine Innings'],
  ['H/9', 'Hits Allowed per Nine Innings'],
  ['BB/9', 'Walks Per Nine Innings'],


  //passing Exclusive Stats
  ['AR', 'Air Yards'],
  ['APT', 'Average Pocket Time'],
  ['H', 'Hurries'],
  ['K', 'Knockdowns'],
  ['OTH', 'On Target Throws'],
  ['RAT', 'Red Zone Attempts'],

  

  
  // ruching Exclusive Stats
  ['YAC', 'Yards After Catch'],
  ['YACO', 'Yards After Contact'],
  ['BT', 'Broken Tackles'],
  ['RZA', 'Red Zone Targets'],
  ['S', 'Scrambles'],



//receiving Exclusive Stats
['AY', 'Air Yards'],
['BT', 'Broken Tackles'],
['DP', 'Dropped Passes'],
['T', 'Targets'],
['RT', 'Red Zone Targets'],
['YAC', 'Yards After Catch'],
['YACT', 'Yards After Contact'],




  //field goals Exclusive Stats
  ['A', 'Attempts'],
  ['B', 'Blocked'],
  ['L', 'Longest'],
  ['PCT', 'Extra Point Percentage'],


  //defense Exclusive stats
  ['BP', 'Batted Passes'],
  ['H', 'Hurries'],
  ['K', 'Knockdowns'],
  ['QBH', 'QB Hits'],
  ['S', 'Safeties'],

  //Hitter Season Stats
  ['G', 'Games'],
  ['AB', 'At Bats'],
  ['R', 'Runs'],
  ['H', 'Hits'],
  ['twoB', 'Doubles'],
  ['threeB', 'Triples'],
  ['HR', 'Home Runs'],
  ['RBI', 'Runs Batted In'],
  ['BB', 'Walks'],
  ['SO', 'Strikouts'],
  ['SB', 'Stolen Bases'],
  ['CS', 'Caught Stealing'],
  ['AVG', 'Batting Average'],
  ['OBP', 'On-Base Percentage'],
  ['SLG', 'Slugging Percentage'],
  ['OPS', 'On-Base Plus Slugging'],

  //Pitcher Season Stats
  ['G', 'Games'],
  ['W', 'Wins'],
  ['L', 'Loses'],
  ['ERA', 'Earned Run Average'],
  ['SV', 'Saves'],
  ['SVO', 'Save Oppurtunities'],
  ['IP', 'Innings Pitched'],
  ['H', 'Hits'],
  ['R', 'Runs'],
  ['ER', 'Earned Runs'],
  ['HR', 'Home Runs'],
  ['HB', 'Hit Batters'],
  ['BB', 'Walks'],
  ['IBB', 'Intentional Walks'],
  ['SO', 'Strikeouts'],
  ['WHIP', 'Walks Plus Hits Per Inning Pitched'],

  // Passing Season Stats
  ['COMP', 'Completions'],
  ['PATT', 'Passing Attempts'],
  ['PYDS', 'Passing Yards'],
  ['PAVG', 'Yards Per Pass Attempt'],
  ['PTD', 'Touchdowns'],
  ['INT', 'Interceptions'],
  ['SCK', 'Sacks'],
  ['QBR', 'Total QBR'],
  ['RATT', 'Rushing Attempts'],
  ['RYDS', 'Rushing Yards'],
  ['RAVG', 'Yards Per Rush Attempt'],
  ['RTD', 'Rushing Touchdowns'],
  ['FUM', 'Fumbles'],
  ['LOST', 'Fumbles Lost'],

  // Rushing Season Stats
  ['RATT', 'Rushing Attempts'],
  ['RYDS', 'Rushing Yards'],
  ['RAVG', 'Yards Per Rush Attempt'],
  ['LNG', 'Long Rushing'],
  ['RTD', 'Rushing Touchdowns'],
  ['REC', 'Receptions'],
  ['REYDS', 'Receiving Yards'],
  ['REAVG', 'Yards Per Reception'],
  ['RETD', 'Receiving Touchdowns'],

  // Receiving Season Stats
  ['REC', 'Receptions'],
  ['YDS', 'Receiving Yards'],
  ['REAVG', 'Yards Per Reception'],
  ['TD', 'Receiving Touchdowns'],
  ['LNG', 'Longest Touchdown'],
  ['TGT', 'Targets'],

  // Defense Season Stats
  ['AST', 'Assist Tackles'],
  ['TOT', 'Total Tackles'],
  ['SACK', 'Total Sacks'],
  ['SCKYADS', 'Sack Yards'],
  ['TFL', 'Tackles For Loss'],
  ['PD', 'Passes Defended'],
  ['INT', 'Interceptions'],
  ['FR', 'Fumbles Recovered'],

  // Field Goals Season Stats
  ['ATT', 'Field Goal Attempts'],
  ['M', 'Field Goals Made'],
  ['MI', 'Field Goals Missed'],
  ['PCT', 'Field Goal Percentage'],
  ['LNG', 'Longest'],
  ['EM', 'Extra Points Made'],
  ['EPCT', 'Extra Point Percentage'],

  // NBA ALl in One Season Stats
  ['GP','Games Played'],
  ['MIN','Minutes Played'],
  ['PTS','Points'],
  ['REB','Rebounds'],
  ['ASTT','Assists'],
  ['TOV','Turnovers'],
  ['STL','Steals'],
  ['BLK','Blocks'],
  ['FGM','Field Goals Made'],
  ['FGA','Field Goals Attempted'],
  ['FGP','Field Goal Percentage'],
  ['THPM','3 Point Field Goals Made'],
  ['THPA','3 Point Field Goals Attempted'],
  ['THPP','3 Point Field Goal Percentage'],
  ['FTA','Free Throws Made'],
  ['FTP','Free Throw Percentage'],
  ['PF','Personal Fouls'],
  ['DDT','Double Doubles'],
  ['TDTH','Triple Doubles'],



  

  // NBA Exclusive stats
  ['EFF_EX', 'Efficiency'],
  ['TSP_EX', 'True Shooting Percentage'],
  ['FTR_EX', 'Free-Throw Rate'],
  ['EFP_EX', 'Effective Field Goal Percentage'],
  ['FBP_EX', 'Fast Break Percentage'],
  ['PPP_EX', 'Points in the Paint Percentage'],
  ['SCP_EX', 'Second Change Percentage'],

]);


const getVariant = [
  {
    name:'success'
  },
  {
    name:'warning'
  },
  {
    name:'danger'
  }
]

const calculatePercentage = (item, total) => {  
   const percentage = Math.round(((item/total) * 100));
   return percentage === 0 ? 50 : isNaN(percentage) ? 50 : percentage === -1 ? 50 :percentage ;
 }


export const Progessbars = (props) => {
  return <Fragment>
  <ProgressBar>
    <ProgressBar label={props.label1}  variant="success" now={props.player1} key={1} />
    <ProgressBar label={props.label2} variant="warning" now={props.player2} key={2} />
    <ProgressBar  label={props.label3} variant="danger" now={props.player3} key={3} />
    </ProgressBar>
  </Fragment>;
};

//{props.data.map((season,index)=>(
    
    
  // )) }
// <ProgressBar label={props.label1}  variant={getVariant[index]} now={props.player1} key={index+1} />
export const ProgessbarsDemo = (props) => {

  let total = pathOr(0,['value','0'],props.data)+pathOr(0,['value','1'],props.data)+pathOr(0,['value','2'],props.data)
    
  return <Fragment>
  <div className="mb-3">
    <p className='mb-0 text-center'>{getStatFullName.get(props.data.keys)}</p>
    <ProgressBar>
      {propOr([],'value',props.data).map((state, index)=> (
        // console.log("ecx" state > 0 ? state : 0)
          <ProgressBar label={state === -1 ? "0" :state}  variant={getVariant[index].name} now={calculatePercentage(state, total)} key={index+1} />
      ))}
    </ProgressBar>
  </div>  
  </Fragment>;
};
