import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "./Utility/store";
import { OpenUrls } from "./Utility/actionMethod";

const Footer = () => {
  const [state] = useContext(Context);
  let islogin = sessionStorage.getItem("islogged");
  const openUrl = (url) => {
    window.open(url, "_blank");
  };
  return (
    <Fragment>
      <footer className="p-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <div className="footer-logo">
                <img src="assets/images/logo.png" alt="logo" />
                <p>
                  A sports search engine and analysis platform to introduce
                  better and straight forward approach to sports search results
                  in speeding up your fantasy sports and sports betting
                  analysis.
                </p>
              </div>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6">
              <ul className="qiuck-links">
                <li>
                  <Link to="/"> Home </Link>{" "}
                </li>

                {/* <li><span onClick={() => OpenUrls("https://www.fanalyze.com/privacy_policy.html")}> Privacy Policy </span>  </li>
                <li><span onClick={() => OpenUrls("https://www.fanalyze.com/terms_service.html")}> Term of use </span>  </li> */}

                <li>
                  <Link to="/privacy"> Privacy Policy </Link>{" "}
                </li>
                <li>
                  <Link to="/terms"> Terms of Use </Link>{" "}
                </li>

                <li>
                  <Link to="/pricing"> pricing </Link>{" "}
                </li>
              </ul>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6">
              <ul className="qiuck-links">
                {/* <li>
                  <Link to="/odd-comparision">Odds Comparison</Link>{" "}
                </li> */}
                <li>
                  <Link to="/nfloddcalc"> Odds Calculator </Link>{" "}
                </li>
              </ul>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <ul className="social-links">
                <h5>follow us</h5>
                <li
                  onClick={() => openUrl("https://www.facebook.com/fanalyze")}
                >
                  <Link>
                    {" "}
                    <i className="fab fa-facebook-f"></i>{" "}
                  </Link>{" "}
                </li>
                <li onClick={() => openUrl("https://twitter.com/fanalyze")}>
                  {" "}
                  <Link>
                    {" "}
                    <i className="fab fa-twitter"></i>{" "}
                  </Link>{" "}
                </li>
                <li
                  onClick={() => openUrl("https://www.instagram.com/fanalyze")}
                >
                  <Link>
                    {" "}
                    <i className="fab fa-instagram"></i>{" "}
                  </Link>{" "}
                </li>
                <li onClick={() => openUrl("https://www.youtube.com/fanalyze")}>
                  <Link>
                    {" "}
                    <i class="fab fa-youtube"></i>{" "}
                  </Link>{" "}
                </li>
              </ul>
            </div>
            <div className="col-lg-12">
              <div className="store-btns">
                <img
                  src="assets/images/ios.png"
                  alt="-"
                  onClick={() =>
                    OpenUrls(
                      "https://apps.apple.com/us/app/fanalyze/id1436233274?ls=1"
                    )
                  }
                />
                <img
                  src="assets/images/play_store.png"
                  alt="-"
                  onClick={() =>
                    OpenUrls(
                      "https://play.google.com/store/apps/details?id=com.fanalyze.app"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright-wrapper">
        <div className="copyright">
          <h6>
            Copyright © 2024 <span>FANALYZE</span>
          </h6>
          <p>
            DISCLAIMER: This site and the Fanalyze App is 100% for entertainment
            purpose only and does not involve real money betting or prizes. OH
            only. 21+. <br></br>
            Know When To Stop Before You Start.® Gambling problem? Call
            1-800-GAMBLER
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
