import React, { Component } from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import {BrowserRouter} from "react-router-dom";
import Main from "./Components/Main";
import {Store} from "./Components/Utility/store";
// import Pricing from "./Components/Pricing";


class App extends Component {
  constructor(props) {
    super(props)
    window.Chargebee.init({
      site: "honeycomics-v3-test",
      publishableKey: "test_qoH22RugUvm5IcxoqUD5Svdcu9mX5figf"
    })
  }

  render() {
    return (
    <BrowserRouter>
      <Store>
       <Main/>
      </Store>
   </BrowserRouter>
    );
}
}

export default App;
