import {pathOr,propOr,map} from 'ramda';
import moment from 'moment';
import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import momentTimezone from 'moment';


const getScheduledDate = (time) => {
    const momentDate = moment(time).format('llll')
    const n = 2; // second comma
    const splittedDate = momentDate.split(',')
    const date = splittedDate.slice(0, n).join(',')
    return date;
  }
const getScheduledTime = (time, format) => momentTimezone(time).tz("America/New_York").format(format);  

export const getFormattedSchedule = (response, teamId, selectGameType) => {
  
    const schedules = pathOr([], ['data', 'response'], response);
  
    return map((schedule) => {
      return formatSchedule(schedule, teamId, selectGameType);
    }, schedules);
  
};
  

const formatSchedule = (schedule, teamId, selectGameType) => {
   

    const isNFL = selectGameType === 'nfl'
    const isNBA = selectGameType === 'nba'
  
    const scheduledTime = isNFL ? pathOr('', ['weeks', 'games', 'scheduled'], schedule) : propOr('', 'scheduled', schedule)
    //const getScheduledTime = (time) => moment(time).format('LT')
  
    let opponent = '';
    let homeoraway = '';
  
    if(isNFL){
        if (teamId ===pathOr('', ['weeks', 'games', 'home', 'id'], schedule)){
          opponent = `@ ${pathOr('-', ['weeks', 'games', 'away', 'name'], schedule)}`
          homeoraway = 'Away'
        }else{
          opponent = `${pathOr('-', ['weeks', 'games', 'home', 'name'], schedule)}`
          homeoraway= 'Home'
        }
      }else if(isNBA){
        if (teamId ===pathOr('', ['home', 'id'], schedule)){
          opponent = `@ ${pathOr('-', ['away', 'name'], schedule)}`
          homeoraway = 'Away'
        }else{
          opponent = `${pathOr('-', ['home', 'name'], schedule)}`
          homeoraway= 'Home'
        }
      }else {
        if (teamId ===propOr('', 'home_team', schedule)){
          opponent = `@ ${pathOr('-', ['away', 'name'], schedule)}`
        }else{
          opponent = `${pathOr('-', ['home', 'name'], schedule)}`
        }
      }
  
    // if (teamId === isNFL ? pathOr('', ['weeks', 'games', 'home', 'id'], schedule) : propOr('', 'home_team', schedule)){
    //    opponent = `@ ${isNFL ? pathOr('-', ['weeks', 'games', 'away', 'name'], schedule) : pathOr('-', ['away', 'name'], schedule)}`
    //    homeoraway = 'Away'
    //  }else {
    //   opponent = `${isNFL ? pathOr('-', ['weeks', 'games', 'home', 'name'], schedule) : pathOr('-', ['home', 'name'], schedule)}`
    //   homeoraway= 'Home'
    // }
    return isNFL ? [pathOr('', ['weeks', 'x_week'], schedule) ,getScheduledDate(scheduledTime), `${opponent}`, homeoraway ,getScheduledTime(scheduledTime, 'LT')] : isNBA ? [getScheduledDate(scheduledTime), `${opponent}`, homeoraway ,getScheduledTime(scheduledTime, 'LT')] : [getScheduledDate(scheduledTime), `${opponent}`, getScheduledTime(scheduledTime, 'LT')];
    // return {
    //   date: getScheduledDate(scheduledTime),
    //   time: getScheduledTime(scheduledTime),
    //   opponentName: pathOr('', ['away', 'name'], schedule),
    // };
  }