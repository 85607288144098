import React,{Fragment,useState,useEffect,useContext} from 'react';
import  axios from 'axios';
import {Link,useHistory} from "react-router-dom";
import {Tab,Tabs,Modal,Spinner, NavLink } from 'react-bootstrap';
import request from './Utility/Request';
import {nflrankData,nbarankData} from './Utility/GetRankingData';
import {If,Then} from "react-if";
import {has,propOr,pathOr,map} from 'ramda';
import { GetTeamPlayerData, getUserSettings, DraftRank, FormatDraftCheatSheet } from './Utility/actionMethod';
import {Context} from "./Utility/store";
import {apiInstance} from "./Utility/axios";
import {errorToast} from "./Utility/toastify";
import { ToastContainer} from 'react-toastify';
import {isEmptyOrNil,} from "./Utility/actionMethod";

function NflDraftCheat() {
var categoryType = (localStorage.getItem("category_type").toLowerCase()); 
// var draftTeamList = JSON.parse(localStorage.getItem("draftCheatTeam"));
let history = useHistory();
  
const [state,dispatch] = useContext(Context);
const selected_cat = (localStorage.getItem("category_type")).toLowerCase();
const [Ranking,setRanking] = useState([])    
const [showRankingModal, setShowRankingModal] = useState(false);
const [showDraftTeamModal, setDraftTeamModal] = useState(false);
const [selectednflTeamPos,setselectednflTeamPos] = useState(selected_cat === "nfl"? "QB":"PG");
const [myTeam , setMyteam] = useState((localStorage.getItem("draftCheatTeam") !== null || localStorage.getItem("draftCheatTeam") !== "") ? JSON.parse(localStorage.getItem("draftCheatTeam")): [])

const RankingHandleClose = () => setShowRankingModal(false);
const RankingHandleOpen = (data,detail) => { setSource({"data":data,"detail":detail}); setShowRankingModal(true)};


const DraftModalClose = () => setDraftTeamModal(false);


const [showComparisionModal, setshowComparisionModal] = useState(false);
const comparisonHandleClose = () => setshowComparisionModal(false);

const [showSeacrhModal, setshowSeacrhModal] = useState(false);
const SeacrhModalClose = () => setshowSeacrhModal(false);

const [searchValue,setsearchValue] = useState('');
const [ShowPlayerList,setShowPlayerList] = useState(false);
const [SearchedPlayer,setSearchedPlayer] = useState([]);
const [Player1Compare,setPlayer1Compare] = useState([]);
const [Player2Compare,setPlayer2Compare] = useState([]);
const [counter, setCounter] = useState(0)

const [week,setWeek] = useState(["25"])
const [AllPlayer,setAllPlayer] = useState([]);
const [Source,setSource] = useState([]);
const [playerList , setPlayerList] = useState([]);
const [teamList , setTeamList] = useState([]);
const searchList = [...playerList,...teamList];

// const draftCheatbtn = ['ALL', 'C', '1B', '2B', '3B', 'SS' ,'OF','SP','RP'];
// const draftCheatbtn = ['ALL', 'C', '1B', '2B', '3B', 'SS' ,'OF','SP','RP'];
const draftCheatbtn = selected_cat === "nfl" || selected_cat === "NFL" ? ['ALL', 'QB', 'K', 'TE', 'WR', 'RB' ,'DE'] : ["ALL","PG", "SG", "SF", "PF", "C"];
const [isDraftCheat,setIsdarftcheat] = useState(false);
const [selectedDraftbtn,setselectedDraftbtn] = useState("ALL");
const [draftCheatData,setdraftCheatData] = useState([])
const [draftRanking,setdraftRanking] = useState(FormatDraftCheatSheet(draftCheatData, selectedDraftbtn))
const [loader,setLoader] = useState(true);
const [searchTerm,setsearchTerm] = useState("");
const [login,setLogin] = useState(false);
const user = localStorage.getItem("userDetail");
 
useEffect(() => {
    getDraftData()
  var userDetail = localStorage.getItem("userDetail");
    if (
      userDetail === null ||
      userDetail === undefined ||
      userDetail === "" ||
      userDetail === "null"
    ) {
      setLogin(false);
    } else {
      setLogin(true);
      var subscribedPlans = JSON.parse(user).subscribedPlans;
      
      var subscribedPlans = JSON.parse(user).subscribedPlans;
      subscribedPlans.map((ele) => {
        if (ele[0] === "draft-cheat-sheet") {
          if (ele[1] !== false || ele[1] !== "false") {
            setIsdarftcheat(true);
          }
        }
      });
    }

  
  
}, [])

useEffect(()=>{
    if(searchValue === ""){
        setShowPlayerList(false) 
    }else{
        setShowPlayerList(true)
    }
},[searchValue])

useEffect(() => {
    var modeldraft = FormatDraftCheatSheet(draftCheatData, selectedDraftbtn)
    var myTeam = (localStorage.getItem("draftCheatTeam") === null ||  localStorage.getItem("RemovedraftPlayer") === "") ? [] : JSON.parse(localStorage.getItem("draftCheatTeam"));
    var myTeamRemove = (localStorage.getItem("RemovedraftPlayer") === null ||  localStorage.getItem("RemovedraftPlayer") === "") ? [] : JSON.parse(localStorage.getItem("RemovedraftPlayer"));
        modeldraft.map((item)=> {
            item.isAdded = myTeam.find(element => element.player === item.player) !== undefined ? "1" : myTeamRemove.find(element => element.player === item.player) !== undefined ? "2" : "0"
        })
    setdraftRanking(modeldraft)
}, [selectedDraftbtn])

useEffect(()=>{
    let allData = [];
    map((pos) => {
        map((data) => {
            allData.push(data);
        }, 
              (propOr([], pos, draftCheatData)),
    );
    },draftCheatbtn);

    setAllPlayer(allData);
    //  var subplans = JSON.parse(user).subscribedPlans;
    //  const foundDraft = subplans.find(
    //    (element) => element[0] === "draft-cheat-sheet"
    //  );
},[draftCheatData])
   
const getDraftData = async() => {
    let response 
      try{
            let requestApi = selected_cat === "NFL" ||  selected_cat === "nfl" ? request.GET_RANK_DATA : request.GET_NBA_DRAFT_DATA;  
            response = await axios.get(requestApi);
            // response = await axios.get(request.GET_MLB_DRAFT_DATA);
            setdraftCheatData(response.data);
            var modeldraft = FormatDraftCheatSheet(response.data, selectedDraftbtn)
            var myTeam = (localStorage.getItem("draftCheatTeam") === null || localStorage.getItem("draftCheatTeam") === "")? [] :  JSON.parse(localStorage.getItem("draftCheatTeam"));
            var myTeamRemove = (localStorage.getItem("RemovedraftPlayer") === null || localStorage.getItem("RemovedraftPlayer") === "") ? [] : JSON.parse(localStorage.getItem("RemovedraftPlayer"));
                modeldraft.map((item)=> {
                    item.isAdded =  myTeam.find(element => element.player === item.player) !== undefined ? "1" :  myTeamRemove.find(element => element.player === item.player) !== undefined ? "2" : "0"
                })
            setdraftRanking(modeldraft)
            setLoader(false);
        }catch(err)
        {
            console.log(err);
        }
}

// ======= GET PLAYER LIST =======
const getPlayerHanlder = (e) => {
    if(e === ""){
        setSearchedPlayer("");
    }
    
    const datafilter = AllPlayer.filter((val)=>{
        return val.player.toLowerCase().includes(e.toLowerCase())
    })
    setSearchedPlayer(datafilter)
}    
 
// ========== COMPARE PLAYER ========
const PlayerCompare = (data) => {
    if(data.id === Player1Compare.id){
        errorToast("player Already Selected");
    }else if(data.id === Player2Compare.id){
        errorToast("player Already Selected");
    }else{
        if(counter === 0){
            setPlayer1Compare(data);
            setsearchValue('');
            setCounter(counter + 1);
        }
        if(counter === 1){
            setPlayer2Compare(data);
            setsearchValue('');
            setCounter(counter + 1);
           
        }
    }

    
}
   // ========== NEW TAB ==========
const openUrl = (url) => {
    window.open(url,'_blank');
} 
// ========= OPEN COMPARE MODAL =====
const HandleCompareModal = () => {
    setshowComparisionModal(true)
}

const getPlayerList = async(player) => {
    var categoryType = (localStorage.getItem("category_type").toLowerCase()); 
    try{
        const response = await apiInstance.get(categoryType+"/players/list?prefix="+player);
        if(response){
            setPlayerList(response.data.response); 
            setLoader(false);  
        }
     }catch(err){
        // console.log(err);
    }
}

const getTeamList = async(teamVal) => {
    var categoryType = (localStorage.getItem("category_type").toLowerCase()); 
    try{
        const response = await apiInstance.get(categoryType+"/teams/list?prefix="+teamVal);
        if(response){
            setTeamList(response.data.response);
        }
     }catch(err){
        // console.log(err);
    }
}

const getlist = (e) => {
    getPlayerList(e.target.value);
    getTeamList(e.target.value);
    if(e.target.value.length > 3 ){
        setShowPlayerList(true);
    }else{
        setShowPlayerList(false);
    }
}

const removePlayerHandler = (id) => {
    if(Player1Compare.id === id){
        setPlayer1Compare(Player2Compare);
        setCounter(counter - 1)
        setPlayer2Compare("");
    }else{
        setPlayer2Compare("");
        setCounter(counter - 1)
    
    }
}

const singlePlayerHandler = async(data) => {
    //let type = propOr('','logo_url',data)
    let type = has('logo_url',data) ? 'team' : 'player';
    let id =  propOr('','id',data);
    await GetTeamPlayerData(dispatch,categoryType.toLowerCase(),type,id,state)
    let teamurl  = data.logo_url;
    if(teamurl === undefined){
        localStorage.setItem("player_1" ,"");
        localStorage.setItem("player_1" ,JSON.stringify(data));
        localStorage.setItem("isplayer1",true);         
    }else{
   
        localStorage.setItem("team_1" ,"");
        localStorage.setItem("team_1" ,JSON.stringify(data));
        localStorage.setItem("isteam",true);       
    }
    // localStorage.setItem("player_1" ,"");
    // localStorage.setItem("player_1" ,JSON.stringify(player));
    // localStorage.setItem("isplayer1",true);
    history.push("/player-profile");
}

const AddPlayerTodraft = (player) => {
    let draftTeam;
    var data = localStorage.getItem("draftCheatTeam");
    let storedDraftTeam = (data === '' || data === null) ? [] : JSON.parse(data)
    storedDraftTeam.push(player)
    localStorage.setItem("draftCheatTeam","");
    localStorage.setItem("draftCheatTeam",JSON.stringify(storedDraftTeam));
    setMyteam(storedDraftTeam);
    var myTeam = JSON.stringify(storedDraftTeam);
    var  AddedTeam = JSON.parse(myTeam);

    var modeldraft = FormatDraftCheatSheet(draftCheatData, selectedDraftbtn)
    
    var myTeamRemove = localStorage.getItem("RemovedraftPlayer") === null || localStorage.getItem("RemovedraftPlayer") === ""  ? [] : JSON.parse(localStorage.getItem("RemovedraftPlayer"));
        modeldraft.map((item)=> {
            item.isAdded = myTeamRemove.find(element => element.player === item.player) !== undefined ? "2" : AddedTeam.find(element => element.player === item.player) !== undefined ? "1" : "0"
        })
        setdraftRanking(modeldraft)
    
}

const RemovePlayerFromdraft = (player) => {
    let draftTeam;
    var data = localStorage.getItem("RemovedraftPlayer");
    let storedDraftTeam = (data === null || data === "") ? [] : JSON.parse(data)
    storedDraftTeam.push(player)
    localStorage.setItem("RemovedraftPlayer","");
    localStorage.setItem("RemovedraftPlayer",JSON.stringify(storedDraftTeam));
    var myTeamRemove = JSON.stringify(storedDraftTeam);
    var  AddedTeamRemove = JSON.parse(myTeamRemove);
    var modeldraft = FormatDraftCheatSheet(draftCheatData, selectedDraftbtn)    
    var myTeam =( localStorage.getItem("draftCheatTeam") === null ||  localStorage.getItem("draftCheatTeam") === "") ? [] : JSON.parse(localStorage.getItem("draftCheatTeam"));
        modeldraft.map((item)=> {
            item.isAdded = myTeam.find(element => element.player === item.player) !== undefined ? "1" : AddedTeamRemove.find(element => element.player === item.player) !== undefined ? "2" : "0"
        })
    setdraftRanking(modeldraft); 
}

const RemovePlayerHandler = (e,data) => {
    
    if(e.detail === 2){
        if(data.isAdded === "2"){
            var datasss = JSON.parse(localStorage.getItem("RemovedraftPlayer"));
             const selectdata = datasss.filter(function (item) {
               return item.player !== data.player;
             });
            localStorage.setItem("RemovedraftPlayer","");
            localStorage.setItem("RemovedraftPlayer",JSON.stringify(selectdata));
            var modeldraft = FormatDraftCheatSheet(draftCheatData, selectedDraftbtn)  
            var myTeam = (localStorage.getItem("draftCheatTeam") === null || localStorage.getItem("draftCheatTeam") === "")? [] :  JSON.parse(localStorage.getItem("draftCheatTeam"));  
            modeldraft.map((item)=> {
                item.isAdded = selectdata.find(element => element.player === item.player) !== undefined ?  "2" : myTeam.find(element => element.player === item.player) !== undefined ?  "1" : "0"

                })
            setdraftRanking(modeldraft); 
        }
        if(data.isAdded === "1"){
            var datasss = JSON.parse(localStorage.getItem("draftCheatTeam"));
            const selectdata = datasss.filter(function (item) {
                return item.player !== data.player;
              });
            localStorage.setItem("draftCheatTeam","");
            localStorage.setItem("draftCheatTeam",JSON.stringify(selectdata));
            setMyteam(selectdata);
            var myTeamremove = (localStorage.getItem("RemovedraftPlayer") === null || localStorage.getItem("RemovedraftPlayer") === "")? [] :  JSON.parse(localStorage.getItem("RemovedraftPlayer"));  
            var modeldraft = FormatDraftCheatSheet(draftCheatData, selectedDraftbtn)    
            modeldraft.map((item)=> {
                item.isAdded = selectdata.find(element => element.player === item.player) !== undefined ?  "1" : myTeamremove.find(element => element.player === item.player) !== undefined ?  "2" : "0"
                })
            setdraftRanking(modeldraft); 
        }

      
    }
}

const RemovePlayerFromModal = (data) => {
    var datasss = JSON.parse(localStorage.getItem("draftCheatTeam"));
    const selectdata = datasss.filter(function (item) {
      return item.player !== data.player;
    });
   localStorage.setItem("draftCheatTeam","");
   localStorage.setItem("draftCheatTeam",JSON.stringify(selectdata));
   setMyteam(selectdata);

   var myTeamremove = (localStorage.getItem("RemovedraftPlayer") === null || localStorage.getItem("RemovedraftPlayer") === "")? [] :  JSON.parse(localStorage.getItem("RemovedraftPlayer"));  
   var modeldraft = FormatDraftCheatSheet(draftCheatData, selectedDraftbtn)    
   modeldraft.map((item)=> {
       item.isAdded = selectdata.find(element => element.player === item.player) !== undefined ?  "1" : myTeamremove.find(element => element.player === item.player) !== undefined ?  "2" : "0"
       })
   setdraftRanking(modeldraft); 
}

const loginHanlder = () => {
  history.push("/login")
  //dispatch({ type: "OPEN_LOGIN_MODAL", payload: true });
}

  return (
    <Fragment>
      {/* ====== BREADCRUMB ====== */}

      {!loader ? (
        <Fragment>
          <section className="brd-cmb  p-50">
            <div className="container">
              <div className="breadcrumb-wrapper">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="./">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {selected_cat === "nfl" || selected_cat === "nfl" ? "NFL" : "NBA"} DraftCheat
                    </li>
                  </ol>
                </nav>
                {/* <div className="search-button-wrapper">
                  <div className="search-wrapper">
                    <div className="player-search-wrap mt-0">
                      <div className="player-search-box">
                        <React.Fragment>
                          <span>
                            <i className="fas fa-search"></i>
                          </span>
                          <input
                            type="text"
                            name=""
                            placeholder={
                              categoryType === "nfl"
                                ? "Search NFL Players or Teams"
                                : categoryType === "mlb"
                                ? "Search MLB Players or Teams"
                                : "Search NBA Players or Teams"
                            }
                            onChange={getlist}
                          />
                        </React.Fragment>
                      </div>

                      <If condition={ShowPlayerList}>
                        <Then>
                          <ul
                            className={
                              searchList.length < 5
                                ? "search-result h-auto"
                                : "search-result h-400"
                            }
                          >
                            <div className={loader ? "sipnner-wrap" : "d-none"}>
                              <Spinner animation="border" />
                            </div>

                            {searchList.length !== 0 ? (
                              searchList.map((searchitem, index) => (
                                <div>
                                  {
                                    <li
                                      onClick={() =>
                                        singlePlayerHandler(searchitem)
                                      }
                                      key={index}
                                    >
                                      {" "}
                                      <span className="searchListImage">
                                        <img
                                          src={
                                            searchitem.photo_url !== undefined
                                              ? searchitem.photo_url
                                              : searchitem.logo_url
                                          }
                                          alt="-"
                                        />
                                      </span>{" "}
                                      {searchitem.photo_url
                                        ? (categoryType === "nfl"
                                            ? searchitem.name.length
                                            : searchitem.full_name.length) ===
                                          ""
                                          ? "No Such Player or Team Found"
                                          : categoryType === "nfl"
                                          ? searchitem.name
                                          : searchitem.full_name
                                        : searchitem.market_name}
                                    </li>
                                  }
                                </div>
                              ))
                            ) : (
                              <li>No such Player or Team Found </li>
                            )}
                          </ul>
                        </Then>
                      </If>
                    </div>
                  </div>
                  <div className="compare-btn-wrap">
                    <button onClick={HandleCompareModal}>
                      <img
                        src="assets/images/icons/compare.png"
                        alt="compare"
                      />
                    </button>
                    <button
                      onClick={() => {
                        myTeam === null || myTeam === "" || myTeam.length === 0
                          ? errorToast("No player added to team")
                          : setDraftTeamModal(true);
                      }}
                    >
                      <img src="assets/images/icons/team.png" alt="compare" />
                    </button>
                    <button>
                      <img src="assets/images/icons/share.png" alt="compare" />
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </section>

          <section
            className={`nfl-draftCheat-main-wrapper position-relative shadow-none pt-5 ${isDraftCheat} ${
              login ? (isDraftCheat ? "drattrue" : "") : ""
            } `}
          >
            <div className="not-logged">
              <div>
                {login ? 
                  !isDraftCheat ?  (
                  <Link to="/pricing" className="common-btn check-sub-btn">
                    Upgarde To Access
                  </Link>
                ):"":
                   (
                  <button className="common-btn check-sub-btn" onClick={loginHanlder}>
                    signup to access
                  </button>
                ) }
              </div>
            </div>
            <section className="ranking-table-wrap ranking-search mb-5">
            <div className="table-player-search">
                  <div className="search-button-wrapper">
                    <div className="search-wrapper">
                      <div className="player-search-wrap ranking-Outer-Search mt-0">
                        <div className="player-search-box">
                          <React.Fragment>
                            <span>
                              <i className="fas fa-search"></i>
                            </span>
                            <input
                              type="text"
                              name=""
                              placeholder="Search Player"
                              value={searchTerm}
                              onChange={(e) => {
                                setsearchTerm(e.target.value);
                              }}
                            />
                          </React.Fragment>
                        </div>
                        <div className="compare-btn-wrap">
                          <button onClick={HandleCompareModal}>
                            <img
                              src="assets/images/icons/compare.png"
                              alt="compare"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              <div className="container mt-4 position-relative">
                <ul className="mb-3 nav nav-tabs">
                  {draftCheatbtn.map((val, index) => (
                    <li key={index} className="nav-item">
                      <button
                        type="button"
                        className={
                          selectedDraftbtn === val
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => setselectedDraftbtn(val)}
                      >
                        {val}
                      </button>
                    </li>
                  ))}
                </ul>
                
               
              </div>
            </section>
            <section className="ranking-table-wrap draft-table-wrap p-0">
              <table className="table table-bordered table-striped  draft-table">
                <thead>
                  <tr>
                    <th className="text-center">ADP+</th>
                    <th className="text-center">Player</th>
                    <th className="text-center">Sources</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {draftRanking
                    .filter((val) => {
                      if (searchTerm == "") {
                        return val;
                      } else if (
                        val.player
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    .map((data, index) => (
                      <tr
                        key={index}
                        onClick={(e) => {
                          RemovePlayerHandler(e, data);
                        }}
                        class={
                          data.isAdded === "1"
                            ? "playerAdded"
                            : data.isAdded === "2"
                            ? "playerRemove"
                            : ""
                        }
                      >
                        <td className="text-center">{data.rank}</td>
                        <td className="table-center text-center">
                          <span
                            className="draft-plus"
                            onClick={() => {
                              AddPlayerTodraft(data);
                            }}
                          >
                            <i className="fas fa-plus"></i>
                          </span>
                          {data.player} {data.team} ({data.position})
                        </td>
                        <td>
                          <img
                            src="assets/images/info.svg"
                            alt="table_check"
                            onClick={() =>
                              RankingHandleOpen(data.sources, data)
                            }
                          />
                        </td>
                        <td>
                          {" "}
                          <span
                            className="draft-minus"
                            onClick={() => {
                              RemovePlayerFromdraft(data);
                            }}
                          >
                            <i className="fas fa-minus"></i>
                          </span>{" "}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </section>
          </section>
        </Fragment>
      ) : (
        <div className={loader ? "sipnner-wrap" : "d-none"}>
          <Spinner animation="border" />
        </div>
      )}

      {/* ===============  RANKING MODAL===============  */}
      <Modal
        show={showRankingModal}
        onHide={RankingHandleClose}
        size="lg"
        centered
        keyboard={false}
        className="ranking-modal"
      >
        <Modal.Header>
          {showRankingModal ? (
            <h4 className="modal-title">
              {Source.detail.player} {Source.detail.team} (
              {Source.detail.position})
            </h4>
          ) : (
            ""
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            {showRankingModal ? (
              <h6> {Source.detail.rank} Average Ranking</h6>
            ) : (
              ""
            )}
            <ul>
              {showRankingModal
                ? Source.data.map((data, index) => (
                    <li key={index}>
                      <span>{data.rank}</span>
                      <p>{data.wbname}</p>
                      <span
                        className="weburl"
                        onClick={() => {
                          openUrl(data.wburl);
                        }}
                      >
                        <i className="fas fa-external-link-alt"></i>
                      </span>
                    </li>
                  ))
                : ""}
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      {/* =============== COMPARISION MODAL  ===============  */}
      <Modal
        show={showComparisionModal}
        onHide={comparisonHandleClose}
        size="lg"
        centered
        keyboard={false}
        className="ranking-modal comparision-modal"
      >
        <Modal.Header>
          <h5>Player Comparision</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="search-button-wrapper">
            <div className="search-wrapper">
              <div className="player-search-wrap mt-0">
                <div className="player-search-box">
                  {counter !== 2 ? (
                    <React.Fragment>
                      <span>
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        type="text"
                        name=""
                        placeholder="Search Player"
                        value={searchValue}
                        onChange={(e) => {
                          setsearchValue(e.target.value);
                          getPlayerHanlder(e.target.value);
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  <If condition={counter > 0}>
                    <div className="sreached-player-block">
                      {counter > 0 ? (
                        <span
                          onClick={() => removePlayerHandler(Player1Compare.id)}
                        >
                          {Player1Compare.player}
                          <i className="far fa-times-circle"></i>
                        </span>
                      ) : (
                        ""
                      )}
                      {counter === 2 ? (
                        <span
                          onClick={() => removePlayerHandler(Player2Compare.id)}
                        >
                          {Player2Compare.player}
                          <i className="far fa-times-circle"></i>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </If>
                </div>
              </div>
              <If condition={ShowPlayerList}>
                <ul
                  className={
                    SearchedPlayer.length < 5
                      ? "search-result h-auto"
                      : "search-result h-400"
                  }
                >
                  {SearchedPlayer.length !== 0
                    ? SearchedPlayer.map((list, index) => (
                        <li key={index} onClick={() => PlayerCompare(list)}>
                          {list.player}
                        </li>
                      ))
                    : ""}
                </ul>
              </If>
            </div>
          </div>
          <div className="playerComparisonWrap">
            <div className="playerComparison1">
              {Player2Compare.length !== 0 ? (
                <h4 className="modal-title">
                  {Player1Compare.player} {Player1Compare.team} (
                  {Player1Compare.position})
                </h4>
              ) : (
                ""
              )}
              {Player2Compare.length !== 0 ? (
                <h6> {Player1Compare.rank} Average Ranking</h6>
              ) : (
                ""
              )}
              <ul>
                {Player2Compare.length !== 0
                  ? Player1Compare.sources.map((data, index) => (
                      <li key={index}>
                        <span>{data.rank}</span>
                        <p>{data.wbname}</p>
                        <span
                          className="weburl"
                          onClick={() => {
                            openUrl(data.wburl);
                          }}
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </span>
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
            <div className="playerComparison2">
              {Player2Compare.length !== 0 ? (
                <h4 className="modal-title">
                  {Player2Compare.player} {Player2Compare.team} (
                  {Player2Compare.position})
                </h4>
              ) : (
                ""
              )}
              {Player2Compare.length !== 0 ? (
                <h6> {Player2Compare.rank} Average Ranking</h6>
              ) : (
                ""
              )}
              <ul>
                {Player2Compare.length !== 0
                  ? Player2Compare.sources.map((data, index) => (
                      <li key={index}>
                        <span>{data.rank}</span>
                        <p>{data.wbname}</p>
                        <span
                          className="weburl"
                          onClick={() => {
                            openUrl(data.wburl);
                          }}
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </span>
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ===============  TEAM MODAL===============  */}
      <Modal
        show={showDraftTeamModal}
        onHide={DraftModalClose}
        size="lg"
        centered
        keyboard={false}
        className="ranking-modal"
      >
        <Modal.Header>{<h4 className="modal-title">My Team</h4>}</Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            {showDraftTeamModal ? (
              localStorage.getItem("draftCheatTeam") !== null ||
              localStorage.getItem("draftCheatTeam") !== "" ? (
                <table className="table table-bordered table-striped feature_table rank_table draft-table">
                  <thead>
                    <tr>
                      <th>ADP+</th>
                      <th>Player</th>
                      <th>Sources</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {myTeam.map((data, index) => (
                      <tr key={index}>
                        <td>{data.rank}</td>
                        <td className="table-center">
                          {data.player} {data.team} ({data.position})
                        </td>
                        <td>
                          <img
                            src="assets/images/info.svg"
                            alt="table_check"
                            onClick={() =>
                              RankingHandleOpen(data.sources, data)
                            }
                          />
                        </td>
                        <td>
                          <img
                            src="assets/images/cancel.svg"
                            alt="table_check"
                            onClick={() => {
                              RemovePlayerFromModal(data);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                "No player Added"
              )
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
  
}

export default NflDraftCheat