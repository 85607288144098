import React, { Fragment, useState, useEffect, useContext } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { If, Then } from "react-if";
import { Context } from "./Utility/store";
import { apiInstance } from "./Utility/axios";
import { FormatUpcomingGames, GetTeamPlayerData, OpenUrls, getUserSettings, getWeekNumber, moveGameAndRemoveDate, processData, weekHandler } from "./Utility/actionMethod";
import { propOr, has } from "ramda";
import axios from "axios";
import request from "./Utility/Request";


const Home = () => {
  const [tabType, setTabType] = useState("news");
  const [nflNews, setNflNews] = useState([]);
  const [mlbNews, setMlbNews] = useState([]);
  const [nbaNews, setNbaNews] = useState([]);
  const [state, dispatch] = useContext(Context);
  const [visible, setVisible] = useState(4);
  const [categoryType, setCategoryType] = useState([]);
  const [onchange, setonchange] = useState(false);
  const [playerList, setPlayerList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [ShowPlayerList, setShowPlayerList] = useState(false);
  const [loader, setLoader] = useState(false);
  // const searchList = [...playerList,...teamList];
  const [searchList, setSearchList] = useState([...playerList, ...teamList]);
  const [searchedValue, setSearchedValue] = useState("");
  const [upcomingGame, setUpcomingGame] = useState([]);
  const [weekTitle, setWeekTitle] = useState();

  let history = useHistory();
  let source = axios.CancelToken.source();

  useEffect(() => {
    let islogged = localStorage.getItem("islogged");
    if (islogged === null || islogged === false) {
      localStorage.setItem("islogged", false);
    }
    // if(islogged === false || islogged === "false"){
    //     localStorage.clear();
    // }
    setCategoryType(state.category_type);
    localStorage.setItem("category_type", "nfl");
    localStorage.setItem("player_1", "");
    localStorage.setItem("player_2", "");
    localStorage.setItem("player_3", "");
    localStorage.setItem("player1_team", "");
    localStorage.setItem("player2_team", "");
    localStorage.setItem("player3_team", "");
    localStorage.setItem("team_1", "");
    localStorage.setItem("team_2", "");
    localStorage.setItem("team_3", "");
    localStorage.setItem("isteam", false);
    localStorage.setItem("team1_info", "");
    localStorage.setItem("team2_info", "");
    localStorage.setItem("team3_info", "");
    localStorage.setItem("onePT", "");
    localStorage.setItem("twoPT", "");
    localStorage.setItem("chartData", "");
    localStorage.setItem("isReload", false);
    localStorage.setItem("isReload2", false);
    localStorage.setItem("playerteamInfo", "");
    // getNews("nfl");

    getUserSettings(dispatch);
    dispatch({ type: "SEASON_EXCLUSIVE_DATA_1", payload: [] });
    dispatch({ type: "SEASON_EXCLUSIVE_DATA_1_DEMO", payload: [] });
    dispatch({ type: "SEASON_EXCLUSIVE_DATA_2", payload: [] });
    dispatch({ type: "SEASON_EXCLUSIVE_DATA_2_DEMO", payload: [] });
    dispatch({ type: "SEASON_EXCLUSIVE_DATA_3", payload: [] });
    dispatch({ type: "SEASON_EXCLUSIVE_DATA_3_DEMO", payload: [] });

    getUserSettings(dispatch);
    getNflNews();
    getMlbfNews();
    getNbaNews();
    getUpcomingGames();
    dispatch({ type: "TEAM_PLAYER_SELECTED", payload: false });
  }, []);


  const getUpcomingGames = async () => {


    // Example usage with starting week number 12

    const resultArray = getWeekNumber();

    // const week = request.GAME_WEEK - 1;
    //console.log('getUpcomingGames',data.weeks[week]['games'])
    try {
      const response = await axios.get(request.SCHEDULE_GAMES);
      if (response) {
        //const games = processData(response.data);
        setWeekTitle(response.data.week);
        const games = FormatUpcomingGames(response.data.games)
        games.then((result) => {
          const updatedSchedule = moveGameAndRemoveDate(result, 'Friday, December 15TH', 'Saturday, December 16TH', 'Sunday, December 17TH');
          setUpcomingGame(updatedSchedule)
        })

      }
    } catch (err) {
      console.log(err);
    }



  }




  const getPlayerList = async (player) => {
    var categoryType = localStorage.getItem("category_type").toLowerCase();
    try {
      const response = await apiInstance.get(
        categoryType + "/players/list?prefix=" + player
      );
      if (response) {
        setPlayerList(response.data.response);
        setLoader(false);
      }
    } catch (err) { }
  };

  const getTeamList = async (teamVal) => {
    var categoryType = localStorage.getItem("category_type").toLowerCase();
    try {
      const response = await apiInstance.get(
        categoryType + "/teams/list?prefix=" + teamVal
      );
      if (response) {
        setTeamList(response.data.response);
      }
    } catch (err) { }
  };

  const getList = async (val) => {
    const newData = [];

    var categoryType = localStorage.getItem("category_type").toLowerCase();
    source = axios.CancelToken.source();
    Promise.all([
      apiInstance.get(categoryType + "/teams/list?prefix=" + val, {
        cancelToken: source.token,
        signal: source.signal,
      }),
      apiInstance.get(categoryType + "/players/list?prefix=" + val, {
        cancelToken: source.token,
        signal: source.signal,
      }),
    ]).then((res) => {
      let data = [...res[0].data.response, ...res[1].data.response];
      let filteredData;
      if (categoryType.toLowerCase() === "nfl") {
        filteredData = data.filter(item => {
          if (item.name !== "" && item.name !== null && item.name !== undefined) {

            return !item.name.toLowerCase().includes("tom brady");
          } else {

            return item;
          }
        });
      } else {
        filteredData = data;
      }
      setSearchList(filteredData);
      // more calculation
      //newData.push(el.someValue);
    });
  };

  const singlePlayerHandler = async (data) => {
    //let type = propOr('','logo_url',data)
    let type = has("logo_url", data) ? "team" : "player";
    let id = propOr("", "id", data);
    await GetTeamPlayerData(
      dispatch,
      categoryType.toLowerCase(),
      type,
      id,
      state
    );
    let teamurl = data.logo_url;
    if (teamurl === undefined) {
      localStorage.setItem("player_1", "");
      localStorage.setItem("player_1", JSON.stringify(data));
      localStorage.setItem("isplayer1", true);
    } else {
      localStorage.setItem("team_1", "");
      localStorage.setItem("team_1", JSON.stringify(data));
      localStorage.setItem("isteam", true);
    }
    // localStorage.setItem("player_1" ,"");
    // localStorage.setItem("player_1" ,JSON.stringify(player));
    // localStorage.setItem("isplayer1",true);
    history.push("/player-profile");
  };

  // ======= GET TEAM =======
  const getPlayerHanlder = (e) => {
    source.cancel();
    setSearchedValue(e.target.value);
    // getPlayerList(e.target.value);
    // getTeamList(e.target.value);
    getList(e.target.value);
    if (e.target.value.length > 3) {
      setShowPlayerList(true);
    } else {
      setShowPlayerList(false);
    }
  };

  const ChangeCategoryHandler = (category) => {
    dispatch({ type: "CATEGORY_TYPE", payload: { category } });
    localStorage.setItem("category_type", category);
    setVisible(4);
    setSearchedValue("");
    setShowPlayerList(false);
    setonchange(true);
    setCategoryType(category);
    setSearchList([]);
    setTabType("news")
    // setLoader(true);

    // setTimeout(() => {
    //     getNews(category);
    // }, 2000);
  };

  useEffect(() => {
    localStorage.setItem("category_type", state.category_type.category);
    setCategoryType(state.category_type.category);
    setonchange(false);
  }, [onchange]);

  let cat =
    localStorage.getItem("category_type") !== null
      ? localStorage.getItem("category_type").toLowerCase("nfl")
      : "";
  const showMoreNews = () => {
    setVisible((prevalue) => prevalue + 4);
  };

  const openUrl = (url) => {
    window.open(url, "_blank");
  };

  const parseDate = (input) => {
    var parts = input.split("-");
    var timeBits = parts[2].split(" ");
    var hms = timeBits[1].split(":");
    // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(
      parts[0],
      parts[1] - 1,
      timeBits[0],
      hms[0],
      hms[1],
      hms[2]
    ); // Note: months are 0-based
  };

  function calcTime(dateString) {

    const currentDate = new Date();
    const targetDate = new Date(dateString);

    const timeDifference = currentDate - targetDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    // Get current time in 12-hour format (e.g., "02:30:45 PM")
    const currentTime = currentDate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    if (daysDifference >= 1) {
      return `${daysDifference} day${daysDifference > 1 ? "s" : ""} ago`
    } else if (hoursDifference >= 1) {
      return `${hoursDifference} hour${hoursDifference > 1 ? "s" : ""} ago`

    } else if (minutesDifference >= 1) {
      return `${minutesDifference} minute${minutesDifference > 1 ? "s" : ""} ago`;
    } else {
      return "Less than a minute ago"
    }

  };

  // ====== GET NEWS ===============

  const getNews = async (selected_game) => {
    let response = "";
    try {
      if (selected_game === "nfl") {
        response = await axios.get(request.GET_NEWS_NFL);
      }
      if (selected_game === "mlb") {
        response = await axios.get(request.GET_NEWS_MLB);
      }
      if (selected_game === "nba") {
        response = await axios.get(request.GET_NEWS_NBA);
      }
      if (response) {
        //setNews(response.data);
        var msg = { news: response.data.response };
        dispatch({ type: "NEWS", payload: msg });
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getNflNews = async () => {
    let response = "";
    try {
      response = await axios.get(request.GET_NEWS_NFL);
      if (response) {
        setNflNews(response.data);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getMlbfNews = async () => {
    let response = "";
    try {
      response = await axios.get(request.GET_NEWS_MLB);
      if (response) {
        setMlbNews(response.data);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getNbaNews = async () => {
    let response = "";
    try {
      response = await axios.get(request.GET_NEWS_NBA);
      if (response) {
        setNbaNews(response.data);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const Image = (props) => {
    const [imageError, setImageError] = useState(false);
    return (
      <img
        className="slick-slide-image"
        src={
          props.img === "" || props.img === undefined
            ? "assets/images/dummy.png"
            : imageError
              ? "assets/images/dummy.png"
              : props.img
        }
        onError={(e) => setImageError(true)}
      />
    );
  };


  const compareTeamHandler = (game) => {
    let categoryType = localStorage.getItem("category_type").toLowerCase();
    // let api = "http://www.fanalyze.com/profilesharing/";
    let path = "profilesharing/";
    let url = path + game.awayTeamId + "/" + game.awayTeam + "/0/" + game.homeTeamId + "/" + game.homeTeam + "/0/" + categoryType + "/team";
    url = url.split(" ").join("");
    history.push(url);
  }


  return (
    <Fragment>
      {/*======= BANNER ======== */}
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="create-team-wrapper home_create_wrapper">
                <div className="player-search-wrap">
                  <div className="select-team-wrap">
                    <div
                      className={
                        categoryType === "nfl" || categoryType === "NFL"
                          ? "team-search active"
                          : "team-search"
                      }
                      onClick={() => ChangeCategoryHandler("nfl")}
                    >
                      <img src="assets/images/icons/NFL.png" alt="nfl" />
                      <h6>NFL</h6>
                    </div>
                    <div
                      className={
                        categoryType === "mlb" || categoryType === "mlb"
                          ? "team-search active"
                          : "team-search"
                      }
                      onClick={() => ChangeCategoryHandler("mlb")}
                    >
                      <img src="assets/images/icons/MLB.png" alt="mlb" />
                      <h6>mlb</h6>
                    </div>
                    <div
                      className={
                        categoryType === "nba" || categoryType === "nba"
                          ? "team-search active"
                          : "team-search"
                      }
                      onClick={() => ChangeCategoryHandler("nba")}
                    >
                      <img src="assets/images/icons/NBA.png" alt="nba" />
                      <h6>nba</h6>
                    </div>
                  </div>
                  <div className="player-search-box">
                    <span>
                      <i className="fas fa-search"></i>
                    </span>
                    <input
                      type="text"
                      name=""
                      value={searchedValue}
                      placeholder={
                        categoryType === "nfl" || categoryType === "NFL"
                          ? "Search NFL Players or Teams"
                          : categoryType === "mlb"
                            ? "Search MLB Players or Teams"
                            : "Search NBA Players or Teams"
                      }
                      onChange={getPlayerHanlder}
                    />
                  </div>

                  <If condition={ShowPlayerList}>
                    <Then>
                      <ul
                        className={
                          searchList !== undefined &&
                            searchList.length !== undefined
                            ? "search-result h-auto"
                            : "search-result h-400"
                        }
                      >
                        <div className={loader ? "sipnner-wrap" : "d-none"}>
                          <Spinner animation="border" />
                        </div>

                        {searchList !== undefined &&
                          searchList.length !== undefined ? (
                          searchList.map((searchitem, index) => (
                            <div>
                              {
                                <li
                                  onClick={() =>
                                    singlePlayerHandler(searchitem)
                                  }
                                  key={index}
                                >
                                  {" "}
                                  <span className="searchListImage">
                                    <Image
                                      img={
                                        searchitem.photo_url !== undefined
                                          ? searchitem.photo_url
                                          : searchitem.logo_url
                                      }
                                    />
                                  </span>{" "}
                                  {searchitem.photo_url
                                    ? (cat === "nfl"
                                      ? searchitem.name.length
                                      : searchitem.full_name.length) === ""
                                      ? "No Such Player or Team Found"
                                      : cat === "nfl"
                                        ? searchitem.name
                                        : searchitem.full_name
                                    : searchitem.market_name}
                                </li>
                              }
                            </div>
                          ))
                        ) : (
                          <li>No such Player or Team Found </li>
                        )}
                      </ul>
                    </Then>
                  </If>
                </div>
              </div>
            </div>

            {/* {localStorage.getItem("category_type") === "NFL" ||
              localStorage.getItem("category_type") === "nfl" ? (
              <div className="col-lg-12">
                <Link to="/nfldraftcheat" className="draft-btn">
                  <span>
                    <img src="assets/images/draft-icon.png" />
                  </span>
                  Draft cheat sheet
                </Link>
              </div>
            ) : ""} */}
          </div>
        </div>
        {/* 
        <div className="col-lg-12">
          <Link to="/nfldraftcheat" className="draft-btn">
            <span>
              <img src="assets/images/draft-icon.png" />
            </span>
            Draft cheat sheet
          </Link>
        </div> */}
      </section>

      {/*======= FEATURES ======== */}
      <If condition={cat !== ""}>
        <Then>
          <section className="features-wrapper pt-4 ">
            <div className="container">
              <div className="feature-tab-outterbox">
                {/*
                <Link className="feature-tab" to="odds">
                  <img src="assets/images/icons/odds.png" alt="ranking" />
                  <h6> Odds </h6>
                </Link>
               */}
                <Link className="feature-tab" to="ranking">
                  <img src="assets/images/icons/ranking.png" alt="ranking" />
                  <h6> Rankings </h6>
                </Link>
                <Link className="feature-tab" to="projectile">
                  <img
                    src="assets/images/icons/projection.png"
                    alt="projection"
                  />
                  <h6> Projections </h6>
                </Link>
                <Link className="feature-tab" to="startsit">
                  <img
                    src="assets/images/icons/startorsit.png"
                    alt="startorsit"
                  />
                  <h6> Start And Sit </h6>
                </Link>

                <Link className="feature-tab" to="wavier">
                  <img
                    src="assets/images/icons/waiverwire.png"
                    alt="waiverwire"
                  />
                  <h6> Waiver Wire </h6>
                </Link>
              </div>
              <div className="col-lg-12 mt-3">
                <div className="store-btns">
                  <img
                    src="assets/images/ios.png"
                    alt="-"
                    onClick={() =>
                      OpenUrls(
                        "https://apps.apple.com/us/app/fanalyze/id1436233274?ls=1"
                      )
                    }
                  />
                  <img
                    src="assets/images/play_store.png"
                    alt="-"
                    onClick={() =>
                      OpenUrls(
                        "https://play.google.com/store/apps/details?id=com.fanalyze.app"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </section>
        </Then>
      </If>


      <section className={`tabsWrapper ${categoryType}`}>

        {
          categoryType === "nfl" || categoryType === "NFL" ?
            <div className={`container contentTabs`}>
              <div className="commonbtn-Wrp text-center mt-3">
                <span className={tabType === "news" ? "tab-btn active-tab" : "tab-btn"} onClick={() => setTabType("news")}> <span><img src="./assets/images/icon_news.svg" /></span> News</span>
                {/*<span className={tabType === "game" ? "tab-btn active-tab" : "tab-btn"} onClick={() => setTabType("game")}> <span><img src="./assets/images/icon_games.svg" /></span>  Games</span>*/}
              </div>
            </div>
            : ""
        }




        {/*======= NEWS ======== */}
        {!loader ? (
          tabType === "news" ?
            <section className="news">
              <div className="container">
                <div className="row">

                  {categoryType === "nfl" || categoryType === "NFL"
                    ? nflNews?.slice(0, visible).map((newss) => (
                      <div className="col-xl-6 col-lg-6" key={newss.title}>
                        <span
                          className="news-tab"
                          onClick={() => {
                            openUrl(newss.url);
                          }}
                        >
                          <div className="new-from">
                            <div>
                              {newss.source.split(".")[0] === "espn" && (
                                <img
                                  src="assets/images/espn.png"
                                  alt={newss.source.split(".")[0]}
                                />
                              )}
                              {newss.source.split(".")[0] === "yahoo" && (
                                <img
                                  src="assets/images/yahoosports.png"
                                  alt={newss.source.split(".")[0]}
                                />
                              )}

                              {newss.source.split(".")[0] === "cbssports" && (
                                <img
                                  src="assets/images/cbssports.jpeg"
                                  alt={newss.source.split(".")[0]}
                                />
                              )}

                              {newss.source.split(".")[0] === "usatoday" && (
                                <img
                                  src="assets/images/usatoday.png"
                                  alt={newss.source.split(".")[0]}
                                />
                              )}

                              {newss.source.split(".")[0] ===
                                "bleacherreport" && (
                                  <img
                                    src="assets/images/bleacherreport.png"
                                    alt={newss.source.split(".")[0]}
                                  />
                                )}

                              {newss.source.split(".")[0] === "sportingnews" && (
                                <img
                                  src="assets/images/sportingnews.png"
                                  alt={newss.source.split(".")[0]}
                                />
                              )}
                              {newss.source.split(".")[0] === "nfl" && (
                                <img
                                  src="assets/images/nfl_logo.png"
                                  alt={newss.source.split(".")[0]}
                                />
                              )}

                              <h6>{newss.source.split(".")[0]}</h6>
                            </div>

                            {cat !== "mlb" ? (
                              <p>
                                <span>
                                  <i className="far fa-clock"></i>
                                </span>{" "}
                                {calcTime(newss.dt_created)}{" "}
                              </p>
                            ) : (
                              <p>
                                <span>
                                  <i className="far fa-clock"></i>
                                </span>{" "}
                                {calcTime(newss.dt_created)}{" "}
                              </p>
                            )}
                          </div>

                          <div className="new-head-container">
                            <div className="new-head-img">
                              {newss.img_url !== "" ? (
                                <img src={newss.img_url} alt="-" />
                              ) : (
                                <img src={"assets/images/dummy.png"} alt="-" />
                              )}
                            </div>

                            <div className="headline-container">
                              <h5>{newss.title}</h5>
                              <p>
                                {propOr("", "desc", newss).length > 230
                                  ? propOr("", "desc", newss).substr(0, 150 - 1) +
                                  "..."
                                  : propOr("", "desc", newss)}
                              </p>
                            </div>
                          </div>
                        </span>
                      </div>
                    ))
                    : ""}

                  {categoryType === "mlb" || categoryType === "MLB"
                    ? mlbNews?.slice(0, visible).map((newss) => (
                      <div className="col-xl-6 col-lg-6" key={newss.title}>
                        <span
                          className="news-tab"
                          onClick={() => {
                            openUrl(newss.url);
                          }}
                        >
                          <div className="new-from">
                            <div>
                              {newss.source.split(".")[0] === "espn" && (
                                <img src="assets/images/espn.png" alt="-" />
                              )}
                              {newss.source.split(".")[0] === "yahoo" && (
                                <img
                                  src="assets/images/yahoosports.png"
                                  alt="-"
                                />
                              )}

                              {newss.source.split(".")[0] === "cbssports" && (
                                <img
                                  src="assets/images/cbssports.jpeg"
                                  alt="cbssports"
                                />
                              )}

                              {newss.source.split(".")[0] === "usatoday" && (
                                <img
                                  src="assets/images/usatoday.png"
                                  alt="cbssports"
                                />
                              )}

                              {newss.source.split(".")[0] ===
                                "bleacherreport" && (
                                  <img
                                    src="assets/images/bleacherreport.png"
                                    alt="cbssports"
                                  />
                                )}

                              {newss.source.split(".")[0] === "sportingnews" && (
                                <img
                                  src="assets/images/sportingnews.png"
                                  alt="cbssports"
                                />
                              )}
                              {newss.source.split(".")[0] === "mlb" && (
                                <img src={newss.fav_icon} alt="cbssports" />
                              )}

                              <h6>{newss.source.split(".")[0]}</h6>
                            </div>

                            {cat !== "mlb" ? (
                              <p>
                                <span>
                                  <i className="far fa-clock"></i>
                                </span>{" "}
                                {calcTime(newss.dt_created)}{" "}
                              </p>
                            ) : (
                              <p>
                                <span>
                                  <i className="far fa-clock"></i>
                                </span>{" "}
                                {calcTime(newss.dt_created)}{" "}
                              </p>
                            )}
                          </div>

                          <div className="new-head-container">
                            <div className="new-head-img">
                              {newss.img_url !== "" ? (
                                <img src={newss.img_url} alt="-" />
                              ) : (
                                <img src={"assets/images/dummy.png"} alt="-" />
                              )}
                            </div>

                            <div className="headline-container">
                              <h5>{newss.title}</h5>
                              <p>
                                {propOr("", "desc", newss).length > 230
                                  ? propOr("", "desc", newss).substr(0, 150 - 1) +
                                  "..."
                                  : propOr("", "desc", newss)}
                              </p>
                            </div>
                          </div>
                        </span>
                      </div>
                    ))
                    : ""}

                  {categoryType === "nba" || categoryType === "NBA"
                    ? nbaNews?.slice(0, visible).map((newss) => (
                      <div className="col-xl-6 col-lg-6" key={newss.title}>
                        <span
                          className="news-tab"
                          onClick={() => {
                            openUrl(newss.url);
                          }}
                        >
                          <div className="new-from">
                            <div>
                              {newss.source.split(".")[0] === "espn" && (
                                <img src="assets/images/espn.png" alt="-" />
                              )}
                              {newss.source.split(".")[0] === "yahoo" && (
                                <img
                                  src="assets/images/yahoosports.png"
                                  alt="-"
                                />
                              )}

                              {newss.source.split(".")[0] === "cbssports" && (
                                <img
                                  src="assets/images/cbssports.jpeg"
                                  alt="cbssports"
                                />
                              )}

                              {newss.source.split(".")[0] === "usatoday" && (
                                <img
                                  src="assets/images/usatoday.png"
                                  alt="cbssports"
                                />
                              )}

                              {newss.source.split(".")[0] ===
                                "bleacherreport" && (
                                  <img
                                    src="assets/images/bleacherreport.png"
                                    alt="cbssports"
                                  />
                                )}

                              {newss.source.split(".")[0] === "sportingnews" && (
                                <img
                                  src="assets/images/sportingnews.png"
                                  alt="cbssports"
                                />
                              )}

                              {newss.source.split(".")[0] === "nba" && (
                                <img src={newss.fav_icon} alt="cbssports" />
                              )}

                              <h6>{newss.source.split(".")[0]}</h6>
                            </div>

                            {cat !== "mlb" ? (
                              <p>
                                <span>
                                  <i className="far fa-clock"></i>
                                </span>{" "}
                                {calcTime(newss.dt_created)}{" "}
                              </p>
                            ) : (
                              <p>
                                <span>
                                  <i className="far fa-clock"></i>
                                </span>{" "}
                                {calcTime(newss.dt_created)}{" "}
                              </p>
                            )}
                          </div>

                          <div className="new-head-container">
                            <div className="new-head-img">
                              {newss.img_url !== "" ? (
                                <img src={newss.img_url} alt="-" />
                              ) : (
                                <img src={"assets/images/dummy.png"} alt="-" />
                              )}
                            </div>

                            <div className="headline-container">
                              <h5>{newss.title}</h5>
                              <p>
                                {propOr("", "desc", newss).length > 230
                                  ? propOr("", "desc", newss).substr(0, 150 - 1) +
                                  "..."
                                  : propOr("", "desc", newss)}
                              </p>
                            </div>
                          </div>
                        </span>
                      </div>
                    ))
                    : ""}

                  <div className="col-lg-12">
                    <If condition={cat === "nfl"}>
                      <Then>
                        <If condition={!(visible >= nflNews.length)}>
                          <Then>
                            <button
                              className="common-btn view-more"
                              onClick={showMoreNews}
                            >
                              {" "}
                              View more
                            </button>
                          </Then>
                        </If>
                      </Then>
                    </If>

                    <If condition={cat === "mlb"}>
                      <Then>
                        <If condition={!(visible >= mlbNews.length)}>
                          <Then>
                            <button
                              className="common-btn view-more"
                              onClick={showMoreNews}
                            >
                              {" "}
                              View more
                            </button>
                          </Then>
                        </If>
                      </Then>
                    </If>

                    <If condition={cat === "nba"}>
                      <Then>
                        <If condition={!(visible >= nbaNews.length)}>
                          <Then>
                            <button
                              className="common-btn view-more"
                              onClick={showMoreNews}
                            >
                              {" "}
                              View more
                            </button>
                          </Then>
                        </If>
                      </Then>
                    </If>
                  </div>
                </div>
              </div>
            </section>
            :
            <section className="Games-wrapper">
              <div className="container">
                <div className="week-title">
                  <p className="text-center weeks">WEEk {weekTitle}</p>
                </div>

              </div>


              {upcomingGame.map((gameList, i) => (
                <div className="container mt-5" key={i}>

                  <h2>{gameList.date}</h2>
                  {gameList.games.map((game, index) => (
                    <div className="game-content d-flex" key={index} onClick={() => compareTeamHandler(game)}>
                      <div className="Timing-content">
                        <h6>{game.time} <span>PST</span></h6>
                        <p>{game.broadcast}</p>
                      </div>
                      <div className="containts d-flex align-items-center">
                        <div className="team1-wrp d-flex align-items-center">
                          <div className="team1">
                            {/* { <h5>({game.homeTeamWinLoss})  {game.homeTeam}</h5> } */}
                            <h5>  {game.homeTeam}</h5>
                          </div>
                          <div className="team-logo-wrapper">
                            <img src={game.homeTeamLogo} alt="homeTeamLogo" />
                          </div>
                        </div>

                        <div className="Img-wrapper">
                          <img src="./assets/images/at.svg" alt="at" />
                        </div>


                        <div className="team2-wrp d-flex align-items-center">
                          <div className="team-logo-wrapper">
                            <img src={game.awayTeamLogo} alt="awayTeamLogo" />
                          </div>

                          <div className="team2">
                            {/* {<h5>{game.awayTeam} ({game.awayTeamWinLoss})</h5> } */}
                            <h5>{game.awayTeam}</h5>
                          </div>
                        </div>

                      </div>
                    </div>
                  ))}
                </div>
              ))}


            </section>
        ) : (
          <div className={loader ? "sipnner-wrap with-back" : "d-none"}>
            <Spinner animation="border" />
          </div>
        )}
      </section>
    </Fragment>
  );
};

export default Home;

{/* <div className="game-content d-flex">
<div className="Timing-content">
  <h6>{game.time} <span>PST</span></h6>
  <p>{game.broadcast}</p>
</div>
<div className="containts d-flex align-items-center">
    <div>
      <h5>{game.homeTeam}</h5>
    </div>
    <div className="Img-wrapper">
      <img src="https://fanalyze-images.s3.us-east-1.amazonaws.com/nfl/team_logos/a20471b4-a8d9-40c7-95ad-90cc30e46932.svg" alt="google" />
    </div>
    <div className="Img-wrapper">
      <img src="https://fanalyze-images.s3.us-east-1.amazonaws.com/nfl/team_logos/22052ff7-c065-42ee-bc8f-c4691c50e624.svg" alt="google" />
    </div>
    <div className="Img-wrapper">
      <img src="https://fanalyze-images.s3.us-east-1.amazonaws.com/nfl/team_logos/22052ff7-c065-42ee-bc8f-c4691c50e624.svg" alt="google" />
    </div>
    <div>
      <h5>{game.awayTeam}</h5>
    </div>
</div>
</div> */}