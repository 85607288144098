import React, { Fragment, useState, useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Home from "./Home";
import Pricing from "./Pricing";
import Pricing_copy from "./Pricing_copy";
import Setting from "./Setting";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import Subscription from "./Subscription";
import Team from "./Team";
import Ranking from "./Ranking";
import Projectile from "./Projectile";
import StartSit from "./StartSit";
import Wavier from "./Wavier";
import NflOdds from "./NflOdds";
import NflOddCalc from "./NflOddCalc";
import BannerShape from "./BannerShape";
import PlayerProfile from "./PlayerProfile";
import PlayerComparision from "./PlayerComparision";
import PageNotFound from "./PageNotFound";
import Card from "./Utility/Card";
import ForgetPassword from "./ForgetPassword";
// import PlayerProfile2 from './PlayerProfile2';
import ProfileSharing from "./ProfileSharing";
import OddsComparision from "./OddsComparision";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import MlbDraftCheat from "./MlbDraftCheat";
import NflDraftCheat from "./NflDraftCheat";
import Odds from "./Odds";
import Payment from "./Payment";
import Login from "./Login";
import Register from "./Register";
import ForgetPasswordw from "./ForgetPasswordw";
import ImportTeam from "./ImportTeam";

const Main = () => {
  const [checkHome, setcheckHome] = useState(false);
  const [forgetPass, setforgetPass] = useState(false);
  // let ids = "3069db07-aa43-4503-ab11-2ae5c0002721";
  // let gameType = "nfl";
  // let pttype = "player"
  useEffect(() => {
    const loc = window.location.pathname;
     const url = window.location.href.split("/").slice(-2)[0];
    //const url = window.location.pathname.split("/")[1];
    
    if (loc === "/") {
      setcheckHome(true);
    } else {
      setcheckHome(false);
    }
    if (url === "forgetpassword" ) {
      setforgetPass(true);
    } else {
      setforgetPass(false);
    }
  });

  return (
    <Fragment>
      {checkHome ? <BannerShape /> : ""}
      {!forgetPass ? <Navbar /> : ""}

      <main className={forgetPass ? "mt-0" : ""}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/pricing-copy" component={Pricing_copy} />
          <Route exact path="/forgetpassword/:key" component={ForgetPassword} />
          <Route exact path="/setting" component={Setting} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          {/* <Route exact path="/forgetpassword" component={ForgetPasswordw} /> */}
          <Route exact path="/changepassword" component={ChangePassword} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/subscription" component={Subscription} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/importteam" component={ImportTeam} />
          <Route exact path="/ranking" component={Ranking} />
          <Route exact path="/projectile" component={Projectile} />
          <Route exact path="/startsit" component={StartSit} />
          <Route exact path="/wavier" component={Wavier} />
          <Route exact path="/nflodds" component={NflOdds} />
          <Route exact path="/nfloddcalc" component={NflOddCalc} />
          <Route exact path={"/player-profile"} component={PlayerProfile} />
          <Route exact path={"/odd-comparision"} component={OddsComparision} />
          <Route exact path={"/mlbdraftcheat"} component={MlbDraftCheat} />
          <Route exact path={"/nfldraftcheat"} component={NflDraftCheat} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path={"/odds"} component={Odds} />
          {/* <Route  exact path={"/player-profile2/:ids/:id2/:gameType/:pttype"} component={PlayerProfile2}/>   */}
          {/*  <Route  exact path={["/player-profile2/:ids/:gameType/:pttype", "/player-profile2/:ids/:id2/:gameType/:pttype"]} component={PlayerProfile2}/>   */}
          <Route
            exact
            path={[
              "/profilesharing/:id1/:name1/:position1/:gameType/:pttype",
              "/profilesharing/:id1/:name1/:position1/:id2/:name2/:position2/:gameType/:pttype",
              "/profilesharing/:id1/:name1/:position1/:id2/:name2/:position2/:id3/:name3/:position3/:gameType/:pttype",
            ]}
            component={ProfileSharing}
          />
          <Route
            exact
            path={"/player-comparision"}
            component={PlayerComparision}
          />
          <Route exact path={"/card"} component={Card} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </main>
      {!forgetPass ? <Footer /> : ""}
    </Fragment>
  );
};

export default withRouter(Main);
