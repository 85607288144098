import { Fragment, useEffect, useState,useRef } from "react";
import {  useHistory,Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { successToast, errorToast } from "./Utility/toastify";
import axios from "./Utility/axios";
import request from "./Utility/Request";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { initializeApp } from 'firebase/app';
import { getAnalytics,logEvent } from 'firebase/analytics';
import { firebaseConfig } from "./Utility/actionMethod";


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



const CheckoutForm = ({ plan_id, subAmt, subPlan }) => {
  let history = useHistory();
   const stripe = useStripe();
   const elements = useElements();
   const [loader, setLoader] = useState(false);
   const [state, setState] = useState(
     {
       name:"",
      //  email:""
     }
   )
   const [errors, setErrors] = useState({
    
   });

   
 const formHandle = (e) => {
   setState({
     ...state,
     [e.target.name]: e.target.value,
   });
 }
 
 const ValiditState = () => {
   let error = {};
   if(state.name.trim() === ""){
     error.name ="Please enter Name";
   }
  //  if (state.email.trim() === "") {
  //    error.email = "Please enter Email";
  //  }
   return error;
 }

  const createSubscription = async () => {
    setErrors(ValiditState());
    if (Object.keys(errors).length === 0) {
      try {
        const paymentMethod = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement("card"),
          billing_details: { name: state.name,email:state.email },
        });


        var id = paymentMethod.paymentMethod.id;
        const Paydetail = {
          subscriptiontype: plan_id,
          tokenId: id,
        };
        console.log('Paydetail',Paydetail)
        pay(Paydetail);
      } catch (error) {
        console.log(error);
      }
    }
    else{
    return;

    }
  };
  
    
  const pay = async (paymentDetail) => {
    let sendheaders = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tokens"),
      },
    };
    try {
      setLoader(true);
      const response = await axios.post(
        request.SUBCRIPTION,
        {
          subscriptiontype: paymentDetail.subscriptiontype,
          tokenId: paymentDetail.tokenId,
        },
        sendheaders
      );

      
      // console.log({response})
      if (response) {
          setLoader(false);
        console.log("pay",response)

        // var details = {
        //   name : response.data.name,
        //   email : response.data.email,
        //   clientSecret : response.data.clientSecret
        // } 
        confirmCardHandler(response.data, paymentDetail.tokenId,paymentDetail)
        // history.push("/");  
        // getUser();
      } else {
        const message = response.data.message;
        errorToast(message);
      }
    } catch (err) {
      if (!err.respsonse) {
        setLoader(false);
        errorToast(err.message);
      }
    }
  };

  const confirmCardHandler = async (data,id,paymentDetail) => {
  console.log("asdasd",data,id,paymentDetail)
    try {
      const result = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: id
      });

      if (result.error) {
        console.error(result.error.message);
      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        // Payment succeeded, handle the success
        retriveSubscription(data,id,paymentDetail)
      }
    } catch (error) {
      console.error(error);
    }
  };


  const retriveSubscription = async (data,id,paymentDetail) => {

    stripe.retrievePaymentIntent(data.clientSecret).then(({paymentIntent}) => {
      const message = document.querySelector('#message')
      switch (paymentIntent.status) {
        case 'succeeded':
        stripeSuccess(data.subscriptionId,paymentDetail.subscriptiontype)
        break;
    
        case 'processing':
          errorToast("message","Payment processing. We'll update you when payment is received.")   
          break;
    
        case 'requires_payment_method':
          errorToast("message",'Payment failed. Please try another payment method.')  
          break;
    
        default:
          message.innerText = 'Something went wrong.';
          break;
      }
    });


  }

  const stripeSuccess = async (subscriptionId,subscriptiontype) => {
    let sendheaders = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tokens"),
      },
    };
    try {
      setLoader(true);
      const response = await axios.post(
        request.SUBSCRIPTION_SUCCESS,
        {
          subscriptionId,
          subscriptiontype,
        },
        sendheaders
      );
      if (response) {
          setLoader(false);
          successToast(response.data.message);
          logEvent(analytics, 'subscribe', {"subscriptionId": subscriptionId, "subscriptiontype":subscriptiontype,"Plan":subPlan});
          history.push("/");    
      }
    } catch (err) {
      if (!err.respsonse) {
        setLoader(false);
        errorToast(err.message);
      }
    }
  };
  
 
 
  return (
 
    <div className="sample" >
      <div className={loader ? "sipnner-wrap" : "d-none"}>
        <Spinner animation="border" />
      </div>
      <div className="form-control">
      <label>Cardholders Name</label>
      <input
        type="text"
        placeholder="Enter Name"
        name="name"
        value={state.name}
        onChange={formHandle}
      />
      <span>{errors?.name}</span>
      </div>
      {/* <div className="form-control">
      <label>Email</label>
        <input
          type="email"
          placeholder="Enter Email"
          name="email"
          value={state.email}
          onChange={formHandle}
        />
        <span>{errors?.email}</span>
      </div>
     */}
      <div className="sample-wrap">
      <label>Cardholders Information</label>
        <CardElement />
      </div>
      <br />
      <button onClick={createSubscription}>
        {loader ? "loading..." : `Subscribe`}
      </button>
      <p className="mt-4 mb-0">By confirming your subscription, you allow Fanalyze Inc. to charge your card for this payment and future payments in accordance with their terms. You can always cancel your subscription</p>

      <div className="stripe-image">
        <img src="assets/images/stripe.svg" />
      </div>
    </div>
   
  );
 };




function Payment({location}) {
const {state} = location
const [loader, setLoader] = useState(true);
const [purchasingPlan,setPurchasingPlan] =  useState({})

//stripeID
const stripePromise = loadStripe("pk_live_hlYVqbW5NS2J6wefkRzDGkKy");
// const stripePromise = loadStripe("pk_test_J8vcCdew12TjN2N0tIUNdHRu");
const AllPlans = [
  {Plan_id:"1", subAmt:"8.99", Plan:"Fantasy Sports Monthly",period:"Monthly"},
  {Plan_id:"2", subAmt:"53.99", Plan:"Fantasy Sports Yearly",period:"Yearly"},
  {Plan_id:"3", subAmt:"28.99", Plan:"Fantasy Sports & Sports Betting Monthly",period:"Monthly"},
  {Plan_id:"4", subAmt:"174.99", Plan:"Fantasy Sports & Sports Betting Yearly",period:"Yearly"},
  {Plan_id:"5", subAmt:"14.99", Plan:"Draft Cheat Sheet",period:""},
  
]
   
const ref = useRef(null);

const handleClick = () => {
  ref.current?.scrollIntoView({behavior: 'smooth'});
};

  
useEffect(()=>{
if(state.id !== "" && state.id !== undefined){
  
const foundPlan = AllPlans.find(plan => plan.Plan_id === state.id);
if (foundPlan) {
  setPurchasingPlan(foundPlan);
  setLoader(false);
  handleClick();
} else {
  console.log("Plan not found");
}
}
},[state])


  
  return (
    <Fragment>
      <section className="brd-cmb  p-50 pb-0" ref={ref}>
        <div className="container">
          <div className="breadcrumb-wrapper">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/pricing">Pricing</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Payment
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
    
    <section className="p-50" >
      <div className="container">
      <div className={loader ? "sipnner-wrap" : "d-none"}>
        <Spinner animation="border" />
      </div>
        <div className="row">
          <div className="col-lg-6">  
              <div className="payment-details">
                <h6> {purchasingPlan.Plan}</h6>
                <h2>${purchasingPlan.subAmt}</h2><span>  {purchasingPlan.period} <sup>per</sup></span>
                <p>{purchasingPlan.Plan} subscription for fanalyze application</p>
              </div>
          </div>
          <div className="col-lg-6">
            <Elements stripe={stripePromise}>
              <CheckoutForm  subPlan={purchasingPlan.Plan} plan_id={purchasingPlan.Plan_id} subAmt={purchasingPlan.subAmt} />
            </Elements>
          </div>
        </div>
      </div>
    </section>

    </Fragment>
  );
}

export default Payment;