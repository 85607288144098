import React,{Fragment} from 'react'

const BannerShape = () => {

    return (
    <Fragment>
    {/*
        <img alt="banner-shape" className="banner-shape" src="assets/images/banner_shape.png"/>
        <img alt="banner-shape" className="banner-shape_2" src="assets/images/banner_shape_2.png"/>
    */}
    </Fragment>
    
    )
}

export default BannerShape
