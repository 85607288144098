import React,{Fragment, useEffect, useState} from 'react';
import {Tab,Tabs,Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {apiInstance} from "./Utility/axios";



import {getFormattedAllGameOdds} from "./Utility/FormattedOddsData"



const NflOdds = () => {
const [loader, setLoader] = useState(true);
const [moneyLine , setmoneyLine] = useState([]);
const [isMonyline , setisMonyline] = useState(false);
const [spread,setspread] = useState([]);
const [isspread,setisspread] = useState(false);
const [total,settotal] = useState([]);
const [istotal,setistotal] = useState(false);


useEffect(()=>{
    getOddsData()
},[])


// ======== GET RANKING DATA ========
const getOddsData = async() => {
    try{
        const response = await apiInstance.get("nfl/odds");
        if(response){
            let oddsResponse = getFormattedAllGameOdds(response);
            setspread(oddsResponse.spread)
            setmoneyLine(oddsResponse.moneyline)
            settotal(oddsResponse.total)
            setTimeout(() => {
                setisMonyline(true)
                setisspread(true)
                setistotal(true)
                setLoader(false)
                FirstTeam();
            }, 5000);
        }
        }catch(err){
        console.log(err);
    }
}


const FirstTeam = async(id) => {
    try{
        const response = await apiInstance.get("nfl/team/"+id+"/profile");
        if(response){
        }
        }catch(err){
        console.log(err);
    }

}



    return (
        <Fragment>
         {/* ====== BREADCRUMB ====== */}
        <section className="brd-cmb  p-50">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/profile">Profile</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">settings</li>
                    </ol>
                </nav>
            </div>
        </section> 

        <section className="p-50 common-section-lg">
            <div className="container">
            { !loader ? 
            <Tabs defaultActiveKey="moneyline" id="uncontrolled-tab-example" className="mb-3 exclusive_data_tab_pills  ">
                <Tab eventKey="moneyline" title="moneyline">
                    {isMonyline ?
                    <table className="table table-bordered table-striped nfl-odds season-table">
                    <thead>
                    <tr>
                    {(moneyLine.statsHeader).map((data,i,index)=>(
                        <th key={index}>{data}</th>
                    ))}
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="7">
                                <div className="analyze-wrap">
                                    <button  className="common-btn">ANALYZE</button>
                                    <p>Miami Dolphines vs new york Gaints Sun, Dec.5 at 1:00pm</p>
                                </div>
                            </td>
                            
                        </tr>
                          {moneyLine.statsData.map((data,i,index) => (
                             // {
                            //     ((i+1)%2 === 0)?"True":false 
                            // }
                            <tr key={index}>
                            {data.map((tdData,i,row)=>(
                                    (i + 1 === row.length)? 
                                    ""
                                    :<td key={row}>{tdData}</td>
                                ))}
                            </tr>
                          ))}  
                    </tbody>
                    </table>
                    : <div className={loader ? "sipnner-wrap" : "d-none"}>
                    <Spinner animation="border" />
                  </div>}
                </Tab>
                <Tab eventKey="spread" title="spread">
                {isspread ?
                    <table className="table table-bordered table-striped nfl-odds season-table">
                    <thead>
                    <tr>
                    {(spread.statsHeader).map((data,i,index)=>(
                        <th key={index}>{data}</th>
                    ))}
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="7">
                                <div className="analyze-wrap">
                                    <Link to="/"  className="common-btn">ANALYZE</Link>
                                    <p>Miami Dolphines vs new york Gaints Sun, Dec.5 at 1:00pm</p>
                                </div>
                            </td>
                            
                        </tr>
                          {spread.statsData.map((data,i,index) => (
                             // {
                            //     ((i+1)%2 === 0)?"True":false 
                            // }
                            <tr key={index}>
                            {data.map((tdData,i,row)=>(
                                    (i + 1 === row.length)? ""
                                    :<td>{tdData}</td>
                                ))}
                            </tr>
                          ))}  
                    </tbody>
                    </table>
                    : <div className={loader ? "sipnner-wrap" : "d-none"}>
                    <Spinner animation="border" />
                  </div>}
                </Tab>
                <Tab eventKey="Total" title="Total">
                {istotal ?
                    <table className="table table-bordered table-striped nfl-odds season-table">
                    <thead>
                    <tr>
                    {(total.statsHeader).map((data,i,index)=>(
                        <th key={index}>{data}</th>
                    ))}
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="7">
                                <div className="analyze-wrap">
                                    <Link to="/" className="common-btn">ANALYZE</Link>
                                    <p>Miami Dolphines vs new york Gaints Sun, Dec.5 at 1:00pm</p>
                                </div>
                            </td>
                            
                        </tr>
                          {total.statsData.map((data,i,index) => (
                             // {
                            //     ((i+1)%2 === 0)?"True":false 
                            // }
                            <tr key={index}>
                            {data.map((tdData,i,row)=>(
                                    (i + 1 === row.length)? ""
                                    :<td>{tdData}</td>
                                ))}
                            </tr>
                          ))}  
                    </tbody>
                    </table>
                    : <div className={loader ? "sipnner-wrap" : "d-none"}>
                        <Spinner animation="border" />
                  </div>}
                </Tab>
            </Tabs>
            : <div className={loader ? "sipnner-wrap" : "d-none"}>
                <Spinner animation="border" />
              </div> }
            </div>
            
        </section>

        </Fragment>
    )
}

export default NflOdds
