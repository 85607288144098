import React,{Fragment,useEffect} from 'react'
import axios from "./Utility/axios";
import request from "./Utility/Request"
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import {successToast,errorToast} from "./Utility/toastify";
import { ToastContainer} from 'react-toastify';
import { Link} from "react-router-dom";

const Profile = () => {


useEffect(() => {   
    userDetail();    
},[]);

const userDetail = async () => {
  let sendheaders = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("tokens"),
    },
  };
  try {
    const response = await axios.get(request.GET_USER_DATA, sendheaders);
    if (response) {

      if (response.status === 200) {
        setValue("name", response.data.username);
        setValue("email", response.data.email);
        setValue("Id", JSON.stringify(response.data.user_id));
      }
    }
  } catch (err) {
    if (!err.respsonse) {
    }
  }
};

const profileValidation = Yup.object({
    name: Yup.string().required("name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
  }).required();



// PROFILE FORM 
const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({ resolver: yupResolver(profileValidation) });
  const profileSubmit = (data) => {
    let id = JSON.parse(data.Id);
    let name = data.name;
    let email = data.email;
   const datas ={
        "id": id,
        "username": name,
        "email": email
    }
      let sendheaders = {
        headers: {
          Authorization: "Bearer "+ request.Token,
        },
      };
      const profileUpdate = async (datas) => {
        try {
          const response = await axios.post(
            request.UPDATE_USER_DATA,
            datas,
            sendheaders
          );
          if (response.status === 200) {
            successToast("Your Profile is updated");
            userDetail();
          } else {
            const message = response.data.message;
            errorToast("Profile Not Updated Please Try Again");
          }
        } catch (err) {
          if (!err.respsonse) {
            errorToast(err.message);
          }
        }
      };
    profileUpdate(datas);
  };



return (
        <Fragment>
          {/* === BREADCRUMB ====*/} 
        <section className="brd-cmb  p-50">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="./">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Profile</li>
                    </ol>
                </nav>
            </div>
        </section>

         {/*=== UPDATE PROFILE ==== */}

    <section className="update-profile pb-100">
        <div className="container">
            <form action="" onSubmit={handleSubmit(profileSubmit)}>
            <input type="hidden" className="form-control" placeholder="Id" name="Id" {...register("Id")}/>
                <div>
                    <div className="input-group mb-3">
                        <span className="input-group-text"><i className="fas fa-user"></i></span>
                        <input type="text" className="form-control" placeholder="name" name="name" {...register("name")}/>
                    </div>
                    <span className="error">{errors.name?.message}</span>
                </div>  
                <div>  
                    <div className="input-group mb-3">
                        <span className="input-group-text"><i className="fas fa-envelope"></i></span>
                        <input type="text" className="form-control" placeholder="Email" name="email" {...register("email")}/>
                    </div>
                    <span className="error">{errors.email?.message}</span>
                </div>

                {/*
                <div className="input-group mb-3">
                    <span className="input-group-text">
                    <FaLock/>
                    </span>
                    <input
                    type={passwordShown ? "text" : "password"} 
                    className="form-control"
                    placeholder="password"
                    name="password"
                    id="password"
                    
                    />
                    <span
                    toggle="#password"
                    className="fa fa-fw fa-eye-slash field-icon toggle-password"
                    className="toggle-password"
                    onClick={togglePassword}
                    >  {passwordShown ? <FaRegEye/> :<FaRegEyeSlash/>}</span>
                </div>
              */}

                <button className="common-btn with-border">update profile </button>
                
            </form>
        </div>
    </section>
    <ToastContainer autoClose={2000}/>
        </Fragment>
    )
}

export default Profile
