import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "./Utility/axios";
import request from "./Utility/Request";
import Successfully from "../assets/images/Successfully.png";
import Unsupported from "../assets/images/unsupported.png";

const loginWrapper = {
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#eee",
  marginBottom: "20px",
};

const signin_form = {
  width: "100%",
  maxWidth: "450px",
  backgroundColor: "#fff",
  padding: "50px",
  borderRadius: "5px",
};
const signin_form_logo = {
  textAlign: "center",
  textTransform: "capitalize",
  marginBottom: "30px",
};
const signin_form_label = {
  textTransform: "capitalize",
};
const login_btn = {
  width: "100%",
  backgroundColor: "green",
  border: "none",
  outline: "none",
  height: "40px",
  borderRadius: "5px",
  textTransform: "capitalize",
  color: "#fff",
};
const error = {
  color: "red",
};

const forgetpasswordInner = {
  background: "#ffffff",
  boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  width: "100%",
  maxWidth: "850px",
  margin: "0 auto",
  padding: "50px 130px",
};

const commonBtn = {
  padding: "10px 30px",
  display: "inline-block",
  marginTop: "30px",
  width: "inherit",
  backgroundColor: "#2b7d3e",
  color: "white",
  fontFamily: "TT-Regular",
  border: "1px solid transparent",
  borderRadius: "4px",

  fontSize: "18px",
  textTransform: "capitalize",
  transition: "0.5s linear",
};

// ===========================================//

const ForgetPassword = () => {
  const [newPass, setNewPass] = useState("");
  const [cfmnewPass, setcfmNewPass] = useState("");
  const [iserror, setIserror] = useState(false);
  const [error, setErrors] = useState("");
  const [verification, setverification] = useState(true);
  const [success, setsuccess] = useState(false);
  const { key } = useParams();
  let history = useHistory();

  useEffect(() => {
    Chkverification();
  }, []);

  const Chkverification = async () => {
    try {
      const response = await axios.get(request.VERIFICATION + key);
      setverification(true);
    } catch (e) {
      setverification(false);
    }
  };

  const updatePassword = async (password) => {
    let data = { new_password: password };
    try {
      const response = await axios.post(request.UPDATE_PASSWORD + key, data);
      if (response.data.status === "Successfull") {
        setsuccess(true);
        setverification(false);
      }
    } catch (e) {
      setverification(false);
    }
  };

  const ForgetPasswordHandler = (e) => {
    e.preventDefault();
    if (newPass !== cfmnewPass) {
      setErrors("New Password and Confirm Password Does not macth");
      setIserror(true);
    } else {
      updatePassword(newPass);
    }
  };

  return (
    <React.Fragment>
      {verification ? (
        <section style={loginWrapper}>
          <form action="" style={signin_form} onSubmit={ForgetPasswordHandler}>
            <div style={signin_form_logo}>
              <img
                alt="logo"
                src="assets/images/logo.png"
                className="Login_logo"
              />
            </div>
            {iserror ? <p className="error">{error}</p> : ""}
            <div className="from-group mb-4">
              <div className="login-input-wrap ">
                <input
                  id="newPass"
                  type="password"
                  name="newPassword"
                  className="form-control effect-8"
                  placeholder="Enter New Password"
                  onChange={(e) => {
                    setNewPass(e.target.value);
                    setErrors("");
                    setIserror(false);
                  }}
                />
                <span className="focus-border">
                  {" "}
                  <i></i>
                </span>
              </div>
            </div>

            <div className="from-group mb-4">
              <div className="login-input-wrap ">
                <input
                  id="cnfrmpassword"
                  type="password"
                  name="confirmnewPassword"
                  className="form-control effect-8"
                  placeholder="Enter Confirm  Password"
                  onChange={(e) => {
                    setcfmNewPass(e.target.value);
                    setErrors("");
                    setIserror(false);
                  }}
                />
                <span className="focus-border">
                  {" "}
                  <i></i>
                </span>
              </div>
            </div>
            <div className="form-group">
              <button style={login_btn}>Change Password</button>
            </div>
          </form>
        </section>
      ) : success || success === "true" ? (
        ""
      ) : (
        <section
          className="forgetpassword-wrapper"
          style={{ padding: "100px 0px" }}
        >
          <div style={forgetpasswordInner}>
            <div className="text-center" style={{ marginBottom: "30px" }}>
              <img src={Unsupported} />
            </div>
            <div className="pass-fail" style={{ textAlign: "center" }}>
              <h2>
                Unsupported <span>Link</span>
              </h2>

              <p>Sorry! , this link is not supported , please try again</p>
              {/*
              <Link to="/" style={commonBtn}>
                Go to Home
              </Link>
      */}
            </div>
          </div>
        </section>
      )}
      {success || success === "true" ? (
        <section
          className="forgetpassword-wrapper"
          style={{ padding: "100px 0px" }}
        >
          <div style={forgetpasswordInner}>
            <div className="text-center" style={{ marginBottom: "30px" }}>
              <img src={Successfully} />
            </div>
            <div className="pass-success" style={{ textAlign: "center" }}>
              <h2>
                Successfully <span>Changed Your Password</span>
              </h2>

              <p>
                Congratulations!,Your password has been changed
                successfully.Please Login again{" "}
              </p>
              {/*
              <Link to="/" style={commonBtn}>
                Go to Home
              </Link>
              */}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default ForgetPassword;
