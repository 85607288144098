import {propOr,pathOr,map,has} from 'ramda';
import moment from 'moment';
import momentTimezone from 'moment';

const getScheduledTime = (time, format) => momentTimezone(time).tz("America/New_York").format(format);

const getScheduledDate = (time) => {
  const momentDate = moment(time).format('llll')
  const n = 2; // second comma
  const splittedDate = momentDate.split(',')
  const date = splittedDate.slice(0, n).join(',')
  return date;
}

export const getFormattedLast10Games = (response, playerPosition, teamId, type, selectGameType) => {
  console.log("selectGameType",response, playerPosition, teamId, type, selectGameType);
  const gameSummary = pathOr([], ['data', 'response'], response);
  const hitterStatsHeader = ['DATE', 'VS', 'R', 'H', 'HR', 'RBI', 'BB', 'SO', 'SB', 'CS', 'AVG', 'OBP', 'SLG', 'OPS']
  const pitcherStatsHeader = ['DATE', 'VS', 'W', 'L', 'ERA', 'SV', 'SVO', 'IP', 'H', 'R', 'ER', 'HR', 'HB', 'BB', 'IBB', 'SO', 'WHIP']
  const passingStatsHeader = ['DATE', 'VS', 'COMP', 'ATT', 'YDS', 'AVG', 'TD', 'INT', 'SCK', 'QBR', 'ATT', 'YDS', 'AVG', 'TD', 'FUM', 'LOST']
  const rushingStatsHeader = ['DATE', 'VS', 'ATT', 'YDS', 'AVG', 'LNG', 'TD', 'REC', 'YDS', 'AVG', 'TD']
  const receivingStatsHeader = ['DATE', 'VS', 'REC', 'YDS', 'AVG', 'TD', 'LNG', 'TGT']
  const defenseStatsHeader = ['DATE', 'VS', 'AST','TOT','SACK','SCKYADS','TFL','PD','INT','FR']
  const passingStatsHeaderTeam = ['DATE', 'VS', 'COMP', 'ATT', 'YDS', 'AVG', 'TD', 'INT', 'SCK', 'QBR']
  const rushingStatsHeaderTeam = ['DATE', 'VS', 'ATT', 'YDS', 'AVG', 'LNG', 'TD']
  const fieldgoalsStatsHeader = ['DATE', 'VS', 'ATT', 'M', 'MI', 'PCT', 'LNG', 'M', 'PCT']
  const nbaStatsHeader = ['DATE', 'VS', 'PTS', 'MIN', 'FGM', 'FGA', 'FG%', '3PM', '3PA', '3P%', 'FTA', 'FT%', 'OREB', 'DREB', 'AST', 'TOV', 'STL', 'BLK', 'PF']
//   const pitchers = ['P', 'SP', 'RP'];
//   const hitters = ['3B', 'CF', 'SS', '2B', 'C', '1B', 'RF', 'LF', 'DH', 'OF'];

  const pitchers = ['P', 'SP', 'RP', 'G', 'AB', 'R', 'H', '2B', '3B', 'HR', 'RBI', 'BB', 'SO', 'SB', 'CS', 'AVG', 'OBP', 'SLG', 'OPS']
  const hitters = ['3B', 'CF', 'SS', '2B', 'C', '1B', 'RF', 'LF', 'DH', 'OF', 'G', 'GS', 'W', 'L', 'ERA', 'SV', 'SVO', 'IP', 'H', 'R', 'ER', 'HR', 'HB', 'BB', 'IBB', 'SO', 'WHIP']

  let isNFL = false;
  map((game)=> {isNFL = has('summary', game)}, gameSummary);
  const isNBA = selectGameType === 'nba';
  
if(selectGameType === "mlb"){
  if (pitchers.includes(playerPosition)) {
      
    return {statsHeader: pitcherStatsHeader, widthArr: [80, 80, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35],
      statsData: map((game) => {
        return formatPitcherDataForLast10Games(game, teamId, type);
      }, gameSummary)
     
    }
  }
  if (hitters.includes(playerPosition)) {
    return {
      statsHeader: hitterStatsHeader,
      widthArr: [80, 80, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35],
      statsData: map((game) => {
        return formatHitterDataForLast10Games(game, teamId, type);
      }, gameSummary)
    }
  }
}

if(selectGameType === "nfl"){
  if('QB'===playerPosition){
    return {
      statsHeader: passingStatsHeader,
      widthArr: [80, 80, 55, 50, 50, 50, 35, 35, 50, 50, 50, 50, 50, 35, 50, 50],
      statsData: map((game) => {
        return formatPassingDataForLast10Games(game, teamId, type);
      }, gameSummary)
    }
  }
  if('RB'===playerPosition){
    return {
      statsHeader: rushingStatsHeader,
      widthArr: [80, 80, 50, 50, 50, 50, 50, 50, 50, 50, 50],
      statsData: map((game) => {
        return formatRushingDataForLast10Games(game, teamId, type);
      }, gameSummary)
    }
  }
  if('WR'===playerPosition){
    return {
      statsHeader: receivingStatsHeader,
      widthArr: [80, 80, 50, 50, 50, 50, 50, 50],
      statsData: map((game) => {
        return formatReceivingDataForLast10Games(game, teamId, type);
      }, gameSummary)
    }
  }
  if('TE'===playerPosition){
    return {
      statsHeader: receivingStatsHeader,
      widthArr: [80, 80, 50, 50, 50, 50, 50, 50],
      statsData: map((game) => {
        return formatReceivingDataForLast10Games(game, teamId, type);
      }, gameSummary)
    }
  }
  if('K'===playerPosition){
    return {
      statsHeader: fieldgoalsStatsHeader,
      widthArr: [80, 80, 50, 50, 50, 50, 50, 50, 50],
      statsData: map((game) => {
        return formatFieldGoalsDataForLast10Games(game, teamId, type);
      }, gameSummary)
    }
  }
  if('D'===playerPosition){
    return {
      statsHeader: defenseStatsHeader,
      widthArr: [80, 80, 50, 50, 50, 50, 80, 50, 50, 50],
      statsData: map((game) => {
        return formatDefenseDataForLast10Games(game, teamId, type);
      }, gameSummary)
    }
  }
}

  if(isNFL && type === 'team'){
    return {
    passing: {
      statsHeader: passingStatsHeaderTeam,
      widthArr: [80, 80, 60, 50, 50, 50, 50, 50, 50, 50],
      statsData: map((game) => {
        return formatPassingDataForLast10GamesTeam(game, teamId, type);
      }, gameSummary)
    },
    rushing: {
      statsHeader: rushingStatsHeaderTeam,
      widthArr: [80, 80, 50, 50, 50, 50, 50],
      statsData: map((game) => {
        return formatRushingDataForLast10Games(game, teamId, type);
      }, gameSummary)
    },
    receiving: {
      statsHeader: receivingStatsHeader,
      widthArr: [80, 80, 50, 50, 50, 50, 50, 50],
      statsData: map((game) => {
        return formatReceivingDataForLast10Games(game, teamId, type);
      }, gameSummary)
    },
    defense: {
      statsHeader: defenseStatsHeader,
      widthArr: [80, 80, 50, 50, 50, 80, 50, 50, 50, 50],
      statsData: map((game) => {
        return formatDefenseDataForLast10Games(game, teamId, type);
      }, gameSummary)
    },
    field_goals: {
      statsHeader: fieldgoalsStatsHeader,
      widthArr: [80, 80, 50, 50, 50, 50, 50, 50, 50],
      statsData: map((game) => {
        return formatFieldGoalsDataForLast10Games(game, teamId, type);
      }, gameSummary)
    },
  }

  }

  if(selectGameType === 'nba'){
    if(type === 'team'){
      return {
        nba: {
          statsHeader: nbaStatsHeader,
          widthArr: [80, 80, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
          statsData: map((game) => {
            return formatNBADataForLast10Games(game, teamId, type);
          }, gameSummary.slice(-10))
        }
      }
    }else{
      return {
        statsHeader: nbaStatsHeader,
        widthArr: [80, 80, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
        statsData: map((game) => {
          return formatNBADataForLast10Games(game, teamId, type);
        }, gameSummary.slice(-10))
      }
    }
  }


  return {
    hitting: {
      statsHeader: hitterStatsHeader,
      widthArr: [80, 80, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35],
      statsData: map((game) => {
        return formatHitterDataForLast10Games(game, teamId, type);
      }, gameSummary)
    },
    pitching: {
      statsHeader: pitcherStatsHeader,
      widthArr: [80, 80, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35],
      statsData: map((game) => {
        return formatPitcherDataForLast10Games(game, teamId, type);
      }, gameSummary)
    },

  };
};

const formatPitcherDataForLast10Games = (game, teamId, type) => {
    const scheduledTime = propOr('', 'scheduled', game)
    const DATE = getScheduledDate(scheduledTime)
    let VS = '';
    if (teamId === pathOr('', ['home', 'id'], game)) VS = `@ ${pathOr('-', ['away', 'name'], game)}`
    else VS = `${pathOr('-', ['home', 'name'], game)}`
    const path = [type, 'statistics', 'pitching', 'overall']
    const W = pathOr('-', [...path, 'games', 'win'], game)
    const L = pathOr('-', [...path, 'games', 'loss'], game)
    const ERA = pathOr('-', [...path, 'era'], game)
    const SV = pathOr('-', [...path, 'games', 'save'], game)
    const SVO = pathOr('-', [...path, 'games', 'svo'], game)
    const IP = pathOr('-', [...path, 'ip_2'], game)
    const H = pathOr('-', [...path, 'onbase', 'h'], game)
    const R = pathOr('-', [...path, 'runs', 'total'], game)
    const ER = pathOr('-', [...path, 'runs', 'earned'], game)
    const HR = pathOr('-', [...path, 'onbase', 'hr'], game)
    const HB = pathOr('-', [...path, 'onbase', 'hbp'], game)
    const BB = pathOr('-', [...path, 'onbase', 'bb'], game)
    const IBB = pathOr('-', [...path, 'onbase', 'ibb'], game)
    const SO = pathOr('-', [...path, 'pitches', 'ktotal'], game)
    const WHIP = pathOr('-', [...path, 'whip'], game)
    return [DATE, VS, W, L, ERA, SV, SVO, IP, H, R, ER, HR, HB, BB, IBB, SO, WHIP];
  }
  
  const formatHitterDataForLast10Games = (game, teamId, type) => {
    const scheduledTime = propOr('', 'scheduled', game)
    const DATE = getScheduledDate(scheduledTime)
    let VS = '';
    if (teamId === pathOr('', ['home', 'id'], game)) VS = `@ ${pathOr('-', ['away', 'name'], game)}`
    else VS = `${pathOr('-', ['home', 'name'], game)}`
    const path = [type, 'statistics', 'hitting', 'overall']
    const R = pathOr('-', [...path, 'runs', 'total'], game)
    const H = pathOr('-', [...path, 'onbase', 'h'], game)
    const HR = pathOr('-', [...path, 'onbase', 'hr'], game)
    const RBI = pathOr('-', [...path, 'rbi'], game)
    const BB = pathOr('-', [...path, 'onbase', 'bb'], game)
    const SO = pathOr('-', [...path, 'outcome', 'ktotal'], game)
    const SB = pathOr('-', [...path, 'steal', 'stolen'], game)
    const CS = pathOr('-', [...path, 'steal', 'caught'], game)
    const AVG = pathOr('-', [...path, 'avg'], game)
    const OBP = pathOr('-', [...path, 'obp'], game)
    const SLG = pathOr('-', [...path, 'slg'], game)
    const OPS = pathOr('-', [...path, 'ops'], game)
    return [DATE, VS, R, H, HR, RBI, BB, SO, SB, CS, AVG, OBP, SLG, OPS];
  }
  
const formatPassingDataForLast10Games = (game, teamId, type) => {
    const scheduledTime = propOr('', 'scheduled', game)
  const DATE = getScheduledDate(scheduledTime)
    let VS = '';
    if(teamId === pathOr('', ['summary', 'home', 'id'], game)){
      VS =  `@ ${pathOr('-', ['summary', 'away', 'name'], game)}`
    }else{
      VS = `${pathOr('-', ['summary', 'home', 'name'], game)}`
    }
    // if (teamId === pathOr('', ['summary', 'home', 'id'], game)) VS = `@ ${pathOr('-', ['summary', 'away', 'name'], game)}`
    // else VS = `${pathOr('-', ['summary', 'home', 'name'], game)}`
    const path = ['statistics', 'passing']
    const pathR = ['statistics', 'rushing']
    const pathF = ['statistics', 'fumbles']
    const COMP = pathOr('-', [...path, 'completions'], game)
    const ATT = pathOr('-', [...path, 'attempts'], game)
    const YDS = pathOr('-', [...path, 'yards'], game)
    const AVG = pathOr('-', [...path, 'avg_yards'], game)
    const TD = pathOr('-', [...path, 'touchdowns'], game)
    const INT = pathOr('-', [...path, 'interceptions'], game)
    const SCK = pathOr('-', [...path, 'sacks'], game)
    const QBR = pathOr('-', [...path, 'rating'], game)
    const ATTR = pathOr('-', [...pathR, 'attempts'], game)
    const YDSR = pathOr('-', [...pathR, 'yards'], game)
    const AVGR = pathOr('-', [...pathR, 'avg_yards'], game)
    const TDR = pathOr('-', [...pathR, 'touchdowns'], game)
    const FUM = pathOr('-', [...pathF, 'fumbles'], game)
    const LOST = pathOr('-', [...pathF, 'lost_fumbles'], game)
    return [DATE, VS, COMP, ATT, YDS, AVG, TD, INT, SCK, QBR, ATTR, YDSR, AVGR, TDR, FUM, LOST];
  }
  
const formatPassingDataForLast10GamesTeam = (game, teamId, type) => {
    const scheduledTime = propOr('', 'scheduled', game)
    const DATE = getScheduledDate(scheduledTime)
    let VS = '';
    if (teamId === pathOr('', ['summary', 'home', 'id'], game)) VS = `@ ${pathOr('-', ['summary', 'away', 'name'], game)}`
    else VS = `${pathOr('-', ['summary', 'home', 'name'], game)}`
    const path = ['statistics', 'passing', 'totals']
    const COMP = pathOr('-', [...path, 'completions'], game)
    const ATT = pathOr('-', [...path, 'attempts'], game)
    const YDS = pathOr('-', [...path, 'yards'], game)
    const AVG = pathOr('-', [...path, 'avg_yards'], game)
    const TD = pathOr('-', [...path, 'touchdowns'], game)
    const INT = pathOr('-', [...path, 'interceptions'], game)
    const SCK = pathOr('-', [...path, 'sacks'], game)
    const QBR = pathOr('-', [...path, 'rating'], game)
    return [DATE, VS, COMP, ATT, YDS, AVG, TD, INT, SCK, QBR];
  }
  
  
  const formatRushingDataForLast10Games = (game, teamId, type) => {
    const scheduledTime = propOr('', 'scheduled', game)
    const DATE = getScheduledDate(scheduledTime)
    let VS = '';
   
    // if(teamId === pathOr('', ['summary', 'home', 'id'], game)){
    //   VS =  `@ ${pathOr('-', ['summary', 'away', 'name'], game)}`
    // }else{
    //   VS = `${pathOr('-', ['summary', 'home', 'name'], game)}`
    // }
     if (teamId === pathOr('', ['summary', 'home', 'id'], game)) VS = `@ ${pathOr('-', ['summary', 'away', 'name'], game)}`
     else VS = `${pathOr('-', ['summary', 'home', 'name'], game)}`
    const pathR = ['statistics', 'rushing']
    const pathRE = ['statistics', 'receiving']
    if(type==='player'){
      const ATT = pathOr('-', [...pathR, 'attempts'], game)
      const YDS = pathOr('-', [...pathR, 'yards'], game)
      const AVG = pathOr('-', [...pathR, 'avg_yards'], game)
      const LNG = pathOr('-', [...pathR, 'longest'], game)
      const TD = pathOr('-', [...pathR, 'touchdowns'], game)
      const REC = pathOr('-', [...pathRE, 'receptions'], game)
      const YDSRE = pathOr('-', [...pathRE, 'yards'], game)
      const AVGRE = pathOr('-', [...pathRE, 'avg_yards'], game)
      const TDRE = pathOr('-', [...pathRE, 'touchdowns'], game)
      return [DATE, VS, ATT, YDS, AVG, LNG, TD, REC, YDSRE, AVGRE, TDRE];
    }else{
      const pathRTeam = ['statistics', 'rushing', 'totals']
      const ATT = pathOr('-', [...pathRTeam, 'attempts'], game)
      const YDS = pathOr('-', [...pathRTeam, 'yards'], game)
      const AVG = pathOr('-', [...pathRTeam, 'avg_yards'], game)
      const LNG = pathOr('-', [...pathRTeam, 'longest'], game)
      const TD = pathOr('-', [...pathRTeam, 'touchdowns'], game)
      return [DATE, VS, ATT, YDS, AVG, LNG, TD];
    }
  }
  
  const formatReceivingDataForLast10Games = (game, teamId, type) => {
    const scheduledTime = propOr('', 'scheduled', game)
    const DATE = getScheduledDate(scheduledTime)
    let VS = '';
    if (teamId === pathOr('', ['summary', 'home', 'id'], game)) VS = `@ ${pathOr('-', ['summary', 'away', 'name'], game)}`
    else VS = `${pathOr('-', ['summary', 'home', 'name'], game)}`
    const pathR = type === 'player' ? ['statistics', 'receiving'] : ['statistics', 'receiving', 'totals']
    const REC = pathOr('-', [...pathR, 'receptions'], game)
    const YDS = pathOr('-', [...pathR, 'yards'], game)
    const AVG = pathOr('-', [...pathR, 'avg_yards'], game)
    const TD = pathOr('-', [...pathR, 'touchdowns'], game)
    const LNG = pathOr('-', [...pathR, 'longest_touchdown'], game)
    const TGT = pathOr('-', [...pathR, 'targets'], game)
    return [DATE, VS, REC, YDS, AVG, TD, LNG, TGT];
  }
  
  const formatDefenseDataForLast10Games = (game, teamId, type) => {
    const scheduledTime = propOr('', 'scheduled', game)
    const DATE = getScheduledDate(scheduledTime)
    let VS = '';
    if (teamId === pathOr('', ['summary', 'home', 'id'], game)) VS = `@ ${pathOr('-', ['summary', 'away', 'name'], game)}`
    else VS = `${pathOr('-', ['summary', 'home', 'name'], game)}`
    const path = type === 'player' ? ['statistics', 'defense'] : ['statistics', 'defense', 'totals']
    const AST = pathOr('-', [...path, 'assists'], game)
    const TOT = pathOr('-', [...path, 'tackles'], game)
    const SACK = pathOr('-', [...path, 'sacks'], game)
    const SCKYADS = pathOr('-', [...path, 'sack_yards'], game)
    const TFL = pathOr('-', [...path, 'tloss_yards'], game)
    const PD = pathOr('-', [...path, 'passes_defended'], game)
    const INT = pathOr('-', [...path, 'interceptions'], game)
    const FR = pathOr('-', [...path, 'fumble_recoveries'], game)
    return [DATE, VS, AST, TOT, SACK, SCKYADS, TFL, PD, INT, FR];
  }
  
  const formatFieldGoalsDataForLast10Games = (game, teamId, type) => {
    const scheduledTime = propOr('', 'scheduled', game)
    const DATE = getScheduledDate(scheduledTime)
    let VS = '';
    if (teamId === pathOr('', ['summary', 'home', 'id'], game)) VS = `@ ${pathOr('-', ['summary', 'away', 'name'], game)}`
    else VS = `${pathOr('-', ['summary', 'home', 'name'], game)}`
  
    const path = type=== 'player' ? ['statistics', 'field_goals'] : ['statistics', 'field_goals', 'totals']
    const pathE = type=== 'player' ? ['statistics', 'extra_points'] : ['statistics', 'extra_points', 'kicks', 'totals']
    const ATT = pathOr('-', [...path, 'attempts'], game)
    const M = pathOr('-', [...path, 'made'], game)
    const MI = pathOr('-', [...path, 'missed'], game)
    const PCT = pathOr('-', [...path, 'pct'], game)
    const LNG = pathOr('-', [...path, 'longest'], game)
    const EM = pathOr('-', [...pathE, 'made'], game)
    const EPCT = pathOr('-', [...pathE, 'pct'], game)
    return [DATE, VS, ATT, M, MI,PCT, LNG, EM, EPCT];
  }
  
  const formatNBADataForLast10Games = (game, teamId, type) => {
    const scheduledTime = propOr('', 'scheduled', game)
    const DATE = getScheduledDate(scheduledTime)
    let VS = '';
    if (teamId === pathOr('', ['home', 'id'], game)) VS = `@ ${pathOr('-', ['away', 'name'], game)}`
    else VS = `${pathOr('-', ['home', 'name'], game)}`
  
  
    const PTS = pathOr('-', ['statistics', 'points'], game)
    const MIN = pathOr('-', ['statistics', 'minutes'], game)
    const FGM = pathOr('-', ['statistics', 'field_goals_made'], game)
    const FGA = pathOr('-', ['statistics', 'field_goals_att'], game)
    const FGP = pathOr('-', ['statistics', 'field_goals_pct'], game)
    const THPM = pathOr('-', ['statistics', 'three_points_made'], game)
    const THPA = pathOr('-', ['statistics', 'three_points_att'], game)
    const THPP = pathOr('-', ['statistics', 'three_points_pct'], game)
    const FTA = pathOr('-', ['statistics', 'free_throws_made'], game)
    const FTP = pathOr('-', ['statistics', 'free_throws_pct'], game)
    const OREB = pathOr('-', ['statistics', 'offensive_rebounds'], game)
    const DREB = pathOr('-', ['statistics', 'defensive_rebounds'], game)
    const AST = pathOr('-', ['statistics', 'assists'], game)
    const TOV = pathOr('-', ['statistics', 'turnovers'], game)
    const STL = pathOr('-', ['statistics', 'steals'], game)
    const BLK = pathOr('-', ['statistics', 'blocks'], game)
    const PF = pathOr('-', ['statistics', 'personal_fouls'], game)
  
    return [DATE, VS, PTS, MIN, FGM, FGA, FGP, THPM, THPA, THPP, FTA, FTP, OREB, DREB, AST, TOV, STL, BLK, PF];
  
  }


