import React, { Fragment, useState, useContext } from 'react';
import { map } from 'ramda';
import { Link } from 'react-router-dom';
import { Context } from "./Utility/store";

const NflOddCalc = () => {

  const [profit, setprofit] = React.useState(0);
  const [fraction, setFraction] = React.useState('0/0');
  const [decimal, setDecimal] = React.useState(0);
  const [impliedProbability, setImpliedProbability] = React.useState('0.00');
  const [betAmount, setbetAmount] = useState('100');
  const [oddAmount, setoddAmount] = useState(0);
  const [parlaybet, setparlaybet] = useState(true);

  const [odds, setOdds] = React.useState('-110');
  const [isBetError, setBetError] = React.useState(false);
  const [isOddsError, setOddsError] = React.useState(false);



  // Parlay
  const [parlayProfit, setParlayProfit] = React.useState(0);
  // const [parlayFraction, setParlayFraction] = React.useState('0/0');
  const [parlayProbability, setParlayProbability] = React.useState(0);
  const [parlayBetAmount, setParlayBetAmount] = React.useState('100');
  const [teamNumber, setTeamNumber] = React.useState(2);
  const [parlayOdds, setParlayOdds] = React.useState('0');
  const [isParlayBetError, setParlayBetError] = React.useState(false);
  const [isParlayOddsError, setParlayOddsError] = React.useState(false);

  const [state, dispatch] = useContext(Context);




  const betAmountHandler = (e) => {
    setbetAmount(e.target.value)
    if (e.target.value > 10000) {
      setBetError(true)
    } else {
      setBetError(false)
    }
  }

  const parlaybetAmountHandler = (e) => {
    setParlayBetAmount(e.target.value)
    if (e.target.value > 10000) {
      setParlayBetError(true)
    } else {
      setParlayBetError(false)
    }
  }

  const betoddAmountHandler = (e) => {
    setOdds(e.target.value)
    if (e.target.value > 10000) {
      setOddsError(true)
    } else {
      setOddsError(false)
    }
  }

  // const oddAmountHandler = (e) => {
  //     setoddAmount(e.target.value)
  //     if(e.target.value > 10000){
  //         setOddsError(true)
  //     }else{
  //         setOddsError(false)
  //     }
  // }

  const parlayOddsoddAmountHandler = (e) => {
    setParlayOdds(e.target.value)
    if (e.target.value > 10000) {
      setParlayOddsError(true)
    } else {
      setParlayOddsError(false)
    }
  }

  const EnterkeyPress = (e) => {
    if (e.key === "Enter") {
      var arr = [];
      arr = state.oddAmount
      arr.push(parseInt(e.target.value))
      addOdds(arr);
      dispatch({ type: 'ODD_AMOUNT', payload: arr })
      setoddAmount(0);
    }
  }





  const resetHandler = () => {
    setbetAmount('100');
    setOdds('-110');
    setParlayBetAmount(100);
    setParlayOdds(0);
    dispatch({ type: 'ODD_AMOUNT', payload: [-110] });
  }

  const removeoddAmount = (val) => {
    let list = state.oddAmount

    list.splice(list.indexOf(val), 1)
    dispatch({ type: 'ODD_AMOUNT', payload: list })
  }



  // ODDS

  React.useEffect(() => {
    const fractionValue = fraction.split('/');
    setDecimal(parseInt(fractionValue[0]) / parseInt(fractionValue[1]) + 1);
  }, [fraction]);

  React.useEffect(() => {
    const impliedProbabilityValue = ((1 / decimal) * 100).toFixed(2);
    setprofit(parseInt(betAmount) * (decimal - 1));
    setImpliedProbability(`${impliedProbabilityValue}`);
  }, [decimal, betAmount]);

  React.useEffect(() => {
    parseInt(betAmount) > 10000 ? setBetError(true) : setBetError(false);
    parseInt(odds) > 0
      ? odds.length < 3
        ? setOddsError(true)
        : odds.length > 4
          ? setOddsError(true)
          : setOddsError(false)
      : odds.length < 4
        ? setOddsError(true)
        : odds.length > 5
          ? setOddsError(true)
          : setOddsError(false);
  }, [betAmount, odds]);
  React.useEffect(() => {
    if (odds !== '-' && odds.length > 2) {
      fractionalF(odds, 100);
    }
  }, [odds]);


  //   parlay 

  const addOdds = (chips) => {
    let isAccess = false;
    map((odds) => {
      if (odds < 0) {
        if (odds > -100) {
          isAccess = true;
        }
        if (odds < -1000) {
          isAccess = true;
        }
      }
      if (odds > 0) {
        if (odds < 100) {
          isAccess = true;
        }
        if (odds > 1000) {
          isAccess = true;
        }
      }
    }, chips)

    if (isAccess) {
      setParlayOddsError(true);
    } else {
      setParlayOddsError(false);
      //   setOddsArray(chips);
      dispatch({ type: 'ODD_AMOUNT', payload: chips })
    }
  }

  React.useEffect(() => {
    calculator()
    console.log("state", state)
  }, [state]);

  React.useEffect(() => {
    setTeamNumber(2);

    if (!parlaybet) {
      setParlayBetAmount('100')
      dispatch({ type: 'ODD_AMOUNT', payload: [-110] })
    }
  }, [parlaybet]);

  const calculator = () => {
    let parlayAddAmount = 0;
    let parlayprofitAmount = 0;
    let parlayProbabilityV = 100;
    setParlayProbability(0);
    setParlayProfit(0);


    parlayAddAmount = parseInt(parlayBetAmount)
    console.log("parlayAddAmount", parlayAddAmount)
    for (let i = 0; i < state.oddAmount.length; i++) {

      let fraction = fractionalParalayFD(state.oddAmount[i], 100).split("/")
      console.log("fraction", fraction)
      let decimal = parseInt(fraction[0]) / parseInt(fraction[1]) + 1;
      console.log("decimal", decimal)
      parlayprofitAmount = (parlayAddAmount * (decimal))
      parlayAddAmount = parlayprofitAmount
      parlayProbabilityV = ((1 / decimal) * parlayProbabilityV).toFixed(2);
      console.log("parlayProbabilityV", parlayProbabilityV, parlayAddAmount)
    }

    setParlayProbability(parlayprofitAmount === 0 ? 0 : parlayProbabilityV);
    setParlayProfit(parlayprofitAmount);
  }

  React.useEffect(() => {
    parseInt(parlayBetAmount) > 10000
      ? setParlayBetError(true)
      : setParlayBetError(false);
    calculator()
  }, [parlayBetAmount]);


  const fractionalF = (a, b) => {
    let gcdValue = gcd(a, b)
    setFraction(
      (a > 0 ? Math.abs(a / gcdValue) : Math.abs(b / gcdValue)) +
      '/' +
      (a > 0 ? Math.abs(b / gcdValue) : Math.abs(a / gcdValue)),
    );

  };

  //   const fractionalParalayF = (a, b) => {
  //     let gcdValue = gcd(a, b)
  //     setParlayFraction(
  //       (a > 0 ? Math.abs(a / gcdValue) : Math.abs(b / gcdValue)) +
  //         '/' +
  //         (a > 0 ? Math.abs(b / gcdValue) : Math.abs(a / gcdValue)),
  //     );
  //   };


  const fractionalParalayFD = (a, b) => {
    let gcdValue = gcd(a, b)
    return (a > 0 ? Math.abs(a / gcdValue) : Math.abs(b / gcdValue)) + '/' + (a > 0 ? Math.abs(b / gcdValue) : Math.abs(a / gcdValue))
  };

  const gcd = (a, b) => {
    return b === 0 ? a : gcd(b, a % b);
  };

  return <Fragment>
    {/* === BREADCRUMB ====*/}
    <section className="brd-cmb  p-50 ">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to='./'> Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Odds Calculator</li>
          </ol>
        </nav>
      </div>
    </section>

    <section className='odds-calc-tab team_acc mb-4'>
      <div className="container">
        <div className="player-team-wrapper">
          <div className="player-team-inner">
            <span className={parlaybet ? "active" : ""} onClick={() => setparlaybet(true)}> odds Bet </span>
            <span className={parlaybet ? "" : "active"} onClick={() => setparlaybet(false)}> Parlay Bet </span>
          </div>
        </div>
      </div>
    </section>

    <section className='odds-bet'>
      <div className='container'>
        <div className='odds-bet-wrapper'>
          {/*  ===== ODDS =====*/}

          {parlaybet ?
            <Fragment>
              <div className="odd-bet-title">
                <h6>bet amount ($)</h6>
                <p className='mb-0 color-grey'>choose the amount of money you want to bet</p>
                <div className="input-group-wrapper">
                  <div className='range-wrapper'>
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" placeholder="Bet Amount" value={betAmount} name="betamount" onChange={betAmountHandler} />
                    </div>
                    {parlaybet ?
                      <input
                        id="typeinp"
                        type="range"
                        min="0" max="10000"
                        value={betAmount}
                        className="range-slider"
                        onChange={(e) => { setbetAmount(e.target.value) }}
                        step="1" />
                      : ""}
                  </div>


                  {isBetError ? <p className='error'>Minimum bet required 100</p> : ""}

                </div>
                <p className="color-grey">Min: 0, Max:10,000</p>

              </div>

              <div className="odd-bet-title mt-4">
                <h6>odd</h6>
                <Fragment>
                  <p className='mb-0 color-grey'>choose the amount of money you want to bet</p>
                  <div className="input-group-wrapper">
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" placeholder="Odd" value={odds} name="oddamount" onChange={betoddAmountHandler} />
                    </div>
                    {isOddsError ? <p className='error'>Minimum bet required 100</p> : ""}

                  </div>
                </Fragment>



                <div>
                  {
                    !parlaybet ?
                      state.oddAmount.map((val, i) => (
                        <span className='oddAmt' onClick={() => removeoddAmount(val)}>{val}<i className="fas fa-times-circle"></i></span>
                      ))
                      : ""
                  }

                </div>
                <p className='color-grey'>Min: -1,000, Max:+1,000</p>

              </div>
            </Fragment>
            :
            <Fragment>
              <div className="odd-bet-title">
                <h6>bet amount ($)</h6>
                <p className='mb-0 color-grey'>choose the amount of money you want to bet</p>
                <div className="input-group-wrapper">
                  <div className='range-wrapper'>
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" placeholder="Bet Amount" value={parlayBetAmount} name="betamount" onChange={parlaybetAmountHandler} />
                    </div>
                  </div>

                  {isParlayBetError ? <p className='error'>Minimum bet required 100</p> : ""}

                </div>
                <p className="color-grey">Min: 0, Max:10,000</p>

              </div>

              <div className="odd-bet-title mt-4">
                <h6>odd</h6>


                <p className='mb-0 color-grey'>choose the amount of money you want to bet</p>
                <div className="input-group-wrapper">
                  <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Odd" value={parlayOdds} name="oddamount" onKeyPress={EnterkeyPress} onChange={parlayOddsoddAmountHandler} />
                  </div>
                  {isParlayOddsError ? <p className='error'>Minimum bet required 100</p> : ""}

                </div>



                <div>
                  {
                    !parlaybet ?
                      state.oddAmount.map((val, i) => (
                        <span className='oddAmt' onClick={() => removeoddAmount(val)}>{val}<i className="fas fa-times-circle"></i></span>
                      ))
                      : ""
                  }

                </div>
                <p className='color-grey'>Min: -1,000, Max:+1,000</p>

              </div>
            </Fragment>
          }
        </div>


        {parlaybet ?
          <div className="payout-wrapper">
            <h6>Payout</h6>
            <p>Your total payout with the current odds and stakes</p>
            <h3>${(parseInt(betAmount) + profit).toFixed(2)}</h3>
            <div className="payoutList">
              <div className='profit'>
                <p className="mb-0">Profit</p>
                <h6> ${profit.toFixed(2)}</h6>
              </div>
              <div className='profit'>
                <p className="mb-0">implied probability</p>
                <h6> {impliedProbability}%</h6>
              </div>
            </div>
            <div className="payoutList mt-3">
              <div className='profit'>
                <p className="mb-0">decimal</p>
                <h6> {decimal.toFixed(3)}</h6>
              </div>
              <div className='profit'>
                <p className="mb-0">Fractional / UK</p>
                <h6> {fraction}</h6>
              </div>
            </div>
          </div>
          :
          <div className="payout-wrapper">
            <h6>Payout</h6>
            <p>Your total payout with the current odds and stakes</p>
            <h3> ${parlayProfit.toFixed(2)}</h3>
            <div className="payoutList">
              <div className='profit'>
                <p className="mb-0">Profit</p>
                <h6> ${(parlayProfit === 0 ? 0.00 : parlayProfit - parseInt(parlayBetAmount)).toFixed(2)}</h6>
              </div>
              <div className='profit'>
                <p className="mb-0">implied probability</p>
                <h6> {parlayProbability}%</h6>
              </div>
            </div>

          </div>
        }




      </div>
    </section>
    <div className="btn-wrapper mb-4">
      <button className='common-btn' onClick={resetHandler}>Reset</button>
    </div>
  </Fragment>
};

export default NflOddCalc;