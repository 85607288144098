import React, { Fragment } from 'react'



const getStatFullName = new Map([
    //Next Game
    ['show_next_game', 'Next Game'],
  
    //Player Trends
    ['show_player_trends', 'Player Trends'],
  
    //Hitters Exclusive Stats
    ['BABIP', 'Batting Average on Balls in Play'],
    ['ISO', 'Isolated Power'],
    ['BB%', 'Walks Per Plate Appearance'],
    ['LD%', 'Line Drive Percentage'],
    ['FB%', 'Fly Ball Percentage'],
    ['GB%', 'Ground Ball Percentage'],
    ['HR/FB', 'Home Run to Fly Ball Rate'],
    ['GBFB', 'Ground Ball to Fly Ball Ratio Total'],
    ['K%', 'Strikeout Rate'],
    //Pitchers Exclusive Stats
    ['K/9', 'Strikeouts Per Nine Innings'],
    ['K/BB', 'Strikeout to Walk Ratio'],
    ['H/9', 'Hits Allowed Per Nine Innings'],
    ['IRA', 'Inherited Runs Allowed'],
    ['BQR', 'Bequeathed Runners'],
    ['BQR-S', 'Bequeathed Runners Allowed'],
    ['PO', 'Pop Outs Forced'],
    ['P/IP', 'Pitches Per Inning Played'],
    ['HR/9', 'Home Runs Per Nine Innings'],
    ['BB/9', 'Walks Per Nine Innings'],
    //Passing Exclusive
    ['air_yards', 'Air Yards'],
    ['avg_pocket_time', 'Average Pocket Time'],
    ['hurries', 'Hurries'],
    ['knockdowns', 'Knockdowns'],
    ['on_target_throws', 'On Target Throws'],
    ['redzone_attempts', 'Red Zone Attempts'],
    ['dropped_passes', 'dropped passes'],
  
  
    
    // ruching Exclusive Stats
    ['yards_after_catch', 'Yards After Catch'],
    ['yards_after_contact', 'Yards After Contact'],
    ['broken_tackles', 'Broken Tackles'],
    ['redzone_targets', 'Red Zone Targets'],
    ['Scrambles', 'Scrambles'],
  
    //receiving Exclusive Stats
    ['air_yards', 'Air Yards'],
    ['broken_tackles', 'Broken Tackles'],
    ['dropped_passes', 'Dropped Passes'],
    ['targets', 'Targets'],
    ['redzone_targets', 'Red Zone Targets'],
    ['yards_after_catch', 'Yards After Catch'],
    ['yards_after_contact', 'Yards After Contact'],
  
    //field goals Exclusive Stats
    ['attempts', 'Attempts'],
    ['blocked', 'Blocked'],
    ['longest', 'Longest'],
  
    //defense Exclusive stats
    ['batted_passes', 'Batted Passes'],
    ['hurries', 'Hurries'],
    ['knockdowns', 'Knockdowns'],
    ['qb_hits', 'QB Hits'],
    ['safeties', 'Safeties'],
  
    //Hitter Season Stats
    ['G', 'Games'],
    ['AB', 'At Bats'],
    ['R', 'Runs'],
    ['H', 'Hits'],
    ['twoB', 'Doubles'],
    ['threeB', 'Triples'],
    ['HR', 'Home Runs'],
    ['RBI', 'Runs Batted In'],
    ['BB', 'Walks'],
    ['SO', 'Strikouts'],
    ['SB', 'Stolen Bases'],
    ['CS', 'Caught Stealing'],
    ['AVG', 'Batting Average'],
    ['OBP', 'On-Base Percentage'],
    ['SLG', 'Slugging Percentage'],
    ['OPS', 'On-Base Plus Slugging'],
    //Pitcher Season Stats
    ['G', 'Games'],
    ['W', 'Wins'],
    ['L', 'Loses'],
    ['ERA', 'Earned Run Average'],
    ['SV', 'Saves'],
    ['SVO', 'Save Oppurtunity'],
    ['IP', 'Innings Pitched'],
    ['H', 'Hits'],
    ['R', 'Runs'],
    ['ER', 'Earned Runs'],
    ['HR', 'Home Runs'],
    ['HB', 'Hit Batters'],
    ['BB', 'Walks'],
    ['IBB', 'Intentional Walks'],
    ['SO', 'Strikeouts'],
    ['WHIP', 'Walks Plus Hits Per Inning Pitched'],
    // Passing Season Stats
    ['COMP', 'Completions'],
    ['PATT', 'Passing Attempts'],
    ['PYDS', 'Passing Yards'],
    ['PAVG', 'Yards Per Pass Attempt'],
    ['PTD', 'Touchdowns'],
    ['INT', 'Interceptions'],
    ['SCK', 'Sacks'],
    ['QBR', 'Total QBR'],
    ['RATT', 'Rushing Attempts'],
    ['RYDS', 'Rushing Yards'],
    ['RAVG', 'Yards Per Rush Attempt'],
    ['RTD', 'Rushing Touchdowns'],
    ['FUM', 'Fumbles'],
    ['LOST', 'Fumbles Lost'],
  
    // Rushing Season Stats
    ['RATT', 'Rushing Attempts'],
    ['RYDS', 'Rushing Yards'],
    ['RAVG', 'Yards Per Rush Attempt'],
    ['LNG', 'Long Rushing'],
    ['RTD', 'Rushing Touchdowns'],
    ['REC', 'Receptions'],
    ['REYDS', 'Receiving Yards'],
    ['REAVG', 'Yards Per Reception'],
    ['RETD', 'Receiving Touchdowns'],
  
    // Receiving Season Stats
    ['REC', 'Rushing Attempts'],
    ['YDS', 'Rushing Yards'],
    ['REAVG', 'Yards Per Rush Attempt'],
    ['TD', 'Long Rushing'],
    ['LNG', 'Rushing Touchdowns'],
    ['TGT', 'Receptions'],
  
    // Defense Season Stats
    ['AST', 'Assist Tackles'],
    ['TOT', 'Total Tackles'],
    ['SACK', 'Total Sacks'],
    ['SCKYADS', 'Sack Yards'],
    ['TFL', 'Tackles For Loss'],
    ['PD', 'Passes Defended'],
    ['INT', 'Interceptions'],
    ['FR', 'Fumbles Recovered'],
  
    // Field Goals Season Stats
    ['ATT', 'Field Goal Attempts'],
    ['M', 'Field Goals Made'],
    ['MI', 'Field Goals Missed'],
    ['PCT', 'Field Goal Percentage'],
    ['LNG', 'Longest'],
    ['EM', 'Extra Points Made'],
    ['EPCT', 'Extra Point Percentage'],
  
    // NBA ALl in One Season Stats
    ['GP', 'Games Played'],
    ['MIN', 'Minutes Played'],
    ['PTS', 'Points'],
    ['REB', 'Rebounds'],
    ['ASTT', 'Assists'],
    ['TOV', 'Turnovers'],
    ['STL', 'Steals'],
    ['BLK', 'Blocks'],
    ['FGM', 'Field Goals Made'],
    ['FGA', 'Field Goals Attempted'],
    ['FGP', 'Field Goal Percentage'],
    ['THPM', '3 Point Field Goals Made'],
    ['THPA', '3 Point Field Goals Attempted'],
    ['THPP', '3 Point Field Goal Percentage'],
    ['FTA', 'Free Throws Made'],
    ['FTP', 'Free Throw Percentage'],
    ['PF', 'Personal Fouls'],
    ['DDT', 'Double Doubles'],
    ['TDTH', 'Triple Doubles'],
    // NBA Exclusive stats
    ['EFF_EX', 'Efficiency'],
    ['TSP_EX', 'True Shooting Percentage'],
    ['FTR_EX', 'Free-Throw Rate'],
    ['EFP_EX', 'Effective Field Goal Percentage'],
    ['FBP_EX', 'Fast Break Percentage'],
    ['PPP_EX', 'Points in the Paint Percentage'],
    ['SCP_EX', 'Second Change Percentage'],
  ]);

const ToggleComp = (props) => {
    const {value, name, updateSettings ,playerOrteam,state,type} = props
  return (
    <Fragment>
    <div className="col-xl-6 col-lg-6 col-md-6">
    <div className="next-game exclusive-chk">
    <h5>{getStatFullName.get(name)}</h5>
    <div className="form-check form-switch ">
        <input
          className="form-check-input"
          type="checkbox"
          id="mySwitch"
          name="darkmode"
          value="yes"
          checked={value}
          onChange={(e)=>updateSettings(e.target.checked, name ,playerOrteam,type,state)}
          />{" "}

      </div>
</div>
    </div>
    </Fragment>
  )
}

export default ToggleComp