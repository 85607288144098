import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import {
    FaLock,
    FaRegEye,
    FaRegEyeSlash,
  } from "react-icons/fa";
  import { useForm } from "react-hook-form";
  import axios from "./Utility/axios";
  import request from "./Utility/Request";

  import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { successToast, errorToast } from "./Utility/toastify";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import {LoginSocialFacebook} from 'reactjs-social-login'
import {FacebookLoginButton} from 'react-social-login-buttons'
import { Context } from "./Utility/store";
import { initializeApp } from 'firebase/app';
import { getAnalytics,logEvent } from 'firebase/analytics';
import { GetLogin, firebaseConfig } from "./Utility/actionMethod";

import logo from "../assets/images/logo.png";

const Login = () => {
    const [state, dispatch] = useContext(Context);
    const [passwordShown, setPasswordShown] = useState(false);
    const [forgetpass,setforgetPass]= useState(false);
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    let history = useHistory();
    const loginValidation = Yup.object({
        email: Yup.string().required("Email is required").email("Email is invalid"),
        password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
    }).required();

    const forgetPassValidation = Yup.object({
      email: Yup.string().required("Email is required").email("Email is invalid"),
    }).required();

  //LOGIN FORM
  const {
    register: register2,
    handleSubmit: handleSubmit,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm({ resolver: yupResolver(loginValidation) });

  const loginSubmit = async (data) => {
    const login = async () => {
      try {
        const response = await axios.post(request.LOGIN, data);
        if (response.data.email_varify === "1") {
          if (response.data.Authorization) {
            successToast("You have logged Successfully");
            localStorage.setItem("tokens", response.data.Authorization);
            localStorage.setItem("user_id", response.data.user_id);
            localStorage.setItem("user_name", response.data.username);
            localStorage.setItem("userDetail", JSON.stringify(response.data));
            // sessionStorage.setItem("tokens", response.data.Authorization);
            // sessionStorage.setItem("user_id", response.data.user_id);
            // sessionStorage.setItem("user_name", response.data.username);
            //setuserName(response.data.username);

            localStorage.setItem("islogin", true);
            localStorage.setItem("islogged", true);
            //sessionStorage.setItem('userDetail',JSON.stringify(response.data))
            //sessionStorage.setItem("islogin", true);
            //sessionStorage.setItem("islogged", true);
            dispatch({ type: "LOGIN", payload: true });
            dispatch({ type: "TOKEN", payload: response.data.Authorization });
            //setisLogin(true);

            setTimeout(() => {
              //getUserSettings(dispatch);
              localStorage.setItem("category_type", "nfl");
              // getUser();
              // userTeam();
              history.push("/");
            }, 1000);
          }
        } else {
          errorToast("Please Verify Your Account");
        }
      } catch (err) {
        if (!err.respsonse) {
          errorToast(err.response.data.message);
        }
      }
    };
    login(data);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const responseFacebook = (response) => {

    if (response.accessToken !== "" || response.accessToken !== undefined) {
      const userLoginData = {
        token: response.accessToken,
        id: response.id,
        email: response.email,
        name: response.name,

        type: "facebook",
      };
      
      console.log("userLoginData",userLoginData)
      socialLogin(userLoginData);
    }
  };
      

  const responseGoogle = (response) => {
    var decode = jwt_decode(response.credential);

    if (decode) {
      const userLoginData = {
        token: decode.sub,
        id: decode.jti,
        email: decode.email,
        name: decode.name,
        type: "google",
      };
      socialLogin(userLoginData);
    } else {
      console.error("Google login response is missing required properties.");
    }
  };

  const socialLogin = async (data) => {
    try {
      const response = await axios.post(request.SOCIAL_LOGIN, data);

      if (response.data.status === "failed") {
        errorToast(response.data.message);
      } else {
        successToast("You have logged Successfully");
        //LoginHandleClose();
        localStorage.setItem("tokens", response.data.Authorization);
        localStorage.setItem("user_id", response.data.user_id);
        localStorage.setItem("user_name", response.data.username);
        localStorage.setItem("userDetail", JSON.stringify(response.data));
        //setuserName(response.data.username);
        localStorage.setItem("islogin", true);
        localStorage.setItem("islogged", true);
        dispatch({ type: "LOGIN", payload: true });
        dispatch({ type: "TOKEN", payload: response.data.Authorization });
        //setisLogin(true);
           // Log registration event
         
            logEvent(analytics, 'registration_event',  {
              tokens: response.data.Authorization,
              user_name:response.data.username,
              
            });

        setTimeout(() => {
          //getUserSettings(dispatch);
          localStorage.setItem("category_type", "nfl");
          // getUser();
          // userTeam();
          //history.push("/");
        }, 1000);
      }
    } catch (err) {
      if (!err.respsonse) {
        errorToast(err.response.data.message);
      }
    }
  };



 // FORGET PASSWORD

 const {
  register: register3,
  handleSubmit: handleSubmit3,
  formState: { errors: errors3 },
  reset: reset3,
} = useForm({ resolver: yupResolver(forgetPassValidation) });

const forgetpassSubmit = (data) => {
  const forgetpass = async () => {
    try {
      const response = await axios.post(request.RESET_PASSWORD, data);
      if (response.data.status === "Successfull") {
        successToast(response.data.message);
        history.push("/")
      }
    } catch (err) {
      if (!err.respsonse) {
        errorToast(err.message);
      }
    }
  };
  forgetpass(data);
};

  return (
  <section className="login-wrapper">
      
    <div className="login-form">
              
              {
                !forgetpass ?
              <form key="2" onSubmit={handleSubmit(loginSubmit)}>
               <h4 className="mt-0">Sign In</h4> 
               <h5>
                 
                  Welcome to <span> Fanalyze! </span> We're building the sports
                  search engine and analysis platform.
                </h5>

                <div>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="fas fa-envelope"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      {...register2("email")}
                    />
                  </div>
                  <span className="error">{errors2.email?.message}</span>
                </div>

                <div>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <FaLock />
                    </span>
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="form-control"
                      placeholder="password"
                      {...register2("password")}
                    />
                    <span
                      toggle="#password"
                      className="toggle-password"
                      onClick={togglePassword}
                    >
                      {" "}
                      {passwordShown ? <FaRegEye /> : <FaRegEyeSlash />}
                    </span>
                  </div>
                  <span className="error">{errors2.password?.message}</span>
                </div>

                <button className="common-btn login-btn">login</button>
                <div className="line-wrap">
                  <h6 className="line">or</h6>
                </div>

                <div>
                  <LoginSocialFacebook
                  appId="1265278907519029"
                  onResolve={(response)=>{
                    console.log(response.data)
                    responseFacebook(response.data)
                  }}
                  onReject={(err)=>{
                    console.log(err)
                  }}
                  >
                    <FacebookLoginButton/>
                  </LoginSocialFacebook>
                </div>
                
                {/*361827779858-57du0jecouul4cb8b9ttnvuosu9m091r.apps.googleusercontent.com*/}
                <div className={"google-btn"}>
                  <GoogleOAuthProvider clientId="361827779858-57du0jecouul4cb8b9ttnvuosu9m091r.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                    ></GoogleLogin>
                  </GoogleOAuthProvider>
                </div>
                <span
                  className="forget-pass"
                  onClick={()=>setforgetPass(true)}
                >
                  forget password?
                </span>

                <p>
                  Don’t have an account?{" "}
                  <span onClick={()=>history.push("register")}>Signup</span>
                </p>
              </form>
                :
              <form key="3" onSubmit={handleSubmit3(forgetpassSubmit)}>
                  <h4 className="mb-3"> forget password </h4>
                  <div>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        {...register3("email")}
                      />
                    </div>
                    <span className="error">{errors3.email?.message}</span>
                  </div>
                  <button className="common-btn with-border">
                    Reset Password
                  </button>
                  <p>
                    got password ?
                  <span onClick={()=>setforgetPass(false)}>login</span>
                  </p>
                </form>
                }
    </div>

  </section>
  
  )
}

export default Login