import { pathOr, propOr, map, pick, has } from 'ramda';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

export const getFormattedAllGameOdds = (response) => {
    const odds = pathOr([], ['data', 'response'], response);
    
    return getFormattedOddsGames(map((odd) => {
      return FormattedAllOddsData(odd);
    }, odds));
  };


 
const getScheduledTime = (time, format) => momentTimezone(time).tz("America/New_York").format(format); 

const getScheduledDate = (time) => {
    const momentDate = moment(time).format('llll')
    const n = 2; // second comma
    const splittedDate = momentDate.split(',')
    const date = splittedDate.slice(0, n).join(',')
    return date;
  }
  

  const FormattedAllOddsData = (response) => {
    var moneylineOddsInfo = [];
    var totalOddsInfo = [];
    var spreadOddsInfo = [];
  
  
    map((books) => {
     const oddsName = propOr('', 'name', books);
     const booksData = propOr([], 'books', books);
     if(oddsName === "2way"){
         moneylineOddsInfo =  formatOddsDataForGames(booksData, response, oddsName, '');
     }
     if(oddsName === "total"){
        totalOddsInfo = formatOddsDataForGames(booksData, response, oddsName, '');
     }
     if(oddsName === "spread"){
        spreadOddsInfo = formatOddsDataForGames(booksData, response, oddsName, '');
     }
   }, propOr([], 'markets', response));
  
  
    return {
      moneyline: {
        statsHeader: moneylineOddsInfo[0],
        widthArr: moneylineOddsInfo[1],
        statsData:moneylineOddsInfo[2],
      },
      total: {
        statsHeader: totalOddsInfo[0],
        widthArr: totalOddsInfo[1],
        statsData: totalOddsInfo[2],
      },
      spread: {
        statsHeader: spreadOddsInfo[0],
        widthArr: spreadOddsInfo[1],
        statsData: spreadOddsInfo[2],
      },
    };
  
  };

  const getFormattedOddsGames = (odd) => {
    let oddsDataMoneyLine = [];
    let oddsDataTotal = [];
    let oddsDataSpread = [];
  
    const bookHeadermoneyline = pathOr([], ['moneyline', 'statsHeader'], odd[0]);
    const bookWidthmoneyline = pathOr([], ['moneyline', 'widthArr'], odd[0]);
  
    const bookHeadertotal = pathOr([], ['total', 'statsHeader'], odd[0]) ;
    const bookWidthtotal = pathOr([], ['total', 'widthArr'], odd[0]) ;
  
    const bookHeaderspread = pathOr([], ['spread', 'statsHeader'], odd[0]);
    const bookWidthspread = pathOr([], ['spread', 'widthArr'], odd[0]);
  
    map((o) => {
  
      {
  
        const bookInfo = pathOr([], ['moneyline', 'statsData'], o);
        map((oddsInfo)=> {
          oddsDataMoneyLine.push(oddsInfo);
        }, bookInfo)
      }
  
      {
  
        const bookInfo = pathOr([], ['total', 'statsData'], o);
        map((oddsInfo)=> {
          oddsDataTotal.push(oddsInfo);
        }, bookInfo)
      }
  
  
      {
  
        const bookInfo = pathOr([], ['spread', 'statsData'], o);
        map((oddsInfo)=> {
          oddsDataSpread.push(oddsInfo);
        }, bookInfo)
      }
    }, odd);
  
  
      return {
        moneyline: {
          statsHeader: bookHeadermoneyline,
          widthArr: bookWidthmoneyline,
          statsData:oddsDataMoneyLine,
        },
        total: {
          statsHeader: bookHeadertotal,
          widthArr: bookWidthtotal,
          statsData: oddsDataTotal,
        },
        spread: {
          statsHeader: bookHeaderspread,
          widthArr: bookWidthspread,
          statsData: oddsDataSpread,
        },
      };
  };  


  const formatOddsDataForGames = (books, oddsdata, oddaName, selectGameType) => {
    const scheduledTime = propOr('', 'scheduled', oddsdata);
    const competitors = propOr('', 'competitors', oddsdata);
  
  
    const gameHome = pathOr('', ['0','name'], competitors);
    let comeHomeId = pathOr('', ['0','uuids'], competitors).split(",")[0];
    if(pathOr('', ['0','uuids'], competitors).split(",")[1].length > 7){
      comeHomeId = pathOr('', ['0','uuids'], competitors).split(",")[1];
    }
  
    const gameAway = pathOr('', ['1','name'], competitors);
    let comeAwayId = pathOr('', ['1','uuids'], competitors).split(",")[0];
    if(pathOr('', ['1','uuids'], competitors).split(",")[1].length > 7){
      comeAwayId = pathOr('', ['1','uuids'], competitors).split(",")[1];
    }
    const date = getScheduledDate(scheduledTime);
    const time = getScheduledTime(scheduledTime, 'LT');
  
    let booksInfoHome = [];
    let booksInfoAway = [];
  
    let booksHeader = [];
    let booksWidth = [];
    let booksInfo = [];
  
    booksHeader.push('Game');
    booksWidth.push(80);
    booksHeader.push('Date/Time');
    booksWidth.push(80);
  
  
    booksInfoHome.push(gameHome);
    booksInfoHome.push(date);
  
    booksInfoAway.push(gameAway);
    booksInfoAway.push(time);
  
  
     map((outcomes)=> {
       if(has('outcomes', outcomes)){
         if(oddaName === '2way'){
           const SPORT = propOr('', 'name', outcomes);
           booksHeader.push(SPORT);
           booksWidth.push(120);
           booksInfoHome.push(pathOr(0, ['outcomes','0','odds'], outcomes));
           booksInfoAway.push(pathOr(0, ['outcomes','1','odds'], outcomes));
  
         }else if(oddaName === 'total'){
  
           const SPORT = propOr('', 'name', outcomes);
           booksHeader.push(SPORT);
           booksWidth.push(120);
           booksInfoHome.push('o'+ pathOr('', ['outcomes','0','total'], outcomes)+"  "+pathOr(0, ['outcomes','0','odds'], outcomes));
           booksInfoAway.push('u'+pathOr('', ['outcomes','1','total'], outcomes)+"  "+pathOr(0, ['outcomes','1','odds'], outcomes));
  
         }else if(oddaName === 'spread'){
  
           const SPORT = propOr('', 'name', outcomes);
           booksHeader.push(SPORT);
           booksWidth.push(120);
           booksInfoHome.push(pathOr(0, ['outcomes','0','spread'], outcomes)+"  "+pathOr(0, ['outcomes','0','odds'], outcomes));
           booksInfoAway.push(pathOr(0, ['outcomes','1','spread'], outcomes)+"  "+pathOr(0, ['outcomes','1','odds'], outcomes));
  
         }
      }
    }, books);
    booksInfoHome.push(comeHomeId);
    booksInfoAway.push(comeAwayId);
    return {
      0: booksHeader,
      1: booksWidth,
      2: [booksInfoHome, booksInfoAway],
    }
  }