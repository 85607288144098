import React, {
  ReactDOM,
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import {  useHistory } from "react-router-dom";
import { map, reduce, keys, forEach, curry, assoc, pathOr , propOr } from "ramda";
import { Modal, Accordion, Spinner } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { If, Then, Else } from "react-if";
import { Context } from "./Utility/store";

import { apiInstance } from "./Utility/axios";
import axios from "./Utility/axios";
import request from "./Utility/Request";

import { successToast, errorToast } from "./Utility/toastify";
import { ToastContainer } from "react-toastify";

const Team = () => {
  var categoryType = localStorage.getItem("category_type").toLowerCase();
  const [state, dispatch] = useContext(Context);
  const history = useHistory()
  const [showCreateTeam, setShowCreateTeam] = useState(false);
  const createTeamHandleClose = () => {
    setShowCreateTeam(false);
    setNewTeamName();
    setteamNerror();
    setNewTeamName();
    setTeamName();
    
  };
  const createTeamHandleShow = () => setShowCreateTeam(true);

  const [loader, setLoader] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  // const [playerList , setplayerList] = useState([]);
  // const [inputList , setInputList] = useState("");
  const [isteam, setisTeam] = useState(true);
  const [teamData, setteamData] = useState([]);
  const [token, settoken] = useState(localStorage.getItem("tokens"));
  //const [editTeam, setEditTeam] = useState([{id:"",playerInfo:""}]);
  const [editTeam, setEditTeam] = useState([]);
  const [editTeamId, setEditTeamId] = useState([]);
  const [isedit, setIsEdit] = useState(false);
  const [playerInfo, setplayerInfo] = useState([]);
  const [categorType, setcategorType] = useState("");
  const [TeamName, setTeamName] = useState("");
  const [NewTeamName, setNewTeamName] = useState(isedit && TeamName);
  const [teamNerror,setteamNerror] = useState();

  const [playerList, setPlayerList] = useState([]);
  const [ShowPlayerList, setShowPlayerList] = useState(false);
  const searchList = [...playerList];
  const [PlayerSelected, setPlayerSelected] = useState(false);
  const [addPlayerlist, setaddPlayerlist] = useState([]);

  const [optimaldata, setOptimaldata] = useState([])
  const [selectedBtn, setSelectedBtn] = useState("players")
  const [selectedOptions, setSelectedOptions] = useState();

  const [selectedOptimalPlayer, setSelectedOptimalPlayer] = useState({});

  const [rankData,setRankData] = useState([])
  const [topRank,setTopRank] = useState([]);
  const [optimalRankData , setOptimalRankData] = useState([]);


  const positionRank = ["QB", "RB", "WR", "TE", "DE", "K"];
  const nba_draft_pos = ["PG", "SG", "SF", "PF", "C"];

  let cat_type = localStorage.getItem("category_type");
  let optData = localStorage.getItem("NFL_OPTIMAL_RANKING");
  // let topRank = []
  const positions = cat_type === 'NFL' || cat_type === 'nfl'  ? ["QB", "RB", "WR", "TE", "K", "D"] : cat_type === 'NBA' || cat_type === 'nba' ?  ["G", "C", "F", "F-G", "C-F", "G-F"] : ["C", "P", "OF", "IF"]


const getTopCount = [
  ["QB", 1],
  ["RB", 2],
  ["WR", 2],
  ["TE", 1],
  ["K", 1],
  ["D", 1],

  ["G", 1],
  ["C", 1],
  ["F", 1],
  ["F-G", 1],
  ["C-F", 1],
  ["G-F", 1],

  ["C", 1],
  ["P", 1],
  ["OF", 1],
  ["IF", 1],
];


  const categoriesList = {
    nba: {
      category: 1,
      name: "NFL",
    },
    mlb: {
      category: 2,
      name: "NFL",
    },
    nfl: {
      category: 3,
      name: "NFL",
    },
  };

  useEffect(() => {
    createTeamHandleClose();
    userTeam();
    setOptimaldata(
       JSON.parse(optData)
    );

      let allData = [];
      let MapPostion = cat_type === "nfl" || cat_type === "NFL" ? positionRank : nba_draft_pos;
      
        MapPostion.map(
          (pos) => {
            propOr([], pos, JSON.parse(optData)).map((data) => {
              allData.push(data);
            });
          },
          cat_type === "nfl" || cat_type === "NFL"
            ? positionRank
            : nba_draft_pos
        );
      setRankData(allData);
  }, []);


  useEffect(() => {
    if(state.nfloptimalranking.length > 0){
      setOptimaldata(state.nfloptimalranking);
    }else{
       setOptimaldata(JSON.parse(optData));
    }
  }, [state.nfloptimalranking]);



  
  
useEffect(() => {

}, [teamData]);


useEffect(() => {
  
}, [selectedOptimalPlayer]);


  useEffect(() => {
    if (state.myteam) {
      dispatch({ type: "ADD_PLAYER_LIST", payload: [] });
    }

  


  }, [state.myteam]);
  // ======= GET TEAM =======

  const userTeam = async () => {
    let sendheaders = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokens"),
      },
    };
    try {
      const response = await axios.get(request.GET_USER_TEAMS, sendheaders);
      if (response.data.length > 0) {
                setisTeam(true);
        playerInfoList(response.data);
        setLoader(false);

        let len = response.data.length;

        response.data.forEach((ele,ind) => {
          setSelectedOptimalPlayer((prev)=>{
            if (ind === len-1){
              setteamData(response.data);
            } 
            return { ...prev, [ele.id]: "players" };
          })
        });

        // setTimeout(()=>{
        //   setteamData(response.data);
        // },5000)
      } else {
        setisTeam(false);
      }
    } catch (err) {
      if (!err.respsonse) {
      }
    }
  };

  // ======= MAPPING PLAYERINFO ========
  const playerInfoList = (playerData) => {
    playerData.map((playersInfo) => {
      setplayerInfo((prevValue) => [...prevValue, playersInfo.players_info]);
    });
  };

  // ======= DELETE TEAM =======
  const deleteTeamHandler = async (id) => {
    let sendheaders = {
      headers: {
        Authorization: "Bearer " +localStorage.getItem("tokens"),
      },
    };
     dispatch({ type: "MY_TEAM_UPATED", payload: false });
    try {
      const response = await axios.get(
        request.DELETE_USER_TEAM + "/" + id,
        sendheaders
      );
      if (response) {
        successToast(response.message);
        userTeam(token);
        dispatch({ type: "MY_TEAM_UPATED", payload: true });
      }
    } catch (err) {
      if (!err.respsonse) {
        errorToast(err.message);
      }
    }
  };

  // ======= DELETE TEAM PLAYER =======
  const deletePlayerHandler = async (playerId, teamId) => {
    let sendheaders = {
      headers: {
        Authorization: "Bearer " +localStorage.getItem("tokens"),
      },
    };
 dispatch({ type: "MY_TEAM_UPATED", payload: false });
    try {
      const response = await axios.get(
        request.DELETE_PLAYER_FROM_TEAM + "/" + teamId + "/" + playerId,
        sendheaders
      );
      if (response) {
        if (response.data.status === "success") {
          setShowCreateTeam(false);
          successToast(response.data.message);
          userTeam(token);
           dispatch({ type: "MY_TEAM_UPATED", payload: true });
        } else {
          errorToast(response.data.message);
        }
      }
    } catch (err) {
      if (!err.respsonse) {
        errorToast(err.message);
      }
    }
  };

  const CreateTeam = async (players) => {
    let teamName = NewTeamName;

    if(NewTeamName !== ""){
  const team = {
    team_name: teamName,
    category:
      cat_type.toLowerCase() === "nfl"
        ? 3
        : cat_type.toLowerCase() === "mlb"
        ? 2
        : 1,
    players_info: JSON.stringify(formatPlayersForCreatingTeam(players)),
  };
  let sendheaders = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  dispatch({ type: "MY_TEAM_UPATED", payload: false });

  try {
    const response = await axios.post(
      request.CREATE_USER_TEAM,
      team,
      sendheaders
    );
    if (response) {
      if (response.data.status === "success");
      successToast("Update Successfully");
      userTeam(token);
      setShowCreateTeam(false);
      dispatch({ type: "MY_TEAM_UPATED", payload: true });
    } else {
      errorToast(response.data.message);
    }
  } catch (err) {
    if (!err.respsonse) {
    }
  }
    }else{
      setteamNerror("Please add Team Name")
    }


  
  };

  const updateTeam = async (players) => {
    if (TeamName !== NewTeamName) {
      updateTeamName(NewTeamName);
    }
    const formattedPlayer = formatPlayersForCreatingTeam(players);
    let team_id = editTeamId;
  dispatch({ type: "MY_TEAM_UPATED", payload: false });
    let sendheaders = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    try {
      const response = await axios.post(
        request.ADD_PLAYER_TO_TEAM + "/" + team_id,
        { players_info: JSON.stringify(formattedPlayer) },
        sendheaders
      );
      if (response) {
        if (response.data.status === "success") createTeamHandleClose();
        successToast("Update Successfully");
        userTeam(token);
         dispatch({ type: "MY_TEAM_UPATED", payload: true });
      } else {
        errorToast(response.data.message);
      }
    } catch (err) {
      if (!err.respsonse) {
      }
    }
  };

  const updateTeamName = async (name) => {
    let team_id = editTeamId;
    let sendheaders = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
     dispatch({ type: "MY_TEAM_UPATED", payload: false });
    try {
      const response = await axios.post(
        request.UPDATE_TEAM_NAME + "/" + team_id,
        { team_name: name },
        sendheaders
      );
      if (response) {
        if (response.data.status === "success") createTeamHandleClose();
        userTeam(token);
         dispatch({ type: "MY_TEAM_UPATED", payload: true });
      } else {
        errorToast(response.data.message);
      }
    } catch (err) {
      if (!err.respsonse) {
      }
    }
  };

  const renameKeysInObject = curry((keysMap, obj) =>
    reduce(
      (acc, key) => assoc(keysMap[key] || key, obj[key], acc),
      {},
      keys(obj)
    )
  );

  const formatPlayersForCreatingTeam = (players) => {
    const renameKeys = {
      name: "player_name",
      id: "id_of_player",
      photo_url: "url",
    };
    return map((player) => {
      return renameKeysInObject(renameKeys, player);
    }, players);
  };

  // ======= EDIT TEAM  =======
  const editTeamHandler = (tid) => {
    dispatch({ type: "ADD_PLAYER_LIST", payload: [] });
    setEditTeamId(tid);
    {
      teamData
        .filter((person) => person.id === tid)
        .map((filteredPerson) => setEditTeam(filteredPerson));
    }
    setIsEdit(true);
    createTeamHandleShow();
  };

  useEffect(() => {
    if (editTeam.players_info !== undefined) {
      setplayerInfo(editTeam.players_info);
    }
  }, [editTeam]);

  // ======= GET TEAM =======
  const getPlayerHanlder = (e) => {
    getPlayerList(e.target.value);
    setPlayerSelected(false);

    if (e.target.value.length > 3) {
      setShowPlayerList(true);
    } else {
      setShowPlayerList(false);
    }
  };

  const getPlayerList = async (player) => {
    try {
      const response = await apiInstance.get(
        categoryType + "/players/list?prefix=" + player
      );
      if (response) {
        setPlayerList(response.data.response);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const singlePlayerHandler = async (data , catType) => {
    const idExists = state.add_Player_List.some((player) => player.id === data.id);
    if (!idExists) {
        var selectedcategoryType = localStorage.getItem("category_type").toLowerCase();
      var arr = [];
      if (selectedcategoryType === "mlb" || selectedcategoryType.toLowerCase() === "nba") {
        data.name = data.full_name;
        delete data.full_name;
      }
      
      arr = state.add_Player_List;
      arr.push(data);
       dispatch({ type: "ADD_PLAYER_LIST", payload: arr });
      setShowPlayerList(false);
    } else {
      setPlayerSelected(true)
       setShowPlayerList(false);
      //alert("Player alreadt added");
    }
  };

  useEffect(() => {
    setaddPlayerlist(state.add_Player_List);
  }, [state]);


  const playerFormatter = (players) => {
    const group = players.reduce((r, a) => {
      r[a.position] = [...(r[a.position] || []), a];
      return r;
    }, {});
    return group;
  };

  // useEffect(()=>{
  //   console.log("use",selectedOptimalPlayer)
  // },[selectedOptimalPlayer])



 const comparePlayerName = (playerName, rankName) => {
   let playerNameArray  = playerName
     .replace(/[^a-zA-Z ]/g, "")
     .split(" ");
   let rankNameArray = rankName
     .replace(/[^a-zA-Z ]/g, "")
     .split(" ");

   if (playerNameArray.length === 1) {
     if (playerName === rankName) {
       return true;
     } else {
       return false;
     }
   } else if (playerNameArray.length === 2) {
     let data = playerNameArray.filter((item) => rankNameArray.includes(item));
     if (data.length === 2) {
       return true;
     } else {
       return false;
     }
   } else if (playerNameArray.length === 3) {
     let data = playerNameArray.filter((item) => rankNameArray.includes(item));
     if (data.length === 2) {
       return true;
     } else {
       return false;
     }
   } else if (rankNameArray.length === 3) {
     let data = rankNameArray.filter((item) => playerNameArray.includes(item));
     if (data.length === 2) {
       return true;
     } else {
       return false;
     }
   }
 };
 

 let tempArr = [];


  const PositionHandler = (position,group,teamId) => {

      if (group[position] !== undefined) {
        group[position].map((play) => {
          console.log({play})
          let data = rankData.find((rank) =>
            comparePlayerName(
                cat_type === "nfl" || cat_type === "NFL"
                ? play.player_name.toLowerCase()
                : cat_type === "nba" || cat_type === "NBA"
                ? play.player_name.toLowerCase() 
                : play.player_name.toLowerCase()
                 ,
              rank.player.toLowerCase()
            )
          );

     

            if (data !== undefined) {
              const formatTopRank = {
                name: play.player_name,
                url: play.url,
                pid: play.id_of_player,
                id: play.id,
                position: play.position,
                rank: parseFloat(data.rank),
              };
              topRank.push(formatTopRank);
           
            }
            // console.log({ topRank });
        });
      }

   
            

    const finalR = topRank.sort((a, b) => a["rank"] - b["rank"]);
     
    const playerInfo = finalR.reduce((r, a) => {
      r[a.position] = [...(r[a.position] || []), a];
      return r;
    }, {});


    let mergingIdPlayer = {
      teamId,
      playerInfo,
    };


   

    
    updateOrPush(optimalRankData, mergingIdPlayer);
    // preVData.push(mergingIdPlayer);   
    
    
  }

  function updateOrPush(dataArray, newData) {
  const index = dataArray.findIndex(item => item.teamId === newData.teamId);

  if (index !== -1) {
    // Update existing data
    dataArray[index] = newData;
  } else {
    // Push new data
    dataArray.push(newData);
  }
setOptimalRankData(dataArray);
  
}



  useEffect(()=>{
    // console.log(optimalRankData);
 
  },[optimalRankData])

  function getTopCountForTeam(team) {
    const countEntry = getTopCount.find((entry) =>(entry[0] === team));
    console.log(countEntry[1]);
    return countEntry ? countEntry[1] : null;
  }

  const OptimalRankHandler = (team) => {
    setTopRank([])
   const group = team.players_info.reduce((r, a) => {
        r[a.position] = [...(r[(a).position] || []), a];
        return r;
      },{});
    positions.map((p)=>{
      PositionHandler(p, group,team.id)
    })
  }




  // const handleOptionClick = (id) => {
  //   const team = teamData.find((item) => item.id === id);

  //   let prevTeam = teamData;
  //   console.log({ team });
  //   console.log({ optimaldata });

  //   //if (cat_type.toLowerCase() !== "nfl") return;

  //   const players = playerFormatter(team?.players_info) || {};
  //   console.log({ players });

  //     //   const group = team.players_info.reduce((r, a) => {
  //     //   r[a.position] = [...(r[(a).position] || []), a];
  //     //   return r;
  //     // },{});



  //  /*  map((play) => {
  //    console.log({ play });
  //     // let data = rankdata.find((rank) =>
  //     //   comparePlayerName(play.name.toLowerCase(), rank.player.toLowerCase())
  //     // );
  //     // if (data !== undefined) {
  //     //   const formatTopRank = {
  //     //     name: play.name,
  //     //     url: play.ProfileImageUrl,
  //     //     pid: play.pid,
  //     //     id: play.id,
  //     //     position: play.position,
  //     //     rank: parseFloat(data.rank),
  //     //   };
  //     //   topRank.push(formatTopRank);
  //     // }
  //   }, players[team]); */




    




  //   const names = Object.fromEntries(
  //     Object.entries(players).map(([key, value]) => [
  //       key,
  //       value.map((r) => r.player_name),
  //     ])
  //   );

  //   console.log({ optimaldata });

  //   let newObj = {};
  //   Object.keys(players).forEach((ele) => {
  //     if (optimaldata.hasOwnProperty(ele)) {
  //       newObj[ele] = optimaldata[ele].filter((e) =>
  //         names[ele].includes(e.player)
  //       );
  //     }
  //   });

  //   console.log({ newObj });
  //   const teamIdToUpdate = id;
  //   const RankDataArry = rankData;

  //   const teamToUpdateIndex = prevTeam.findIndex(
  //     (team) => team.id === teamIdToUpdate
  //   );

  //   if (teamToUpdateIndex !== -1) {
  //     const updatedPlayersArray = JSON.parse(
  //       JSON.stringify(prevTeam[teamToUpdateIndex].players_info)
  //     );

  //     updatedPlayersArray.forEach((playerInfo) => {
  //       const playerName = playerInfo.player_name;
  //       const player = Object.values(newObj)
  //         .flatMap((players) => players)
  //         .find((player) => player.player === playerName);

  //       if (player) {
  //         playerInfo.position = player.position;
  //         //playerInfo.url = player.sources[0]?.wburl || "";
  //         playerInfo.source = player.sources[0]?.wburl || "";
  //         playerInfo.rank = player.rank;
  //       }
  //     });

  //     prevTeam[teamToUpdateIndex].players_info = updatedPlayersArray;

  //     const existingTeamIndex = RankDataArry.filter(
  //       (ele) => ele.id === team.id
  //     );

  //     if (existingTeamIndex.length > 0) {
  //       //RankDataArry[team.id] = prevTeam[teamToUpdateIndex];
  //       let index = RankDataArry.findIndex((ele) => ele.id === team.id);
  //       RankDataArry[index] = team;
  //     } else {
  //       setRankData([...rankData, team]);
  //       RankDataArry.push(team);
  //     }
  //   }
  //   console.log({ RankDataArry });
  // };


  
     
  return (
    <Fragment>
      <If condition={isteam}>
        <Then>
          {/* ===============  MY TEAM ===============  */}
          <div className="my-team p-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="title_2 "> My Team </h2>
                </div>
              </div>

              <div className="create-team ">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="team-btns">
                      <button
                        onClick={() => {
                          setNewTeamName("");
                          setIsEdit(false);
                          createTeamHandleShow();
                          setShowPlayerList(false);
                          setIsEdit(false);
                        }}
                        className="common-btn with-border"
                        // onClick={createTeamHandleShow}
                      >
                        Create team
                      </button>
                      <button  className="common-btn with-border" onClick={()=>history.push("/importteam")}>Import Team</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {teamData.map(
                (myteams, index) =>
                  myteams.category.toLowerCase() === cat_type.toLowerCase() && (
                    <div className="row" key={myteams.id}>
                      <div className="col-lg-12">
                        <Accordion defaultActiveKey="0" className="team_acc">
                          <Accordion.Item eventKey={"" + index}>
                            <Accordion.Header className="test">
                              <div className="team-name">
                                <h6>{myteams.team_name}</h6>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="team-actions">
                                <div className="player-team-wrapper">
                                  <div className="player-team-inner">
                                    <span
                                      className={`${
                                        selectedOptimalPlayer[myteams.id] ===
                                          "players" ||
                                        selectedOptimalPlayer[myteams.id] ===
                                          undefined
                                          ? "active"
                                          : ""
                                      } `}
                                      onClick={() =>
                                        //handleOptionClick(index, "players")
                                        setSelectedOptimalPlayer({
                                          ...selectedOptimalPlayer,
                                          [myteams.id]: "players",
                                        })
                                      }
                                    >
                                      {" "}
                                      Players{" "}
                                    </span>
                                    <span
                                      className={`${
                                        selectedOptimalPlayer[myteams.id] ===
                                        "optimalrank"
                                          ? "active"
                                          : ""
                                      } `}
                                      onClick={() => {
                                        OptimalRankHandler(myteams);
                                        setSelectedOptimalPlayer({
                                          ...selectedOptimalPlayer,
                                          [myteams.id]: "optimalrank",
                                        });
                                      }}
                                    >
                                      Optimal Lineup{" "}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <span
                                    className="edit"
                                    onClick={() => {
                                      editTeamHandler(myteams.id);
                                      setTeamName(myteams.team_name);
                                      setNewTeamName(myteams.team_name);
                                    }}
                                  >
                                    <i className="fas fa-pencil-alt"></i>
                                  </span>
                                </div>
                                <div>
                                  <span
                                    className="delete"
                                    onClick={() => {
                                      deleteTeamHandler(myteams.id);
                                    }}
                                  >
                                    <i className="fas fa-trash"></i>
                                  </span>
                                </div>
                              </div>
                              <div className="positions-wrapper">
                                <If
                                  condition={myteams.players_info.length === 0}
                                >
                                  <Then>
                                    <p>No Players Added</p>
                                  </Then>
                                  <Else>
                                    {selectedOptimalPlayer[myteams.id] ===
                                    "players" ? (
                                      Object.keys(
                                        playerFormatter(myteams.players_info)
                                      ).map((val, key) => (
                                        <>
                                          <div className="player-positions">
                                            {" "}
                                            <span>{val}</span>{" "}
                                          </div>
                                          <ul className="player_list">
                                            {playerFormatter(
                                              myteams.players_info
                                            )[val].map((players) => (
                                              <li key={players.id}>
                                                <div className=" player-detail">
                                                  <div className="player-img-3">
                                                    <span>
                                                      <img
                                                        src={players.url}
                                                        loading="lazy"
                                                        alt="-"
                                                      />
                                                    </span>
                                                  </div>
                                                  {players.player_name === ""
                                                    ? "no player"
                                                    : players.player_name}
                                                </div>
                                                <div
                                                  className="delete-player"
                                                  onClick={() => {
                                                    deletePlayerHandler(
                                                      players.id,
                                                      myteams.id
                                                    );
                                                  }}
                                                >
                                                  <i className="fas fa-trash"></i>{" "}
                                                </div>
                                              </li>
                                            ))}
                                          </ul>
                                        </>
                                      ))
                                    ) : (
                                      <div className="">
                                        {optimalRankData.map(
                                          (teams) =>
                                            teams.teamId === myteams.id &&
                                            Object.keys(teams.playerInfo).map(
                                              (position) => (
                                                <>
                                                  <div>
                                                    <div className="player-positions">
                                                      <span>{position}</span>
                                                    </div>
                                                  </div>
                                                  <ul className="player_list">
                                                    {teams["playerInfo"][
                                                      position
                                                    ].map((player, i) => (
                                                      <li>
                                                        {console.log({
                                                          player,
                                                        })}
                                                        <div className="player-detail">
                                                          <div className="player-img-3">
                                                            <span>
                                                              <img
                                                                src={player.url}
                                                                loading="lazy"
                                                                alt="-"
                                                              />
                                                            </span>
                                                          </div>
                                                          {player.name}
                                                        </div>

                                                        <div>
                                                          <span className="common-btn ">
                                                            {player.rank}
                                                          </span>
                                                        </div>
                                                      </li>
                                                    ))}
                                                  </ul>
                                                </>
                                              )
                                            )
                                        )}
                                      </div>
                                    )}
                                  </Else>
                                </If>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className={loader ? "sipnner-wrap" : "d-none"}>
              <Spinner animation="border" />
            </div>
          </div>
        </Then>
        <Else>
          {/* ===============  CREATE TEAM =============== */}
          <div className="create-team p-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="title_2 pb-5"> No Any team Yet! </h2>{" "}
                </div>
                <div className="col-lg-12">
                  <div className="create-team-img">
                    {" "}
                    <img
                      src="assets/images/create-team.png"
                      alt="create-team"
                    />{" "}
                  </div>
                  <button
                    className="common-btn with-border"
                    onClick={() => {
                      createTeamHandleShow();
                      dispatch({ type: "ADD_PLAYER_LIST", payload: [] });
                      setIsEdit(false);
                    }}
                  >
                    {" "}
                    Create team
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Else>
      </If>

      {/* ===============  CREATE TEAM MODAL=============== */}
      <Modal
        show={showCreateTeam}
        onHide={createTeamHandleClose}
        size="lg"
        centered
        keyboard={false}
        className="login-modal create_team"
      >
        <Modal.Header>
          <h4 className="title_2">
            {isedit ? "Edit your own team" : "Create your own team"}{" "}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="add_team_name">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Team Name"
                name="team_name"
                value={NewTeamName}
                onChange={(e) => {
                  setNewTeamName(e.target.value);
                  setteamNerror("");
                }}
              />{" "}
            </div>
            {teamNerror !== "" && <span className="error">{teamNerror}</span>}
          </div>
          <div className="create-team-wrapper mt-3">
            <div className="player-search-box">
              <span>
                {filteredData.length === 0 ? <FaSearch /> : <MdOutlineClose />}
              </span>
              <span></span>
              <input
                type="text"
                name=""
                placeholder="Search Players"
                onChange={getPlayerHanlder}
              />{" "}
              <If condition={ShowPlayerList}>
                <Then>
                  <ul
                    className={
                      searchList.length < 5
                        ? "search-result h-auto"
                        : "search-result h-400"
                    }
                  >
                    <div className={loader ? "sipnner-wrap" : "d-none"}>
                      <Spinner animation="border" />
                    </div>
                    {searchList.length !== 0 ? (
                      searchList.map((searchitem, index) => (
                        <div>
                          {
                            <li
                              onClick={() =>
                                singlePlayerHandler(searchitem, categorType)
                              }
                              key={index}
                            >
                              {" "}
                              <span className="searchListImage">
                                <img
                                  src={
                                    searchitem.photo_url !== undefined
                                      ? searchitem.photo_url
                                      : searchitem.logo_url
                                  }
                                  alt="-"
                                />{" "}
                              </span>{" "}
                              {categoryType === "nfl"
                                ? searchitem.name
                                : categoryType === "mlb"
                                ? searchitem.full_name
                                : searchitem.full_name}
                            </li>
                          }
                        </div>
                      ))
                    ) : (
                      <li>NFL Player or Team Found </li>
                    )}
                  </ul>
                </Then>
              </If>
            </div>

            <div className="create-team-btn">
              <button
                className="common-btn with-border"
                onClick={() =>
                  isedit
                    ? updateTeam(state.add_Player_List)
                    : CreateTeam(state.add_Player_List)
                }
              >
                {isedit ? "Update" : "Create"}
              </button>
            </div>
          </div>
          {PlayerSelected ? (
            <p className="error"> Player Already Present in team </p>
          ) : (
            ""
          )}
          {state.add_Player_List.length > 0 || isedit ? (
            <ul className="player_list">
              {state.add_Player_List.map((playerDetail, index) => (
                <li key={index}>
                  <div className=" player-detail">
                    <div className="add_player-img adasd">
                      <span>
                        <img src={playerDetail.photo_url} alt="-" />
                      </span>
                      {playerDetail.name}
                    </div>
                  </div>
                  <div
                    className="delete-player"
                    onClick={async () => {
                      let list = state.add_Player_List;
                      list.splice(index, 1);
                      dispatch({ type: "ADD_PLAYER_LIST", payload: list });
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </div>
                </li>
              ))}
              {isedit &&
                editTeam.players_info &&
                editTeam.players_info.map((players, i) => (
                  <li key={players.id}>
                    <div className=" player-detail">
                      <div className="player-img-3">
                        {" "}
                        <span>
                          <img src={players.url} loading="lazy" alt="-" />
                        </span>
                      </div>
                      {players.player_name === ""
                        ? "no player"
                        : players.player_name}
                    </div>
                    <div
                      className="delete-player"
                      onClick={() => {
                        deletePlayerHandler(players.id, editTeam.id);
                      }}
                    >
                      {" "}
                      <i className="fas fa-trash"></i>{" "}
                    </div>
                  </li>
                ))}
            </ul>
          ) : (
            ""
          )}

          {/*isedit && (
            <ul className="player_list">
              <p>edit</p>
              {isedit &&
                editTeam.players_info &&
                editTeam.players_info.map((players, i) => (
                  <li key={players.id}>
                    <div className=" player-detail">
                      <div className="player-img-3">
                        {" "}
                        <span>
                          <img src={players.url} loading="lazy" alt="-" />
                        </span>
                      </div>
                      {players.player_name === ""
                        ? "no player"
                        : players.player_name}
                    </div>
                    <div
                      className="delete-player"
                      onClick={() => {
                        deletePlayerHandler(players.id, editTeam.id);
                      }}
                    >
                      {" "}
                      <i className="fas fa-trash"></i>{" "}
                    </div>
                  </li>
                ))}
            </ul>
                    )*/}
        </Modal.Body>
      </Modal>

      <ToastContainer autoClose={2000} />
    </Fragment>
  );
};

export default Team;
