import React,{Fragment,useState,useEffect,useContext} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';
import { Context } from "./store";
import {propOr,pathOr} from 'ramda';
import { Else, If, Then} from "react-if";

  const CustomizedDot2 = (props) => {
    const { cx, cy} = props;
      return(
        <Fragment>
        <span>Name</span>
        <svg x={cx - 10} y={cy - 10} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#01092D"/>
        </svg>
        </Fragment>
      )
  }


  const CustomizedDot3 = (props) => {
    const { cx, cy } = props;
      return(
        <Fragment>
        <span>Name</span>
        <svg x={cx - 10} y={cy - 10} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#2b7d3e"/>
        </svg>
        </Fragment>
      )
  }

  const CustomizedDot = (props) => {
    const { cx, cy } = props;
      return(
        <svg x={cx - 10} y={cy - 10} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#F30042"/>
        </svg>
      )
  }

 
const Chart = (props) => {
const [chartValue, setchartValue] = useState("");
const [state,dispatch] = useContext(Context);
const [chartData,setchartData] = useState([]);
const [ishow,setisshow] = useState(false);

const colors = ['#F30042', '#01092D', '#2b7d3e'];
const nflTeam = ['passing','rushing','receiving','defense','field_goals']

const [data,setData] = useState("");
// const [data2,setData2] = useState("");
// const [props.selectedPos,setprops.selectedPos] = useState( props.selectedGame === "nfl"? "passing":"hitting");

var playerTeamInfo = localStorage.getItem("playerteamInfo") !== '' ? JSON.parse(localStorage.getItem("playerteamInfo")) : []; 

const hitters = ['3B', 'CF', 'SS', '2B', 'C', '1B', 'RF', 'LF', 'DH', 'OF']
const pitchers = ['P', 'SP', 'RP']

useEffect(()=>{
    
  setTimeout(() => {
    if(props.selectedGame === "nba"){
      setchartValue("PTS")   
    }
    if(props.selectedGame === "mlb"){
      if(props.selectedType === "player"){
        if(hitters.includes(playerTeamInfo[0]['position'])) {
          setchartValue("RBI")
        }else{
          setchartValue("SO")
        }
    }else{
      if(props.selectedPos === "hitting") {
        setchartValue("RBI")
      }else{
        setchartValue("SO")
      }
    }
  }
    if(props.selectedGame === "nfl"){
      if(props.selectedType === "player"){
     
        if( playerTeamInfo[0]['position'] === "QB") {
          setchartValue("QBR")
        }else if ( playerTeamInfo[0]['position'] === "RB"){
          setchartValue("AVG")
        }
        else if ( playerTeamInfo[0]['position'] === "WR" ||playerTeamInfo[0]['position'] === "TE"){
          setchartValue("REC")
        }
        else if ( playerTeamInfo[0]['position'] === "D" ){
          setchartValue("FRS")
        }else{
          setchartValue("FG")
        }
      }else{
        if(props.selectedPos === "passing") {
          setchartValue("QBR")
        }else if (props.selectedPos === "rushing"){
          setchartValue("AVG")
        }
        else if (props.selectedPos === "receiving"){
          setchartValue("REC")
        }
        else if (props.selectedPos === "defense"){
          setchartValue("FRS")
        }else{
          setchartValue("FG")
        }
      }
    }
  }, 2000);
   
  setTimeout(() => {
    setisshow(true)
  }, 3000);
},[])




const setChartValue = (val) => {
    setchartValue(val)
}


useEffect(()=>{



setchartData(state.chartData);

  if(props.selectedGame === "nba"){
    setchartValue("PTS")   
  }
  if(props.selectedGame === "mlb"){
    if(props.selectedType === "player"){
      if(hitters.includes(playerTeamInfo[0]['position'])) {
        setchartValue("RBI")
      }else{
        setchartValue("SO")
      }
  }else{
    if(props.selectedPos === "hitting") {
      setchartValue("RBI")
    }else{
      setchartValue("SO")
    }
  }
}
  if(props.selectedGame === "nfl"){
    if(props.selectedType === "player"){
      if( playerTeamInfo[0]['position'] === "QB") {
        setchartValue("QBR")
      }else if ( playerTeamInfo[0]['position'] === "RB"){
        setchartValue("AVG")
      }
      else if ( playerTeamInfo[0]['position'] === "WR" ||playerTeamInfo[0]['position'] === "TE"){
        setchartValue("REC")
      }
      else if ( playerTeamInfo[0]['position'] === "D" ){
        setchartValue("FRS")
      }else{
        setchartValue("FG")
      }
    }else{
      if(props.selectedPos === "passing") {
        setchartValue("QBR")
      }else if (props.selectedPos === "rushing"){
        setchartValue("AVG")
      }
      else if (props.selectedPos === "receiving"){
        setchartValue("REC")
      }
      else if (props.selectedPos === "defense"){
        setchartValue("FRS")
      }else{
        setchartValue("FG")
      }
    }
  }
  

},[state.chartData, props.selectedPos])

useEffect(()=>{
  
  if(props.selectedGame === "mlb"){
    if(props.selectedType === "player"){
    setData(chartData['pitchingArr']);
    }else{
      setData(chartData[props.selectedPos]);
    }
  }
  if(props.selectedGame === "nba"){
    setData(chartData['chartArr']);
  }
  if(props.selectedGame === "nfl"){
    if(props.selectedType === "player"){
      setData(chartData['chartArr']);
    }else{
      setData(chartData[props.selectedPos]);
    }
  }
},[chartData,props.selectedPos])



  return(<Fragment>
    <If condition={props.selectedType === "team"}>
    {
      props.selectedGame === "nfl" ?
    <section className='ranking-table-wrap'>
    <div className="container">
       
    </div>                        
</section> 
    : props.selectedGame === "mlb" ?
  <section className='ranking-table-wrap'>
    <div className="container">
        
    </div>                        
  </section> 
  :""
  }

    </If> 
    
    
    <If condition={props.selectedGame === "nba"}>
    <div class="player-team-wrapper mb-4 mt-2">
    <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
        <div class="player-team-inner">
            <button class={chartValue === "PTS"? "active":""} onClick={()=>setChartValue("PTS")}> PTS </button>
            <button class={chartValue === "REB"? "active":""}  onClick={()=>setChartValue("REB")}> REB </button>
            <button class={chartValue === "AST"? "active":""} onClick={()=>setChartValue("AST")}> AST </button>
        </div>
    </div>
    </If>

    <If condition={props.selectedGame === "mlb"}>
      <If condition={props.selectedType === "player"}>
        <Then>
        <If condition={hitters.includes(playerTeamInfo[0]['position'])}>
          <Then>
            <div class="player-team-wrapper mb-4">
            <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
              <div class="player-team-inner">
                <button class={chartValue === "H"? "active":""} onClick={()=>setChartValue("H")}> H </button>
                <button class={chartValue === "RBI"? "active":""} onClick={()=>setChartValue("RBI")}> RBI </button>
                <button class={chartValue === "HR"? "active":""}  onClick={()=>setChartValue("HR")}> HR </button>
               
              </div>
            </div>
          </Then>
          <Else>
            <div class="player-team-wrapper mb-4">
            <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
              <div class="player-team-inner">
                <button class={chartValue === "ERA"? "active":""} onClick={()=>setChartValue("ERA")}> ERA </button>
                <button class={chartValue === "SO"? "active":""}  onClick={()=>setChartValue("SO")}> SO </button>
                <button class={chartValue === "WHIP"? "active":""} onClick={()=>setChartValue("WHIP")}> WHIP </button>
              </div>
           </div>
          </Else>
          </If>
        </Then>
        <Else>
          <If condition={props.selectedPos === "hitting"}>
          <Then>
            <div class="player-team-wrapper mb-4">
            <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
              <div class="player-team-inner">
                <button class={chartValue === "H"? "active":""} onClick={()=>setChartValue("H")}> H </button>
                <button class={chartValue === "RBI"? "active":""} onClick={()=>setChartValue("RBI")}> RBI </button>
                <button class={chartValue === "HR"? "active":""}  onClick={()=>setChartValue("HR")}> HR </button>
               
              </div>
            </div>
          </Then>
          <Else>
            <div class="player-team-wrapper mb-4">
            <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
              <div class="player-team-inner">
                <button class={chartValue === "ERA"? "active":""} onClick={()=>setChartValue("ERA")}> ERA </button>
                <button class={chartValue === "SO"? "active":""}  onClick={()=>setChartValue("SO")}> SO </button>
                <button class={chartValue === "WHIP"? "active":""} onClick={()=>setChartValue("WHIP")}> WHIP </button>
              </div>
           </div>
          </Else>
          </If>
        </Else>
      </If>
     
    </If>

    <If condition={props.selectedGame === "nfl"}>
    <If condition={props.selectedType === "player"}>
        <Then>
        <If condition={playerTeamInfo[0]['position'] === "QB"}>
        <div class="player-team-wrapper mb-4">
        <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
        <div class="player-team-inner">
          <button class={chartValue === "QBR"? "active":""} onClick={()=>setChartValue("QBR")}> QBR </button>
          <button class={chartValue === "TDS"? "active":""}  onClick={()=>setChartValue("TDS")}> TDS </button>
          <button class={chartValue === "YDS"? "active":""} onClick={()=>setChartValue("YDS")}> YDS </button>
          </div>
          </div>
        </If>
        <If condition={playerTeamInfo[0]['position'] === "RB"}>
        <div class="player-team-wrapper mb-4">
        <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
         <div class="player-team-inner">
           <button class={chartValue === "AVG"? "active":""} onClick={()=>setChartValue("AVG")}> AVG </button>
          <button class={chartValue === "TDS"? "active":""}  onClick={()=>setChartValue("TDS")}> TDS </button>
          <button class={chartValue === "YDS"? "active":""} onClick={()=>setChartValue("YDS")}> YDS </button>
         </div>
          </div>
          </If>
        <If condition={ playerTeamInfo[0]['position'] === "TE" ||  playerTeamInfo[0]['position'] === "WR"}>
        <div class="player-team-wrapper mb-4">
        <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
         <div class="player-team-inner">
           <button class={chartValue === "REC"? "active":""} onClick={()=>setChartValue("REC")}> REC </button>
          <button class={chartValue === "TDS"? "active":""}  onClick={()=>setChartValue("TDS")}> TDS </button>
          <button class={chartValue === "YDS"? "active":""} onClick={()=>setChartValue("YDS")}> YDS </button>
         </div>
          </div>
          </If>
        <If condition={playerTeamInfo[0]['position'] === "D"}>
        <div class="player-team-wrapper mb-4">
        <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
         <div class="player-team-inner">
           <button class={chartValue === "FRS"? "active":""} onClick={()=>setChartValue("FRS")}> FRS </button>
          <button class={chartValue === "INT"? "active":""}  onClick={()=>setChartValue("INT")}> INT </button>
          <button class={chartValue === "TUM"? "active":""} onClick={()=>setChartValue("TUM")}> TUM </button>
         </div>
          </div>
          </If>
        <If condition={  playerTeamInfo[0]['position'] === "K"}>
        <div class="player-team-wrapper mb-4">
        <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
         <div class="player-team-inner">
           <button class={chartValue === "FG"? "active":""} onClick={()=>setChartValue("FG")}> FG </button>
          <button class={chartValue === "P"? "active":""}  onClick={()=>setChartValue("P")}> P </button>
          <button class={chartValue === "XP"? "active":""} onClick={()=>setChartValue("XP")}> XP </button>
         </div>
          </div>
          </If>
          </Then>
          <Else>
          <If condition={props.selectedPos === "passing"}>
          <div class="player-team-wrapper mb-4">
          <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
          <div class="player-team-inner">
            
            <button class={chartValue === "TDS"? "active":""}  onClick={()=>setChartValue("TDS")}> TDS </button>
            <button class={chartValue === "YDS"? "active":""} onClick={()=>setChartValue("YDS")}> YDS </button>
            <button class={chartValue === "QBR"? "active":""} onClick={()=>setChartValue("QBR")}> QBR </button>
            </div>
            </div>
          </If>
          <If condition={props.selectedPos === "rushing"}>
          <div class="player-team-wrapper mb-4">
          <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
           <div class="player-team-inner">
            <button class={chartValue === "TDS"? "active":""}  onClick={()=>setChartValue("TDS")}> TDS </button>
            <button class={chartValue === "YDS"? "active":""} onClick={()=>setChartValue("YDS")}> YDS </button>
            <button class={chartValue === "AVG"? "active":""} onClick={()=>setChartValue("AVG")}> AVG </button>
           </div>
            </div>
            </If>
          <If condition={props.selectedPos === "receiving"}>
          <div class="player-team-wrapper mb-4">
          <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
           <div class="player-team-inner">
            <button class={chartValue === "TDS"? "active":""}  onClick={()=>setChartValue("TDS")}> TDS </button>
            <button class={chartValue === "YDS"? "active":""} onClick={()=>setChartValue("YDS")}> YDS </button>
            <button class={chartValue === "REC"? "active":""} onClick={()=>setChartValue("REC")}> REC </button>
           </div>
            </div>
            </If>
          <If condition={props.selectedPos === "defense"}>
          <div class="player-team-wrapper mb-4">
          <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
           <div class="player-team-inner">
            <button class={chartValue === "TUM"? "active":""} onClick={()=>setChartValue("TUM")}> TUM </button>
            <button class={chartValue === "INT"? "active":""}  onClick={()=>setChartValue("INT")}> INT </button>
            <button class={chartValue === "FRS"? "active":""} onClick={()=>setChartValue("FRS")}> FRS </button>
           </div>
            </div>
            </If>
          <If condition={props.selectedPos === "field_goals"}>
          <div class="player-team-wrapper mb-4">
          <h3 className='title_2 '>{props.selectedType === "team" ? "Team Trends" : "Player Trends"} </h3>
           <div class="player-team-inner">
             <button class={chartValue === "FG"? "active":""} onClick={()=>setChartValue("FG")}> FG </button>
            <button class={chartValue === "XP"? "active":""} onClick={()=>setChartValue("XP")}> XP </button>
            <button class={chartValue === "P"? "active":""}  onClick={()=>setChartValue("P")}> P </button>
           </div>
            </div>
            </If>
        
        </Else>
        </If>
  </If>


    <If condition={ishow}>

  <ResponsiveContainer>
    <LineChart
    width={1250}
    height={500}
    compact={true}
    
    // data={data}
    
    data={props.selectedType==='player' ? props.data.length > 0 ? props.data.map((value, index)=> {
      
      var object = {'name': value['DATE']}
     
      if (playerTeamInfo.length === 1 && value['TRENDS'][chartValue] !== undefined && value['TRENDS'] !== undefined) {
        object[pathOr('',['0','name'],playerTeamInfo)] = value['TRENDS'][chartValue][index]
      } else if (playerTeamInfo.length === 2 && value['TRENDS'][chartValue] !== undefined && value['TRENDS'] !== undefined)  {
        object[pathOr('',['0','name'],playerTeamInfo)] = value['TRENDS'][chartValue][index]
        if( props.data2 !== undefined && props.data2.length !==0 ){
        object[pathOr('',['1','name'],playerTeamInfo)] = props.data2[index]['TRENDS'][chartValue][index]
        }
      } else if (playerTeamInfo.length === 3 && value['TRENDS'][chartValue] !== undefined && value['TRENDS'] !== undefined)  {
        object[pathOr('',['0','name'],playerTeamInfo)] = value['TRENDS'][chartValue][index]
        if(  props.data2!== undefined && props.data2.length !==0 ){
        object[pathOr('',['1','name'],playerTeamInfo)] = props.data2[index]['TRENDS'][chartValue][index]
        }
        if( props.data3!== undefined && props.data3.length !==0){
        object[pathOr('',['2','name'],playerTeamInfo)] = props.data3[index]['TRENDS'][chartValue][index]
        }
      }
      
      return object
      }
    ) : [] : ( props.data.length !==0 && props.data[props.selectedPos].length > 0) ? props.data[props.selectedPos].map((value, index)=> {
      
      var object = {'name': value['DATE']}

      if (playerTeamInfo.length === 1 && value['TRENDS'][chartValue] !== undefined) {
        object[pathOr('',['0','name'],playerTeamInfo)] = value['TRENDS'][chartValue][index]
      } else if (playerTeamInfo.length === 2 && value['TRENDS'][chartValue] !== undefined) {
        object[pathOr('',['0','name'],playerTeamInfo)] = value['TRENDS'][chartValue][index]
        if(props.data2 !== undefined && props.data2.length !==0 ){
          object[pathOr('',['1','name'],playerTeamInfo)] = props.data2[props.selectedPos][index]['TRENDS'][chartValue][index]
        }
      } else if (playerTeamInfo.length === 3 && value['TRENDS'][chartValue] !== undefined) {
        object[pathOr('',['0','name'],playerTeamInfo)] = value['TRENDS'][chartValue][index]
  
        if(props.data2!== undefined &&props.data2.length !==0 ){
          object[pathOr('',['1','name'],playerTeamInfo)] = props.data2[props.selectedPos][index]['TRENDS'][chartValue][index]
          
        }
        if( props.data3!== undefined && props.data3.length !==0){
          object[pathOr('',['2','name'],playerTeamInfo)] = props.data3[props.selectedPos][index]['TRENDS'][chartValue][index]
        }
      }
     
      return object
      
      }
    ) : []}
    margin={{top: 5,right: 30, left: 20,bottom: 5, }}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis />
    <YAxis />
    <Legend /> 
    {playerTeamInfo.map((val,index)=>(
      <Line type="monotone" dataKey={propOr('','name',val)} stroke={colors[index]} strokeWidth={4} strokeOpacity={0.3} activeDot={{ r: 8 }}  dot={ index === 0 ? <CustomizedDot/>:index === 1 ? <CustomizedDot2/>:<CustomizedDot3/> }  />
    ))}

    
    </LineChart> 
    </ResponsiveContainer>
    </If>

  

    

    
    </Fragment>
    )
};

export default Chart;
