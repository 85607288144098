import {pathOr,propOr} from 'ramda';
const pass =  'passing';
const rush = 'rushing';
const fumb = 'fumbles';
const recei = 'receiving';
const field = 'field_goals';
const extra = 'extra_points';
const defen = 'defense';
const total = 'total';

const hitters = ['3B', 'CF', 'SS', '2B', 'C', '1B', 'RF', 'LF', 'DH', 'OF']
const pitchers = ['P', 'SP', 'RP']

const game = ['pitching','overall','games',]
const runs = ['pitching','overall','runs',]


export const getFormattedExclusiveStats = (response, position, type ,selectGameType) => {
  const exclusiveStats = pathOr([], ['data', 'response'], response);
  if (pitchers.includes(position)) {
      return propOr({}, 'pitching', exclusiveStats)
  }
  if (hitters.includes(position)) {
    
     return propOr({}, 'hitting', exclusiveStats)
    
  }
  if ('QB'===position) {
    // return propOr({}, 'passing', exclusiveStats)
    return formatPassingDataForExclusiceStat(exclusiveStats, type)
    
  }

  if('RB'===position){
    // return propOr({}, 'rushing', exclusiveStats)
    return formatRushingDataForExclusiceStat(exclusiveStats, type)
  }

  if('WR'===position || 'TE'===position){
    // return propOr({}, 'receiving', exclusiveStats)
    return formatReceivingDataForExclusiceStat(exclusiveStats, type)
  }

  if('D'===position){
    // return propOr({}, 'defense', exclusiveStats)
    return formatDefenseDataForExclusiceStat(exclusiveStats, type)
  }

  if('K'===position){
    // return propOr({}, 'field_goals', exclusiveStats)
    return formatFieldGoalsDataForExclusiceStat(exclusiveStats, type)
  }
  

  if(selectGameType === 'nba'){
    
    if(type === 'team'){
      return {
        nba: formatNBADataForExclusiveStats(exclusiveStats, type),
      }
    }else{
      return formatNBADataForExclusiveStats(exclusiveStats, type)

    }

  }

  return {
  
    hitting: propOr({}, 'hitting', exclusiveStats),
    pitching: propOr({}, 'pitching', exclusiveStats),
    passing: formatPassingDataForExclusiceStat(pathOr([], ['data', 'response','record'], response), type),
    rushing: formatRushingDataForExclusiceStat(pathOr([], ['data', 'response','record'], response), type),
    receiving: formatReceivingDataForExclusiceStat(pathOr([], ['data', 'response','record'], response), type),
    defense: formatDefenseDataForExclusiceStat(pathOr([], ['data', 'response','record'], response), type),
    field_goals: formatFieldGoalsDataForExclusiceStat(pathOr([], ['data', 'response','record'], response), type),

  };
}



// export const seasonalStatus = (response,type,selected_cat,position) 
export const seasonalStatus = (response, position, type, selectGameType) => {
    //const hitterStatsHeader = ['G', 'AB', 'R', 'H', '2B', '3B', 'HR', 'RBI', 'BB', 'SO', 'SB', 'CS', 'AVG', 'OBP', 'SLG', 'OPS']
    //const pitcherStatsHeader = ['G', 'GS', 'W', 'L', 'ERA', 'SV', 'SVO', 'IP', 'H', 'R', 'ER', 'HR', 'HB', 'BB', 'IBB', 'SO', 'WHIP']
    const seasonStats = pathOr([], ['data', 'response'], response);
  
    
    if (pitchers.includes(position)) {

      return formatPitcherDataForSeasonStats(seasonStats, type)
    }
    if (hitters.includes(position)) {
      return formatHitterDataForSeasonStats(seasonStats, type)
    }
  
    if('QB'=== position){
      return formatPassingDataForSeasonStats(seasonStats, type)
    }
  
    if('RB'=== position){
      return formatRushingDataForSeasonStats(seasonStats, type)
    }
  
    if('WR'===position || 'TE'===position){
      return formatReceivingDataForSeasonStats(seasonStats, type)
    }
  
    if('D'===position){
      return formatDefenseDataForSeasonStats(seasonStats, type)
    }
  
    if('k'===position){
      return formatFieldGoalsDataForSeasonStats(seasonStats, type)
    }
  
    if(selectGameType === 'nba'){
      if(type === 'team'){
        return {
          nba: formatNBADataForSeasonStats(seasonStats, type),
        }
      }else{
        return formatNBADataForSeasonStats(seasonStats, type)
      }
  
    }
  
    return {
      hitting: formatHitterDataForSeasonStats(seasonStats, type),
      pitching: formatPitcherDataForSeasonStats(seasonStats, type),
      passing: formatPassingDataForSeasonStats(pathOr([], ['data', 'response','record'], response), type),
      rushing: formatRushingDataForSeasonStats(pathOr([], ['data', 'response','record'], response), type),
      receiving: formatReceivingDataForSeasonStats(pathOr([], ['data', 'response','record'], response), type),
      defense: formatDefenseDataForSeasonStats(pathOr([], ['data', 'response','record'], response), type),
      field_goals: formatFieldGoalsDataForSeasonStats(pathOr([], ['data', 'response','record'], response), type),
    };
  }
  




// for QB POsition
const formatPassingDataForSeasonStats = (stats, type) => {
    //const playerPath = 'passing';
    //const teamPath = ['statistics', 'passing']
    //const path = type === 'player' ? playerPath : teamPath;
    if(type==='player'){
      const COMP = pathOr(0, [pass, 'completions'], stats)
      const PATT = pathOr(0, [pass, 'attempts'], stats) // 'P' ->  Passing
      const PYDS = pathOr(0, [pass, 'yards'], stats) // 'P' ->  Passing
      const PAVG = pathOr(0, [pass, 'avg_yards'], stats) // 'P' ->  Passing
      const PTD = pathOr(0, [pass, 'touchdowns'], stats) // 'P' ->  Passing
      const INT = pathOr(0, [pass, 'interceptions'], stats)// 'P' ->  Passing
      const SCK = pathOr(0, [pass, 'sacks'], stats)
      const QBR = pathOr(0, [pass, 'rating'], stats)
      const RATT = pathOr(0, [rush, 'attempts'], stats) // 'R' ->  Rushing
      const RYDS = pathOr(0, [rush, 'yards'], stats) // 'R' ->  Rushing
      const RAVG = pathOr(0, [rush, 'avg_yards'], stats) // 'R' ->  Rushing
      const RTD = pathOr(0, [rush, 'touchdowns'], stats) // 'R' ->  Rushing
      const FUM = pathOr(0, [fumb, 'fumbles'], stats)
      const LOST = pathOr(0, [fumb, 'lost_fumbles'], stats)
      return { COMP, PATT, PYDS, PAVG: Number(PAVG), PTD, INT, SCK, QBR, RATT, RYDS, RAVG: Number(RAVG), RTD, FUM, LOST };
    }
    else{
      const COMP = pathOr(0, [pass, 'completions'], stats)
      const PATT = pathOr(0, [pass, 'attempts'], stats)
      const PYDS = pathOr(0, [pass, 'yards'], stats)
      const PAVG = pathOr(0, [pass, 'avg_yards'], stats)
      const PTD = pathOr(0, [pass, 'touchdowns'], stats)
      const INT = pathOr(0, [pass, 'interceptions'], stats)
      const SCK = pathOr(0, [pass, 'sacks'], stats)
      const QBR = pathOr(0, [pass, 'rating'], stats)
      return {  COMP, PATT, PYDS, PAVG: Number(PAVG), PTD, INT, SCK, QBR };
    }
  }
  
  // for RB POsition
  const formatRushingDataForSeasonStats = (stats, type) => {
    //const playerPath = 'passing';
    //const teamPath = ['statistics', 'passing']
    //const path = type === 'player' ? playerPath : teamPath;
  
    if(type==='player'){
      const RATT = pathOr(0, [rush, 'attempts'], stats) // 'R' ->  Rushing
      const RYDS = pathOr(0, [rush, 'yards'], stats) // 'R' ->  Rushing
      const RAVG = pathOr(0, [rush, 'avg_yards'], stats) // 'R' ->  Rushing
      const LNG = pathOr(0, [rush, 'longest'], stats)
      const RTD = pathOr(0, [rush, 'touchdowns'], stats) // 'R' ->  Rushing
      const REC = pathOr(0, [recei, 'receptions'], stats)
      const REYDS = pathOr(0, [recei, 'yards'], stats) // 'RE' ->  Receiving
      const REAVG = pathOr(0, [recei, 'avg_yards'], stats) // 'RE' ->  Receiving
      const RETD = pathOr(0, [recei, 'touchdowns'], stats) // 'RE' ->  Receiving
      return { RATT, RYDS, RAVG: Number(RAVG), LNG, RTD, REC, REYDS, REAVG, RETD };
    }
    else{
      const RATT = pathOr(0, [rush, 'attempts'], stats)
      const RYDS = pathOr(0, [rush, 'yards'], stats)
      const RAVG = pathOr(0, [rush, 'avg_yards'], stats)
      const LNG = pathOr(0, [rush, 'longest'], stats)
      const RTD = pathOr(0, [rush, 'touchdowns'], stats)
      return { RATT, RYDS, RAVG: Number(RAVG), LNG, RTD };
    }
  }
  
  
  // for WR/TE POsition
  const formatReceivingDataForSeasonStats = (stats, type) => {
    //const playerPath = 'passing';
    //const teamPath = ['statistics', 'passing']
    //const path = type === 'player' ? playerPath : teamPath;
  
    if(type==='player'){
      const REC = pathOr(0, [recei, 'receptions'], stats)
      const YDS = pathOr(0, [recei, 'yards'], stats)
      const REAVG = pathOr(0, [recei, 'avg_yards'], stats)
      const TD = pathOr(0, [recei, 'touchdowns'], stats)
      const LNG = pathOr(0, [recei, 'longest_touchdown'], stats)
      const TGT = pathOr(0, [recei, 'targets'], stats)
      return { REC, YDS, REAVG: Number(REAVG), TD, LNG, TGT };
    }
    else{
      const REC = pathOr(0, [recei, 'receptions'], stats)
      const YDS = pathOr(0, [recei, 'yards'], stats)
      const REAVG = pathOr(0, [recei, 'avg_yards'], stats)
      const TD = pathOr(0, [recei, 'touchdowns'], stats)
      const LNG = pathOr(0, [recei, 'longest_touchdown'], stats)
      const TGT = pathOr(0, [recei, 'targets'], stats)
      return { REC, YDS, REAVG: Number(REAVG), TD, LNG, TGT };
    }
  }
  
  
  // for D POsition
  const formatDefenseDataForSeasonStats = (stats, type) => {
    //const playerPath = 'passing';
    //const teamPath = ['statistics', 'passing']
    //const path = type === 'player' ? playerPath : teamPath;
      const AST = pathOr(0, [defen, 'assists'], stats)
      const TOT = pathOr(0, [defen, 'tackles'], stats)
      const SACK = pathOr(0, [defen, 'sacks'], stats)
      const SCKYADS = pathOr(0, [defen, 'sack_yards'], stats)
      const TFL = pathOr(0, [defen, 'tloss_yards'], stats)
      const PD = pathOr(0, [defen, 'pass_defended'], stats)
      const INT = pathOr(0, [defen, 'interceptions'], stats)
      const FR = pathOr(0, [defen, 'fumble_recoveries'], stats)
      return { AST, TOT, SACK, SCKYADS, TFL, PD, INT, FR};
  }
  
  const formatFieldGoalsDataForSeasonStats = (stats, type) => {
    //const playerPath = 'passing';
    //const teamPath = ['statistics', 'passing']
    //const path = type === 'player' ? playerPath : teamPath;
      const ATT = pathOr(0, [field, 'attempts'], stats)
      const M = pathOr(0, [field, 'made'], stats)
      const MI = pathOr(0, [field, 'missed'], stats)
      const PCT = pathOr(0, [field, 'pct'], stats)
      const LNG = pathOr(0, [field, 'longest'], stats)
      const EM = pathOr(0, [extra, 'kicks', 'made'], stats)
      const EPCT = pathOr(0, [extra, 'kicks', 'pct'], stats)
      return { ATT, M, MI, PCT, LNG, EM, EPCT};
  }
  
  const formatNBADataForSeasonStats = (stats, type) => {
    //const playerPath = 'passing';
    //const teamPath = ['statistics', 'passing']
    //const path = type === 'player' ? playerPath : teamPath;
    if(type==='player'){
      const GP = pathOr(0, ['total', 'games_played'], stats)
      const MIN = pathOr(0, ['total', 'minutes'], stats)
      const PTS = pathOr(0, ['total', 'points'], stats)
      const REB = pathOr(0, ['total', 'rebounds'], stats)
      const ASTT = pathOr(0, ['total', 'assists'], stats)
      const TOV = pathOr(0, ['total', 'turnovers'], stats)
      const STL = pathOr(0, ['total', 'steals'], stats)
      const BLK = pathOr(0, ['total', 'blocks'], stats)
      const FGM = pathOr(0, ['total', 'field_goals_made'], stats)
      const FGA = pathOr(0, ['total', 'field_goals_att'], stats)
      const FGP = pathOr(0, ['total', 'field_goals_pct'], stats)
      const THPM = pathOr(0, ['total', 'three_points_made'], stats)
      const THPA = pathOr(0, ['total', 'three_points_att'], stats)
      const THPP = pathOr(0, ['total', 'three_points_pct'], stats)
      const FTA = pathOr(0, ['total', 'free_throws_made'], stats)
      const FTP = pathOr(0, ['total', 'free_throws_pct'], stats)
      const PF = pathOr(0, ['total', 'personal_fouls'], stats)
      const DDT = pathOr(0, ['total', 'double_doubles'], stats)
      const TDTH = pathOr(0, ['total', 'triple_doubles'], stats)
      return { GP, MIN, PTS, REB, ASTT, TOV, STL, BLK, FGM, FGA, FGP, THPM, THPA, THPP, FTA, FTP, PF, DDT, TDTH};
    }else{
      const GP = pathOr(0, ['own_record', 'total', 'games_played'], stats)
      const PTS = pathOr(0, ['own_record', 'total', 'points'], stats)
      const REB = pathOr(0, ['own_record', 'total', 'rebounds'], stats)
      const ASTT = pathOr(0, ['own_record', 'total', 'assists'], stats)
      const TOV = pathOr(0, ['own_record', 'total', 'turnovers'], stats)
      const STL = pathOr(0, ['own_record', 'total', 'steals'], stats)
      const BLK = pathOr(0, ['own_record', 'total', 'blocks'], stats)
      const FGM = pathOr(0, ['own_record', 'total', 'field_goals_made'], stats)
      const FGA = pathOr(0, ['own_record', 'total', 'field_goals_att'], stats)
      const FGP = pathOr(0, ['own_record', 'total', 'field_goals_pct'], stats)
      const THPM = pathOr(0, ['own_record', 'total', 'three_points_made'], stats)
      const THPA = pathOr(0, ['own_record', 'total', 'three_points_att'], stats)
      const THPP = pathOr(0, ['own_record', 'total', 'three_points_pct'], stats)
      const FTA = pathOr(0, ['own_record', 'total', 'free_throws_made'], stats)
      const FTP = pathOr(0, ['own_record', 'total', 'free_throws_pct'], stats)
      const PF = pathOr(0, ['own_record', 'total', 'personal_fouls'], stats)
      return { GP, PTS, REB, ASTT, TOV, STL, BLK, FGM, FGA, FGP, THPM, THPA, THPP, FTA, FTP, PF};
    }
  }
  

  
    const formatPitcherDataForSeasonStats = (stats, type) => {
    const playerPath = ['pitching', 'overall'];
    const teamPath = ['statistics','pitching', 'overall']
    const path = type === 'player' ? playerPath : teamPath;
    const W = pathOr(0, [...path, 'games', 'win'], stats)
    const L = pathOr(0, [...path, 'games', 'loss'], stats)
    const G = W + L
    const ERA = pathOr(0, [...path, 'era'], stats)
    const SV = pathOr(0, [...path, 'games', 'save'], stats)
    const SVO = pathOr(0, [...path, 'games', 'svo'], stats)
    const IP = pathOr(0, [...path, 'ip_2'], stats)
    const H = pathOr(0, [...path, 'onbase', 'h'], stats)
    const R = pathOr(0, [...path, 'runs', 'total'], stats)
    const ER = pathOr(0, [...path, 'runs', 'earned'], stats)
    const HR = pathOr(0, [...path, 'onbase', 'hr'], stats)
    const HB = pathOr(0, [...path, 'onbase', 'hbp'], stats)
    const BB = pathOr(0, [...path, 'onbase', 'bb'], stats)
    const IBB = pathOr(0, [...path, 'onbase', 'ibb'], stats)
    const SO = pathOr(0, [...path, 'pitches', 'ktotal'], stats)
    const WHIP = pathOr(0, [...path, 'whip'], stats)
    return { G, W, L, ERA, SV, SVO, IP, H, R, ER, HR, HB, BB, IBB, SO, WHIP };
  }
  
  const formatHitterDataForSeasonStats = (stats, type) => {
    const playerPath = ['hitting', 'overall'];
    const teamPath = ['statistics', 'hitting', 'overall']
    const path = type === 'player' ? playerPath : teamPath;
    const G = pathOr(0, [...path, 'games', 'play'], stats)
    const AB = pathOr(0, [...path, 'ab'], stats)
    const R = pathOr(0, [...path, 'runs', 'total'], stats)
    const H = pathOr(0, [...path, 'onbase', 'h'], stats)
    const HR = pathOr(0, [...path, 'onbase', 'hr'], stats)
    const twoB = pathOr(0, [...path, 'onbase', 'd'], stats)
    const threeB = pathOr(0, [...path, 'onbase', 't'], stats)
    const RBI = pathOr(0, [...path, 'rbi'], stats)
    const BB = pathOr(0, [...path, 'onbase', 'bb'], stats)
    const SO = pathOr(0, [...path, 'outs', 'ktotal'], stats)
    const SB = pathOr(0, [...path, 'steal', 'stolen'], stats)
    const CS = pathOr(0, [...path, 'steal', 'caught'], stats)
    const AVG = pathOr(0, [...path, 'avg'], stats)
    const OBP = pathOr(0, [...path, 'obp'], stats)
    const SLG = pathOr(0, [...path, 'slg'], stats)
    const OPS = pathOr(0, [...path, 'ops'], stats)
    return { G, AB, R, H, HR, twoB, threeB, RBI, BB, SO, SB, CS, AVG: Number(AVG), OBP, SLG, OPS };
  }
  


// ===== For Exclusive ====



// const HitterExclusive = ['BABIP','ISO','BB','LD','FB','GB','HRFB','GBFB','K']
// const PitchersExclusive = [ 'K9', 'KBB', 'IRA', 'BQR', 'BQRS', 'PO', 'PIP', 'HR9', 'R', 'ER', 'HR', 'BB9']
// const PassingExclusive = ['AR', 'APT', 'H', 'K', 'OTH', 'RAT']
// const RushingExclusive = ['BT','RZA','YAC','YAC','S']
// const ReceivingExclusive = ['AY','BT','DP','T','RT','YAC','YACT']
// const DefenseExclusive = ['BP','H','K','QBH','S',]
// const FieldGoalsExclusive = ['A','B','L','PCT']
// const NbaExclusive = ['EFF_EX','TSP_EX','FTR_EX','EFP_EX','FBP_EX','PPP_EX','SCP_EX']


const formatPitcherDataForExclusiceStat = (stats, type) => {
 
  const playerPath = ['pitching', 'overall'];
  const teamPath = ['statistics', 'pitching', 'overall']
  const K9 = pathOr(-1, ['pitching', 'overall', 'k9'], stats)
  const KBB = pathOr(-1, ['pitching', 'overall', 'kbb'], stats)
  const IRA = pathOr(-1, ['pitching', 'overall','runs','ira'], stats)
  const BQR = pathOr(-1, ['pitching', 'overall','runs','bqr'], stats)
  const BQRS = pathOr(-1, ['pitching', 'overall','runs','bqra'], stats)
  const PO = pathOr(-1, ['pitching', 'overall','outs','po'], stats)
  const PIP = pathOr(-1, ['pitching', 'overall','pitches','per_ip'], stats)
  const HR9 = pathOr(-1, ['pitching', 'overall','onbase','hr9'], stats)
  const HR = pathOr(-1, ['pitching', 'overall', 'onbase', 'hr'], stats)
  return { K9, KBB, IRA, BQR, BQRS, PO, PIP, HR9,  HR};
}

const formatHitterDataForExclusiceStat = (stats, type) => {
  const playerPath = ['hitting', 'overall'];
  const teamPath = ['statistics', 'hitting', 'overall']
  const BABIP = pathOr(-1, ['hitting', 'overall', 'babip'], stats)
  const ISO = pathOr(-1, ['hitting', 'overall', 'iso'], stats)
  const BB = pathOr(-1, ['hitting', 'overall','onbase','bb'], stats)
  const LD = pathOr(-1, ['hitting', 'overall', 'linedrive'], stats)
  const FB = pathOr(-1, ['hitting', 'overall', 'flyball'], stats)
  const GB = pathOr(-1, ['hitting', 'overall', 'groundball'], stats)
  return {BABIP,ISO,BB,LD,FB,GB};
}




// for QB POsition
const formatPassingDataForExclusiceStat = (stats, type) => {
  //const playerPath = 'passing';
  //const teamPath = ['statistics', 'passing']
  //const path = type === 'player' ? playerPath : teamPath;
  if(type==='player'){
    const AR = pathOr(0, [pass, 'air_yards'], stats)
    const APT = pathOr(0, [pass, 'avg_pocket_time'], stats) // 'P' ->  Passing
    const H = pathOr(0, [pass, 'hurries'], stats) // 'P' ->  Passing
    const K = pathOr(0, [pass, 'knockdowns'], stats) // 'P' ->  Passing
    const OTH = pathOr(0, [pass, 'on_target_throws'], stats) // 'P' ->  Passing
    const RAT = pathOr(0, [pass, 'redzone_attempts'], stats)// 'P' ->  Passing
   
    return { AR, APT, H, K, OTH, RAT};
  }
  else{
    const AR = pathOr(0, [pass, 'air_yards'], stats)
    const APT = pathOr(0, [pass, 'avg_pocket_time'], stats) // 'P' ->  Passing
    const H = pathOr(0, [pass, 'hurries'], stats) // 'P' ->  Passing
    const K = pathOr(0, [pass, 'knockdowns'], stats) // 'P' ->  Passing
    const OTH = pathOr(0, [pass, 'on_target_throws'], stats) // 'P' ->  Passing
    const RAT = pathOr(0, [pass, 'redzone_attempts'], stats)// 'P' ->  Passing
    return { AR, APT, H, K, OTH, RAT};
  }
}

// for RB POsition


const formatRushingDataForExclusiceStat = (stats, type) => {
  //const playerPath = 'passing';
  //const teamPath = ['statistics', 'passing']
  //const path = type === 'player' ? playerPath : teamPath;
  if(type==='player'){
    const BT = pathOr(-1, [rush, 'broken_tackles'], stats)
    const RZA = pathOr(-1, [rush, 'redzone_attempts'], stats) // 'P' ->  Passing
    const YACO = pathOr(-1, [rush, 'yards_after_contact'], stats) // 'P' ->  Passing
    const S = pathOr(-1, [rush, 'scrambles'], stats) // 'P' ->  Passing
    const YAC = pathOr(-1, [recei, 'yards_after_catch'], stats) // 'P' ->  Passing
   
    return { BT, RZA, YACO,S,YAC};
  }
  else{
    const BT = pathOr(-1, [rush, 'broken_tackles'], stats)
    const RZA = pathOr(-1, [rush, 'redzone_attempts'], stats) // 'P' ->  Passing
    const YACO = pathOr(-1, [rush, 'yards_after_contact'], stats) // 'P' ->  Passing
    const S = pathOr(-1, [rush, 'scrambles'], stats) // 'P' ->  Passing
    const YAC = pathOr(-1, [recei, 'yards_after_catch'], stats) // 'P' ->  Passing
   
    return { BT, RZA, YACO, S,  YAC};
  }
}




// for WR/TE POsition


const formatReceivingDataForExclusiceStat = (stats, type) => {
  if(type==='player'){
    const AY = pathOr(-1, [recei, 'air_yards'], stats)
    const BT = pathOr(-1, [recei, 'broken_tackles'], stats) // 'P' ->  Passing
    const DP = pathOr(-1, [recei, 'dropped_passes'], stats) // 'P' ->  Passing
    const T = pathOr(-1, [recei, 'targets'], stats) // 'P' ->  Passing
    const RT = pathOr(-1, [recei, 'redzone_targets'], stats) // 'P' ->  Passing
    const YAC = pathOr(-1, [recei, 'yards_after_contact'], stats) // 'P' ->  Passing
    const YACT = pathOr(-1, [recei, 'yards_after_catch'], stats) // 'P' ->  Passing
    return {AY,BT,DP,T,RT,YAC,YACT};
  }
  else{
    const AY = pathOr(-1, [recei, 'air_yards'], stats)
    const BT = pathOr(-1, [recei, 'broken_tackles'], stats) // 'P' ->  Passing
    const DP = pathOr(-1, [recei, 'dropped_passes'], stats) // 'P' ->  Passing
    const T = pathOr(-1, [recei, 'targets'], stats) // 'P' ->  Passing
    const RT = pathOr(-1, [recei, 'redzone_targets'], stats) // 'P' ->  Passing
    const YAC = pathOr(-1, [recei, 'yards_after_contact'], stats) // 'P' ->  Passing
    const YACT = pathOr(-1, [recei, 'yards_after_catch'], stats) // 'P' ->  Passing
    return {AY,BT,DP,T,RT,YAC,YACT};
  }
}



// for KICK / FIELDGOALS  POsition
const formatFieldGoalsDataForExclusiceStat = (stats, type) => {
  if(type==='player'){
    const A = pathOr(-1, [field, 'attempts'], stats)
    const B = pathOr(-1, [field, 'blocked'], stats) // 'P' ->  Passing
    const L = pathOr(-1, [field, 'longest'], stats) 
    const PCT = pathOr(-1, [extra, 'pct'], stats) // 'P' ->  Passing
    return {A,B,L,PCT};
  }
  else{
    const A = pathOr(-1, [field, 'attempts'], stats)
    const B = pathOr(-1, [field, 'blocked'], stats) // 'P' ->  Passing
    const L = pathOr(-1, [field, 'longest'], stats) 
    const PCT = pathOr(-1, [extra, 'pct'], stats) // 'P' ->  Passing
    return {A,B,L,PCT};
  }
}





const formatDefenseDataForExclusiceStat = (stats, type) => {
  if(type==='player'){
    const BP = pathOr(-1, [defen, 'batted_passes'], stats)
    const H = pathOr(-1, [defen, 'hurries'], stats) // 'P' ->  Passing
    const K = pathOr(-1, [defen, 'knockdowns'], stats) // 'P' ->  Passing
    const QBH = pathOr(-1, [defen, 'qb_hits'], stats) // 'P' ->  Passing
    const S = pathOr(-1, [defen, 'safeties'], stats) // 'P' ->  Passing
    return {BP,H,K,QBH,S};
  }
  else{
    const BP = pathOr(-1, [defen, 'batted_passes'], stats)
    const H = pathOr(-1, [defen, 'hurries'], stats) // 'P' ->  Passing
    const K = pathOr(-1, [defen, 'knockdowns'], stats) // 'P' ->  Passing
    const QBH = pathOr(-1, [defen, 'qb_hits'], stats) // 'P' ->  Passing
    const S = pathOr(-1, [defen, 'safeties'], stats) // 'P' ->  Passing
    return {BP,H,K,QBH,S};
  }
}


const formatNBADataForExclusiveStats = (stats, type) => {
  //const playerPath = 'passing';
  //const teamPath = ['statistics', 'passing']
  //const path = type === 'player' ? playerPath : teamPath;

  if(type==='player'){
    const EFF_EX = pathOr(-1, ['total', 'efficiency'], stats)
    const TSP_EX = pathOr(-1, ['total', 'true_shooting_pct'], stats)
    const FTR_EX = pathOr(-1, ['total', 'free_throws_att'], stats)/pathOr(-1, ['total', 'field_goals_att'], stats)
    const EFP_EX = pathOr(-1, ['total', 'effective_fg_pct'], stats)
    const FBP_EX = pathOr(-1, ['total', 'fast_break_pct'], stats)
    const PPP_EX = pathOr(-1, ['total', 'points_in_paint_pct'], stats)
    const SCP_EX = pathOr(-1, ['total', 'second_chance_pct'], stats)
    return { EFF_EX, TSP_EX, FTR_EX, EFP_EX, FBP_EX, PPP_EX, SCP_EX};
  }else{
    const EFF_EX = pathOr(-1, ['own_record', 'total', 'efficiency'], stats)
    const TSP_EX = pathOr(-1, ['own_record', 'total', 'true_shooting_pct'], stats)
    const FTR_EX = pathOr(-1, ['own_record', 'total', 'free_throws_att'], stats)/pathOr(-1, ['own_record','total', 'field_goals_att'], stats)
    const EFP_EX = pathOr(-1, ['own_record', 'total', 'effective_fg_pct'], stats)
    const FBP_EX = pathOr(-1, ['own_record', 'total', 'fast_break_pct'], stats)
    const PPP_EX = pathOr(-1, ['own_record', 'total', 'points_in_paint_pct'], stats)
    const SCP_EX = pathOr(-1, ['own_record', 'total', 'second_chance_pct'], stats)
    return { EFF_EX, TSP_EX, FTR_EX, EFP_EX, FBP_EX, PPP_EX, SCP_EX};
  }
}


