import React, { Fragment, useState } from "react";
import { Spinner } from "react-bootstrap";

const OddsComparision = () => {
  const [loader, setLoader] = useState(true);
  setTimeout(() => {
    setLoader(false);   
    if(localStorage.getItem("isReload2") === "false"){
      window.location.reload();
      localStorage.setItem("isReload2",true)
    }
  }, 3000);


  
  return (

<Fragment>
  <div className={loader ? "sipnner-wrap" : "d-none"}>
     <Spinner animation="border" />
  </div>
  {
    loader ? 
    "":
  <section>
    <iframe width="100%" height="1000" src="https://tallysight.com/widget/org/fanalyze/NBA/odds/latest/" title="Odd Comparision" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  </section>
  }
  </Fragment>
  )
}

export default OddsComparision