import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  FaEnvelope,
  FaUserAlt,
  FaLock,
  FaRegEye,
  FaRegEyeSlash,
} from "react-icons/fa";
import { useForm } from "react-hook-form";
import axios from "./Utility/axios";
import request from "./Utility/Request";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { successToast, errorToast } from "./Utility/toastify";
import { initializeApp } from 'firebase/app';
import { getAnalytics,logEvent } from 'firebase/analytics';
import { firebaseConfig } from "./Utility/actionMethod";


const clientId =
  "361827779858-57du0jecouul4cb8b9ttnvuosu9m091r.apps.googleusercontent.com";

const registrationValidation = Yup.object({
  name: Yup.string().required("name is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
}).required();


const Register = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    let history = useHistory();

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };
  // REGITER FORM
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(registrationValidation) });
  const registerSubmit = (data) => {
    let newdata = {
      username: data.name,
      email: data.email,
      password: data.password,
    };
    const register = async () => {
      try {
        const response = await axios.post(request.SIGN_UP, newdata);
        if (response.data.Authorization) {
          if(response.data.email_varify === "1"){
          const message = response.data.message;
          successToast(message);
      
       
        if (response.data.Authorization) {
          const message = response.data.message;
          successToast(message);
  
        }
      }else{
        successToast("Register Successfully Please verify your email");
         // Log registration event
         logEvent(analytics, 'registration_event',  {
          tokens: response.data.Authorization,
          user_name:response.data.username,
          
        });

        history.push("login")
        
       
      }
      }
        if (response.data.status === "failure") {
          const message = response.data.message;
          errorToast(message);
        }
      } catch (err) {
        if (!err.respsonse) {
          errorToast(err.message);
        }
      }
    };
    register(data);
  };
  return (
    <section className="login-wrapper">
   
            {/* <div className="col-xl-6">
              <div className="login-detail">
                <img src={loginLogo} alt="login-logo" />
                <h5>
                  {" "}
                  Welcome to <span> Fanalyze! </span> We're building the sports
                  search engine and analysis platform.{" "}
                </h5>
                <h6>
                  Speeding up research for fantasy sports and sports betting.
                </h6>
              </div>
            </div> */}
      
      <div className="login-form">
    
                <form onSubmit={handleSubmit(registerSubmit)}>
                <h4 className="mt-0">Sign up</h4> 
               <h5>
                 
                  Welcome to <span> Fanalyze! </span> We're building the sports
                  search engine and analysis platform.
                </h5>
                  <div>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <FaUserAlt />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        {...register("name")}
                      />
                    </div>
                    <span className="error">{errors.name?.message}</span>
                  </div>

                  <div>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <FaEnvelope />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        {...register("email")}
                      />
                    </div>
                    <span className="error"> {errors.email?.message}</span>
                  </div>

                  <div>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <FaLock />
                      </span>
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control"
                        placeholder="password"
                        {...register("password")}
                      />
                      <span
                        toggle="#password"
                        className="toggle-password"
                        onClick={togglePassword}
                      >
                        {" "}
                        {passwordShown ? <FaRegEye /> : <FaRegEyeSlash />}
                      </span>
                    </div>
                    <span className="error"> {errors.password?.message}</span>
                  </div>

                  <button className="common-btn with-border">signup </button>
                </form>
                
                <p>
                  Already have an account?{" "}
                  <span onClick={()=>history.push("login")}>SignIn</span>
                </p>
              
              </div>
</section>
  )
}

export default Register