import {pathOr} from 'ramda';
const pitchers = ['P', 'SP', 'RP'];
const hitters = ['3B', 'CF', 'SS', '2B', 'C', '1B', 'RF', 'LF', 'DH', 'OF'];


export const seasonData = (response, position, type, selectGameType) => {
   
    const isNFL = selectGameType === 'nfl'
    const isNBA = selectGameType === 'nba'
    const seasonalStats = pathOr([], ['data', 'response'], response);
    //POSITION QB
    if(position === "QB"){
        
        const path = ['passing'];
        return [{
                name: 'TD',
                value: pathOr('-', [...path, 'touchdowns'], seasonalStats)
                }, {
                name: 'YDS',
                value: pathOr('-', [...path, 'yards'], seasonalStats)
                }, {
                name: 'QBR',
                value: pathOr('-', [...path, 'rating'], seasonalStats)
                }, {
                name: 'INT',
                value: pathOr('-', [...path, 'interceptions'], seasonalStats)
                }]
            
            //  setseasonStats(qb_data);
            //  setLoader(false);
    }
    //POSITION RB


    if('RB' === position){
      const path = ['rushing']
      return [{
        name: 'TD',
        value: pathOr('-', [...path, 'touchdowns'], seasonalStats)
      }, {
        name: 'YDS',
        value: pathOr('-', [...path, 'yards'], seasonalStats)
      }, {
        name: 'AVG',
        value: pathOr('-', [...path, 'avg_yards'], seasonalStats)
      }, {
        name: 'ATT',
        value: pathOr('-', [...path, 'attempts'], seasonalStats)
      }]
    }


    // if(position === "RB"){
        
    //     const path = ['rushing'];
    //     return  [{
    //             name: 'TD',
    //             value: pathOr('-', [...path, 'touchdowns'], seasonalStats)
    //             }, {
    //             name: 'YDS',
    //             value: pathOr('-', [...path, 'yards'], seasonalStats)
    //             }, {
    //             name: 'QBR',
    //             value: pathOr('-', [...path, 'rating'], seasonalStats)
    //             }, {
    //             name: 'INT',
    //             value: pathOr('-', [...path, 'interceptions'], seasonalStats)
    //             }]
               
           
    // }
    //POSITION TE AND WR
    if(position === "TE" || position === "WR" ){
        
        const path = ['receiving']
        return  [{
            name: 'TD',
            value: pathOr('-', [...path, 'touchdowns'], seasonalStats)
        }, {
            name: 'YDS',
            value: pathOr('-', [...path, 'yards'], seasonalStats)
        }, {
            name: 'REC',
            value: pathOr('-', [...path, 'receptions'], seasonalStats)
        }, {
            name: 'AVG',
            value: pathOr('-', [...path, 'avg_yards'], seasonalStats)
        }]
        
        // setseasonStats(te_wr_data);
        // setLoader(false);
    }
    //POSITION D
     if(position === "D" ){
        
        const path = ['defense']
        return [{
            name: 'TOT',
            value: pathOr('-', [...path, 'tackles'], seasonalStats)
        }, {
            name: 'SACL',
            value: pathOr('-', [...path, 'sacks'], seasonalStats)
        }, {
            name: 'INT',
            value: pathOr('-', [...path, 'interceptions'], seasonalStats)
        }, {
            name: 'FR',
            value: pathOr('-', [...path, 'fumble_recoveries'], seasonalStats)
        }]
        
        // setseasonStats(d_data);
        // setLoader(false);
    }
    //POSITION PICHERS
    var isPither =  pitchers.find(element => element === position);   
    if(isPither !== undefined){
        
        const path = ['pitching', 'overall'];
        return [{
          name: 'ERA',
          value: pathOr('-', [...path, 'era'], seasonalStats)
        }, {
          name: 'SO',
          value: pathOr('-', [...path, 'pitches', 'ktotal'], seasonalStats)
        }, {
          name: 'WHIP',
          value: pathOr('-', [...path, 'whip'], seasonalStats)
        }, {
          name: 'W/L',
          value: `${pathOr('-', [...path, 'games', 'win'], seasonalStats)}/${pathOr('-', [...path, 'games', 'loss'], seasonalStats)}`
        }]
        // setseasonStats(pitch_data);
        // setLoader(false);   

    }
    //POSITION HITTERS
    var ishitter =  hitters.find(element => element === position);
    if(ishitter !== undefined){
       
        const path = ['hitting', 'overall']
        return [{
            name: 'AVG',
            value: pathOr('-', [...path, 'avg'], seasonalStats)
        }, {
            name: 'HR',
            value: pathOr('-', [...path, 'onbase', 'hr'], seasonalStats)
        }, {
            name: 'RBI',
            value: pathOr('-', [...path, 'rbi'], seasonalStats)
        }, {
            name: 'OBP',
            value: pathOr('-', [...path, 'obp'], seasonalStats)
        }]
       
        // setseasonStats(hitter_data);
        // setLoader(false);
    }
    //POSITION NBA 
    if(isNBA){
        //POSITION OPPONENTS
        if(response.data.response.opponents !== undefined){
            
            const path = ['opponents','total'];
            return  [{
                name: 'PTS',
                value: pathOr('-', [...path, 'points'], seasonalStats)
              }, {
                name: 'REB',
                value: pathOr('-', [...path, 'rebounds'], seasonalStats)
              }, {
                name: 'AST',
                value: pathOr('-', [...path, 'assists'], seasonalStats)
              }, {
                name: 'STL',
                value: pathOr('-', [...path, 'steals'], seasonalStats)
              }]
            
            //   setseasonStats(opp_data);
            //   setLoader(false);
        }else{
            const path = ['total'];
            return  [{
                name: 'PTS',
                value: pathOr('-', [...path, 'points'], seasonalStats)
              }, {
                name: 'REB',
                value: pathOr('-', [...path, 'rebounds'], seasonalStats)
              }, {
                name: 'AST',
                value: pathOr('-', [...path, 'assists'], seasonalStats)
              }, {
                name: 'STL',
                value: pathOr('-', [...path, 'steals'], seasonalStats)
              }]
              
                // setseasonStats(total_data);
                // setLoader(false);
        }
    }
    //POSITION TEAM 
  
        return [{
        name: isNFL ? 'TDS' : 'AVG',
        value: isNFL ? pathOr('-', ['record', 'touchdowns', 'total'], seasonalStats) : pathOr('-', ['statistics', 'hitting', 'overall', 'avg'], seasonalStats)
      }, {
        name: isNFL ? 'Pass TDS' : 'HR',
        value: isNFL ? pathOr('-', ['record', 'touchdowns', 'pass'], seasonalStats) : pathOr('-', ['statistics', 'hitting', 'overall', 'onbase', 'hr'], seasonalStats)
      }, {
        name: isNFL ? 'Rush TDS' : 'RBI',
        value: isNFL ? pathOr('-', ['record', 'touchdowns', 'rush'], seasonalStats) : pathOr('-', ['statistics', 'hitting', 'overall', 'rbi'], seasonalStats)
      }, {
        name: isNFL ? 'INT TDS' : 'OBP',
        value: isNFL ? pathOr('-', ['record', 'touchdowns', 'int_return'], seasonalStats) : pathOr('-', ['statistics', 'hitting', 'overall', 'obp'], seasonalStats)
      }];
      
    //   setseasonStats(other_data);
    //   setLoader(false);
   
}