import React,{Fragment,useEffect,useState} from 'react'
import {Spinner ,Modal} from 'react-bootstrap';
import axios from "./Utility/axios";
import request from "./Utility/Request";
import {successToast,errorToast} from "./Utility/toastify";
import { Link } from "react-router-dom";
import { ToastContainer} from 'react-toastify';
import {  propOr, pathOr} from "ramda";

const Subscription = () => {
const [subscripedPlan,setsubscripedPlan] = useState([])
const [issubscripedPlan,setissubscripedPlan] = useState()
const [payment, setpayment] = useState(false);
const [loader, setLoader] = useState(true);
const [showModal,setShowModal] = useState(false);
const [cancelingPlan,setCancelingPlan] = useState("")

let user_id =localStorage.getItem("user_id");
  

    useEffect(() => {
      getUser();
    }, [])
    
     const getUser = async () => {
        setLoader(true)
       setTimeout(async () => {
         let sendheaders = {
           headers: {
             Authorization: "Bearer " + localStorage.getItem("tokens"),
           },
         };
         try {
           const response = await axios.get(request.GET_USER_DATA, sendheaders);
           if (response) {
              //  if(response.data.success){
             setsubscripedPlan(response.data.subscribedPlans);   
             localStorage.setItem("userDetail", JSON.stringify(response.data));
              setLoader(false);
           }
          //}
         } catch (err) {}
       }, 1500);
     };

    const cancleSubscription = async (plan) => {;
       let data = { "subscription_planId": plan, user_id: user_id };
       let sendheaders = {
         headers: {
           Authorization: "Bearer " + localStorage.getItem("tokens"),
         },
       };
        try{
            const response = await axios.post(request.CANCELSUBSCRIPTION,data,sendheaders);
            if(response){
                if(response.data.status === "failure"){
                    errorToast(response.data.message);
                    getUser();
                }else{
                    successToast(response.data.message)
                    setShowModal(false);
                    setCancelingPlan("")
                    setissubscripedPlan("")

                    getUser();
                }
            }
            }catch(err){
            console.log(err);
        }
    }
    return (
      <Fragment>
        {/* === BREADCRUMB ====*/}
        <section className="brd-cmb  p-50">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="./">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  subscription
                </li>
              </ol>
            </nav>
          </div>
        </section>

        {/*=== SUBSCRIBTIONS PROFILE ====*/}
        <section className="active_subscription_plan pb-100">
          <div className="container">
            {!loader ? (
              <div className="active_sub_plan">
                {subscripedPlan.map((plans, i) =>
                  plans[1] === false ? (
                    <div className="sub_plan">
                      <p>{plans[0]}</p>
                      {/* <button className='common-btn btn-danger'>Cancel cancleSubscription(plans[0]) Subscription</button>setCancelingPlan */}
                      {plans[0] !== "draft-cheat-sheet" && (
                        <span onClick={() =>{setCancelingPlan(plans[0]);setShowModal(true);setissubscripedPlan(plans[0])}}>
                          <i className="far fa-times-circle"></i> Cancel Subscription
                        </span>
                      )}
                    </div>
                  ) : (
                    "No Subscribed Plans"
                  )
                )}
              </div>
            ) : (
              <div className={loader ? "sipnner-wrap" : "d-none"}>
                <Spinner animation="border" />
              </div>
            )}
            {!loader && subscripedPlan.length === 0 && (
              <div className="active_sub_plan">
                <div className="sub_plan">
                  <p>"No Subscribed Plans Yet"</p>
                  <Link to="/pricing" className="common-btn view-more">
                    Please Subscribe
                  </Link>
                </div>
              </div>
            )}
          </div>
        </section>

        <Modal
        show={showModal}
        //onHide={}
        size="lg"
        centered
        keyboard={false}
        className="confirmation-modal"
      >
        <Modal.Body>
          <div className='confirmation-wrapper'>
            <div className='icon'>
            <i class="fas fa-exclamation"></i>
            </div>
           <h4>Are you sure you want to cancel your <br></br> <b>" {cancelingPlan} " </b>subscription ?</h4>
          <div className='button-wrapper'>
            <button className='cancel-btn' onClick={()=>{setShowModal(false);setCancelingPlan("");}}>Cancel</button>
            <button className='yes-btn'  onClick={()=>{cancleSubscription(issubscripedPlan)}}>Yes Unsubscribe</button>
           </div>
          </div>   
        </Modal.Body>
      </Modal>

        <ToastContainer autoClose={2000} />
      </Fragment>
    );
}

export default Subscription
