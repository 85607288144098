import {pathOr,isEmpty,propOr,map,has} from 'ramda';
import moment from 'moment';
import 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import momentTimezone from 'moment';


const hitters = ['3B', 'CF', 'SS', '2B', 'C', '1B', 'RF', 'LF', 'DH', 'OF']
const pitchers = ['P', 'SP', 'RP']

const getColor = (profilesLength) => {
        switch (profilesLength) {
          case 1:
            return 'rgba(242, 0, 65, 1)'
            break;
          case 2:
            return 'rgba(1, 9, 44, 1)'
            break;
          case 3:
            return 'rgba(49, 121, 50, 1)'
            break;
        }
      }    

export const getFormattedTrends = (response, playerPosition, type, profilesLength ,name ,selectGameType) => {
  

    const gameSummary = pathOr([], ['data', 'response'], response);
    const pitcherPath = [type, 'statistics', 'pitching', 'overall']
    const hitterPath = [type, 'statistics', 'hitting', 'overall']
    const passingPath = ['statistics', 'passing']
    const rushingPath = ['statistics', 'rushing']
    const receivingPath = ['statistics', 'receiving']
    const defensePath = ['statistics', 'defense']
    const fieldgoalsPath = ['statistics', 'field_goals']
    const extrapointsPath = ['statistics', 'extra_points']
    const passingPathteam = ['statistics', 'passing', 'totals']
    const rushingPathteam = ['statistics', 'rushing', 'totals']
    const receivingPathteam = ['statistics', 'receiving', 'totals']
    const defensePathteam = ['statistics', 'defense', 'totals']
    const fieldgoalsPathteam = ['statistics', 'field_goals', 'totals']
    const extrapointsPathteam = ['statistics', 'extra_points', 'kicks', 'totals']
    const dates = [0]
    gameSummary.slice(-10).map((game) => {
      dates.push(dates[dates.length - 1] + 1);
      return moment(propOr('', 'scheduled', game)).format('M-DD');
    })
    
    let isNFL = false;
    const isNBA = selectGameType ==='nba'
    map((game)=> {isNFL = has('summary', game)}, gameSummary);
  
    
    if (pitchers.includes(playerPosition)) {
      const ERAstat = gameSummary.slice(-10).map((game) => pathOr('', [...pitcherPath, 'era'], game))
      const SOstat = gameSummary.slice(-10).map((game) => pathOr('', [...pitcherPath, 'outs', 'ktotal'], game))
      const WHIPstat = gameSummary.slice(-10).map((game) => pathOr('', [...pitcherPath, 'whip'], game))
  
      ERAstat.splice(0, 0, '0')
      SOstat.splice(0, 0, '0')
      WHIPstat.splice(0, 0, '0')
  
      return {
        dates,
        profileNameColor:
        {
          name,
          color: getColor(profilesLength),
        },
        trends: {
          ERA: ERAstat,
          SO: SOstat,
          WHIP: WHIPstat,
        }
      }
    }
    if (hitters.includes(playerPosition)) {
      const Hstat = gameSummary.slice(-10).map((game) => pathOr('', [...hitterPath, 'onbase', 'h'], game))
      const RBIstat = gameSummary.slice(-10).map((game) => pathOr('', [...hitterPath, 'rbi'], game))
      const HRstat = gameSummary.slice(-10).map((game) => pathOr('', [...hitterPath, 'onbase', 'hr'], game))
      Hstat.splice(0, 0, '0')
      RBIstat.splice(0, 0, '0')
      HRstat.splice(0, 0, '0')
      return {
        dates,
        profileNameColor:
        {
          name,
          color: getColor(profilesLength),
        },
        trends: {
          H: Hstat,
          RBI: RBIstat,
          HR: HRstat
        }
      }
    }
    if('QB'=== playerPosition){
      const TDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...passingPath, 'knockdowns'], game))
      const YDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...passingPath, 'yards'], game))
      const QBRstat = gameSummary.slice(-10).map((game) => pathOr('', [...passingPath, 'rating'], game))
  
      TDSstat.splice(0, 0, '0')
      YDSstat.splice(0, 0, '0')
      QBRstat.splice(0, 0, '0')
    
      return {
        dates,
        profileNameColor:
        {
          name,
          color: getColor(profilesLength),
        },
        trends: {
          TDS: TDSstat,
          YDS: YDSstat,
          QBR: QBRstat
        }
      }
    }
    if('RB'===playerPosition){
      const TDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...rushingPath, 'touchdowns'], game))
      const YDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...rushingPath, 'yards'], game))
      const AVGstat = gameSummary.slice(-10).map((game) => pathOr('', [...rushingPath, 'avg_yards'], game))
      TDSstat.splice(0, 0, '0')
      YDSstat.splice(0, 0, '0')
      AVGstat.splice(0, 0, '0')
      
      return {
        dates,
        profileNameColor:
        {
          name,
          color: getColor(profilesLength),
        },
        trends: {
          TDS: TDSstat,
          YDS: YDSstat,
          AVG: AVGstat
        }
      }
    }
    if('WR'===playerPosition){
      const TDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...receivingPath, 'touchdowns'], game))
      const YDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...receivingPath, 'yards'], game))
      const RECstat = gameSummary.slice(-10).map((game) => pathOr('', [...receivingPath, 'receptions'], game))
      TDSstat.splice(0, 0, '0')
      YDSstat.splice(0, 0, '0')
      RECstat.splice(0, 0, '0')
      return {
        dates,
        profileNameColor:
        {
          name,
          color: getColor(profilesLength),
        },
        trends: {
          TDS:TDSstat,
          YDS:YDSstat,
          REC:RECstat
        }
      }
    }
    if('TE'===playerPosition){
      const TDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...receivingPath, 'touchdowns'], game))
      const YDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...receivingPath, 'yards'], game))
      const RECstat = gameSummary.slice(-10).map((game) => pathOr('', [...receivingPath, 'receptions'], game))
      TDSstat.splice(0, 0, '0')
      YDSstat.splice(0, 0, '0')
      RECstat.splice(0, 0, '0')
      return {
        dates,
        profileNameColor:
        {
          name,
          color: getColor(profilesLength),
        },
        trends: {
          TDS: TDSstat,
          YDS: YDSstat,
          REC: RECstat
        }
      }
    }
    if('K'===playerPosition){
      const FGstat = gameSummary.slice(-10).map((game) => pathOr('', [...fieldgoalsPath, 'pct'], game))
      const XPstat = gameSummary.slice(-10).map((game) => pathOr('', [...extrapointsPath, 'pct'], game))
      const Pstat = gameSummary.slice(-10).map((game) => pathOr('', [...fieldgoalsPath, 'pct'], game))
      FGstat.splice(0, 0, '0')
      XPstat.splice(0, 0, '0')
      Pstat.splice(0, 0, '0')
      return {
        dates,
        profileNameColor:
        {
          name,
          color: getColor(profilesLength),
        },
        trends: {
          FG:FGstat,
          XP:XPstat,
          P: Pstat
        }
      }
    }
    if('D'===playerPosition){
      const TUMstat = gameSummary.slice(-10).map((game) => pathOr('', [...defensePath, 'interceptions'], game)+pathOr('', [...defensePath, 'fumble_recoveries'], game))
      const INTstat = gameSummary.slice(-10).map((game) => pathOr('', [...defensePath, 'interceptions'], game))
      const FRstat = gameSummary.slice(-10).map((game) => pathOr('', [...defensePath, 'fumble_recoveries'], game))
      
      TUMstat.splice(0, 0, '0')
      INTstat.splice(0, 0, '0')
      FRstat.splice(0, 0, '0')
      return {
        dates,
        profileNameColor:
        {
          name,
          color: getColor(profilesLength),
        },
        trends: {
          TUM:TUMstat,
          INT:INTstat,
          FR: FRstat
        }
      }
    }
  
    if(isNFL && type === 'team'){
      const PTDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...passingPathteam, 'touchdowns'], game))
      const PYDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...passingPathteam, 'yards'], game))
      const PQBRstat = gameSummary.slice(-10).map((game) => pathOr('', [...passingPathteam, 'rating'], game))
      PTDSstat.splice(0, 0, '0')
      PYDSstat.splice(0, 0, '0')
      PQBRstat.splice(0, 0, '0')
  
      const RTDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...rushingPathteam, 'touchdowns'], game))
      const RYDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...rushingPathteam, 'yards'], game))
      const RAVGstat = gameSummary.slice(-10).map((game) => pathOr('', [...rushingPathteam, 'avg_yards'], game))
      RTDSstat.splice(0, 0, '0')
      RYDSstat.splice(0, 0, '0')
      RAVGstat.splice(0, 0, '0')
  
      const RETDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...receivingPathteam, 'touchdowns'], game))
      const REYDSstat = gameSummary.slice(-10).map((game) => pathOr('', [...receivingPathteam, 'yards'], game))
      const RERECstat = gameSummary.slice(-10).map((game) => pathOr('', [...receivingPathteam, 'receptions'], game))
      RETDSstat.splice(0, 0, '0')
      REYDSstat.splice(0, 0, '0')
      RERECstat.splice(0, 0, '0')
  
      const DTUMstat = gameSummary.slice(-10).map((game) => pathOr(0, [...defensePathteam, 'interceptions'], game)+pathOr(0, [...defensePathteam, 'fumble_recoveries'], game))
      const DINTstat = gameSummary.slice(-10).map((game) => pathOr('', [...defensePathteam, 'interceptions'], game))
      const DFRSstat = gameSummary.slice(-10).map((game) => pathOr('', [...defensePathteam, 'fumble_recoveries'], game))
      DTUMstat.splice(0, 0, 0)
      DINTstat.splice(0, 0, '0')
      DFRSstat.splice(0, 0, '0')
  
      const FFGstat = gameSummary.slice(-10).map((game) => pathOr('', [...fieldgoalsPathteam, 'pct'], game))
      const FXPstat = gameSummary.slice(-10).map((game) => pathOr('', [...extrapointsPathteam, 'pct'], game))
      const FPstat = gameSummary.slice(-10).map((game) => pathOr(0, [...fieldgoalsPathteam, 'made'], game)+pathOr(0, [...extrapointsPathteam, 'made'], game))
      FFGstat.splice(0, 0, '0')
      FXPstat.splice(0, 0, '0')
      FPstat.splice(0, 0, 0)
  
      return {
        passing: {
          dates,
          profileNameColor:
          {
            name,
            color: getColor(profilesLength),
          },
          trends: {
            TDS: PTDSstat,
            YDS: PYDSstat,
            QBR: PQBRstat
          }
        },
        rushing: {
          dates,
          profileNameColor:
          {
            name,
            color: getColor(profilesLength),
          },
          trends: {
            TDS: RTDSstat,
            YDS: RYDSstat,
            AVG: RAVGstat
          }
        },
        receiving: {
          dates,
          profileNameColor:
          {
            name,
            color: getColor(profilesLength),
          },
          trends: {
            TDS: RETDSstat,
            YDS: REYDSstat,
            REC: RERECstat
          }
        },
        defense: {
          dates,
          profileNameColor:
          {
            name,
            color: getColor(profilesLength),
          },
          trends: {
            TUM: DTUMstat,
            INT: DINTstat,
            FRS: DFRSstat
          }
        },
        field_goals: {
          dates,
          profileNameColor:
          {
            name,
            color: getColor(profilesLength),
          },
          trends: {
            FG: FFGstat,
            XP: FXPstat,
            P: FPstat
          }
        }
      }
    }
  
    if(isNBA){
      if(type === 'team'){
        const PTSstat = gameSummary.slice(-10).map((game) => pathOr('', ['statistics', 'points'], game))
        const REBstat = gameSummary.slice(-10).map((game) => pathOr('', ['statistics', 'rebounds'], game))
        const ASTstat = gameSummary.slice(-10).map((game) => pathOr('', ['statistics', 'assists'], game))
        PTSstat.splice(0, 0, '0')
        REBstat.splice(0, 0, '0')
        ASTstat.splice(0, 0, '0')
  
        return {
          nba: {
            dates,
            profileNameColor:
            {
              name,
              color: getColor(profilesLength),
            },
            trends: {
              PTS: PTSstat,
              REB: REBstat,
              AST: ASTstat
            }
          },
        }
      }else{
        const PTSstat = gameSummary.slice(-10).map((game) => pathOr('', ['statistics', 'points'], game))
        const REBstat = gameSummary.slice(-10).map((game) => pathOr('', ['statistics', 'rebounds'], game))
        const ASTstat = gameSummary.slice(-10).map((game) => pathOr('', ['statistics', 'assists'], game))
        PTSstat.splice(0, 0, '0')
        REBstat.splice(0, 0, '0')
        ASTstat.splice(0, 0, '0')
        
        return {
          dates,
          profileNameColor:
          {
            name,
            color: getColor(profilesLength),
          },
          trends: {
            PTS: PTSstat,
            REB: REBstat,
            AST: ASTstat
          }
        }
      }
    }
    const Hstat = gameSummary.slice(-10).map((game) => pathOr('', [...hitterPath, 'onbase', 'h'], game))
    const RBIstat = gameSummary.slice(-10).map((game) => pathOr('', [...hitterPath, 'rbi'], game))
    const HRstat = gameSummary.slice(-10).map((game) => pathOr('', [...hitterPath, 'onbase', 'hr'], game))
    Hstat.splice(0, 0, '0')
    RBIstat.splice(0, 0, '0')
    HRstat.splice(0, 0, '0')
  
    const ERAstat = gameSummary.slice(-10).map((game) => pathOr('', [...pitcherPath, 'era'], game))
    const SOstat = gameSummary.slice(-10).map((game) => pathOr('', [...pitcherPath, 'outs', 'ktotal'], game))
    const WHIPstat = gameSummary.slice(-10).map((game) => pathOr('', [...pitcherPath, 'whip'], game))
    ERAstat.splice(0, 0, '0')
    SOstat.splice(0, 0, '0')
    WHIPstat.splice(0, 0, '0')
    return {
      hitting: {
        dates,
        profileNameColor:
        {
          name,
          color: getColor(profilesLength),
        },
        trends: {
          H: Hstat,
          RBI: RBIstat,
          HR: HRstat
        }
      },
      pitching: {
        dates,
        profileNameColor:
        {
          name,
          color: getColor(profilesLength),
        },
        trends: {
          ERA: ERAstat,
          SO: SOstat,
          WHIP: WHIPstat,
        }
      },
  
  
    };
  };
  