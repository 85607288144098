import React,{Fragment,useState}from 'react'
import {FaLock,FaRegEye,FaRegEyeSlash } from 'react-icons/fa';
import axios from "./Utility/axios";
import request from "./Utility/Request"
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import {successToast,errorToast} from "./Utility/toastify";
import { ToastContainer} from 'react-toastify';
import {Link} from "react-router-dom";

const ChangePassword = () => {
    const [oldpasswordShown, setOldPasswordShown] = useState(false);
    const toggleOldPassword = () => {setOldPasswordShown(!oldpasswordShown); };

    const [newpasswordShown, setNewPasswordShown] = useState(false);
    const toggleNewPassword = () => {setNewPasswordShown(!newpasswordShown); };  

    const [cfnnewpasswordShown, setCfnNewPasswordShown] = useState(false);
    const toggleCfnNewPassword = () => {setCfnNewPasswordShown(!cfnnewpasswordShown); };  


const changePasswordValidation = Yup.object({
    oldpassword: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
    newpassword: Yup.string().required('New Password is required'),
    confirmpassword:Yup.string().required('Confirm Password is required').oneOf([Yup.ref('newpassword')], 'Passwords must match With New Password')
    }).required();
    


   // CHANGE PASSWORD FORM 
const {
    register,
    handleSubmit,
    formState: { errors },

  } = useForm({ resolver: yupResolver(changePasswordValidation) });
  const changePasswordSubmit = (data) => {
      let sendheaders = {
        headers: {
          Authorization: "Bearer "+ request.Token,
        },
      };
      const chnagePassword = async(data) => {
      try{
            const response = await axios.post(request.UPDATE_USER_PASSWORD,data,sendheaders);
            if(response){
            
              const message = response.data.message;
               if(response.data.status === "fail"){
                errorToast(message);     
               }else{
                successToast(message)
               } 
              
            }else{
              const message = response.data.message;
              errorToast(message);
            }
      }catch(err){
        if(!err.respsonse){  
          errorToast(err.message);
        }
      }
    }
    chnagePassword(data);
  }; 


    return (
        <Fragment>
        {/* === BREADCRUMB ====*/} 
        <section className="brd-cmb  p-100">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="./">Home</Link></li>
                        <li className="breadcrumb-item"> <Link to="/profile">Profile</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Change Password</li>
                    </ol>
                </nav>
            </div>
        </section>

        {/* ===== UPDATE PROFILE ==== */}
        <section className="update-profile change-password pb-100">
            <div className="container">
                <form action="" onSubmit={handleSubmit(changePasswordSubmit)}>
                    <div>
                        <div className="input-group mb-3">
                            <span className="input-group-text"><FaLock/></span>
                            <input
                            type={oldpasswordShown ? "text" : "password"} 
                            className="form-control"
                            placeholder="old Password"
                            name="oldpassword"
                            {...register("oldpassword")}
                            
                            />
                            <span
                            toggle="#password"
                    
                            className="toggle-password"
                            onClick={toggleOldPassword}> 
                            {oldpasswordShown ? <FaRegEye/> :<FaRegEyeSlash/>}
                            </span>
                        </div>  
                        <span className="error">{errors.oldpassword?.message}</span>
                     </div>     

                     <div>
                     <div className="input-group mb-3">
                         <span className="input-group-text"><FaLock/></span>
                        <input
                        type={newpasswordShown ? "text" : "password"} 
                        className="form-control"
                        placeholder="New Password"
                        name="newpassword" 
                        {...register("newpassword")}
                        />
                        <span
                        toggle="#password"
                
                        className="toggle-password"
                        onClick={toggleNewPassword}> 
                        {newpasswordShown ? <FaRegEye/> :<FaRegEyeSlash/>}
                        </span>
                    </div> 
                    <span className="error">{errors.newpassword?.message}</span>
                    </div>  

                    <div>
                    <div className="input-group mb-3">
                    <span className="input-group-text"><FaLock/></span>
                    <input
                    type={cfnnewpasswordShown ? "text" : "password"} 
                    className="form-control"
                    placeholder="Confirm Password"
                    name="confirmpassword" 
                    {...register("confirmpassword")}
                    />
                   <span
                   toggle="#password"
            
                   className="toggle-password"
                   onClick={toggleCfnNewPassword}> 
                   {cfnnewpasswordShown ? <FaRegEye/> :<FaRegEyeSlash/>}
                   </span>   
                 </div> 
                 <span className="error">{errors.confirmpassword?.message}</span> 
                 </div>
               
              

                    <button className="common-btn with-border">update password </button>
                    
                </form>
            </div>
        </section>
    
        <ToastContainer autoClose={2000}/>
        </Fragment>
    )
}

export default ChangePassword
