const Reducer = (state, action) => {
    switch (action.type) {
      case "LOGIN":
        return {
          ...state,
          login: action.payload,
        };
      case "TOKEN":
        return {
          ...state,
          token: action.payload,
        };
      case "SUBSCRIPTION_PLAN": {
        return {
          ...state,
          subscriptionPlan: action.payload,
        };
      }
      case "CATEGORY_TYPE": {
        return {
          ...state,
          category_type: action.payload,
        };
      }
      case "OPEN_MODAL": {
        return {
          ...state,
          openModal: action.payload,
        };
      }

      case "OPEN_LOGIN_MODAL": {
        return {
          ...state,
          openLoginModal: action.payload,
        };
      }

      case "PLAYERS": {
        return {
          ...state,
          player_1: action.payload,
        };
      }

      case "CHARTDATA": {
        return {
          ...state,
          chartData: action.payload,
        };
      }

      case "NEWS": {
        return {
          ...state,
          news: action.payload,
        };
      }

      case "ADD_PLAYER_LIST": {
        return {
          ...state,
          add_Player_List: action.payload,
        };
      }

      case "ODD_AMOUNT": {
        return {
          ...state,
          oddAmount: action.payload,
        };
      }

      case "USER_SETTING": {
        return {
          ...state,
          user_setting: action.payload,
        };
      }

      case "TOTAL_POINT": {
        return {
          ...state,
          total_points: action.payload,
        };
      }

      case "MY_TEAM_PLAYER": {
        return {
          ...state,
          my_team_player: action.payload,
        };
      }
      case "SEASON_EXCLUSIVE_DATA_1": {
        return {
          ...state,
          seasonExclusiveData1: action.payload,
        };
      }

      case "SEASON_EXCLUSIVE_DATA_1_DEMO": {
        return {
          ...state,
          seasonExclusiveData1demo: action.payload,
        };
      }

      case "SEASON_EXCLUSIVE_DATA_2": {
        return {
          ...state,
          seasonExclusiveData2: action.payload,
        };
      }

      case "SEASON_EXCLUSIVE_DATA_2_DEMO": {
        return {
          ...state,
          seasonExclusiveData2demo: action.payload,
        };
      }

      case "SEASON_EXCLUSIVE_DATA_3": {
        return {
          ...state,
          seasonExclusiveData3: action.payload,
        };
      }

      case "SEASON_EXCLUSIVE_DATA_3_DEMO": {
        return {
          ...state,
          seasonExclusiveData3demo: action.payload,
        };
      }

      case "PLAYER_TEAM_INFO": {
        return {
          ...state,
          playerteamInfo: action.payload,
        };
      }
      case "MY_TEAM_UPATED": {
        return {
          ...state,
          myteam: action.payload,
        };
      }

      case "TEAM_PLAYER_SELECTED": {
        return {
          ...state,
          teamPlayerSelected: action.payload,
        };
      }

      case "NFL_OPTIMAL_RANKING": {
        return {
          ...state,
          nfloptimalranking: action.payload,
        };
      }

      default:
        return state;
    }
};

export default Reducer;
